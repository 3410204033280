import { createSelector } from '@ngrx/store';
import { RevenueCatPackageDescriptionsUserFriendly } from '@frontend/data-access/subscription';
import { timeFeature } from '@frontend/data-access/time';
import { differenceInWeeks, isSameDay } from 'date-fns';
import { selectBreeds } from '@frontend/data-access/contentful';
import { differenceInDays } from 'date-fns/differenceInDays';
import { LoadingState } from '@frontend/data-access/shared-models';
import { APP_VERSION_NUMBER } from '@shared/constants';
import { householdFeature } from './household.reducer';
import { mapDateOfBirthToAgeInDays } from '@frontend/utility/angular';

export const selectPartner = createSelector(householdFeature.selectPartners, (partners) => {
    if (!partners || partners.length === 0) {
        return undefined;
    }

    return {
        partnerName: partners[0].name,
        partnerId: partners[0].id,
        freeAccessLengthInDays: partners[0].freeAccessLengthInDays,
        userFriendlyAccessLength:
            RevenueCatPackageDescriptionsUserFriendly.get(partners[0].freeAccessLengthInDays) || '',
    };
});

export const selectAgeInDays = createSelector(
    householdFeature.selectDateOfBirth,
    timeFeature.selectToday,
    (date, today) => {
        if (!date) {
            return undefined;
        }

        return mapDateOfBirthToAgeInDays(date, today);
    },
);

export const selectAgeInWeeks = createSelector(
    householdFeature.selectDateOfBirth,
    timeFeature.selectToday,
    (date, today) => {
        if (!date) {
            return undefined;
        }

        return differenceInWeeks(today!, date);
    },
);

export const selectAgeInWeeksAtOnboarding = createSelector(
    householdFeature.selectDateOfBirth,
    householdFeature.selectDateOfOnboardingOrToday,
    (dateOfBirth, onboardingDate) => {
        if (!dateOfBirth || !onboardingDate) {
            return 1;
        } else if (onboardingDate < dateOfBirth) {
            return 0;
        }

        return differenceInWeeks(onboardingDate, dateOfBirth);
    },
);

export const selectOnboardedToday = createSelector(
    timeFeature.selectToday,
    householdFeature.selectDateOfOnboardingOrToday,
    (today, dateOfOnboarding) => {
        if (dateOfOnboarding) {
            return isSameDay(today, dateOfOnboarding);
        }

        return true;
    },
);

export const selectProfileBreedInfo = createSelector(householdFeature.selectBreedId, selectBreeds, (breedId, breeds) =>
    breeds.find((b) => b.id === breedId),
);

export const selectBreedName = createSelector(selectProfileBreedInfo, (breedInfo) => breedInfo?.name);

// Is the pup currently at home
export const selectHasArrived = createSelector(
    householdFeature.selectHasArrivedOrUndefined,
    (hasArrived) => (hasArrived === undefined ? true : hasArrived), // assume puppy has arrived for users with NULL field in database
);

// Was the pup home at the day of onboarding
export const selectHasArrivedAtOnboarding = createSelector(
    householdFeature.selectDateOfArrival,
    householdFeature.selectDateOfOnboardingOrToday,
    (dateOfArrival, dateOfOnboarding) => {
        if (dateOfArrival) {
            return differenceInDays(dateOfOnboarding, dateOfArrival) >= 0;
        }

        return false;
    },
);

// Did the pup arrive at the exact date of onboarding
export const selectHasArrivedOnDateOfOnboarding = createSelector(
    householdFeature.selectDateOfArrival,
    householdFeature.selectDateOfOnboardingOrToday,
    (dateOfArrival, dateOfOnboarding) => {
        if (dateOfArrival) {
            return isSameDay(dateOfArrival, dateOfOnboarding);
        }

        return false;
    },
);

export const selectIsHouseholdRequestInProgress = createSelector(householdFeature.selectHouseholdState, (state) => {
    return (
        state.getHouseholdLoadingState === LoadingState.LOADING ||
        state.createHouseholdLoadingState === LoadingState.LOADING ||
        state.patchDogLoadingState === LoadingState.LOADING ||
        state.patchHouseholdUserLoadingState === LoadingState.LOADING
    );
});

export const selectGetOrCreateHouseholdIsCompleted = createSelector(householdFeature.selectHouseholdState, (state) => {
    return (
        state.getHouseholdLoadingState === LoadingState.LOADED ||
        state.createHouseholdLoadingState === LoadingState.LOADED
    );
});

export const selectOwnerAndDogName = createSelector(
    householdFeature.selectOwnerName,
    householdFeature.selectDogName,
    (ownerName, dogName) => {
        return { ownerName, dogName };
    },
);

export const selectConfigCatUserProperties = createSelector(
    householdFeature.selectCurrentUser,
    householdFeature.selectCurrentDog,
    (user, dog) => {
        return {
            identifier: user?.id,
            email: user?.mail,
            country: user?.countryCode,
            custom: {
                ...(dog?.name && { dogName: dog?.name }),
                appVersionNumber: APP_VERSION_NUMBER,
                ...(user?.dateOfOnboarding && {
                    dateOfOnboarding: user.dateOfOnboarding.getTime().toString(),
                }),
            },
        };
    },
);
