import { LoadingState } from '@frontend/data-access/shared-models';
import { Breed, Gender } from '@shared/user-domain';
import { createSelector } from '@ngrx/store';
import { ExpectedWeightQuery, FoodPortionQuery } from '../food-calculator.model';
import { foodCalculatorFeature } from './food-calculator.reducer';
import { householdFeature, selectAgeInWeeks, selectProfileBreedInfo } from '@frontend/data-access/user/household';

export const selectFoodProductsLoading = createSelector(
    foodCalculatorFeature.selectFoodProductsLoadingState,
    (foodProductsLoadingState) => foodProductsLoadingState === LoadingState.LOADING,
);

export const selectFoodPortionLoading = createSelector(
    foodCalculatorFeature.selectFoodPortionLoadingState,
    (foodPortionLoadingState) => foodPortionLoadingState === LoadingState.LOADING,
);

export const selectFoodPortionQueryWithAgeInWeeks = createSelector(
    foodCalculatorFeature.selectFoodPortionQuery,
    selectAgeInWeeks,
    (foodPortionQuery, profileAgeInWeeks): Partial<FoodPortionQuery> | undefined => {
        return {
            ...foodPortionQuery,
            ageInWeeks: profileAgeInWeeks ?? foodPortionQuery.ageInWeeks,
        };
    },
);

export const selectExpectedWeight = createSelector(
    selectFoodPortionQueryWithAgeInWeeks,
    (query) => query?.expectedWeight,
);

export const selectExpectedWeightQuery = createSelector(
    householdFeature.selectGender,
    selectProfileBreedInfo,
    (gender, breedInfo): ExpectedWeightQuery | undefined => {
        if (!(gender && breedInfo)) {
            return undefined;
        }
        return {
            breed: Object.keys(Breed)[Object.values(Breed).indexOf(breedInfo.name as Breed)] as keyof typeof Breed,
            gender: Object.keys(Gender)[Object.values(Gender).indexOf(gender)] as keyof typeof Gender,
        };
    },
);

export const selectFoodCalculatorVm = createSelector(
    foodCalculatorFeature.selectFoodProducts,
    foodCalculatorFeature.selectFoodPortion,
    foodCalculatorFeature.selectFoodPortionLastCalculated,
    selectFoodProductsLoading,
    selectFoodPortionLoading,
    selectFoodPortionQueryWithAgeInWeeks,
    (
        foodProducts,
        foodPortion,
        foodPortionLastCalculated,
        foodProductsLoading,
        foodPortionLoading,
        foodPortionQuery,
    ) => ({
        foodProductsLoading,
        foodProducts,
        foodPortionLoading,
        foodPortion: foodPortion,
        foodPortionLastCalculated,
        foodPortionQuery,
    }),
);
