import { chatFeature } from '@frontend/data-access/chat';
import { keyboardFeature } from '@frontend/data-access/keyboard';
import { platformFeature } from '@frontend/data-access/platform';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { createSelector } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { selectContentUnlocked } from '../../store/payment/store/payment.selectors';
import { selectQueryParamOptionId } from '../../store/router/router.selectors';
import { selectAiChatSuggestedQuestionsCardData } from './chat-intro/store/ai-chat-intro.selectors';
import { aiChatFeature } from './store/ai-chat.reducer';
import { selectIsUsUser, selectOwnerAndDogName } from '@frontend/data-access/user/household';

const selectAiChatPageChannelInfo = createSelector(
    chatFeature.selectChannelInitialized,
    chatFeature.selectChannelMessageCount,
    (channelInitialized, channelMessageCount) => {
        return {
            channelInitialized,
            channelEmpty: channelMessageCount <= 0,
        };
    },
);

const selectAiChatPageUserInfo = createSelector(
    selectOwnerAndDogName,
    selectIsUsUser,
    ({ ownerName, dogName }, isUsUser) => ({
        ownerName,
        dogName,
        isUsUser,
    }),
);

const selectAiChatZiggyOneFreeQuestionInfo = createSelector(
    aiChatFeature.selectQuestionsRemaining,
    selectQueryParamOptionId,
    (questionsRemaining, initialOptionId) => {
        return {
            questionsRemaining,
            initialOptionId,
        };
    },
);

export const selectPlatformInfo = createSelector(
    platformFeature.selectPlatformIsIos,
    keyboardFeature.selectIsKeyboardOpen,
    (isIos, isKeyboardOpen) => {
        return {
            isIos,
            isKeyboardOpen,
        };
    },
);

export const selectAiChatPageVm = createSelector(
    selectAiChatPageUserInfo,
    selectAiChatPageChannelInfo,
    selectAiChatSuggestedQuestionsCardData,
    aiChatFeature.selectDidAnswerTimeOut,
    subscriptionFeature.selectHasHistoricalPurchase,
    selectAiChatZiggyOneFreeQuestionInfo,
    selectContentUnlocked,
    selectPlatformInfo,
    (
        user,
        chat,
        suggestedQuestionsCards,
        didAnswerTimeOut,
        hasHistoricalPurchase,
        oneFreeQuestionInfo,
        contentUnlocked,
        platformInfo,
    ) => {
        const errorMessageProps = {
            backgroundColor: Color.Luna,
            textColor: Color.Max,
        };

        return {
            user,
            chat,
            suggestedQuestionsCards,
            errorMessageProps,
            didAnswerTimeOut,
            hasHistoricalPurchase,
            oneFreeQuestionInfo,
            contentUnlocked,
            platformInfo,
        };
    },
);
