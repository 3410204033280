import { Course } from '@frontend/data-access/contentful';
import { Step, StepStatus } from '../../store/step/step.model';
import { CourseSplit } from '../models/courses.models';

export const getSplitsForCourse = (course: Course, stepsWithPractice: Step[]): CourseSplit[] => {
    const fullCourseSplits = [0, ...course.splits]; // hardcode the always active first split that hangs below course card

    const practiceStepPositions = stepsWithPractice
        .filter((step) => step.progress.isPractice)
        .map((step) => stepsWithPractice.indexOf(step));

    const adjustedSplits = adjustSplitsForPractice([...fullCourseSplits], practiceStepPositions).filter(
        // we spread the splits to avoid mutating the original array
        (split) => split < stepsWithPractice.length,
    );

    const stepIdsBySplit = sliceStepsForCourseSplits(fullCourseSplits, course.stepIds);

    return mapSplitIndexesToCourseSplits(adjustedSplits, stepsWithPractice, stepIdsBySplit);
};

export const adjustSplitsForPractice = (courseSplits: number[], practiceSteps: number[]): number[] => {
    const result: number[] = [];

    const moveFutureSplitPositions = (splitIndex: number) => {
        for (let i = splitIndex + 1; i < courseSplits.length; i++) {
            courseSplits[i]++;
        }
    };

    courseSplits.forEach((split, splitIndex) => {
        practiceSteps.forEach((practiceIndex) => {
            const nextSplit = courseSplits[splitIndex + 1];
            const isInSplit = split <= practiceIndex && practiceIndex <= nextSplit;

            if (isInSplit) {
                // move all future split positions by 1
                moveFutureSplitPositions(splitIndex);
            }
        });
        result.push(split);
    });
    return result;
};

export const sliceStepsForCourseSplits = (courseSplits: number[], stepIds: string[]): string[][] => {
    if (courseSplits.length === 0) {
        return [stepIds];
    }

    if (courseSplits.length === 1) {
        if (courseSplits[0] === 0) {
            return [stepIds];
        }

        return [stepIds.slice(0, courseSplits[0]), stepIds.slice(courseSplits[0], stepIds.length)];
    }

    return courseSplits.map((splitPosition, index) => {
        const endPosition = courseSplits[index + 1];

        return stepIds.slice(splitPosition, endPosition);
    });
};

export const mapSplitIndexesToCourseSplits = (
    adjustedSplits: number[],
    stepsIncludingPractice: Step[],
    stepIdsBySplit: string[][],
): CourseSplit[] => {
    let currentCompletedSteps = 0;
    let currentTotalSteps = 0;
    const result: CourseSplit[] = [];

    adjustedSplits.forEach((splitPosition, index) => {
        const stepsInSplit = stepIdsBySplit[index].length;
        const numberOfCompletedSteps = stepsIncludingPractice.filter((step) =>
            isBaseStepWithinSplitComplete(step, stepIdsBySplit[index]),
        ).length;

        result.push({
            splitIndex: splitPosition,
            splitNumber: index + 1,
            totalSteps: stepsInSplit,
            numberOfCompletedSteps,
            active: index === 0 ? true : currentCompletedSteps >= currentTotalSteps,
        });

        currentCompletedSteps += numberOfCompletedSteps;
        currentTotalSteps += stepsInSplit;
    });

    return result;
};

export const isBaseStepWithinSplitComplete = (step: Step, stepIdsInSplit: string[]): boolean => {
    return step.progress.state !== StepStatus.INACTIVE && !step.progress.isPractice && stepIdsInSplit.includes(step.id);
};
