import { Color } from '@shared/utils/typescript';
import { NourishProductTile, NourishTile } from '../models/nourish.model';

// TODO: NBSon - evaluate ALL images in the app and ensure they are optimised, cropped and being used correctly
// we may want to unify these images somehow, e.g. have a file with the filename (src)/alt and unify language - may want to check with Marketing about alts
// we can also for the pups give them identities/personalities and use them consistently across the app

export enum NourishTileId {
    NUTRITIONAL_CONSULTATION = 'nutritional-consultation',
    WHAT_CAN_POOP_TELL_YOU = 'what-can-poop-tell-you',
    PREVENT_DIARRHOEA = 'prevent-diarrhoea',
    RIGHT_FOOD_CHOICE = 'right-food-choice',
    HOW_MUCH_EXERCISE = 'how-much-exercise',
    PUP_NOT_EATING = 'pup-not-eating',
    TUMMY_TROUBLES = 'tummy-troubles',
    WEIGH_YOUR_DOG = 'weigh-your-dog',
    SWITCH_FOOD = 'switch-food',
    WHY_NUTRITION_MATTERS = 'why-nutrition-matters',
    // These don't have modals associated with them
    CALCULATE_FOOD = 'calculate-food',
    FIND_FOOD = 'find-food',
}

export enum NourishModalId {
    NUTRITIONAL_CONSULTATION = 'nutritional-consultation-modal',
    WHAT_CAN_POOP_TELL_YOU = 'what-can-poop-tell-you-modal',
    PREVENT_DIARRHOEA = 'prevent-diarrhoea-modal',
    RIGHT_FOOD_CHOICE = 'right-food-choice-modal',
    HOW_MUCH_EXERCISE = 'how-much-exercise-modal',
    PUP_NOT_EATING = 'pup-not-eating-modal',
    TUMMY_TROUBLES = 'tummy-troubles-modal',
    WEIGH_YOUR_DOG = 'weigh-your-dog-modal',
    SWITCH_FOOD = 'switch-food-modal',
}

export enum NourishFoodFinderOriginId {
    NOURISH_PAGE_US = 'us-page',
    PREVENT_DIARRHOEA_MODAL = 'prevent-diarrhoea-modal',
    RIGHT_FOOD_CHOICE_MODAL = 'right-food-choice-modal',
    TUMMY_TROUBLES_MODAL = 'tummy-troubles-modal',
    SWITCH_FOOD_MODAL = 'switch-food-modal',
}

export const NOURISH_TAB_INDICATOR_ID = 'nourish-tab-indicator';

export const NOURISH_TILE_ANIMATION_ID = 'nourish-tile-animation';

export const NOURISH_WHY_NUTRITION_MATTERS_BRAIN_SNACK_TILE: NourishTile = {
    id: NourishTileId.WHY_NUTRITION_MATTERS,
    title: 'How nutrition shapes your pup’s training',
    pill: 'Brain snack',
    colour: Color.Sophie,
    backgroundColour: Color.Alvin,
    image: {
        src: 'assets/images/ziggy/ziggy-reading.svg',
        alt: 'Ziggy reading',
        height: '45px',
    },
};

export const NOURISH_WHAT_POOP_CAN_TELL_YOU_TILE: NourishTile = {
    id: NourishTileId.WHAT_CAN_POOP_TELL_YOU,
    title: 'What can poop tell you?',
    pill: 'Tips',
    colour: Color.Max,
    image: {
        src: '/assets/images/illustrations/nourish/dog-poop-inside-red-slipper.svg',
        alt: 'A dog poop inside a red slipper',
    },
};

export const NOURISH_PREVENT_DIARRHOEA_TILE: NourishTile = {
    id: NourishTileId.PREVENT_DIARRHOEA,
    title: 'How to prevent diarrhoea?',
    pill: 'Digestion',
    colour: Color.Alvin,
    image: {
        src: '/assets/images/buddy/buddy-diarrhoea.svg',
        alt: 'Buddy looks sad sitting next to diarrhoea',
    },
};

export const NOURISH_PREVENT_DIARRHOEA_TILE_US: NourishTile = {
    id: NourishTileId.PREVENT_DIARRHOEA,
    title: 'How to prevent diarrhea?',
    pill: 'Digestion',
    colour: Color.Alvin,
    image: {
        src: '/assets/images/buddy/buddy-diarrhoea.svg',
        alt: 'Buddy looks sad sitting next to diarrhea',
    },
};

export const NOURISH_RIGHT_FOOD_CHOICE_TILE: NourishTile = {
    id: NourishTileId.RIGHT_FOOD_CHOICE,
    title: 'Making the right food choice',
    pill: 'Nutrition',
    colour: Color.Harley,
    image: {
        src: '/assets/images/buddy/buddy-licks-food-from-treat-mat.svg',
        alt: 'Buddy licking food from a treat mat',
    },
};

export const NOURISH_HOW_MUCH_EXERCISE_TILE: NourishTile = {
    id: NourishTileId.HOW_MUCH_EXERCISE,
    title: 'How much exercise is enough?',
    pill: 'Exercise',
    colour: Color.Buddy,
    image: {
        src: '/assets/images/charly/charly-runs-with-snake-toy-cropped.svg',
        alt: 'Charly running playfully with a toy snake in his mouth',
    },
};

export const NOURISH_PUP_NOT_EATING_TILE: NourishTile = {
    id: NourishTileId.PUP_NOT_EATING,
    title: 'Why is my pup not eating?',
    pill: 'Feeding',
    colour: Color.Alvin,
    image: {
        src: '/assets/images/charly/charly-not-eating.svg',
        alt: 'Charly lays down looking sad next to his food bowl',
    },
};

export const NOURISH_TUMMY_TROUBLES_TILE: NourishTile = {
    id: NourishTileId.TUMMY_TROUBLES,
    title: 'Help with tummy troubles',
    pill: 'Digestion',
    colour: Color.Alvin,
    image: {
        src: '/assets/images/charly/charly-looks-at-poop.svg',
        alt: 'Charly sitting and looking at his poop with surprise',
    },
};

export const NOURISH_WEIGH_YOUR_DOG_TILE: NourishTile = {
    id: NourishTileId.WEIGH_YOUR_DOG,
    title: 'How to weigh your dog?',
    pill: 'Tips',
    colour: Color.Max,
    image: {
        src: '/assets/images/bailey/bailey-on-scales.svg',
        alt: 'Bailey sits on weighing scales',
    },
};

export const NOURISH_SWITCH_FOOD_TILE: NourishTile = {
    id: NourishTileId.SWITCH_FOOD,
    title: 'When and how to switch food',
    pill: 'Nutrition',
    colour: Color.Harley,
    image: {
        src: '/assets/images/oliver/oliver-drooling-food.svg',
        alt: 'Oliver drools next to a bag of dog food',
    },
};

export const NOURISH_INSIGHTS_TILES: NourishTile[] = [
    NOURISH_WHY_NUTRITION_MATTERS_BRAIN_SNACK_TILE,
    NOURISH_WHAT_POOP_CAN_TELL_YOU_TILE,
    NOURISH_PREVENT_DIARRHOEA_TILE,
    NOURISH_RIGHT_FOOD_CHOICE_TILE,
    NOURISH_HOW_MUCH_EXERCISE_TILE,
    NOURISH_PUP_NOT_EATING_TILE,
    NOURISH_TUMMY_TROUBLES_TILE,
    NOURISH_WEIGH_YOUR_DOG_TILE,
    NOURISH_SWITCH_FOOD_TILE,
];

export const NOURISH_INSIGHTS_TILES_US: NourishTile[] = [
    NOURISH_WHY_NUTRITION_MATTERS_BRAIN_SNACK_TILE,
    NOURISH_WHAT_POOP_CAN_TELL_YOU_TILE,
    NOURISH_PREVENT_DIARRHOEA_TILE_US,
    NOURISH_RIGHT_FOOD_CHOICE_TILE,
    NOURISH_HOW_MUCH_EXERCISE_TILE,
    NOURISH_PUP_NOT_EATING_TILE,
    NOURISH_TUMMY_TROUBLES_TILE,
    NOURISH_WEIGH_YOUR_DOG_TILE,
    NOURISH_SWITCH_FOOD_TILE,
];

export enum NourishProductId {
    LILYS_KITCHEN = 'lilys-kitchen',
    TAILS = 'tails',
    PURINA_PRO_PLAN = 'purina-pro-plan',
    NUTRITIONAL_REFERRAL = 'nutritional_referral',
}

export const NOURISH_CALCULATE_FOOD_TASK_TILE: NourishTile = {
    id: NourishTileId.CALCULATE_FOOD,
    title: `Calculate your pup's food portion`,
    pill: 'Task',
    colour: Color.Max,
    image: {
        src: 'assets/images/illustrations/food-calculator/food-bowl.png',
        alt: 'An illustration of a dog bowl with food in it',
    },
};

export const NOURISH_FIND_FOOD_TASK_TILE: NourishTile = {
    id: NourishTileId.FIND_FOOD,
    title: `Find [NAME]'s perfect food`,
    pill: 'Task',
    colour: Color.Max,
    image: {
        src: 'assets/images/oliver/oliver-drooling-food.svg',
        alt: 'Oliver drools next to a bag of dog food',
    },
};

export const NOURISH_LILY_KITCHEN_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.LILYS_KITCHEN,
    title: 'Natural ingredients',
    colour: Color.Alvin,
    image: {
        src: '/assets/images/third-party/lilys-kitchen/lilys-kitchen-logo-light.svg',
        alt: 'An image depicting the Lily\'s Kitchen logo with the tagline "Proper food for pets"',
    },
    //  TODO SJH: Temporarily commenting this out for the 30 day NR as a pick AB test
    // chip: '30% OFF',
};

export const NOURISH_TAILS_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.TAILS,
    title: '100% tailored to [NAME]',
    colour: Color.Harley,
    image: {
        src: '/assets/images/third-party/tails/tails-logo-light.svg',
        alt: 'An image depicting the Tails.com logo',
    },
    //  TODO SJH: Temporarily commenting this out for the 30 day NR as a pick AB test
    // chip: '75% OFF first box',
};

export const NOURISH_PURINA_PRO_PLAN_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.PURINA_PRO_PLAN,
    title: 'Advanced nutrition',
    colour: Color.Jack,
    image: {
        src: '/assets/images/third-party/purina/purina-pro-plan-logo.svg',
        alt: 'An image depicting the Purina Pro Plan logo',
    },
    //  TODO SJH: Temporarily commenting this out for the 30 day NR as a pick AB test
    // chip: 'FREE probiotics',
};

export const NUTRITIONAL_REFERRAL_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.NUTRITIONAL_REFERRAL,
    title: 'Nutritional consultation',
    colour: Color.Maggie,
    buttonLabelColour: Color.Jack,
    image: {
        src: '/assets/images/third-party/purina/powered-by-purina.svg',
        alt: 'An image depicting the Purina logo',
    },
    chip: `<img src="/assets/images/zigzag/z-white.svg" alt="The 'Z' character of the 'Zigzag' logo" class="generic-chip__image" /> Recommends`,
};

export const DEFAULT_NOURISH_PRODUCT_TILES: NourishProductTile[] = [
    NUTRITIONAL_REFERRAL_PRODUCT_TILE,
    NOURISH_TAILS_PRODUCT_TILE,
    NOURISH_PURINA_PRO_PLAN_PRODUCT_TILE,
    NOURISH_LILY_KITCHEN_PRODUCT_TILE,
];

export const LILYS_KITCHEN_ADULT_CTA_LINK =
    'https://www.lilyskitchen.co.uk/for-dogs/adult?dm_t=0%2C0%2C0%2C0%2C0&utm_campaign=crm_zigzag_30FD&utm_medium=app&utm_source=lilys_kitchen';
export const LILYS_KITCHEN_PUPPY_CTA_LINK =
    'https://www.lilyskitchen.co.uk/for-dogs/puppy/?utm_campaign=crm_zigzag_30FD&utm_medium=app&utm_source=lilys_kitchen&dm_t=0,0,0,0,0';

export const TAILS_ADULT_CTA_LINK =
    'https://tails.com/gb/lp/multi7525/?pc=ZIGZAG1&utm_medium=partnership&utm_source=zigzagadult&utm_campaign=75-25&utm_term=closedgroup&utm_content=incentive';
export const TAILS_PUPPY_CTA_LINK =
    'https://tails.com/gb/lp/multi7525/?pc=ZIGZAG75&utm_medium=partnership&utm_source=zigzagpuppy&utm_campaign=75-25&utm_term=closedgroup&utm_content=incentive';

export const PURINA_PRO_PLAN_CTA_LINK =
    'https://shop.purina.co.uk/referrals/zigzag.list?utm_source=referral&utm_medium=zigzag&utm_campaign=email';

export const PET_FOOD_FINDER_LINK =
    'https://www.purina.com/pet-food-finder-direct?utm_campaign=nbm-ecosystem&utm_medium=partnership&utm_source=zigzag&utm_content=cr-nbm_partnerships_pet_food_finder_zigzag_2025_pt-na&utm_term=';

export const NUTRITIONAL_CONSULTATION_A_LINK = 'https://d5ufz7yvzse.typeform.com/to/LU47m88V';
export const NUTRITIONAL_CONSULTATION_B_LINK = 'https://d5ufz7yvzse.typeform.com/to/BT7pFNvO';

export const NOURISH_PRODUCT_FEEDBACK_MODAL_ID = 'nourish-product-feedback-modal';
export const NOURISH_PRODUCT_FEEDBACK_CLOSE_DELAY = 1500;

export enum NourishProductFeedbackModalState {
    INITIAL = 'initial',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}
