import { Routes } from '@angular/router';
import { NotificationsPage } from './notifications/notifications.page';

const notificationsRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: NotificationsPage,
    },
];

export default notificationsRoutes;
