import { AsyncPipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { socialShare } from '@frontend/data-access/capacitor';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { GenericCardSlimComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { randomWithSeed } from '../../../store/app-state.utils';
import { setStepCompleted } from '../../../store/progress/step-progress/step-progress.actions';
import { launchTrainingReminderModal } from '../../../training-reminder/store/training-reminder-modal.actions';
import { selectSelectedLessonPagesVm } from '../lesson.page.selectors';

@Component({
    selector: 'app-lesson-completion-page',
    templateUrl: './lesson-completion-page.component.html',
    styleUrls: ['./lesson-completion-page.component.scss', '../lesson.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        IonContent,
        IonButton,
        IonFooter,
        AnalyticsTrackClickDirective,
        TextReplacementPipe,
        GenericCardSlimComponent,
    ],
})
export class LessonCompletionPage {
    private readonly store = inject(Store);
    private readonly navController = inject(NavController);
    private readonly route = inject(ActivatedRoute);

    public vm = this.store.selectSignal(selectSelectedLessonPagesVm);

    public totalSuccessAnimations = 11;
    public totalFailAnimations = 8;

    public awesomeText = `<p><strong>Wow! [NAME] is a superstar having aced [HIS/HER] training.</strong></p>
                        <p>The more successes you have and the older [NAME] gets, the more you can start to relax, but always start slowly,
                        and go at [HIS/HER] pace.</p>`;
    public noWorriesText = `<p>Hi [NAME] & [OWNERNAME], no worries! 87% of Zigzag users don't nail it at first, it's completely normal.
                           You will have some repetition tasks so no worries!</p>`;

    protected readonly Color = Color;

    getAnimationPath = computed(() => {
        const step = this.vm().step;

        if (!step) {
            return '';
        }

        const { rating } = this.vm().routeParams;

        const success: boolean = rating > 3;

        const { highRatingAnimation, lowRatingAnimation, id } = step.content;

        if (success && highRatingAnimation) {
            return highRatingAnimation;
        }

        if (!success && lowRatingAnimation) {
            return lowRatingAnimation;
        }

        return this.getRandomAnimation(success, id);
    });

    public setTrainingReminder(dogName: string): void {
        this.store.dispatch(launchTrainingReminderModal({ dogName, trigger: 'lesson-completion-page' }));
    }

    public share(): void {
        void this.store.dispatch(
            socialShare({
                message: `Woohoo! ${this.vm().dogName} just completed ${this.vm().step!.content.title} successfully on the Zigzag app!
         Join me on https://zigzg.dog`,
            }),
        );
    }

    public completeStep(): void {
        const step = this.vm().step;

        if (!step) {
            return;
        }

        const { fromPracticeStep, rating } = this.vm().routeParams;

        this.store.dispatch(
            setStepCompleted({
                properties: {
                    title: step.content.title,
                    stepId: step.id,
                    contentType: step.content.contentType,
                    contentId: step.content.id,
                    topicId: step.content.topic,
                    rating,
                    isPractice: fromPracticeStep,
                },
            }),
        );
        void this.navController.navigateBack(['../../../../'], { relativeTo: this.route, animated: false });
    }

    private getRandomAnimation(success: boolean, id: number): string {
        const totalAnimations: number = success ? this.totalSuccessAnimations : this.totalFailAnimations;
        const path: string = success ? 'success' : 'fail';
        const index = Math.ceil(randomWithSeed(id) * totalAnimations);

        return `assets/images/illustrations/step/${path}/animation_${path}_${index}.webp`;
    }
}
