import { createSelector } from '@ngrx/store';
import { authenticationFeature, mapAuthenticationError } from '@frontend/data-access/user/authentication';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { platformFeature } from '@frontend/data-access/platform';
import { householdFeature } from '@frontend/data-access/user/household';
import { LoadingState } from '@frontend/data-access/shared-models';

export const selectLoginPageVm = createSelector(
    authenticationFeature.selectLoginError,
    authenticationFeature.selectLoginLoading,
    householdFeature.selectCreateHouseholdLoadingState,
    selectIsInputOpenOnAndroid,
    platformFeature.selectPlatformIsIos,
    (loginError, loginLoading, createHouseholdLoadingState, isInputFocusedOnAndroid, isIos) => ({
        error: mapAuthenticationError(loginError?.code),
        isLoading: loginLoading || createHouseholdLoadingState === LoadingState.LOADING,
        isInputFocusedOnAndroid,
        isIos,
    }),
);
