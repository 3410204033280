import { createAction, props } from '@ngrx/store';
import { InternalSubscriptionDto } from '@shared/user-api-interface';
import { Entitlement, PurchaseProduct, SubscriptionOffering } from './models/subscription.model';

export const initializeSubscriptionService = createAction(
    '[Subscription] Initialize Subscription Service',
    props<{ id?: string; email?: string }>(),
);

export const initializeSubscriptionServiceSuccess = createAction(
    '[Subscription] Initialize Subscription Service Success',
    props<{ profileId: string; email?: string }>(),
);

export const initializeSubscriptionServiceFailure = createAction(
    '[Subscription] Initialize Subscription Service Failure',
    props<{ error: Error }>(),
);

export const logInToPurchases = createAction(
    '[Subscription] Log in to Purchases',
    props<{
        profileId: string;
        email: string;
    }>(),
);

export const logInToPurchasesSuccess = createAction(
    '[Subscription] Log in to Purchases Success',
    props<{
        data: {
            value: Entitlement[];
            hasHistoricalPurchase?: boolean;
        };
    }>(),
);

export const logInToPurchasesFailure = createAction(
    '[Subscription] Log in to Purchases Failure',
    props<{
        error: Error;
    }>(),
);

export const getEntitlements = createAction('[Subscription] Get Entitlements');

export const getEntitlementsSuccess = createAction(
    '[Subscription] Get Entitlements Success',
    props<{
        data: {
            value: Entitlement[];
            hasHistoricalPurchase?: boolean;
        };
    }>(),
);

export const getEntitlementsFailure = createAction(
    '[Subscription] Get Entitlements Failure',
    props<{
        error: Error;
    }>(),
);

export const GET_INITIAL_OFFERINGS_CORRELATION_ID = 'InitGetOfferings';

export type GetOfferingsCorrelationId = typeof GET_INITIAL_OFFERINGS_CORRELATION_ID;

export const getOfferings = createAction(
    '[Subscription] Get Offerings',
    props<{
        correlationId?: GetOfferingsCorrelationId;
    }>(),
);

export const getOfferingsSuccess = createAction(
    '[Subscription] Get Offerings Success',
    props<{
        data: {
            value: SubscriptionOffering[];
        };
        correlationId?: GetOfferingsCorrelationId;
    }>(),
);

export const getOfferingsFailure = createAction(
    '[Subscription] Get Offerings Failure',
    props<{
        error: Error;
    }>(),
);

export const purchaseProduct = createAction(
    '[Subscription] Purchase Product',
    props<{
        data: PurchaseProduct;
    }>(),
);

export const purchaseProductSuccess = createAction(
    '[Subscription] Purchase Product Success',
    props<{
        data: PurchaseProduct;
    }>(),
);

export const purchaseProductFailure = createAction(
    '[Subscription] Purchase Product Failure',
    props<{
        error: Error;
    }>(),
);

export const purchaseProductCancelled = createAction(
    '[Subscription] Purchase Product Cancelled',
    props<{
        data: PurchaseProduct;
    }>(),
);

export const restoreSubscription = createAction('[Subscription] Restore Subscription');

export const presentPromoCodePrompt = createAction('[Subscription] Present Promo Code Prompt');

export const getActiveSubscription = createAction('[Subscription] Get Active Subscription');

export const getActiveSubscriptionSuccess = createAction(
    '[Subscription] Get Active Subscription Success',
    props<{
        data: {
            value: InternalSubscriptionDto;
        };
    }>(),
);

export const getActiveSubscriptionFailure = createAction(
    '[Subscription] Get Active Subscription Failure',
    props<{
        error: Error;
    }>(),
);
