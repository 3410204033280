<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>Is {{ dogName() }} already at home?</h1>
        </section>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--alvin"
            appTrackClick
            identifier="question-has-arrived-true-btn"
            expand="block"
            data-test="question-has-arrived-true-btn"
            [disabled]="isLoading()"
            (click)="onHasArrivedClick(true)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Yes }
        </ion-button>
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--alvin"
            appTrackClick
            identifier="question-has-arrived-false-btn"
            expand="block"
            data-test="question-has-arrived-false-btn"
            [disabled]="isLoading()"
            (click)="onHasArrivedClick(false)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { No }
        </ion-button>
    </div>
</ion-footer>
