import { Component, inject, Input } from '@angular/core';
import type { DailyBite } from '@frontend/data-access/contentful';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { HeaderComponent } from '@frontend/feature/header';
import { AuthorChipComponent } from '@frontend/ui/author-chip';
import { RichContentPipe } from '@frontend/ui/pipes';
import { ModalService } from '@frontend/utility/modal';
import { IonContent, IonHeader, IonToolbar } from '@ionic/angular/standalone';

@Component({
    selector: 'app-daily-bite-modal',
    templateUrl: './daily-bite-modal.component.html',
    styleUrl: './daily-bite-modal.component.scss',
    imports: [
        IonContent,
        AuthorChipComponent,
        RichContentPipe,
        TextReplacementPipe,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class DailyBiteModalComponent {
    public readonly modalService = inject(ModalService);
    @Input({ required: true }) dailyBite!: DailyBite;

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
