import { UserFeedbackContent } from '../models/user-feedback-content.model';

export class UserFeedbackContentMock {
    private defaultValue: UserFeedbackContent = {
        id: '1',
        userId: '1',
        timestamp: 1234567890,
        contentfulId: '1',
        contentTypeId: '1',
        thumbsDown: false,
        thumbsUp: true,
        comment: 'Test comment',
    };

    constructor(overrides?: Partial<UserFeedbackContent>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): UserFeedbackContent {
        return this.defaultValue;
    }
}
