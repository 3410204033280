@for (card of cards(); track card.title; let cardIndex = $index) {
    <div class="creating-plan-card-container">
        @if (cardSteps[cardIndex].showIconAndHeading) {
            <i class="creating-plan-card-container__location-icon fa-solid fa-location-dot"></i>

            <div class="creating-plan-card">
                <div class="creating-plan-card__heading">
                    <label class="creating-plan-card__title zz-label">{{ card.title }}</label>
                    @if (cardSteps[cardIndex].isLoading) {
                        <ion-spinner class="creating-plan-card__spinner" name="crescent" />
                    }
                </div>

                @for (row of card.rows; track rowIndex; let rowIndex = $index) {
                    @if (cardSteps[cardIndex].visibleRows > rowIndex) {
                        <div class="creating-plan-card__main">
                            <div class="creating-plan-card__row">
                                @for (item of row.items; track item.text; let itemIndex = $index) {
                                    <div class="creating-plan-card__item">
                                        <i
                                            [class]="'creating-plan-card__icon fa-regular ' + row.items[itemIndex].icon"
                                        ></i>
                                        <p>{{ row.items[itemIndex].text }}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                }
            </div>
        }
    </div>
}
