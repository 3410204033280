@if (vm()) {
<ion-header class="today-page-header">
    <ion-toolbar class="today-page-header__toolbar" [color]="Color.AppOutline" />
</ion-header>

<ion-content class="today-page page-ion-content" [fullscreen]="true">
    <app-today-developmental-window
        [@collapseAnimation]="vm().showAiChatView ? 'collapse' : 'expand'"
        class="today-page__developmental-window"
    />
    <app-ai-chat-header
        [@fadeInAnimation]="vm().showAiChatView ? 'in' : 'out'"
        class="today-page__ai-chat-header"
        appTrackClick
        identifier="today-ai-chat-input-back-btn"
        (backClicked)="onAiChatBackClicked()"
    />
    <app-today-ai-chat-input
        #aiChatInput
        class="today-page__ai-chat-input"
        appTrackClick
        identifier="today-ai-chat-input"
        [showSendButton]="!!vm().showAiChatView"
        (inputClicked)="onAiChatClicked(!!vm().showAiChatView)"
        (submitted)="onAiChatSubmitted($event)"
    />

    <div class="text-block" [style.display]="vm().showAiChatView ? 'none' : 'block'">
        @if(filteredCourses().length) {
        <h2 class="title" data-test="today-page-recommended-courses-title">Picked for {{ vm().userInfo.dogName }}</h2>
        <app-slider [gap]="12">
            @for (tile of filteredCourses(); track tile.id) { @if (tile.tileType === tileType.SEASONAL_TOPIC) {
            <app-topic-tile [topic]="tile"></app-topic-tile>
            } @else {
            <app-course-tile [tile]="tile" [attr.data-test]="'today-page-recommended-course-tile-' + tile.id" />
            } }
        </app-slider>
        }
        <app-arrival-tile />
        <app-pinned-topic-overview />
        <h2 class="no-margin-bottom">{{ 'Common questions for pups like ' + vm().userInfo.dogName }}</h2>
        <app-slider [gap]="12">
            <app-suggested-ai-chat-questions
                #suggestedQuestionsPrompt
                [suggestedQuestionsCards]="vm().suggestedQuestions"
                (promptClicked)="onAiChatPromptClicked($event)"
            />
            @for (tile of vm().tiles; track tile.title) {
            <app-generic-card-square
                appTrackClick
                [identifier]="tile.contentUnlocked ? 'today-page-' + tile.cardName + '-tile': 'today-page-' + tile.cardName + '-tile-locked'"
                [title]="tile.title"
                [description]="tile.description"
                [imageUrl]="tile.imageUrl"
                [background]="tile.backgroundColor"
                [unlocked]="tile.contentUnlocked"
                [isBasicPlan]="vm().isBasicPlan"
                [attr.data-test]="tile.contentUnlocked ? 'today-page-' + tile.cardName + '-tile': 'today-page-' + tile.cardName + '-tile-locked'"
                (click)="onCardClick(tile.contentUnlocked, tile.cardName, !vm().isStreamChatBusyCleaningUp)"
            />
            }
        </app-slider>
        <app-today-daily-bites />
    </div>
</ion-content>
}
