import { createFeature, createReducer, on } from '@ngrx/store';
import { showNourishModal } from './nourish-modal/nourish-modal.actions';

export interface UserFeedbackContentState {
    currentInsightsModalId: string;
}

export const initialState: UserFeedbackContentState = {
    currentInsightsModalId: '',
};

export const nourishFeature = createFeature({
    name: 'nourish',
    reducer: createReducer(
        initialState,
        on(showNourishModal, (state, { id }): UserFeedbackContentState => {
            return {
                ...state,
                currentInsightsModalId: id,
            };
        }),
    ),
});
