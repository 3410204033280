<ion-content [color]="Color.Maggie">
    <section class="pup-happiness-modal">
        <button
            class="zz-button xl pup-happiness-modal__close"
            appTrackClick
            identifier="pup-happiness-modal-close-btn"
            data-test="pup-happiness-modal-close-btn"
            (click)="buttonClicked('Close Modal')"
        >
            <i class="fa-light fa-xmark zz-button xxxl pup-happiness-modal__close__icon"></i>
        </button>

        <h2 class="pup-happiness-modal__title l" data-test="pup-happiness-modal-title">Daily happiness check</h2>
        <img
            class="pup-happiness-modal__dog-playing hide-on-small-screen"
            [src]="'/assets/images/charly/charly-plays-with-treat-toy.svg'"
            [alt]="'A puppy playing with a treat toy'"
        />

        <form
            class="pup-happiness-modal__feedback-container"
            id="dailyPupHappinessForm"
            [formGroup]="dailyPupHappinessForm"
            (ngSubmit)="submitForm()"
        >
            <section>
                <h3 class="pup-happiness-modal__feedback-heading">
                    How much joy & satisfaction did you feel with {{ dogName() }} yesterday?
                </h3>
                <ion-range
                    class="pup-happiness-modal__range"
                    formControlName="ownerHappinessScore"
                    [min]="0"
                    [max]="10"
                    [value]="this.defaultValue"
                    [snaps]="true"
                    [ticks]="false"
                />
                <div class="pup-happiness-modal__label-container">
                    <label><i class="fa-regular fa-face-frown-open"></i> Very unhappy</label>
                    <label>Very happy <i class="fa-regular fa-face-laugh"></i></label>
                </div>
            </section>

            <section>
                <h3 class="pup-happiness-modal__feedback-heading">
                    How happy do you think {{ dogName() }} was yesterday?
                </h3>
                <ion-range
                    class="pup-happiness-modal__range"
                    formControlName="pupHappinessScore"
                    [min]="0"
                    [max]="10"
                    [value]="this.defaultValue"
                    [snaps]="true"
                    [ticks]="false"
                />
                <div class="pup-happiness-modal__label-container">
                    <label><i class="fa-regular fa-face-frown-open"></i> Very unhappy</label>
                    <label>Very happy <i class="fa-regular fa-face-laugh"></i></label>
                </div>
            </section>
        </form>

        <p class="pup-happiness-modal__subtitle s" data-test="pup-happiness-modal-subtitle">
            Tracking your dog's mood state can help you both build a better relationship.
        </p>

        <section class="pup-happiness-modal__button-container">
            <ion-button
                class="pup-happiness-modal__submit-button"
                expand="block"
                form="dailyPupHappinessForm"
                type="submit"
                data-test="pup-happiness-modal-submit-btn"
                >Submit
            </ion-button>

            <button
                class="zz-button"
                appTrackClick
                identifier="pup-happiness-modal-dont-show-again-btn"
                data-test="pup-happiness-modal-dont-show-again-btn"
                (click)="dontShowAgain()"
            >
                Don't show this again
            </button>
        </section>
    </section>
</ion-content>
