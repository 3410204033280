import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import {
    getEntitlementsSuccess,
    getOfferings,
    purchaseProductSuccess,
    subscriptionFeature,
} from '@frontend/data-access/subscription';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PaymentModalLimitedOfferComponent } from '../../../payment-modal/payment-modal-limited-offer/payment-modal-limited-offer.component';
import { PaymentModalParentComponent } from '../../../payment-modal/payment-modal-parent/payment-modal-parent.component';
import { PaymentModalTrialReminderComponent } from '../../../payment-modal/payment-modal-trial-reminder/payment-modal-trial-reminder.component';
import { PaymentModalComponent } from '../../../payment-modal/payment-modal.component';
import {
    PAYMENT_MODAL_ID,
    PAYMENT_MODAL_LIMITED_OFFER_ID,
    PAYMENT_MODAL_PLAN_COMPARISON_ID,
} from '../../../payment-modal/payment-modal.constant';
import { selectBasicOfferingProductCards } from '../../../payment-modal/payment-modal.selectors';
import { closePaymentModalClicked, showPaymentModal, showPaymentModalLimitedOffer } from './payment.actions';
import {
    selectContentUnlocked,
    selectIsBasicPlan,
    selectIsEligibleForLimitedOfferPaymentModal,
    selectLimitedOfferProductCardsBasedOnTieredPricingAb,
} from './payment.selectors';
import { combineLatest, skipWhile } from 'rxjs';
import { getConfigCatFeatureFlagsSuccess, selectIsShowPaywallOnAppOpenAb } from '@frontend/data-access/user/config-cat';
import { changeAppReadyStatus } from '../../app-component/app-component.actions';
import { AppReadyStatus } from '../../app-component/app-ready-status.model';
import { SHOW_LIMITED_OFFER_MODAL_TRIGGERS, SHOW_PAYMENT_MODAL_TRIGGERS } from '../constants/payment.constants';
import { getHouseholdSuccess, householdFeature } from '@frontend/data-access/user/household';

@Injectable()
export class PaymentEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    showPaymentModalParent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showPaymentModal),
            concatLatestFrom(() => [
                this.store.select(subscriptionFeature.selectHasHistoricalPurchase),
                this.store.select(selectIsBasicPlan),
            ]),
            filter(
                ([{ trigger, showDiscountProducts }, hasHistoricalPurchase, isBasicPlan]) =>
                    trigger === SHOW_PAYMENT_MODAL_TRIGGERS.ON_APP_OPEN_AB_TEST ||
                    !!showDiscountProducts ||
                    !!hasHistoricalPurchase ||
                    !!isBasicPlan,
            ),
            tap(([{ trigger, showDiscountProducts }]) => {
                void this.modalService.showModal({
                    id: PAYMENT_MODAL_ID,
                    component: PaymentModalComponent,
                    componentProps: {
                        pageComponent: PaymentModalParentComponent,
                        showDiscountProducts,
                        trigger,
                    },
                    cssClass: ['modal', 'modal-fullscreen'],
                    showBackdrop: false,
                });
            }),
            switchMap(([{ trigger, showDiscountProducts }, hasHistoricalPurchase]) => [
                getOfferings({}),
                trackEvent({
                    eventName: 'payment-modal-initiated',
                    eventProperties: {
                        trigger,
                        showDiscountProducts: showDiscountProducts ?? false,
                        hasHistoricalPurchase,
                    },
                }),
            ]),
        );
    });

    showPaymentModalTrialReminder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showPaymentModal),
            concatLatestFrom(() => [
                this.store.select(subscriptionFeature.selectHasHistoricalPurchase),
                this.store.select(selectIsBasicPlan),
            ]),
            filter(
                ([{ trigger, showDiscountProducts }, hasHistoricalPurchase, isBasicPlan]) =>
                    trigger !== SHOW_PAYMENT_MODAL_TRIGGERS.ON_APP_OPEN_AB_TEST &&
                    !showDiscountProducts &&
                    !hasHistoricalPurchase &&
                    !isBasicPlan,
            ),
            tap(([{ trigger, showDiscountProducts }]) => {
                void this.modalService.showModal({
                    id: PAYMENT_MODAL_ID,
                    component: PaymentModalComponent,
                    componentProps: {
                        pageComponent: PaymentModalTrialReminderComponent,
                        trigger,
                        showDiscountProducts,
                    },
                    cssClass: ['modal', 'modal-fullscreen'],
                    showBackdrop: false,
                    /**
                     * NBSon - this is used in combination with the `ionViewWillEnter` and `ionViewWillLeave` lifecycle
                     * to prevent the user from being able to dismiss the modal through the back gesture/Android back button
                     * see {@link PaymentModalComponent.ionViewWillEnter}
                     */
                    backdropDismiss: false,
                });
            }),
            switchMap(([{ trigger, showDiscountProducts }, hasHistoricalPurchase]) => [
                getOfferings({}),
                trackEvent({
                    eventName: 'payment-modal-initiated',
                    eventProperties: {
                        trigger,
                        showDiscountProducts: showDiscountProducts ?? false,
                        hasHistoricalPurchase,
                    },
                }),
            ]),
        );
    });

    closePaymentModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(purchaseProductSuccess, getEntitlementsSuccess, closePaymentModalClicked),
                tap(() => {
                    void this.modalService.dismissById(PAYMENT_MODAL_ID, {
                        data: { dismissed: true },
                    });
                    void this.modalService.dismissById(PAYMENT_MODAL_LIMITED_OFFER_ID, {
                        data: { dismissed: true },
                    });
                    void this.modalService.dismissById(PAYMENT_MODAL_PLAN_COMPARISON_ID, {
                        data: { dismissed: true },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showLimitedOfferModalWhenClosingPaymentModalParent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(closePaymentModalClicked),
            concatLatestFrom(() => this.store.select(selectIsEligibleForLimitedOfferPaymentModal)),
            filter(([_, isEligible]) => isEligible),
            map(() => {
                return showPaymentModalLimitedOffer({
                    trigger: SHOW_LIMITED_OFFER_MODAL_TRIGGERS.CLOSING_PARENT_PAYMENT_MODAL,
                });
            }),
        );
    });

    showPaymentModalLimitedOffer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showPaymentModalLimitedOffer),
            concatLatestFrom(() => {
                return [
                    this.store.select(selectLimitedOfferProductCardsBasedOnTieredPricingAb),
                    this.store.select(selectBasicOfferingProductCards),
                ];
            }),
            filter(([_, limitedOfferProducts]) => limitedOfferProducts.length > 0),
            map(([{ trigger }, limitedOfferProducts, basicProducts]) => {
                const shownProducts = limitedOfferProducts.filter((product) => product.offerEndDate);
                return {
                    shownProducts,
                    limitedOfferProducts: limitedOfferProducts,
                    basicProducts: basicProducts,
                    trigger,
                };
            }),
            tap((props) => {
                void this.modalService.showModal({
                    id: PAYMENT_MODAL_LIMITED_OFFER_ID,
                    component: PaymentModalLimitedOfferComponent,
                    componentProps: props,
                    cssClass: ['modal', 'modal-fullscreen'],
                    showBackdrop: false,
                });
            }),
            switchMap((props) => {
                return [
                    getOfferings({}),
                    trackEvent({
                        eventName: '[Payment] Payment Modal Limited Offer Initiated',
                        eventProperties: props,
                    }),
                ];
            }),
        );
    });

    showPaymentModalOnAppOpen$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getHouseholdSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
            this.actions$.pipe(ofType(changeAppReadyStatus), take(2)),
        ]).pipe(
            skipWhile(([, , { status }]) => status !== AppReadyStatus.ready),
            concatLatestFrom(() => [
                this.store.select(selectIsShowPaywallOnAppOpenAb),
                this.store.select(selectContentUnlocked),
                this.store.select(householdFeature.selectDateOfOnboarding),
                this.store.select(selectIsEligibleForLimitedOfferPaymentModal),
            ]),
            filter(
                ([_, isShowPaywallOnAppOpenAb, isContentUnlocked, dateOfOnboarding]) =>
                    isShowPaywallOnAppOpenAb && !isContentUnlocked && !!dateOfOnboarding,
            ),
            map(([_, , , , isEligibleForLimitedOfferPaymentModal]) => {
                if (isEligibleForLimitedOfferPaymentModal) {
                    return showPaymentModalLimitedOffer({
                        trigger: SHOW_LIMITED_OFFER_MODAL_TRIGGERS.ON_APP_OPEN_AB_TEST,
                    });
                } else {
                    return showPaymentModal({ trigger: SHOW_PAYMENT_MODAL_TRIGGERS.ON_APP_OPEN_AB_TEST });
                }
            }),
        );
    });
}
