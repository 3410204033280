<ion-content class="page-ion-content">
    <ion-header>
        <ion-toolbar class="nourish-page__toolbar" [color]="Color.Bailey" />
    </ion-header>
    @if (isUsUser()) {
    <app-nourish-header-us />
    } @else {
    <app-nourish-header />
    }
    <section class="nourish-page__content" [class.nourish-page__content--us]="isUsUser()">
        <section>
            <h3 class="no-margin">Your insights</h3>
            <ui-slider class="nourish-page__insights-slider" [gap]="12" [lastItemPadding]="20">
                @for (tile of insightsTiles(); track tile.title) {
                <app-nourish-tile
                    [class.nourish-tile--animated]="tile.id === NourishTileId.WHY_NUTRITION_MATTERS && shouldShowAnimation()"
                    [id]="tile.id"
                    [title]="tile.title"
                    [pill]="tile.pill"
                    [image]="tile.image"
                    [colour]="tile.colour"
                    [backgroundColour]="tile.backgroundColour"
                />
                }
            </ui-slider>
        </section>

        @if (isGBUser()) {
        <app-nourish-product-tiles [productTiles]="productTiles()" />
        }

        <section>
            <h3 class="no-margin">Find answers for growing pups</h3>
            <ui-slider [gap]="20" [lastItemPadding]="20">
                @for (tile of supportTiles(); track tile.title) {
                <ui-slider-item>
                    @if (isTodayGenericCardSquare(tile)) {
                    <ui-generic-card-square
                        class="nourish-page__support-tile"
                        appTrackClick
                        [identifier]="tile.contentUnlocked ? 'nourish-page-' + tile.cardName + '-tile': 'nourish-page-' + tile.cardName + '-tile-locked'"
                        [title]="tile.title"
                        [description]="tile.description"
                        [imageUrl]="tile.imageUrl"
                        [background]="tile.backgroundColor"
                        [unlocked]="tile.contentUnlocked"
                        [isBasicPlan]="isBasicPlan()"
                        (click)="onCardClick(tile.contentUnlocked, tile.cardName)"
                    />
                    } @if (isChatTileTopic(tile)) {
                    <ui-option-selector-square
                        #chatTile
                        class="nourish-page__chat-tile"
                        [title]="tile.title"
                        [background]="tile.colour"
                        [imageUrl]="tile.image"
                        [options]="tile.chatTileContent"
                        (selectedChange)="onChatTileTopicClick($event)"
                    />
                    }
                </ui-slider-item>
                }
            </ui-slider>
        </section>
    </section>
</ion-content>
