import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { tabClicked } from '../../../tabs/store/tab.actions';
import { TabRoute_NOURISH } from '../../../tabs/tabs.constants';
import {
    nourishProductCtaClicked,
    nourishProductTileClicked,
    nourishSuggestedAiChatQuestionClicked,
    nourishTileClicked,
} from '../nourish.actions';

@Injectable()
export class NourishTrackingEffects {
    private readonly actions$ = inject(Actions);

    trackNourishTabClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tabClicked),
            filter(({ id }) => id === TabRoute_NOURISH),
            map(() => {
                return trackEvent({
                    eventName: '[Nourish] Tab Clicked',
                });
            }),
        );
    });

    trackNourishTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(({ id }) => {
                return trackEvent({
                    eventName: '[Nourish] Tile Clicked',
                    eventProperties: {
                        tileId: id,
                    },
                });
            }),
        );
    });

    trackNourishProductTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            map(({ id, correlationId }) => {
                return trackEvent({
                    eventName: '[Nourish] Product Tile Clicked',
                    eventProperties: {
                        productId: id,
                        origin: correlationId,
                    },
                });
            }),
        );
    });

    trackNourishProductCtaClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            map(({ id, url }) => {
                return trackEvent({
                    eventName: '[Nourish] Product CTA Clicked',
                    eventProperties: {
                        productId: id,
                        url,
                    },
                });
            }),
        );
    });

    trackSuggestedAiChatQuestionClick$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishSuggestedAiChatQuestionClicked),
            map(({ option, card }) => {
                return trackEvent({
                    eventName: '[Nourish] Ai Chat Suggested Question Clicked',
                    eventProperties: { option, card },
                });
            }),
        );
    });
}
