<div class="datetime">
    <ion-datetime-button class="datetime-button-new-onboarding" [datetime]="id()" [disabled]="isLoading()" />
    <i class="datetime__calendar-icon fa-regular fa-calendar"></i>
</div>

<ion-modal
    #datetimeModal
    [id]="'datetime-modal-' + id()"
    [keepContentsMounted]="true"
    [backdropDismiss]="false"
    (didPresent)="onModalDidPresent()"
    (didDismiss)="onModalDidDismiss()"
>
    <ng-template>
        <ion-datetime
            [id]="id()"
            [presentation]="presentation()"
            [showDefaultButtons]="true"
            [max]="maxDate()"
            [min]="minDate()"
            [value]="defaultValue()"
            (ionChange)="onDatetimeSelect($event)"
        />
    </ng-template>
</ion-modal>
