import { createSelector } from '@ngrx/store';
import {
    createDevelopmentalWindowInfoAccordions,
    getDevelopmentalWindowSubtitles,
    getDevelopmentalWindowTimelineProgressPercentage,
} from './developmental-window-timeline.utils';
import { developmentalWindowsFeature } from '@frontend/data-access/contentful';
import {
    householdFeature,
    selectAgeInDays,
    selectAgeInWeeks,
    selectHasArrived,
} from '@frontend/data-access/user/household';

export const selectDevelopmentalWindowTimelineVm = createSelector(
    developmentalWindowsFeature.selectDevelopmentalWindows,
    selectAgeInDays,
    selectAgeInWeeks,
    selectHasArrived,
    householdFeature.selectDogName,
    (developmentalWindows, ageInDays, ageInWeeks, hasArrived, dogName) => {
        return {
            developmentalWindows: developmentalWindows.map((developmentalWindow, index) => {
                return {
                    ...developmentalWindow,
                    subtitle: getDevelopmentalWindowSubtitles(
                        developmentalWindow,
                        index === 0,
                        ageInWeeks,
                        ageInDays,
                        hasArrived,
                        dogName,
                    ),
                    accordions: createDevelopmentalWindowInfoAccordions(developmentalWindow),
                };
            }),
            progressPercentage: getDevelopmentalWindowTimelineProgressPercentage(ageInDays),
            ageInDays,
        };
    },
);
