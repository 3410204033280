import { Household } from '../models/household.model';
import { Dog } from '../models/dog.model';
import { upsertArrayItem } from '@shared/utils/typescript';
import { HouseholdUser } from '../models/household-user.model';
import { PublicUser } from '../models/public-user.model';
import { InternalHouseholdDto, InternalHouseholdUserDto, InternalPublicUserDto } from '@shared/user-api-interface';

export function upsertDogInHousehold(household: Household | undefined, dog: Dog): Household | undefined {
    if (!household) {
        return undefined;
    }
    return {
        ...household,
        dogs: upsertArrayItem(household.dogs, [dog], (existingEntry, newEntry) => existingEntry.id === newEntry.id),
    };
}

export function upsertUserInHousehold(household: Household | undefined, user: HouseholdUser): Household | undefined {
    if (!household) {
        return undefined;
    }
    return {
        ...household,
        currentUser: user,
        users: upsertArrayItem(
            household.users,
            [mapHouseholdUserToPublicUser(user)],
            (existingEntry, newEntry) => existingEntry.id === newEntry.id,
        ),
    };
}

export function mapInternalHouseholdDtoToHousehold(household: InternalHouseholdDto): Household {
    return {
        ...household,
        currentUser: mapInternalHouseholdUserDtoToHouseholdUser(household.currentUser),
        users: household.users.map((user) => mapInternalPublicUserDtoToPublicUser(user)),
    };
}

export function mapInternalHouseholdUserDtoToHouseholdUser(user: InternalHouseholdUserDto): HouseholdUser {
    return {
        ...user,
        dateOfOnboarding: user.dateOfOnboarding ? new Date(user.dateOfOnboarding) : undefined,
    };
}

export function mapInternalPublicUserDtoToPublicUser(user: InternalPublicUserDto): PublicUser {
    return {
        ...user,
        dateOfOnboarding: user.dateOfOnboarding ? new Date(user.dateOfOnboarding) : undefined,
    };
}

export function mapHouseholdUserToPublicUser(user: HouseholdUser): PublicUser {
    return {
        id: user.id,
        mail: user.mail,
        name: user.name,
        dateOfOnboarding: user.dateOfOnboarding,
    };
}
