import { inject, Injectable } from '@angular/core';
import { getGeoLocationSuccess } from '@frontend/data-access/geo-location';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { zip } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { setAnalyticsProfile } from '@frontend/data-access/analytics';
import { selectAnalyticsProfile } from '../../analytics/analytics.selectors';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from './account.constants';
import { getHouseholdSuccess, householdFeature, patchHouseholdUser } from '@frontend/data-access/user/household';

@Injectable()
export class UpdateAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    patchCountryCode$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getHouseholdSuccess)),
            this.actions$.pipe(ofType(getGeoLocationSuccess)),
        ]).pipe(
            concatLatestFrom(() => {
                return [
                    this.store.select(selectAnalyticsProfile),
                    this.store.select(householdFeature.selectCurrentUserId),
                ];
            }),
            filter(([[{ household }], , profileId]) => !household.currentUser.countryCode && !!profileId),
            switchMap(([[, getGeoLocationSuccess], analyticsProfile, id]) => [
                patchHouseholdUser({
                    user: {
                        countryCode: getGeoLocationSuccess.geoLocation.countryCode,
                        region: getGeoLocationSuccess.geoLocation.region,
                        city: getGeoLocationSuccess.geoLocation.city,
                    },
                    correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                }),
                setAnalyticsProfile({ profile: analyticsProfile, id: id! }),
            ]),
        );
    });
}
