import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { showDailyBiteModal } from './daily-bite-modal.actions';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class DailyBiteModalAnalyticsEffects {
    private readonly actions$ = inject(Actions);

    trackDailyBiteModalOpen$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showDailyBiteModal),
            map(({ dailyBite }) => {
                return trackEvent({
                    eventName: '[Daily Bite] Modal Opened',
                    eventProperties: {
                        dailyBiteId: dailyBite.id,
                    },
                });
            }),
        );
    });
}
