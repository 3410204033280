import { NgClass } from '@angular/common';
import { Component, computed } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonContent } from '@ionic/angular/standalone';
import { NourishFoodFinderOriginId } from '../../constants/nourish.constants';
import { NourishProductTilesComponent } from '../../nourish-product-tiles/nourish-product-tiles.component';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { NourishPetFoodFinderComponent } from '../../nourish-pet-food-finder/nourish-pet-food-finder.component';
import { GenericFeedbackComponent } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-when-and-how-to-switch-food-modal',
    templateUrl: './when-and-how-to-switch-food-modal.component.html',
    styleUrl: './when-and-how-to-switch-food-modal.component.scss',
    imports: [
        NgClass,
        IonContent,
        NourishProductTilesComponent,
        AnalyticsTrackClickDirective,
        NourishPetFoodFinderComponent,
        GenericFeedbackComponent,
    ],
})
export class WhenAndHowToSwitchFoodModalComponent extends NourishModalBaseComponent {
    protected readonly NourishFoodFinderOriginId = NourishFoodFinderOriginId;

    public personalisedText = computed(() => {
        return this.isUsUser() ? 'personalized' : 'personalised';
    });
}
