import { inject, Injectable } from '@angular/core';
import { adjustRequestTrackingConsent } from '@frontend/data-access/adjust';
import { getCoursesSuccess } from '@frontend/data-access/contentful';
import { routeTo } from '@frontend/data-access/router';
import { finalisePageFinishedWaiting, onboardingComplete } from '@frontend/feature/onboarding';
import { removeDuplicates } from '@frontend/utility/angular';
import { AnimationController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FoundationCourseId, PRE_PUP_COURSE_ID } from '@shared/constants';
import { CourseProgressType } from '@shared/user-domain';
import { zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    triggerCreateManyCourseProgress,
    TRIGGERED_FROM_ONBOARDING_CORRELATION_ID,
} from '../../store/progress/course-progress/course-progress.actions';
import { CreateCourseProgressEntry } from '../../store/progress/course-progress/course-progress.model';

@Injectable()
export class OnboardingEffects {
    private readonly actions$ = inject(Actions);
    private readonly animationController = inject(AnimationController);

    finalizeOnboarding$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(finalisePageFinishedWaiting),
            switchMap(() => [
                routeTo({
                    dtos: ['course-preview'],
                    extras: {
                        animation: (baseEl: HTMLElement) => {
                            const enterAnimation = this.animationController
                                .create('enter-animation')
                                .addElement(baseEl)
                                .fromTo('opacity', '0', '1')
                                .beforeStyles({ 'pointer-events': 'none' }) // Prevent user from interacting with the page while animating
                                .afterStyles({ 'pointer-events': 'auto' })
                                .afterAddWrite(() => {
                                    void this.animationController
                                        .create('course-preview-animation')
                                        .addElement(baseEl.querySelector('[data-test="course-preview-title"]')!)
                                        .addElement(baseEl.querySelector('[data-test="course-preview-wrapper"]')!)
                                        .addElement(baseEl.querySelector('[data-test="course-preview-endorsements"]')!)
                                        .addElement(baseEl.querySelector('[data-test="course-preview-footer"]')!)
                                        .fromTo('opacity', '0', '1')
                                        .duration(500)
                                        .play();
                                });

                            const leaveAnimation = this.animationController
                                .create('leave-animation')
                                .addElement(baseEl)
                                .fromTo('opacity', '0', '1');

                            return this.animationController.create().addAnimation([enterAnimation, leaveAnimation]);
                        },
                    },
                    isAnimated: true,
                }),
                adjustRequestTrackingConsent(),
            ]),
        );
    });

    setInProgressCourseAfterOnboarding$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getCoursesSuccess)),
            this.actions$.pipe(ofType(onboardingComplete)),
        ]).pipe(
            map(() => {
                const createCourseProgressEntries = [
                    {
                        id: PRE_PUP_COURSE_ID,
                        progressType: CourseProgressType.IN_PROGRESS,
                    },
                ];

                // NBSon - for setting all foundation courses to in progress after onboarding
                for (const key in FoundationCourseId) {
                    createCourseProgressEntries.push({
                        id: FoundationCourseId[key as keyof typeof FoundationCourseId],
                        progressType: CourseProgressType.IN_PROGRESS,
                    });
                }

                const tidiedCourseProgressEntries = removeDuplicates(
                    createCourseProgressEntries,
                    (a: CreateCourseProgressEntry, b: CreateCourseProgressEntry) => a.id === b.id,
                );

                return triggerCreateManyCourseProgress({
                    courseProgress: tidiedCourseProgressEntries,
                    correlationId: TRIGGERED_FROM_ONBOARDING_CORRELATION_ID,
                });
            }),
        );
    });
}
