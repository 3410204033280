<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Select a product"
            [leftButton]="{ analyticsIdentifier: 'food-product-selector-modal-back-btn', background: 'none' }"
            [rightButton]="false"
            (leftButtonPressed)="back()"
        />
    </ion-toolbar>
</ion-header>

<ui-search-bar
    data-test="food-product-selector-modal-search-bar"
    [placeholder]="'Type product name'"
    [resultListTemplate]="resultListTemplate"
    [noResultTemplate]="noResults"
    [instructionTemplate]="instructions"
    [initialFocus]="true"
    [hasResults]="results.length > 0"
    (searchString)="searchItems($event)"
/>

<ng-template #resultListTemplate>
    <ion-content>
        <ion-list>
            @for (result of results; track result.id) {
                <ion-item
                    class="search-result"
                    data-test="food-product-selector-modal-search-result"
                    (click)="selectProduct(result)"
                >
                    <ion-label class="ion-text-wrap">
                        <h3>{{ result['brandName'] }}</h3>
                        <p class="ion-text-wrap">{{ result['name'] }}</p>
                    </ion-label>
                </ion-item>
            }
        </ion-list>
    </ion-content>
</ng-template>

<ng-template #instructions>
    <div class="center">
        <img
            data-test="food-product-selector-modal-instructions-image"
            src="assets/images/buddy/buddy-looks-longingly-at-steak.png"
        />
        <h2 data-test="food-product-selector-modal-instructions-title">Find your brand & product</h2>
    </div>
</ng-template>

<ng-template #noResults let-searchValue="searchValue">
    <div class="center">
        <img
            data-test="food-product-selector-modal-no-results-image"
            src="assets/images/oliver/oliver-looks-confused.png"
        />
        <h3 data-test="food-product-selector-modal-no-results-title">No results for "{{ searchValue }}"</h3>
        <p data-test="food-product-selector-modal-no-results-text">
            We might not have added the product yet. Check your packaging for the spelling or try again later!
        </p>
    </div>
</ng-template>
