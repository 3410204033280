import { Topic } from '@frontend/data-access/contentful';
import { selectCompletedStepIds } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { StepCardSlimModel } from '../../step/step-card-slim/models/step-card-slim.model';
import { selectContentUnlocked, selectIsBasicPlan } from '../payment/store/payment.selectors';
import { selectRouteParamTopic } from '../router/router.selectors';
import { Step } from '../step/step.model';
import { selectSteps } from '../step/step.selectors';
import { mapStepToStepCardSlim } from '../step/step.utils';
import { selectTopics } from '../topic/topic.selectors';
import { mapStepToLibrarySearchInputItem } from './library.utils';

export const selectSelectedTopic = createSelector(selectRouteParamTopic, selectTopics, (topicId, topics) =>
    topics.find((topic: Topic) => topic.entityId === topicId),
);

export const selectSelectedTopicSteps = createSelector(selectSelectedTopic, selectSteps, (topic, steps) => {
    if (!topic) {
        return [];
    }

    // sort by index as set up in the topic in contentful
    return steps
        .filter((step) => topic.stepIds.includes(step.id))
        .sort((a, b) => topic.stepIds.indexOf(a.id) - topic.stepIds.indexOf(b.id));
});

export const selectSelectedTopicProgress = createSelector(
    selectSelectedTopicSteps,
    selectCompletedStepIds,
    (topicSteps, completedStepIds) => {
        return Math.trunc(
            (topicSteps.filter((step) => completedStepIds.includes(step.id)).length / topicSteps.length) * 100,
        );
    },
);

export const selectSelectedTopicStepDetails = createSelector(
    selectSelectedTopicSteps,
    selectContentUnlocked,
    (steps, contentUnlocked): StepCardSlimModel[] => {
        return steps.map((step: Step) => {
            const locked = !contentUnlocked;

            return mapStepToStepCardSlim(step, locked);
        });
    },
);

export const selectLibrarySearchBarVm = createSelector(
    selectSteps,
    selectContentUnlocked,
    selectIsBasicPlan,
    (steps, unlocked, isBasicPlan) => {
        return {
            cardItems: steps.map((step) => mapStepToStepCardSlim(step, !unlocked)),
            searchItems: steps.map((step) => mapStepToLibrarySearchInputItem(step)),
            isBasicPlan,
        };
    },
);
