<section class="payment-modal-tiered-pricing">
    <div class="payment-modal-tiered-pricing__title-container">
        <h1 class="payment-modal-tiered-pricing__title">
            @if (vm().isBasicPlan) {
                Go <span class="payment-modal-tiered-pricing__premium-text">Premium</span> now
            } @else {
                {{ vm().hasHistoricalPurchase ? 'Continue your journey' : 'Start your free trial now' }}
            }
        </h1>
        @if (!vm().isBasicPlan) {
            <p class="s payment-modal-tiered-pricing__subtitle">Cancel anytime within your trial period</p>
        }
    </div>

    @if (!vm().isBasicPlan) {
        <app-tiered-pricing-comparison-toggle [isPremiumSelected]="premiumSelected" (selectTier)="selectTier($event)" />
    }

    <app-payment-modal-plan-features-list [features]="premiumSelected ? defaultFeaturesList : basicFeaturesList" />
</section>

<section class="payment-modal-tiered-pricing-products">
    @if (premiumSelected || vm().isBasicPlan) {
        @for (premiumProduct of vm().premiumProducts; track premiumProduct.identifier) {
            <app-payment-modal-product
                [isSelected]="premiumProduct.identifier === selectedProduct()?.identifier"
                [product]="premiumProduct"
                (productClicked)="selectProduct($event)"
            />
        }
    } @else {
        @for (basicProduct of vm().basicProducts; track basicProduct.identifier) {
            <app-payment-modal-product
                [isBasicSelected]="basicProduct.identifier === selectedProduct()?.identifier"
                [product]="basicProduct"
                (productClicked)="selectProduct($event)"
            />
        }
    }
</section>

<section class="payment-modal-tiered-pricing-buttons">
    <ion-chip
        outline="true"
        class="payment-modal-tiered-pricing-buttons__compare-plans"
        (click)="launchComparisonModal()"
        >Compare plans
    </ion-chip>

    <button
        class="zz-button"
        appTrackClick
        identifier="tiered-pricing-paywall-payment-restore-button"
        (click)="onRestoreClick()"
    >
        Restore purchases
    </button>
</section>

<app-payment-modal-terms class="payment-modal-tiered-pricing__terms" />

<ion-footer class="payment-modal-tiered-pricing-footer gradient-footer">
    <ion-button
        appTrackClick
        [identifier]="'tiered-pricing-modal-payment-purchase-' + selectedProduct()?.identifier"
        color="max"
        expand="full"
        shape="round"
        [disabled]="!selectedProduct || vm().isPurchaseInProgress"
        (click)="
            onPurchaseClick({
                identifier: selectedProduct()!.identifier,
                priceWithUnit: selectedProduct()!.priceWithUnit,
            })
        "
    >
        @if (vm().isBasicPlan) {
            Upgrade to Premium <i class="fa-regular fa-crown payment-modal-tiered-pricing-footer__premium-icon"></i>
        } @else {
            {{ vm().hasHistoricalPurchase ? 'Start my subscription' : 'Start my free trial' }}
        }
    </ion-button>
</ion-footer>
