import { createAction, props } from '@ngrx/store';
import { CourseSplit } from '../../../courses/models/courses.models';
import {
    CreateStepProgress,
    StepCompletedProperties,
    StepSkippedProperties,
    StepUpdateEventName,
    StepUpdateEventProperties,
} from './step-progress.model';

export const TRIGGERED_FROM_STEP_CORRELATION_ID = 'Triggered from step';

export const triggerCreateStepProgress = createAction(
    '[Step Progress] Trigger Create Step Progress',
    props<{
        progress: CreateStepProgress[];
        correlationId?: string;
    }>(),
);

export const setStepCompleted = createAction(
    '[Step Progress] Set Step Completed',
    props<{
        properties: StepCompletedProperties;
        correlationId?: string;
    }>(),
);

export const setStepSkipped = createAction(
    '[Step Progress] Set Step Skipped',
    props<{
        properties: StepSkippedProperties;
    }>(),
);

export const trackStepUpdate = createAction(
    '[Step Progress] Track Step Update',
    props<{
        eventName: StepUpdateEventName;
        eventProperties: StepUpdateEventProperties;
    }>(),
);

export const updateJumpToProgressForSelectedCourse = createAction(
    '[Step Progress] Update Jump to Progress For Selected Course',
    props<{
        split: CourseSplit;
    }>(),
);
