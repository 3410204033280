<div class="error-screen">
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-header [leftButton]="leftButtonConfig" [rightButton]="false" (leftButtonPressed)="closeModal()" />
        </ion-toolbar>
    </ion-header>

    <ion-content class="error-screen__ion-content page-ion-content ion-text-center" [fullscreen]="true">
        <div class="page-content page-content-padding-top">
            <div class="error-screen__image-container">
                <img
                    class="error-screen__image"
                    alt="digging-dog-image"
                    src="assets/images/buddy/buddy-digs-into-ground.svg"
                />
            </div>
            <div>
                <h1>Oops!</h1>
                <p class="error-screen__error-message">
                    {{ errorMessage() }}
                </p>
            </div>
        </div>
    </ion-content>

    <ion-footer class="ion-text-center page-footer auth-footer">
        <ion-button
            appTrackClick
            identifier="error-screen-try-again-btn"
            expand="block"
            data-test="error-screen-try-again-btn"
            [color]="Color.Max"
            (click)="closeModal()"
            >{{ buttonText() }}
        </ion-button>
    </ion-footer>
</div>
