import { IsNumber, IsString } from 'class-validator';

export class IterablePostPushOpenEventCommand {
    @IsString()
    userId!: string;

    @IsNumber()
    campaignId!: number;

    @IsNumber()
    templateId!: number;

    @IsString()
    messageId!: string;

    @IsNumber()
    createdAt!: number;
}
