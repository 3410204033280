<app-search-bar
    data-test="library-search-bar"
    [showCancelButton]="'focus'"
    [hasResults]="foundItems.length > 0"
    [resultListTemplate]="resultList"
    [noResultTemplate]="noResults"
    [instructionTemplate]="instructions"
    [initialFocus]="false"
    [placeholder]="'Search lessons & articles'"
    [isLibraryPage]="true"
    [isBasicPlan]="isBasicPlan()"
    (search)="searchItems($event)"
    (focusChange)="focusChange($event)"
/>

<ng-template #resultList let-searchValue="searchValue">
    <ion-list lines="none">
        @for (item of foundItems; track item.id) {
            <app-step-card-slim
                [content]="item"
                [attr.data-test]="'search-result-card-item-' + item.id"
                (click)="goToStep(item.id)"
            />
        }
    </ion-list>
</ng-template>

<ng-template #instructions>
    @if (!isBasicPlan()) {
        <div class="library-search-bar-placeholder">
            <img [src]="'assets/images/buddy/buddy-reads-books.png'" alt="search-image" />
            <h2>Explore the library</h2>
            <p>Search for articles, daily bites and lessons.</p>
        </div>
    }
</ng-template>

<ng-template #noResults let-searchValue="searchValue">
    <div class="library-search-bar-placeholder">
        <img [src]="'assets/images/oliver/oliver-looks-confused.png'" alt="no-result-image" />
        <h3>No results for "{{ searchValue }}"</h3>
        <p>Try again using different spelling or keywords.</p>
    </div>
</ng-template>
