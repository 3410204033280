import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { patchHouseholdUser, patchHouseholdUserFailure, patchHouseholdUserSuccess } from './household-user.actions';
import { HouseholdUserService } from '../../services/household-user.service';
import { mapInternalHouseholdUserDtoToHouseholdUser } from '../../utils/household.utils';

@Injectable()
export class HouseholdUserEffects {
    private readonly actions$ = inject(Actions);
    private readonly householdUserService = inject(HouseholdUserService);

    patchHouseholdUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchHouseholdUser),
            concatMap(({ user, correlationId }) =>
                this.householdUserService.patchUser(user).pipe(
                    map((userResponse) =>
                        patchHouseholdUserSuccess({
                            user: mapInternalHouseholdUserDtoToHouseholdUser(userResponse),
                            command: user,
                            correlationId,
                        }),
                    ),
                    catchError((error: Error) => of(patchHouseholdUserFailure({ error, correlationId }))),
                ),
            ),
        );
    });
}
