import { inject, Injectable } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { openToast } from '../toast/toast.actions';
import { selectTopics } from './topic.selectors';
import { addPinnedTopic, pinnedTopicFeature, removePinnedTopic } from '@frontend/data-access/user/pinned-topics';
import { pinTopic, unpinTopic } from './topic.actions';

@Injectable()
export class TopicEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    pinTopic$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(pinTopic),
            concatLatestFrom(() => this.store.select(selectTopics)),
            switchMap(([{ topicEntityId }, topics]) => {
                const foundTopic = topics.find((topic) => topic.entityId === topicEntityId);
                return [
                    addPinnedTopic({ id: topicEntityId }),
                    trackEvent({
                        eventName: '[Topic] Topic Pinned',
                        eventProperties: { topicEntityId },
                    }),
                    openToast({
                        message: `${foundTopic?.title} topic added to Today page`,
                        color: foundTopic?.color ?? Color.Lola,
                    }),
                ];
            }),
        );
    });

    unpinTopic$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(unpinTopic),
            concatLatestFrom(() => [
                this.store.select(selectTopics),
                this.store.select(pinnedTopicFeature.selectPinnedTopics),
            ]),
            switchMap(([{ topicEntityId }, topics, pinnedTopics]) => {
                const foundTopic = topics.find((topic) => topic.entityId === topicEntityId);
                const foundPinnedTopic = pinnedTopics.find(
                    (pinnedTopic) => pinnedTopic.topicId === foundTopic!.entityId,
                );

                return [
                    removePinnedTopic({ pinnedTopic: foundPinnedTopic! }),
                    trackEvent({ eventName: '[Topic] Topic Unpinned', eventProperties: { topicEntityId } }),
                    openToast({
                        message: `${foundTopic?.title} topic removed from Today page`,
                        color: foundTopic?.color ?? Color.Lola,
                    }),
                ];
            }),
        );
    });
}
