export enum SignInProviders {
    Apple = 'apple.com',
    Google = 'google.com',
    Email = 'password',
}

export enum GoogleSSOErrorMessages {
    /** Occurs when a user is already logged in and cancels the Google account selection process */
    UserCancelledSelector = 'User cancelled the selector',
    UserCancelledSignInFlow = 'The user canceled the sign-in flow.',
}
