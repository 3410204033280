import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { LocalNotificationEffects } from './store/local-notification.effects';
import { localNotificationsFeature } from './store/local-notification.reducer';

@NgModule({
    imports: [],
    providers: [provideState(localNotificationsFeature), provideEffects([LocalNotificationEffects])],
})
export class ZigzagFrontendDataAccessLocalNotificationModule {}
