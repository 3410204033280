import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectCurrentIp } from '@frontend/data-access/geo-location';
import {
    mapFeatureFlagsForIterable,
    mapTrackEventToInternalIterablePostEventCommand,
    mapUserToInternalIterableUpsertUserCommand,
    PushNotificationService,
    setPushNotifications,
    trackIterableEvent,
    upsertIterableUser,
    upsertIterableUserSuccess,
} from '@frontend/data-access/push-notification';
import {
    accountFeature,
    createAccountSuccess,
    getAccountSuccess,
    selectProfileBreedInfo,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { configCatFeature, getConfigCatFeatureFlagsSuccess } from '@frontend/data-access/user/config-cat';
import { createStepProgressSuccess, selectCompletedStepIds } from '@frontend/data-access/user/progress';
import { getPlatform } from '@frontend/utility/native-plugins';
import { Platform } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, concatMap, merge, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';

@Injectable()
export class PushNotificationsEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly pushNotificationService = inject(PushNotificationService);
    private readonly platform = inject(Platform);

    checkIfAppropriateUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setPushNotifications),
            filter(() => !this.pushNotificationService.pushNotificationsEnabled()),
            map(() => setPushNotifications()),
        );
    });

    displayPushNotificationPrompt$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setPushNotifications),
                tap(() => this.pushNotificationService.registerIterablePushNotifications()),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trackIterableEvent$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                ofType(trackEvent), // trigger
                filter(({ eventName }) => eventName !== 'App error' && eventName !== 'click'),
            ),
            merge(
                // conditions
                zip([
                    // user finished onboarding and exists in iterable
                    this.actions$.pipe(ofType(createAccountSuccess)),
                    this.actions$.pipe(ofType(upsertIterableUserSuccess)),
                ]),
                this.actions$.pipe(ofType(getAccountSuccess)), // user logged in
            ).pipe(take(1)),
        ]).pipe(
            concatLatestFrom(() => {
                return this.store.select(accountFeature.selectId);
            }),
            filter(([, profileId]) => {
                return !!profileId;
            }),
            map(([[{ eventName, eventProperties }], profileId]) => {
                return trackIterableEvent({
                    command: mapTrackEventToInternalIterablePostEventCommand(profileId!, eventName, eventProperties),
                });
            }),
        );
    });

    upsertIterableUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                createAccountSuccess,
                updateAccountSuccess,
                createStepProgressSuccess,
                getConfigCatFeatureFlagsSuccess,
            ),
            concatLatestFrom(() => [
                this.store.select(accountFeature.selectProfile),
                this.store.select(accountFeature.selectId),
                this.store.select(selectProfileBreedInfo),
                this.store.select(selectCurrentIp),
                this.store.select(selectCompletedStepIds),
                this.store.select(configCatFeature.selectConfigCatFlags),
            ]),
            filter(([, profile, profileId]) => !!profile && !!profileId),
            // Detect changes to key fields that should trigger the upsert
            distinctUntilChanged((prev, curr) => {
                const [, prevProfile, prevProfileId, , , prevSteps, prevFlags] = prev;
                const [, currProfile, currProfileId, , , currSteps, currFlags] = curr;

                // Check for any changes in feature flags, step progress, or profile data
                const hasFeatureFlagChanged = JSON.stringify(prevFlags) !== JSON.stringify(currFlags);
                const hasStepProgressChanged = prevSteps.length !== currSteps.length;
                const hasProfileChanged =
                    JSON.stringify(prevProfile) !== JSON.stringify(currProfile) || prevProfileId !== currProfileId;

                return !(hasFeatureFlagChanged || hasStepProgressChanged || hasProfileChanged);
            }),
            concatMap(([, profile, profileId, breedInfo, ipAddress, completedSteps, featureFlags]) => {
                return [
                    upsertIterableUser({
                        user: mapUserToInternalIterableUpsertUserCommand(
                            profile!,
                            profileId!,
                            getPlatform(this.platform),
                            mapFeatureFlagsForIterable(featureFlags),
                            ipAddress,
                            breedInfo,
                            completedSteps.length,
                        ),
                    }),
                ];
            }),
        );
    });
}
