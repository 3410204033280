import { createSelector } from '@ngrx/store';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { selectIsBasicPlan } from '../../../store/payment/store/payment.selectors';

export const selectTieredPricingComparisonModalVm = createSelector(
    subscriptionFeature.selectHasHistoricalPurchase,
    subscriptionFeature.selectIsPurchaseInProgress,
    selectIsBasicPlan,
    (hasHistoricalPurchase, isPurchaseInProgress, isBasicPlan) => {
        return {
            hasHistoricalPurchase: !!hasHistoricalPurchase,
            isPurchaseInProgress,
            isBasicPlan,
        };
    },
);
