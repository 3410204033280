import { Component, inject } from '@angular/core';
import { DailyBite } from '@frontend/data-access/contentful';
import { Store } from '@ngrx/store';
import { DailyBiteCardComponent } from '../../daily-bites/daily-bite-card/daily-bite-card.component';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { selectTodayDailyBitesVm } from './today-daily-bites.selectors';
import { showDailyBiteModal } from '../../daily-bites/daily-bite-modal/daily-bite-modal.actions';

@Component({
    selector: 'app-today-daily-bites',
    templateUrl: './today-daily-bites.component.html',
    styleUrls: ['./today-daily-bites.component.scss'],
    standalone: true,
    imports: [DailyBiteCardComponent, TextReplacementPipe],
})
export class TodayDailyBitesComponent {
    private readonly store = inject(Store);

    public vm = this.store.selectSignal(selectTodayDailyBitesVm);

    public openDailyBiteModal(dailyBite: DailyBite): void {
        this.store.dispatch(showDailyBiteModal({ dailyBite }));
    }
}
