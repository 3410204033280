@if (selectedTopic()) {
<ion-header class="topic-page-header page-header ion-no-border">
    <ion-toolbar class="topic-page-header__toolbar">
        <app-header
            title="Library"
            background="var(--ion-color-app-background)"
            [leftButton]="{
                    analyticsIdentifier: 'topic-back-button',
                    background: 'none'
                }"
            [rightButton]="{
                    analyticsIdentifier: getRightButtonIdentifier(),
                    sprite: getRightButtonSprite(),
                    iconName: getRightButtonIconName(),
                    background: 'none',
                    iconColour: 'black',
                    isDisabled: arePinnedTopicsLoading(),
                }"
            (leftButtonPressed)="onLeftButtonPressed()"
            (rightButtonPressed)="onRightButtonPressed()"
        />
    </ion-toolbar>
</ion-header>

<ion-content color="app-background" [scrollY]="!isBasicPlan()">
    <div class="topic-page">
        <section class="topic-page__title-container">
            @if (selectedTopic()?.title) {
            <h1 class="topic-page__title">{{ selectedTopic()?.title }}</h1>
            } @if (getCircleProgressOptions()) {
            <circle-progress data-test="topic-progress" [options]="getCircleProgressOptions()" />
            }
        </section>

        @if (selectedTopic()?.intro) {
        <p class="topic-page__topic-intro">{{ selectedTopic()?.intro | textReplacement }}</p>
        }

        <ion-card>
            @if (isBasicPlan()) {
            <app-no-access
                title="Unlock unlimited access to 250+ lessons with premium"
                subTitle="You're on the Basic plan now."
                buttonText="Upgrade to Premium"
                (openPaymentModal)="openPaymentModal()"
            />
            } @for (step of selectedTopicStepDetails(); track step.status) {
            <app-step-card-slim
                appTrackClick
                identifier="to-step-from-library"
                [content]="step"
                [attr.data-test]="'step-card-' + step.id"
                (click)="onStepClick(step.id)"
            />
            }
        </ion-card>

        <section>
            @if (selectedTopic()?.title) {
            <h2 class="no-margin">Have a question about {{ selectedTopic()?.title | lowercase }}?</h2>
            }
            <app-generic-card-slim
                appTrackClick
                [identifier]="'topic-page-ziggy-ai-tile'"
                [attr.data-test]="'topic-page-ziggy-ai-tile'"
                [background]="Color.Sophie"
                [imageUrl]="'assets/images/ziggy/ziggy-close-up.svg'"
                [title]="'Ask Ziggy'"
                [unlocked]="true"
                (click)="onZiggyChatClick()"
            />
        </section>
    </div>
</ion-content>
}
