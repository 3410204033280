export interface SaIterableUpsertUserCommand {
    email: string;
    dataFields: SaIterableDataFields;
}

export interface SaIterableDataFields {
    country: string;
    userId: string;
    dog: SaIterableDogFields;
    results: SaIterableResults;
    consentGiven: boolean;
}

export interface SaIterableDogFields {
    breedId: string;
    gender: string;
    neuterStatus: string;
    ageOfOnset: number;
    knownTraumaticEvent: string;
    separationRelatedProblemLength: string;
}

export interface SaIterableResults {
    exit_frustration: number;
    boredom: number;
    redirect_reactive: number;
    reactive_inhibited: number;
}
