import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { HouseholdService } from '../services/household.service';
import {
    createHousehold,
    createHouseholdFailure,
    createHouseholdSuccess,
    getHousehold,
    getHouseholdFailure,
    getHouseholdSuccess,
} from './household.actions';
import { mapInternalHouseholdDtoToHousehold } from '../utils/household.utils';

@Injectable()
export class HouseholdEffects {
    private readonly actions$ = inject(Actions);
    private readonly householdService = inject(HouseholdService);

    getHousehold$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHousehold),
            concatMap(() =>
                this.householdService.getHousehold().pipe(
                    map((household) =>
                        getHouseholdSuccess({ household: mapInternalHouseholdDtoToHousehold(household) }),
                    ),
                    catchError((error: Error) => of(getHouseholdFailure({ error }))),
                ),
            ),
        );
    });

    createHousehold$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createHousehold),
            concatMap(({ id, accountId, email, countryCode }) =>
                this.householdService.createHousehold({ id, accountId, mail: email, countryCode }).pipe(
                    map((household) =>
                        createHouseholdSuccess({ household: mapInternalHouseholdDtoToHousehold(household) }),
                    ),
                    catchError((error: Error) => of(createHouseholdFailure({ error }))),
                ),
            ),
        );
    });
}
