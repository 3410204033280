import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Weekday } from '@capacitor/local-notifications';
import { HeaderComponent } from '@frontend/feature/header';
import {
    IonButton,
    IonContent,
    IonDatetime,
    IonFooter,
    IonHeader,
    IonLabel,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { DayOption } from '../../models/training-reminder.model';
import { createTrainingReminder, trainingReminderModalCloseClicked } from '../../store/training-reminder-modal.actions';
import { LocalNotificationService } from '@frontend/data-access/local-notification';
import { openToast } from '../../../store/toast/toast.actions';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'app-training-reminder-modal',
    templateUrl: './training-reminder-modal.component.html',
    styleUrl: './training-reminder-modal.component.scss',
    standalone: true,
    imports: [
        NgClass,
        IonButton,
        IonContent,
        IonDatetime,
        IonFooter,
        IonHeader,
        IonLabel,
        IonToolbar,
        HeaderComponent,
        ReactiveFormsModule,
    ],
})
export class TrainingReminderModalComponent {
    private readonly store = inject(Store);
    private localNotificationService = inject(LocalNotificationService);

    public dogName = input.required<string>();

    public form = new FormGroup({
        time: new FormControl<string>(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"), {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });
    public days: DayOption[] = [
        {
            text: 'M',
            weekday: Weekday.Monday,
            selected: true,
        },
        {
            text: 'T',
            weekday: Weekday.Tuesday,
            selected: true,
        },
        {
            text: 'W',
            weekday: Weekday.Wednesday,
            selected: true,
        },
        {
            text: 'T',
            weekday: Weekday.Thursday,
            selected: true,
        },
        {
            text: 'F',
            weekday: Weekday.Friday,
            selected: true,
        },
        {
            text: 'S',
            weekday: Weekday.Saturday,
            selected: true,
        },
        {
            text: 'S',
            weekday: Weekday.Sunday,
            selected: true,
        },
    ];

    public onClickCloseModal(): void {
        this.store.dispatch(trainingReminderModalCloseClicked());
    }

    public async onClickSetReminder() {
        let notificationsAllowed = await this.localNotificationService.checkAllPermissions();

        if (notificationsAllowed.display === 'granted') {
            this.store.dispatch(
                createTrainingReminder({
                    dogName: this.dogName(),
                    time: new Date(this.form.controls.time.value),
                    weekdays: this.days.filter((day) => day.selected).map((day) => day.weekday),
                }),
            );
        } else {
            notificationsAllowed = await this.localNotificationService.requestPermissions();
            if (notificationsAllowed.display === 'denied') {
                this.store.dispatch(
                    openToast({
                        message: 'Please re-enable notifications in your phone settings to use training reminders',
                        color: Color.Luna,
                    }),
                );
            }
        }
    }

    public toggleDay(day: DayOption): void {
        day.selected = !day.selected;
    }
}
