import { ContentFetchingConditions } from './content-effects.constants';

export const containsValueForAtLeastOneCondition = (
    profileFields: Partial<{ [key in keyof typeof ContentFetchingConditions]: unknown }>,
    conditions: ContentFetchingConditions[],
): boolean => {
    return conditions.some((condition) => {
        return profileFields[condition] !== undefined && profileFields[condition] !== null;
    });
};

export const containsValueForAllConditions = (
    profileFields: Partial<{ [key in keyof typeof ContentFetchingConditions]: unknown }>,
    conditions: ContentFetchingConditions[],
): boolean => {
    return conditions.every((condition) => {
        return profileFields[condition] !== null && profileFields[condition] !== undefined;
    });
};
