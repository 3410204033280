import { platformFeature } from '@frontend/data-access/platform';
import { createSelector } from '@ngrx/store';
import { householdFeature, selectCountryCode } from '@frontend/data-access/user/household';
import { LoadingState } from '@frontend/data-access/shared-models';
import { authenticationFeature } from '@frontend/data-access/user/authentication';

export const selectLandingPageVm = createSelector(
    platformFeature.selectPlatformIsIos,
    selectCountryCode,
    authenticationFeature.selectSignUpLoading,
    householdFeature.selectCreateHouseholdLoadingState,
    (isIos, countryCode, signUpLoading, createHouseholdLoadingState) => {
        return {
            isIos,
            countryCode,
            isLoading: createHouseholdLoadingState === LoadingState.LOADING || signUpLoading,
        };
    },
);
