import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { UserFeedbackContentEffects } from './store/user-feedback-content.effects';
import { provideState } from '@ngrx/store';
import { userFeedbackContentFeature } from './store/user-feedback-content.reducer';

@NgModule({
    providers: [provideEffects([UserFeedbackContentEffects]), provideState(userFeedbackContentFeature)],
})
export class ZigzagFrontendDataAccessUserFeedbackContentModule {}
