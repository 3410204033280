import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { HouseholdService } from '../services/household.service';
import { getHousehold, getHouseholdFailure, getHouseholdSuccess } from './household.actions';
import { mapInternalHouseholdDtoToHousehold } from '../utils/household.utils';

@Injectable()
export class GetHouseholdEffects {
    private readonly actions$ = inject(Actions);
    private readonly householdService = inject(HouseholdService);

    getHousehold$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHousehold),
            concatMap(({ correlationId }) =>
                this.householdService.getHousehold().pipe(
                    map((household) =>
                        getHouseholdSuccess({
                            household: mapInternalHouseholdDtoToHousehold(household),
                            correlationId,
                        }),
                    ),
                    catchError((error: Error) => of(getHouseholdFailure({ error }))),
                ),
            ),
        );
    });
}
