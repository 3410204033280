import { InternalConversationMessageDto } from '../conversation-message/internal-conversation-message.dto';

export interface InternalConversationDto {
    id: string;
    channelId: string;
    userId: string;
    conversationTypeId: string;
    creationDateTime: Date;
    messages: InternalConversationMessageDto[];
}
