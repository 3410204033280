import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { routeToCoachChat } from '../chat.actions';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-chat-tile',
    templateUrl: './chat-tile.component.html',
    styleUrls: ['./chat-tile.component.scss'],
    standalone: true,
    imports: [IonCard, AnalyticsTrackClickDirective, AsyncPipe],
})
export class ChatTileComponent {
    private readonly store = inject(Store);

    public sendChatMessage(): void {
        this.store.dispatch(routeToCoachChat());
    }

    public openPaymentModal(): void {
        void this.store.dispatch(showPaymentModal({ trigger: 'chat-tile' }));
    }
}
