import { createSelector } from '@ngrx/store';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { selectIsBasicPlan } from '../../store/payment/store/payment.selectors';
import { PaymentProductCard } from '../payment-modal-product/payment-product-card.model';
import { selectPaymentProducts } from '../payment-modal.selectors';

export const selectPaymentTieredPricingModalVm = createSelector(
    selectPaymentProducts,
    subscriptionFeature.selectHasHistoricalPurchase,
    subscriptionFeature.selectIsPurchaseInProgress,
    selectIsBasicPlan,
    (
        paymentProducts,
        hasHistoricalPurchase,
        isPurchaseInProgress,
        isBasicPlan,
    ): {
        basicProducts: PaymentProductCard[];
        premiumProducts: PaymentProductCard[];
        hasHistoricalPurchase: boolean;
        isPurchaseInProgress: boolean;
        isBasicPlan: boolean;
    } => {
        return {
            premiumProducts: paymentProducts.premiumProducts,
            basicProducts: paymentProducts.basicProducts,
            hasHistoricalPurchase: !!hasHistoricalPurchase,
            isPurchaseInProgress,
            isBasicPlan,
        };
    },
);
