import { createSelector } from '@ngrx/store';
import { localNotificationsFeature } from './local-notification.reducer';
import { ZigzagLocalNotification } from './local-notification.model';
import { Weekday } from '@capacitor/local-notifications';

export const selectLocalNotifications = createSelector(
    localNotificationsFeature.selectNotifications,
    (notifications) => {
        const zigzagNotifications: ZigzagLocalNotification[] = [];

        notifications.forEach((notification) => {
            const foundNotification = zigzagNotifications.find(
                (item) =>
                    item.hour === notification?.schedule?.on?.hour &&
                    item.minute === notification?.schedule?.on?.minute,
            );
            if (foundNotification) {
                foundNotification.notificationIds.push(notification.id);
                foundNotification.weekdays.push(notification.schedule!.on!.weekday!);
                foundNotification.weekdaysString.push(Weekday[notification.schedule!.on!.weekday!].substring(0, 3));

                if (foundNotification.weekdays && foundNotification.weekdays.length === 7) {
                    foundNotification.weekdaysString = ['Every day'];
                }
            } else {
                const newNotification: ZigzagLocalNotification = {
                    notificationIds: [notification.id],
                    weekdays: [notification.schedule!.on!.weekday!],
                    weekdaysString: [Weekday[notification.schedule!.on!.weekday!].substring(0, 3)],
                    hour: notification.schedule!.on!.hour!,
                    minute: notification.schedule!.on!.minute!,
                };

                zigzagNotifications.push(newNotification);
            }
        });

        return zigzagNotifications;
    },
);
