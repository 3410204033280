import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectCourses } from '@frontend/data-access/contentful';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { DEFAULT_PUPPY_NAME } from '@shared/constants';
import { map, tap } from 'rxjs/operators';
import { showCourseCompletionModal } from './course-completion-modal.actions';
import { CourseCompletionModalComponent } from './course-completion-modal.component';
import { COURSE_COMPLETION_MODAL_ID } from './course-completion-modal.model';
import { householdFeature } from '@frontend/data-access/user/household';

@Injectable()
export class CourseCompletionModalEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    showCourseCompletionModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showCourseCompletionModal),
            concatLatestFrom(() => [
                this.store.select(householdFeature.selectDogName),
                this.store.select(selectCourses),
            ]),
            map(([{ courseId }, dogName, courses]) => {
                const course = courses.find((course) => course.id === courseId);
                return {
                    course,
                    dogName,
                };
            }),
            tap(({ course, dogName }) => {
                void this.modalService.showModal({
                    id: COURSE_COMPLETION_MODAL_ID,
                    component: CourseCompletionModalComponent,
                    componentProps: {
                        course,
                        dogName: dogName || DEFAULT_PUPPY_NAME,
                    },
                    cssClass: 'modal',
                });
            }),
            map(({ course }) => {
                return trackEvent({
                    eventName: '[Course] Course Completion Modal Initiated',
                    eventProperties: {
                        course,
                    },
                });
            }),
        );
    });
}
