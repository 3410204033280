export const removeDuplicates = <TValue>(list: TValue[], compareFn: (a: TValue, b: TValue) => boolean) => {
    if (!list) {
        return [];
    }
    return list.filter((obj, index) => list.findIndex((item) => compareFn(item, obj)) === index);
};

export const uniqByKeepLast = <T>(items: T[], keyExtractor: (item: T) => T[keyof T]) => {
    const map = new Map<T[keyof T], T>();

    for (let i = items.length - 1; i >= 0; i--) {
        const item = items[i];
        const key = keyExtractor(item);

        if (!map.has(key)) {
            map.set(key, item);
        }
    }

    return Array.from(map.values()).reverse();
};

export const upsertArrayItem = <TItem>(
    existingItems: TItem[],
    newItems: TItem[],
    findFn: (itemA: TItem, itemB: TItem) => boolean,
): TItem[] => {
    const updatedItems = [...existingItems];

    newItems.forEach((item) => {
        const index = updatedItems.findIndex((existingItem) => findFn(existingItem, item));

        if (index !== -1) {
            // ...if the item is in the existing items, replace it with the updated item
            updatedItems[index] = item;
        } else {
            // ...if the item is not in the existing items, add it to the item array
            updatedItems.push(item);
        }
    });

    return updatedItems;
};

export const seededRandom = (seed: number) => {
    return () => {
        seed = (seed * 9301 + 49297) % 233280;

        return seed / 233280;
    };
};

/**
 * Shuffles an array using the Fisher-Yates algorithm.
 *
 * The resulting array can be deterministic by providing a seed. It will always return the same shuffled array for the same seed.
 * It can end in the same order as the original array.
 */
export const shuffleArray = <T>(array: T[], seed: number) => {
    // If the array is not greater than 1, return it as is
    if (array.length <= 1) {
        return array;
    }

    const random = seededRandom(seed);
    const shuffledArray = [...array];

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
};
