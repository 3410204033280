import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, computed, inject, input, OnInit } from '@angular/core';
import { Course, SeasonalTopic } from '@frontend/data-access/contentful';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color, setCourseBackgroundColor } from '@shared/utils/typescript';
import { goToSeasonalTopicFromTile } from '../../data-access/today.page.actions';

@Component({
    selector: 'app-topic-tile',
    templateUrl: './topic-tile.component.html',
    styleUrl: './topic-tile.component.scss',
    standalone: true,
    imports: [AsyncPipe, NgStyle, IonButton, IonCard, AnalyticsTrackClickDirective],
})
export class TopicTileComponent implements OnInit {
    private readonly store = inject(Store);

    protected readonly Color = Color;

    public seasonalContent!: SeasonalTopic;
    public topic = input.required<Course | SeasonalTopic>();
    public backgroundColor = input(Color.Harley);
    public background = computed(() => {
        return {
            ...setCourseBackgroundColor(this.seasonalContent.backgroundColour),
        };
    });

    public onGoToTopicClick(): void {
        this.store.dispatch(goToSeasonalTopicFromTile({ topicId: this.seasonalContent.topic }));
    }

    ngOnInit() {
        this.seasonalContent = this.topic() as SeasonalTopic;
    }
}
