import { FrontendRescheduledStep } from '../models/rescheduled-step.model';
import { FrontendRescheduledStepDto } from '../dto/rescheduled-step.dto';

export const mapFrontendRescheduledStepDtoToRescheduledStep = (
    step: FrontendRescheduledStepDto,
): FrontendRescheduledStep => ({
    id: step.id,
    dogId: step.dogId,
    stepId: step.stepId,
    contentId: step.contentId,
    type: step.type,
    isActive: step.isActive,
    date: new Date(+step.timestamp),
});
