import { Gender } from '@shared/user-domain';
import { InternalDogDto } from '../../../dtos/internal-api/user/dog/dog.dto';

export class InternalDogDtoMock {
    private defaultValue: InternalDogDto = {
        breedId: '',
        dateOfArrival: new Date(),
        dateOfBirth: new Date(),
        dateOfOnboarding: new Date(),
        gender: Gender.MALE,
        hasArrived: true,
        isApproximateDateOfBirth: false,
        isRescue: false,
        name: 'Bella',
        id: 'uuid-1234',
    };

    constructor(overrides?: Partial<InternalDogDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalDogDto {
        return this.defaultValue;
    }
}
