import { Component } from '@angular/core';
import { openUrlWindow } from '@frontend/data-access/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedPurinaOptIn } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-purina-opt-in',
    templateUrl: 'question-purina-opt-in.page.html',
    styleUrls: ['question-purina-opt-in.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionPurinaOptInPage extends OnboardingQuestionBasePage {
    public onExternalLinkClick(url: string): void {
        this.store.dispatch(openUrlWindow({ url, openInNewWindow: false }));
    }

    public onOptInClick(optedIn: boolean) {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedPurinaOptIn({ purinaMarketingAccepted: optedIn }));
    }
}
