<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Profile"
            background="var(--ion-color-app-background)"
            [leftButton]="false"
            [rightButton]="{
            analyticsIdentifier: 'profile-close-btn',
                sprite: 'light',
                iconName: 'xmark', background: 'none', iconColour: 'black'
            }"
            (rightButtonPressed)="back(profile()!)"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="page-ion-content ion-text-center profile-edit-page" [fullscreen]="true">
    <div class="page-content ion-justify-content-start">
        <section class="profile-edit-page__profile-image-section">
            <app-profile-image [isClickable]="true" [type]="ProfileImageType.EDIT" size="90px" borderWidth="5px" />
        </section>
        <form [formGroup]="userForm">
            <section class="profile-edit-page__dog-section">
                <ion-card class="profile-edit-page__section-card">
                    <ion-item class="profile-edit-page__dog-section-item" lines="full">
                        <ion-input
                            class="profile-edit-page__input"
                            autocapitalize="words"
                            placeholder="Name of your pup"
                            label="Pup name"
                            labelPlacement="stacked"
                            maxlength="16"
                            type="text"
                            appTrackClick
                            identifier="profile-edit-dogname-input"
                            data-test="profile-edit-dogName-input"
                            [formControlName]="'name'"
                            [tabindex]="1"
                        />
                    </ion-item>
                    <ion-item class="profile-edit-page__dog-section-item" lines="full">
                        <ion-select
                            class="profile-edit-page__select"
                            placeholder="Select One"
                            label="Dog breed"
                            labelPlacement="stacked"
                            appTrackClick
                            identifier="profile-edit-breed-input"
                            data-test="profile-edit-breed-input"
                            [formControlName]="'breedId'"
                        >
                            @for (breed of breeds(); track breed.id) {
                            <ion-select-option [value]="breed.id"> {{ breed.name }}</ion-select-option>
                            }
                        </ion-select>
                    </ion-item>
                    <ion-item class="profile-edit-page__dog-section-item" lines="full">
                        <ion-select
                            class="profile-edit-page__select"
                            placeholder="Select One"
                            label="Gender"
                            labelPlacement="stacked"
                            appTrackClick
                            data-test="profile-edit-gender-input"
                            identifier="profile-edit-gender-input"
                            [formControlName]="'gender'"
                        >
                            @for (option of genderOptions; track option.value) {
                            <ion-select-option [value]="option.value"> {{ option.description }}</ion-select-option>
                            }
                        </ion-select>
                    </ion-item>
                    <ion-item class="profile-edit-page__dog-section-item" button lines="none" [detail]="false">
                        <div class="profile-edit-page__date-of-birth-container">
                            <ion-input
                                class="profile-edit-page__input"
                                id="dateOfBirth"
                                data-test="date-of-birth-input"
                                type="date"
                                label="Birthday"
                                labelPlacement="stacked"
                                appTrackClick
                                identifier="profile-edit-date-of-birth-input"
                                [formControlName]="'dateOfBirth'"
                                [max]="currentDate | date:'yyyy-MM-dd'"
                                [tabindex]="1"
                            >
                            </ion-input>
                            @if (!userForm.get('dateOfBirth')?.valid) {
                            <div class="profile-edit-page__error-message">
                                <app-invalid-input-error-message
                                    data-test="date-validation"
                                    errorMessage="Please enter a date in the past"
                                />
                            </div>
                            }
                        </div>
                    </ion-item>
                </ion-card>
            </section>

            <section class="profile-edit-page__owner-section">
                <ion-card class="profile-edit-page__section-card">
                    <ion-item class="profile-edit-page__owner-section-item" lines="full">
                        <ion-input
                            class="profile-edit-page__input"
                            autocapitalize="words"
                            placeholder="Your name"
                            label="Owner name"
                            labelPlacement="stacked"
                            maxlength="32"
                            type="text"
                            appTrackClick
                            identifier="profile-edit-ownername-input"
                            data-test="profile-edit-ownerName-input"
                            [formControlName]="'ownerName'"
                            [tabindex]="2"
                        />
                    </ion-item>
                    @if(signInProvider() === 'password') {
                    <ion-item class="profile-edit-page__owner-section-item" lines="none">
                        <ion-input
                            class="profile-edit-page__input"
                            placeholder="Your e-mail"
                            label="Email"
                            labelPlacement="stacked"
                            readonly="true"
                            maxlength="64"
                            type="text"
                            appTrackClick
                            identifier="profile-edit-email-input"
                            data-test="profile-edit-email-input"
                            [formControlName]="'mail'"
                            [tabindex]="3"
                            (click)="showIsReadonlyModal()"
                        />
                    </ion-item>
                    } @else {
                    <ion-item class="profile-edit-page__owner-section-item" lines="none">
                        <ion-input
                            class="profile-edit-page__input"
                            label="Email"
                            labelPlacement="stacked"
                            readonly="true"
                            type="text"
                            placeholder="Logged in with"
                        />
                        <i
                            class="profile-edit-page__provider-logo fa-brands fa-{{signInProviderLogoString}}"
                            [style.left]="platformIsAndroid() ? '32.5%' : '36%'"
                        ></i>
                    </ion-item>

                    }
                </ion-card>
            </section>
        </form>

        <section class="profile-edit-page__more-section" [ngClass]="{'footer-padding': userForm.dirty }">
            <ion-list lines="none">
                <ion-item class="profile-edit-page__more-section-item" lines="none">
                    <h2 class="profile-edit-page__more-section-header">More</h2>
                </ion-item>
                @if (shouldShowNotificationLink()) {
                <ion-item
                    class="profile-edit-page__more-section-item"
                    button
                    appTrackClick
                    identifier="profile-open-application-details-btn"
                    data-test="profile-open-application-details-btn"
                    [detail]="false"
                    [routerLink]="['/main/today/notifications']"
                >
                    <i class="fa-light fa-bell profile-edit-page__start-icon"></i>
                    Notifications
                </ion-item>
                } @else {
                <ion-item
                    class="profile-edit-page__more-section-item"
                    button
                    appTrackClick
                    identifier="profile-open-application-details-btn"
                    data-test="profile-open-application-details-btn"
                    [detail]="false"
                    (click)="openApplicationDetails({ios: platformIsIos(), android: platformIsAndroid(), capacitor: platformIsCapacitor()})"
                >
                    Notifications
                </ion-item>
                }
                <ion-item
                    class="profile-edit-page__more-section-item"
                    lines="none"
                    button
                    appTrackClick
                    identifier="profile-share-btn"
                    data-test="profile-share-btn"
                    [detail]="false"
                    (click)="share()"
                >
                    <i class="fa-light fa-share-nodes profile-edit-page__start-icon"></i>
                    Invite a friend
                </ion-item>
                @if (subscriptionLink) {
                <ion-item
                    class="profile-edit-page__more-section-item"
                    button
                    appTrackClick
                    identifier="profile-open-subscriptions-btn"
                    data-test="profile-open-subscriptions-btn"
                    [detail]="false"
                    (click)="openSubscriptions(subscriptionLink)"
                >
                    Subscriptions
                </ion-item>
                }
                <ion-item
                    class="profile-edit-page__more-section-item"
                    button
                    appTrackClick
                    identifier="profile-view-terms-btn"
                    data-test="profile-view-terms-btn"
                    [detail]="false"
                    (click)="showTerms(countryCode())"
                >
                    Terms & Conditions
                </ion-item>
                <ion-item
                    class="profile-edit-page__more-section-item"
                    button
                    appTrackClick
                    identifier="profile-view-privacy-btn"
                    data-test="profile-view-privacy-btn"
                    [detail]="false"
                    (click)="showPrivacy(countryCode())"
                >
                    Privacy
                </ion-item>
                <ion-item
                    class="profile-edit-page__more-section-item"
                    button
                    lines="none"
                    data-test="logout-btn"
                    appTrackClick
                    identifier="profile-logout-btn"
                    [detail]="false"
                    (click)="logout()"
                >
                    Logout
                </ion-item>
                <ion-item
                    class="profile-edit-page__delete-account profile-edit-page__more-section-item"
                    button
                    appTrackClick
                    identifier="show-delete-account-btn"
                    data-test="show-delete-account-btn"
                    [detail]="false"
                    (click)="showDeleteAccount()"
                >
                    Delete Account
                </ion-item>
            </ion-list>
        </section>
    </div>
</ion-content>

@if (!isInputFocusedOnAndroid()) {
<ion-footer class="ion-text-center gradient-footer" [ngStyle]="{'visibility': userForm.dirty ? 'visible' : 'hidden'}">
    <ion-button
        appTrackClick
        identifier="profile-edit-save-btn"
        data-test="save-button"
        color="max"
        expand="block"
        [disabled]="!userForm.get('dateOfBirth')?.valid"
        (click)="saveProfile()"
    >
        Save
    </ion-button>
</ion-footer>
}
