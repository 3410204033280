import { createAction, props } from '@ngrx/store';
import { CreateCourseProgressEntry } from './course-progress.model';

export const TRIGGERED_FROM_ONBOARDING_CORRELATION_ID = 'Triggered from onboarding';
export const TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID = 'Triggered from my journey';
export const TRIGGERED_FROM_STEP_CORRELATION_ID = 'Triggered from step';

export const triggerCreateManyCourseProgress = createAction(
    '[Course Progress] Trigger Create Many Course Progress',
    props<{
        courseProgress: CreateCourseProgressEntry[];
        correlationId?: string;
    }>(),
);
