import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { map, Observable } from 'rxjs';
import { InternalDogDto, internalPaths } from '@shared/user-api-interface';
import { httpOptions } from '@frontend/utility/constants';
import { Dog } from '../models/dog.model';
import { mapInternalDogDtoToDog } from '../utils/household.utils';
import { toUTC } from '@shared/utils/typescript';

@Injectable({
    providedIn: 'root',
})
export class DogService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public patchDog(dogId: string, dog: Partial<Dog>): Observable<Dog> {
        return this.http
            .patch<InternalDogDto>(
                `${this.environment.internalApiUrl}/api/${internalPaths.dogPath}/${dogId}`,
                {
                    ...dog,
                    dateOfArrival: dog.dateOfArrival ? toUTC(dog.dateOfArrival).getTime() : undefined,
                    dateOfBirth: dog.dateOfBirth ? toUTC(dog.dateOfBirth).getTime() : undefined,
                    dateOfOnboarding: dog.dateOfOnboarding ? toUTC(dog.dateOfOnboarding).getTime() : undefined,
                },
                httpOptions,
            )
            .pipe(map(mapInternalDogDtoToDog));
    }
}
