@for (option of options(); track option.title; let i = $index) {
    <button
        [class]="'multi-select-button as-div ' + option.backgroundColor"
        [class.multi-select-button--selected]="isSelected(option.value)"
        type="button"
        [id]="option.value"
        [attr.data-test]="'multi-select-button-' + option.value"
        (click)="onOptionSelected(option.value)"
    >
        <p
            class="multi-select-button__text"
            [ngStyle]="{ color: 'var(--ion-color-' + option.backgroundColor + '-contrast)' }"
        >
            {{ option.title }}
        </p>

        <img class="multi-select-button__image" [src]="option.image" [alt]="option.alt" />

        @if (isSelected(option.value)) {
            <div class="multi-select-button__selected-icon-container">
                <i class="multi-select-button__selected-icon fa-solid fa-circle-check"></i>
            </div>
        } @else {
            <div class="multi-select-button__selected-icon-container">
                <i class="multi-select-button__selected-icon fa-light fa-circle"></i>
            </div>
        }
    </button>
}
