<ion-content class="onboarding-ion-content" [fullscreen]="true" [scrollY]="false">
    <main
        class="creating-plan page-content page-content-padding-top ion-justify-content-start"
        [ngClass]="{
            'creating-plan--fade-out-cards': fadeOutCards,
            'creating-plan--fade-in-bailey-gif': fadeInBailey,
            'creating-plan--fade-to-white': fadeOutBailey
        }"
    >
        <div class="creating-plan__background-image"></div>

        <section class="creating-plan__heading">
            <img
                class="creating-plan__zigzag-logo"
                [src]="'assets/images/zigzag/zigzag-logo.png'"
                alt="The Zigzag logo"
                data-test="creating-plan-zigzag-logo"
            />
            <h1 class="creating-plan__waiting-title" data-test="creating-plan-waiting-title">
                Hang on! We’re creating {{ dogName() }}’s personal journey
            </h1>
        </section>

        <app-onboarding-creating-plan-cards class="creating-plan__cards" (complete)="onCardsLoaded()" />

        <img
            class="creating-plan__journey-ready-image"
            src="assets/images/bailey/bailey-runs-towards-you.gif"
            alt="Bailey runs towards you with excitement"
            data-test="creating-plan-ready-image"
        />

        <h1 class="creating-plan__ready-title ion-text-center" data-test="creating-plan-ready-title">
            {{ dogName() }}’s personal journey is ready!
        </h1>
    </main>
</ion-content>
