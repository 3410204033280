import { IterablePostSendEmailCommand } from '../commands/iterable-post-send-email.command';

export class InternalIterablePostSendEmailCommandMock {
    private defaultValue: IterablePostSendEmailCommand = {
        recipientUserId: 'user-id',
        campaignId: 1,
        dataFields: {
            locale: 'en-GB',
        },
    };

    constructor(overrides?: Partial<IterablePostSendEmailCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostSendEmailCommand {
        return this.defaultValue;
    }
}
