import { Component, inject, OnInit, output } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { delay } from '@shared/utils/typescript';
import { CardSteps } from './creating-plan-cards.model';
import { selectCreatingPlanCards } from './creating-plan-cards.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-creating-plan-cards',
    templateUrl: 'creating-plan-cards.component.html',
    styleUrl: 'creating-plan-cards.component.scss',
    imports: [IonSpinner],
})
export class OnboardingCreatingPlanCardsComponent implements OnInit {
    private readonly store = inject(Store);

    public complete = output();

    public cards = this.store.selectSignal(selectCreatingPlanCards);

    public cardSteps: CardSteps[] = this.cards().map(() => ({
        isLoading: true,
        showIconAndHeading: false,
        visibleRows: 0,
    }));

    private readonly delayWithinCards = 2000;
    private readonly delayBetweenCards = 500;

    public ngOnInit(): void {
        void this.displayCards();
    }

    private async displayCards() {
        const lastCardIndex = this.cards().length - 1;

        for (let cardIndex = 0; cardIndex < this.cards().length; cardIndex++) {
            const card = this.cards()[cardIndex];

            // Display title
            this.cardSteps[cardIndex].showIconAndHeading = true;

            const numOfRows = card.rows?.length ?? 0;

            // Display rows
            while (this.cardSteps[cardIndex].visibleRows < numOfRows) {
                await delay(this.delayWithinCards);

                this.cardSteps[cardIndex].visibleRows++;
            }

            if (cardIndex !== lastCardIndex) {
                await delay(this.delayBetweenCards);

                this.cardSteps[cardIndex].isLoading = false;
            }
        }

        // Final delay after the last card
        await delay(this.delayWithinCards);

        this.cardSteps[lastCardIndex].isLoading = false;

        this.complete.emit();
    }
}
