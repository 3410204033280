import { inject, Injectable } from '@angular/core';
import { IConfigCatClient } from 'configcat-common';
import { User } from 'configcat-common/lib/User';
import * as configCat from 'configcat-js';
import { SettingKeyValue } from 'configcat-js';
import { CONFIGCAT_CONFIG, ConfigCatConfig } from '../injection-tokens';
import { ConfigCatServiceAbstract } from './config-cat.service.abstract';

@Injectable({
    providedIn: 'root',
})
export class ConfigCatService implements ConfigCatServiceAbstract {
    private readonly configCatConfig = inject<ConfigCatConfig>(CONFIGCAT_CONFIG);

    private configCatClient: IConfigCatClient | undefined;

    async getConfig(userObject: User): Promise<SettingKeyValue[]> {
        if (!this.configCatClient) {
            this.configCatClient = configCat.getClient(this.configCatConfig.apiKey);
        }

        return this.configCatClient ? this.configCatClient.getAllValuesAsync(userObject) : [];
    }
}
