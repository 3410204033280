<div>
    @if (splits()) {
        @if (splits().length !== 0) {
            <div>
                @for (split of splits(); track trackByFn(i, split); let i = $index) {
                    <ion-card
                        [ngStyle]="{ transform: 'translate(0px,' + (split.coordinates.y - 25) + 'px)' }"
                        data-test="course-path-split"
                    >
                        <div class="split">
                            <div>
                                <h3 class="no-margin" data-test="course-path-split-title">Part {{ i + 1 }}</h3>
                            </div>
                            @if (split.active) {
                                <p class="progress s" data-test="course-path-split-progress">
                                    <b>{{ split.numberOfCompletedSteps }}/{{ split.totalSteps }}</b>
                                </p>
                            } @else {
                                @if (selectContentUnlocked()) {
                                    <ion-button
                                        class="s ignore-screen-based-font-scaling"
                                        appTrackClick
                                        identifier="course-jump-ahead-btn"
                                        size="small"
                                        color="max"
                                        data-test="course-split-jump-ahead-btn"
                                        (click)="jumpAheadClicked(split)"
                                        >Jump ahead
                                    </ion-button>
                                }
                            }
                        </div>
                    </ion-card>
                }
            </div>
        }
    }
</div>
