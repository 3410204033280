import { inject, Injectable } from '@angular/core';
import { routeTo, routeToPrevious } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { routeToAiChat } from '../../../chat/chat.actions';
import {
    nourishCalculateFoodButtonClicked,
    nourishProductHeaderBackButtonClicked,
    nourishProductTileClicked,
    nourishSuggestedAiChatQuestionClicked,
    nourishTileClicked,
} from '../nourish.actions';

@Injectable()
export class NourishRoutingEffects {
    private readonly actions$ = inject(Actions);

    routeToNourishFoodCalculator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            map(() => {
                return routeTo({ dtos: ['main', 'nourish', 'food-portion-calculator'] });
            }),
        );
    });

    routeForwardToNourish$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(() => {
                return routeTo({ dtos: ['main', 'nourish'] });
            }),
        );
    });

    routeBackToNourish$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductHeaderBackButtonClicked),
            map(() => {
                return routeToPrevious({ dtos: ['main', 'nourish'] });
            }),
        );
    });

    routeToProductPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            map(({ id }) => {
                return routeTo({
                    dtos: ['main', 'nourish', 'product', id],
                });
            }),
        );
    });

    routeToAiChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishSuggestedAiChatQuestionClicked),
            map(({ optionId }) => {
                return routeToAiChat({ message: optionId });
            }),
        );
    });
}
