<h3 class="no-margin">{{ ourPickStringFormatted() }}s</h3>

@if (productTiles().length > 1) {
    <ui-slider [gap]="20" [lastItemPadding]="20">
        @for (tile of productTiles(); track tile.title) {
            <ui-slider-item>
                <ng-container *ngTemplateOutlet="productTile; context: { tile: tile, fullWidth: false }"></ng-container>
            </ui-slider-item>
        }
    </ui-slider>
} @else {
    <ng-container *ngTemplateOutlet="productTile; context: { tile: productTiles()[0], fullWidth: true }"></ng-container>
}

<ng-template #productTile let-tile="tile" let-fullWidth="fullWidth">
    <app-nourish-product-tile
        [title]="tile.title"
        [chip]="tile.chip"
        [image]="tile.image"
        [colour]="tile.colour"
        [buttonLabelColour]="tile.buttonLabelColour"
        [fullWidth]="fullWidth"
        (click)="onClickProductTile(tile.id)"
    />
</ng-template>
