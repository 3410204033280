import { createSelector } from '@ngrx/store';
import { selectIsCountryCodeResolved, selectIsNorthAmericaCountryCode } from '@frontend/data-access/user/household';
import { appStatusFeature } from './app-component.reducer';
import { AppReadyStatus } from './app-ready-status.model';

export const selectShowPurinaBadge = createSelector(
    selectIsCountryCodeResolved,
    selectIsNorthAmericaCountryCode,
    (resolved, isNACountryCode) => resolved && isNACountryCode,
);

export const selectShowAppOfTheDayBadge = createSelector(
    selectIsCountryCodeResolved,
    selectIsNorthAmericaCountryCode,
    (resolved, isNonNACountryCode) => {
        return resolved && !isNonNACountryCode;
    },
);

export const selectAppComponentVm = createSelector(
    appStatusFeature.selectAppStatusState,
    selectShowPurinaBadge,
    selectShowAppOfTheDayBadge,
    (appComponentState, showPurina, showAppOfTheDay) => {
        return {
            appReset: appComponentState.appStatus === AppReadyStatus.initial,
            appLoading: appComponentState.appStatus === AppReadyStatus.loading,
            appReady: appComponentState.appStatus === AppReadyStatus.ready,
            showPurina,
            showAppOfTheDay,
        };
    },
);
