import { Component, inject, model, OnInit } from '@angular/core';
import { PurchaseProduct, purchaseProduct, restoreSubscription } from '@frontend/data-access/subscription';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonChip, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { PaymentModalPlanFeaturesListComponent } from '../payment-modal-plan-features-list/payment-modal-plan-features-list.component';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentProductCard } from '../payment-modal-product/payment-product-card.model';
import { PaymentModalTermsComponent } from '../payment-modal-terms/payment-modal-terms.component';
import {
    basicPlanDescriptionList,
    defaultPlanDescriptionList,
    PAYMENT_MODAL_PLAN_COMPARISON_ID,
} from '../payment-modal.constant';
import { selectPaymentTieredPricingModalVm } from './payment-modal-tiered-pricing.selectors';
import { TieredPricingComparisonModalComponent } from './tiered-pricing-comparison-modal/tiered-pricing-comparison-modal.component';
import { TieredPricingComparisonToggleComponent } from './tiered-pricing-comparison-toggle/tiered-pricing-comparison-toggle.component';

@Component({
    standalone: true,
    selector: 'app-payment-modal-tiered-pricing',
    templateUrl: './payment-modal-tiered-pricing.component.html',
    styleUrl: './payment-modal-tiered-pricing.component.scss',
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        IonChip,
        PaymentModalTermsComponent,
        PaymentModalPlanFeaturesListComponent,
        TieredPricingComparisonToggleComponent,
        PaymentModalProductComponent,
    ],
})
export class PaymentModalTieredPricingComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    protected readonly basicFeaturesList = basicPlanDescriptionList;
    protected readonly defaultFeaturesList = defaultPlanDescriptionList;
    protected readonly Color = Color;

    public vm = this.store.selectSignal(selectPaymentTieredPricingModalVm);

    public selectedProduct = model<PaymentProductCard>();
    public premiumSelected = true;

    ngOnInit() {
        this.selectProduct(this.vm().premiumProducts[0]);
    }

    public selectProduct(product: PaymentProductCard) {
        this.selectedProduct.set(product);
    }

    public selectTier(tier: 'premium' | 'basic') {
        if (tier === 'premium') {
            this.premiumSelected = true;
            this.selectProduct(this.vm().premiumProducts[0]);
        } else {
            this.premiumSelected = false;
            this.selectProduct(this.vm().basicProducts[0]);
        }
    }

    public onPurchaseClick(product: PurchaseProduct): void {
        this.store.dispatch(purchaseProduct({ data: product }));
    }

    public async launchComparisonModal() {
        const selectedValues = await this.modalService.showModalWithCallbackAsync<{
            isPremiumSelected: boolean;
            selectedProduct: PaymentProductCard;
        }>({
            id: PAYMENT_MODAL_PLAN_COMPARISON_ID,
            component: TieredPricingComparisonModalComponent,
            componentProps: {
                premiumProducts: this.vm().premiumProducts,
                basicProducts: this.vm().basicProducts,
                initialIsPremiumSelected: this.premiumSelected,
                initialSelectedProduct: this.selectedProduct() ?? this.vm().premiumProducts[0],
            },
            cssClass: ['modal', 'modal-compare-plans'],
        });

        this.premiumSelected = selectedValues?.isPremiumSelected ?? true;
        this.selectedProduct.set(selectedValues?.selectedProduct ?? this.vm().premiumProducts[0]);
    }

    public onRestoreClick(): void {
        this.store.dispatch(restoreSubscription());
    }
}
