import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LoadingState } from '@frontend/data-access/shared-models';
import { Household } from '../models/household.model';
import {
    createHousehold,
    createHouseholdFailure,
    createHouseholdSuccess,
    getHousehold,
    getHouseholdFailure,
    getHouseholdSuccess,
} from './household.actions';
import { upsertDogInHousehold, upsertUserInHousehold } from '../utils/household.utils';
import { patchDog, patchDogFailure, patchDogSuccess } from './dog/dog.actions';
import {
    patchHouseholdUser,
    patchHouseholdUserFailure,
    patchHouseholdUserSuccess,
} from './household-user/household-user.actions';

export interface HouseholdState {
    household: Household | undefined;
    getHouseholdLoadingState: LoadingState;
    createHouseholdLoadingState: LoadingState;
    patchDogLoadingState: LoadingState;
    patchHouseholdUserLoadingState: LoadingState;
}

export const initialState: HouseholdState = {
    household: undefined,
    getHouseholdLoadingState: LoadingState.INIT,
    createHouseholdLoadingState: LoadingState.INIT,
    patchDogLoadingState: LoadingState.INIT,
    patchHouseholdUserLoadingState: LoadingState.INIT,
};

export const householdFeature = createFeature({
    name: 'household',
    reducer: createReducer(
        initialState,
        on(getHousehold, (state) => ({
            ...state,
            getHouseHoldLoadingState: LoadingState.LOADING,
        })),
        on(getHouseholdSuccess, (state, action) => ({
            ...state,
            household: action.household,
            getHouseHoldLoadingState: LoadingState.LOADED,
        })),
        on(getHouseholdFailure, (state) => ({
            ...state,
            household: undefined,
            getHouseHoldLoadingState: LoadingState.LOADED,
        })),
        on(createHousehold, (state) => ({
            ...state,
            createHouseHoldLoadingState: LoadingState.LOADING,
        })),
        on(createHouseholdSuccess, (state, { household }) => ({
            ...state,
            household,
            createHouseHoldLoadingState: LoadingState.LOADED,
        })),
        on(createHouseholdFailure, (state) => ({
            ...state,
            household: undefined,
            createHouseHoldLoadingState: LoadingState.LOADED,
        })),
        on(patchDog, (state) => ({
            ...state,
            patchDogLoadingState: LoadingState.LOADING,
        })),
        on(patchDogSuccess, (state, { dog }) => ({
            ...state,
            household: upsertDogInHousehold(state.household, dog),
            patchDogLoadingState: LoadingState.LOADED,
        })),
        on(patchDogFailure, (state) => ({
            ...state,
            patchDogLoadingState: LoadingState.LOADED,
        })),
        on(patchHouseholdUser, (state) => ({
            ...state,
            patchHouseholdUserLoadingState: LoadingState.LOADING,
        })),
        on(patchHouseholdUserSuccess, (state, { user }) => ({
            ...state,
            household: upsertUserInHousehold(state.household, user),
            patchHouseholdUserLoadingState: LoadingState.LOADED,
        })),
        on(patchHouseholdUserFailure, (state) => ({
            ...state,
            patchHouseholdUserLoadingState: LoadingState.LOADED,
        })),
    ),
    extraSelectors: ({ selectHousehold }) => ({
        selectCurrentUser: createSelector(selectHousehold, (household) => household?.currentUser),
        selectCurrentDog: createSelector(selectHousehold, (household) => household?.dogs[0]),
        selectCurrentUserId: createSelector(selectHousehold, (household) => household?.currentUser.id),
        selectPartners: createSelector(selectHousehold, (household) => household?.currentUser.partners),
        selectEmail: createSelector(selectHousehold, (household) => household?.currentUser.mail),
        selectOwnerName: createSelector(selectHousehold, (household) => household?.currentUser.name ?? ''),
        selectUserToken: createSelector(selectHousehold, (household) => household?.currentUser.userToken),
        selectCountryCode: createSelector(selectHousehold, (household) => household?.currentUser.countryCode),
        selectDateOfOnboarding: createSelector(selectHousehold, (household) => household?.currentUser.dateOfOnboarding),
        selectDateOfOnboardingOrToday: createSelector(
            selectHousehold,
            (household) => household?.currentUser.dateOfOnboarding ?? new Date(),
        ),
        selectGender: createSelector(selectHousehold, (household) => household?.dogs[0].gender),
        selectIsRescuePup: createSelector(selectHousehold, (household) => household?.dogs[0].isRescue),
        selectDateOfArrival: createSelector(selectHousehold, (household) => household?.dogs[0].dateOfArrival),
        selectDateOfBirth: createSelector(selectHousehold, (household) => household?.dogs[0].dateOfBirth),
        selectIsApproximateDateOfBirth: createSelector(
            selectHousehold,
            (household) => household?.dogs[0].isApproximateDateOfBirth,
        ),
        selectBreedId: createSelector(selectHousehold, (household) => household?.dogs[0].breedId),
        selectHasArrivedOrUndefined: createSelector(selectHousehold, (household) => household?.dogs[0].hasArrived),
        selectDogName: createSelector(selectHousehold, (household) => household?.dogs[0].name ?? ''),
    }),
});
