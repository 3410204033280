import { SaIterableDataFields } from '../../commands/user/sa-iterable-upsert-user.command';
import { InternalSaIterableDogFieldsMock } from './internal-sa-iterable-dog-fields.mock';
import { InternalSaIterableResultFieldsMock } from './internal-sa-iterable-results-fields.mock';

export class InternalSaIterableDataFieldsMock {
    private defaultValue: SaIterableDataFields = {
        country: 'UK',
        userId: 'userId',
        dog: new InternalSaIterableDogFieldsMock().value,
        results: new InternalSaIterableResultFieldsMock().value,
        consentGiven: true,
    };

    constructor(overrides?: Partial<SaIterableDataFields>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SaIterableDataFields {
        return this.defaultValue;
    }
}
