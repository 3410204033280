import { NgIf } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { StarRatingComponent } from '@frontend/ui';

@Component({
    selector: 'app-lesson-rating',
    templateUrl: './lesson-rating.component.html',
    styleUrls: ['./lesson-rating.component.scss'],
    standalone: true,
    imports: [NgIf, StarRatingComponent, TextReplacementPipe],
})
export class LessonRatingComponent {
    public rating = input(0);
    public maxRating = input(0);

    public ratingChangedEvent = output<{ ratedStep: boolean; currentRating: number }>();

    public ratedStep = false;
    public ratingText = 'Rate how well [NAME] did this lesson';
    public currentRating = 0;

    public onRatingChange(updatedRating: number): void {
        this.ratedStep = true;

        switch (updatedRating) {
            case 1:
                this.ratingText = 'Not quite there yet ...';
                break;
            case 2:
                this.ratingText = '[NAME] needs more training';
                break;
            case 3:
                this.ratingText = '[NAME] is almost there!';
                break;
            case 4:
                this.ratingText = '[NAME] did great!';
                break;
            case 5:
                this.ratingText = 'Huge success!';
                break;
        }

        this.currentRating = updatedRating;

        this.ratingChangedEvent.emit({
            ratedStep: this.ratedStep,
            currentRating: this.currentRating,
        });
    }
}
