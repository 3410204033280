<section class="payment-modal-default">
    <h1>
        Choose a plan and
        {{ vm().hasHistoricalPurchase ? 'continue your journey' : 'start your free trial now' }}
    </h1>
    <p class="s">Cancel anytime within your free trial period</p>
    <app-payment-modal-plan-features-list [features]="defaultFeaturesList" />
</section>

<section class="payment-modal-default-products">
    @for (product of vm().defaultProducts; track product.identifier) {
        <app-payment-modal-product
            [isSelected]="product.identifier === selectedProduct().identifier"
            [product]="product"
            (productClicked)="onProductClick($event)"
        />
    }

    <ion-footer class="payment-modal-default-products__product-footer">
        <div class="payment-modal-default-products__button-group">
            <ion-button
                appTrackClick
                [identifier]="'default-paywall-payment-purchase-' + selectedProduct().identifier"
                color="max"
                expand="full"
                shape="round"
                [disabled]="!selectedProduct() || vm().isPurchaseInProgress"
                (click)="onPurchaseClick(selectedProduct())"
            >
                {{ vm().hasHistoricalPurchase ? 'Start my subscription' : 'Start my free trial' }}
            </ion-button>
            <button
                class="zz-button"
                appTrackClick
                identifier="default-paywall-payment-restore-button"
                (click)="onRestoreClick()"
            >
                Restore purchases
            </button>
        </div>
        <app-payment-modal-terms />
    </ion-footer>
</section>
