import { IterableSendPushNotificationCommand } from '../commands/iterable-send-push-notification.command';

export class InternalIterableSendPushNotificationCommandMock {
    private defaultValue: IterableSendPushNotificationCommand = {
        recipientUserId: 'user-id',
        campaignId: 1,
    };

    constructor(overrides?: Partial<IterableSendPushNotificationCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterableSendPushNotificationCommand {
        return this.defaultValue;
    }
}
