import { Component, inject, ViewChild } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonInput, IonItem } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';
import { AnalyticsTrackClickDirective, AnalyticsTrackInputDirective } from '@frontend/feature/directives';
import { launchRatingPrompt } from '@frontend/data-access/app-rating';

@Component({
    standalone: true,
    selector: 'app-rating-qualifier-modal',
    templateUrl: './rating-qualifier-modal.component.html',
    styleUrls: ['./rating-qualifier-modal.component.scss'],
    imports: [AnalyticsTrackClickDirective, AnalyticsTrackInputDirective, IonContent, IonButton, IonItem, IonInput],
})
export class RatingQualifierModalComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @ViewChild('reasonInput') reasonInput: IonInput | undefined;

    modalPage = 1;

    launchRatingPrompt() {
        this.store.dispatch(launchRatingPrompt());
        this.closeModal();
    }

    goToPageTwo() {
        this.modalPage = 2;
    }

    finishedProvidingReason(trigger: string) {
        this.store.dispatch(
            trackEvent({
                eventName: '[Rating Qualifier] Negative Comment',
                eventProperties: {
                    trigger,
                    comment: this.reasonInput?.value,
                },
            }),
        );
        this.closeModal();
    }

    closeModal() {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
