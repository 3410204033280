import { Weekday } from '@capacitor/local-notifications';
import { createAction, props } from '@ngrx/store';

export const launchTrainingReminderModal = createAction(
    '[Training Reminder] Launch Training Reminder Modal',
    props<{ dogName: string; trigger: string }>(),
);

export const trainingReminderModalCloseClicked = createAction(
    '[Training Reminder] Training Reminder Modal Close Clicked',
);

export const closeTrainingReminderModal = createAction('[Training Reminder] Close Training Reminder Modal');

export const createTrainingReminder = createAction(
    '[Training Reminder] Create Training Reminder',
    props<{ dogName: string; time: Date; weekdays: Weekday[] }>(),
);
