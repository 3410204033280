import { configCatFeature } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { AnalyticsProfile } from '@frontend/data-access/analytics';
import { mapConfigCatFlagsToKeyValuePairs } from './analytics.utils';
import { householdFeature, selectProfileBreedInfo } from '@frontend/data-access/user/household';

export const selectAnalyticsProfile = createSelector(
    householdFeature.selectCurrentUser,
    householdFeature.selectCurrentDog,
    householdFeature.selectHouseholdId,
    selectProfileBreedInfo,
    configCatFeature.selectConfigCatFlags,
    (user, dog, householdId, breedInfo, featureFlags): AnalyticsProfile => {
        const returnProfile: AnalyticsProfile = {
            dogId: dog?.id,
            householdId,
            ownerName: user?.name,
            mail: user?.mail,
            dateOfOnboarding: user?.dateOfOnboarding,
            partners: user?.partners,
            $email: user?.mail ?? '',
            countryCode: user?.countryCode,
            purinaMarketingAccepted: user?.purinaMarketingAccepted,
            name: dog?.name,
            dateOfBirth: dog?.dateOfBirth,
            gender: dog?.gender,
            dateOfArrival: dog?.dateOfArrival,
            hasArrived: dog?.hasArrived,
            isRescuePup: dog?.isRescue ?? false,
            breed: breedInfo?.name,
            breedType: breedInfo?.breedGroup.name,
            featureFlags: mapConfigCatFlagsToKeyValuePairs(featureFlags),
        };

        if (dog?.dateOfOnboarding === undefined) {
            returnProfile.pupAtHomeWhenOnboardingCompleted = dog?.hasArrived;
        }

        return returnProfile;
    },
);

export const selectAnalyticsFeatureFlagProfile = createSelector(
    configCatFeature.selectConfigCatFlags,
    (featureFlags) => ({
        featureFlags: mapConfigCatFlagsToKeyValuePairs(featureFlags),
    }),
);
