import { DomainPostUserFeedbackContentCommand } from '../../../commands/domain-api/feedback/domain-post-user-feedback-content.command';

export class DomainPostUserFeedbackContentCommandMock {
    private defaultValue: DomainPostUserFeedbackContentCommand = {
        userId: 'user-123',
        contentfulId: 'contentful-id-123',
        contentTypeId: 'type-123',
        comment: undefined,
        thumbsDown: false,
    };

    constructor(overrides?: Partial<DomainPostUserFeedbackContentCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostUserFeedbackContentCommand {
        return this.defaultValue;
    }
}
