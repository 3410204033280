import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { GenderToGenderFaIcon, GenderToYouthTitle } from '@shared/user-domain';
import { mapDateOfBirthToAgeInMonths, mapDateOfBirthToAgeString } from '@shared/utils/typescript';
import { isBefore, isSameDay } from 'date-fns';
import { selectNeedsFromSelectedNeeds } from '../data-access/onboarding.selectors';
import {
    householdFeature,
    selectAgeInWeeks,
    selectBreedName,
    selectHasArrived,
} from '@frontend/data-access/user/household';
import { Card, CardRow, CardRowItem } from './creating-plan-cards.model';

export const selectDateOfBirthString = createSelector(
    householdFeature.selectDateOfBirth,
    timeFeature.selectToday,
    (dateOfBirth, today) => {
        if (!dateOfBirth) {
            return '';
        }

        if (isSameDay(dateOfBirth, today)) {
            return 'Happy Birthday!';
        }

        const ageInMonths = mapDateOfBirthToAgeInMonths(dateOfBirth, today);

        // If the dog is older than 2 years, we don't want to show anything
        if (ageInMonths >= 24) {
            return '';
        }

        return `${mapDateOfBirthToAgeString(dateOfBirth)} old`;
    },
);

export const selectDateOfArrivalString = createSelector(
    householdFeature.selectDateOfArrival,
    selectHasArrived,
    timeFeature.selectToday,
    selectAgeInWeeks,
    (dateOfArrival, hasArrived, today, ageInWeeks) => {
        if (!dateOfArrival && hasArrived && ageInWeeks && ageInWeeks > 9) {
            return 'Is at home';
        }

        if (!dateOfArrival) {
            return '';
        }

        if (!hasArrived && isSameDay(today, dateOfArrival!)) {
            return 'Arriving today';
        }

        // This case handles has arrived and is the first day at home, is not the first day at home, OR if somehow the date of arrival is in the past
        if (isBefore(dateOfArrival!, today) || (hasArrived && isSameDay(today, dateOfArrival!))) {
            return 'Is at home';
        }

        return 'Arriving in ' + mapDateOfBirthToAgeString(today, dateOfArrival!);
    },
);

export const selectNeedsString = createSelector(selectNeedsFromSelectedNeeds, (needs) => {
    return needs.map((need) => need.title).join(', ');
});

export const selectCreatingPlanCards = createSelector(
    householdFeature.selectDogName,
    selectBreedName,
    householdFeature.selectGender,
    selectDateOfBirthString,
    householdFeature.selectIsRescuePup,
    selectDateOfArrivalString,
    selectNeedsString,
    (dogName, breedName, gender, dateOfBirthString, isRescue, dateOfArrivalString, needsString) => {
        const needsRow: CardRow | undefined = needsString
            ? {
                  items: [
                      {
                          text: needsString,
                          icon: 'fa-list',
                      },
                  ],
              }
            : undefined;

        const dateOfBirthRowItem: CardRowItem | undefined = dateOfBirthString
            ? {
                  text: dateOfBirthString,
                  icon: 'fa-cake-candles',
              }
            : undefined;

        const cards: Card[] = [
            {
                title: 'Pup info',
                rows: [
                    {
                        items: [
                            {
                                text: dogName,
                                icon: 'fa-signature',
                            },
                            ...(breedName ? [{ text: breedName, icon: 'fa-dog' }] : []),
                        ],
                    },
                    {
                        items: [
                            ...(gender
                                ? [{ text: GenderToYouthTitle[gender], icon: GenderToGenderFaIcon[gender] }]
                                : []),
                            ...(dateOfBirthRowItem ? [dateOfBirthRowItem] : []),
                            ...(isRescue ? [{ text: 'Rescue', icon: 'fa-box' }] : []),
                        ],
                    },
                ],
            },
            {
                title: 'Building a plan',
                rows: [
                    {
                        items: [
                            {
                                text: dateOfArrivalString,
                                icon: 'fa-house',
                            },
                        ],
                    },
                    ...(needsRow ? [needsRow] : []),
                ],
            },
            {
                title: 'Preparing your journey',
            },
        ];

        return cards;
    },
);
