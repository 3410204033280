import { DomainPostHappinessCheckCommand } from '../../../commands/domain-api/happiness-check/domain-post-happiness-check.command';

export class DomainPostHappinessCheckCommandMock {
    private defaultValue: DomainPostHappinessCheckCommand = {
        userId: 'userId',
        ownerHappinessScore: 0.5,
        dogHappinessScore: 0.6,
        timestamp: 0,
    };

    constructor(overrides?: Partial<DomainPostHappinessCheckCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostHappinessCheckCommand {
        return this.defaultValue;
    }
}
