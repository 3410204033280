import { Routes } from '@angular/router';
import { TodayPage } from './today.page';
import { stepRoutes } from '../step/step.routes';

const todayRoutes: Routes = [
    {
        path: '',
        component: TodayPage,
        pathMatch: 'full',
    },
    {
        path: 'step',
        children: stepRoutes,
    },
    {
        path: 'profile',
        loadChildren: () => import('../profile/profile.routes'),
    },
    {
        path: 'chat',
        loadChildren: () => import('../chat/chat.routes'),
    },
    {
        path: 'notifications',
        loadChildren: () => import('../notifications/notifications.routes'),
    },
];

export default todayRoutes;
