import { AdditionalUserInfo as FirebaseAdditionalUserInfo, User as FirebaseUser } from '@angular/fire/auth';
import { AdditionalUserInfo as GoogleAdditionalUserInfo, User as GoogleUser } from '@capacitor-firebase/authentication';
import { GoogleSSOErrorMessages } from '../consts/sign-in-providers.constants';
import { User } from '../models/user.model';
import { FirebaseErrorCode } from '@shared/constants';

export enum AuthenticationErrorMessages {
    GENERIC = 'Oops, looks like something went wrong... Please try again later or contact contact@zigzag.dog.',
    TOO_MANY_REQUESTS = 'Oops, you’ve reached your maximum number of login attempts. Please try again later. Forgot your password? Click the link below.',
    USER_DISABLED = 'Oops, looks like your account has been disabled. Please contact us at contact@zigzag.dog.',
    NETWORK_REQUEST_FAILED = 'Oops, looks like a network error has occurred. Please try again.',
    USER_NOT_FOUND = 'We couldn’t find this account, please try again with a different email or contact contact@zigzag.com.',
    INVALID_CREDENTIAL = 'Oops, the email or password is invalid. Forgot your password? Click the link below.',
    EMAIL_ALREADY_IN_USE = 'This email is already in use, please try another email or go back to ',
    INVALID_ARGUMENT = 'Please open your authentication app and enter the verification code',
    INVALID_CODE = 'Oops, that code was incorrect, please try again',
    QUOTA_EXCEEDED = 'Oops, you have exceeded the number of login attempts. Please try again later',
}

export interface AuthenticationErrorLink {
    text: string;
    route: string[];
}

export interface AuthenticationErrorObject {
    message: AuthenticationErrorMessages;
    link?: AuthenticationErrorLink;
}

const firebaseAuthErrorMap = new Map<string, AuthenticationErrorObject>([
    [FirebaseErrorCode.USER_NOT_FOUND, { message: AuthenticationErrorMessages.USER_NOT_FOUND }],
    [FirebaseErrorCode.INVALID_CREDENTIAL, { message: AuthenticationErrorMessages.INVALID_CREDENTIAL }],
    [FirebaseErrorCode.TOO_MANY_REQUESTS, { message: AuthenticationErrorMessages.TOO_MANY_REQUESTS }],
    [FirebaseErrorCode.USER_DISABLED, { message: AuthenticationErrorMessages.USER_DISABLED }],
    [FirebaseErrorCode.NETWORK_REQUEST_FAILED, { message: AuthenticationErrorMessages.NETWORK_REQUEST_FAILED }],
    [
        FirebaseErrorCode.EMAIL_ALREADY_IN_USE,
        {
            message: AuthenticationErrorMessages.EMAIL_ALREADY_IN_USE,
            link: { text: 'sign in', route: ['auth', 'login'] },
        },
    ],
    [FirebaseErrorCode.INVALID_ARGUMENT, { message: AuthenticationErrorMessages.INVALID_ARGUMENT }],
    [FirebaseErrorCode.INVALID_CODE, { message: AuthenticationErrorMessages.INVALID_CODE }],
    [FirebaseErrorCode.QUOTA_EXCEEDED, { message: AuthenticationErrorMessages.QUOTA_EXCEEDED }],
]);

export const mapAuthenticationError = (error: string | undefined): AuthenticationErrorObject | undefined => {
    if (!error) {
        return undefined;
    }

    return firebaseAuthErrorMap.get(error) ?? { message: AuthenticationErrorMessages.GENERIC };
};

export const mapFireBaseUserToUser = (user: FirebaseUser | GoogleUser | null, ownerName?: string): { user: User } => {
    if (user === undefined || user === null || user.email === null || user.uid === null || user.uid === '') {
        throw new Error('User is not defined');
    }

    return {
        user: {
            id: user.uid,
            email: user.email,
            ownerName,
            signInProvider: user.providerData[0].providerId,
        },
    };
};

export const mapFireBaseUserWithAdditionalUserInfoToUser = (
    user: FirebaseUser | GoogleUser | null,
    additionalUserInfo: FirebaseAdditionalUserInfo | GoogleAdditionalUserInfo,
    ownerName?: string,
): {
    user: User;
    additionalUserInfo: FirebaseAdditionalUserInfo | GoogleAdditionalUserInfo;
    ownerName?: string;
} => {
    return {
        ...mapFireBaseUserToUser(user, ownerName),
        additionalUserInfo,
    };
};

export const isAuthErrorUserCancelled = (error: object): boolean => {
    return (
        ('errorMessage' in error && error.errorMessage === GoogleSSOErrorMessages.UserCancelledSignInFlow) ||
        ('message' in error && error.message === GoogleSSOErrorMessages.UserCancelledSelector)
    );
};
