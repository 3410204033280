import { AxiosError, AxiosHeaders } from 'axios';

export class AxiosErrorMock {
    private defaultValue: AxiosError = new AxiosError(
        'Bad Request',
        '400',
        {
            url: 'mock-url',
            method: 'POST',
            headers: new AxiosHeaders({
                'Content-Type': 'application/json',
            }),
        },
        {},
        {
            status: 400,
            statusText: 'Bad Request',
            data: { message: '' },
            headers: {},
            config: {
                url: 'mock-url',
                method: 'POST',
                headers: new AxiosHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        },
    );

    constructor(message?: string, status?: number, data?: Record<string, unknown>) {
        this.defaultValue.message = message || this.defaultValue.message;
        this.defaultValue.status = status || this.defaultValue.status;

        if (this.defaultValue.response) {
            this.defaultValue.response.status = status || this.defaultValue.response.status;
            this.defaultValue.response.data = data || this.defaultValue.response.data;
        }
    }

    public get value(): AxiosError {
        return this.defaultValue;
    }
}
