import { NgClass } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Component({
    standalone: true,
    selector: 'app-tiered-pricing-comparison-toggle',
    templateUrl: './tiered-pricing-comparison-toggle.component.html',
    styleUrl: './tiered-pricing-comparison-toggle.component.scss',
    imports: [IonicModule, NgClass],
})
export class TieredPricingComparisonToggleComponent {
    public readonly store = inject(Store);

    public isPremiumSelected = input<boolean>(true);
    public selectTier = output<'premium' | 'basic'>();

    basicClicked() {
        this.selectTier.emit('basic');
    }

    premiumClicked() {
        this.selectTier.emit('premium');
    }
}
