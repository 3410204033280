export const SHOW_PAYMENT_MODAL_TRIGGERS = {
    COURSE_PREVIEW_PAGE: 'course-preview-page',
    PAYWALL_DEEPLINK: 'paywall-deeplink',
    LIMITED_OFFER_PAYWALL_DEEPLINK: 'limited-offer-paywall-deeplink',
    STARTUP: 'startup',
    TRIAL_REMINDER_PAYWALL: 'trial-reminder-paywall',
    ON_APP_OPEN_AB_TEST: 'on-app-open-ab-test',
};

export const SHOW_LIMITED_OFFER_MODAL_TRIGGERS = {
    CLOSING_PARENT_PAYMENT_MODAL: 'closing-parent-payment-modal',
    ON_APP_OPEN_AB_TEST: 'on-app-open-ab-test',
};

// to be shown to users when they are in control variants of paywall tests
export const REVENUE_CAT_DEFAULT_OFFERINGS = {
    DEFAULT: 'Subscription-Q20-Y40',
    DISCOUNT_PAYWALL: 'Discount_Paywall_Offerings_July2024',
};

// to be shown to users in the Tiered Pricing AB Test
export const REVENUE_CAT_TIERED_PRICING_AB_TEST_OFFERINGS = {
    DISCOUNT_PAYWALL_TIERED_PRICING_AB_TEST: 'Discount_Paywall_Offerings_Tiered_Pricing_AB',
    TIERED_PRICING_PREMIUM_PLAN: 'Offering_Premium',
    TIERED_PRICING_BASIC_PLAN: 'Offering_Basic',
};
