import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { GetHouseholdEffects } from './store/get-household.effects';
import { householdFeature } from './store/household.reducer';
import { CreateHouseholdEffects } from './store/create-household.effects';
import { HouseholdUserEffects } from './store/household-user/household-user.effects';
import { DogEffects } from './store/dog/dog.effects';
import { DeleteHouseholdEffects } from './store/delete-household.effects';

@NgModule({
    providers: [
        provideEffects([
            GetHouseholdEffects,
            CreateHouseholdEffects,
            HouseholdUserEffects,
            DogEffects,
            DeleteHouseholdEffects,
        ]),
        provideState(householdFeature),
    ],
})
export class ZigzagFrontendDataAccessHouseholdModule {}
