import {
    accountFeature,
    selectAgeInWeeksAtOnboarding,
    selectCountryCode,
    selectHasArrived,
    selectHasArrivedOnDateOfOnboarding,
} from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { getContentfulLocaleFromAlpha2CountryCode } from '@shared/constants';

export const selectGetContentInfo = createSelector(
    accountFeature.selectBreedId,
    selectCountryCode,
    selectAgeInWeeksAtOnboarding,
    selectHasArrivedOnDateOfOnboarding,
    accountFeature.selectIsRescuePup,
    selectHasArrived,
    (breedId, countryCode, ageInWeeks, hasArrivedOnDateOfOnboarding, isRescue, hasArrived) => {
        return {
            breedId,
            locale: getContentfulLocaleFromAlpha2CountryCode(countryCode),
            ageInWeeks,
            hasArrivedOnDateOfOnboarding,
            isRescue,
            hasArrived,
        };
    },
);
