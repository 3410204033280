import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
    courseSliderChanged,
    setCourseSliderScrollToFirstInactiveStep,
    setCourseSliderScrollToTop,
    setSelectedPathCourseIdScrollToFirstInactiveStep,
    setSelectedPathCourseIdScrollToTop,
} from './course-slider.actions';

@Injectable()
export class CourseSliderEffects {
    private readonly actions$ = inject(Actions);

    setSelectedPathSliderCourseIdScrollToTop$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setCourseSliderScrollToTop, courseSliderChanged),
            map(({ courseId }) => setSelectedPathCourseIdScrollToTop({ courseId })),
        );
    });

    setCourseSliderScrollToFirstInactiveStep$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setCourseSliderScrollToFirstInactiveStep),
            map(({ courseId }) => setSelectedPathCourseIdScrollToFirstInactiveStep({ courseId })),
        );
    });

    trackCourseSliderChanged$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(courseSliderChanged),
            map(({ courseId, courseTitle }) => {
                return trackEvent({
                    eventName: '[Course] Course Slider Swiped',
                    eventProperties: { courseId, courseTitle },
                });
            }),
        );
    });
}
