import { PendingLocalNotificationSchema, Weekday } from '@capacitor/local-notifications';

export interface LocalNotificationState {
    notifications: PendingLocalNotificationSchema[];
}

export interface ZigzagLocalNotification {
    notificationIds: number[];
    weekdays: Weekday[];
    weekdaysString: string[];
    hour: number;
    minute: number;
}
