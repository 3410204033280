import { createAction, props } from '@ngrx/store';
import { Household } from '../models/household.model';
import { InternalApiError } from '../models/internal-api-error.model';

export const FROM_FOUND_VALID_USER_SESSION = 'FromFoundValidUserSession';

export type GetHouseholdCorrelationId = typeof FROM_FOUND_VALID_USER_SESSION;

export const getHousehold = createAction(
    '[Household] Get Household',
    props<{
        correlationId?: GetHouseholdCorrelationId;
    }>(),
);

export const getHouseholdSuccess = createAction(
    '[Household] Get Household Success',
    props<{ household: Household; correlationId?: GetHouseholdCorrelationId }>(),
);

export const getHouseholdFailure = createAction('[Household] Get Household Failure', props<{ error: Error }>());

export const createHousehold = createAction(
    '[Household] Create Household',
    props<{
        id?: string;
        email: string;
        accountId: string;
        countryCode: string;
        region: string;
        city: string;
        ownerName?: string;
    }>(),
);

export const createHouseholdSuccess = createAction(
    '[Household] Create Household Success',
    props<{ household: Household }>(),
);

export const createHouseholdFailure = createAction(
    '[Household] Create Household Failure',
    props<{ error: InternalApiError }>(),
);

export const deleteHousehold = createAction('[Household] Delete Household', props<{ householdId: string }>());

export const deleteHouseholdSuccess = createAction('[Household] Delete Household Success');

export const deleteHouseholdFailure = createAction('[Household] Delete Household Failure', props<{ error: Error }>());

export const resetHouseholdErrorState = createAction('[Household] Reset Household Error State');
