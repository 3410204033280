import { Routes } from '@angular/router';
import { AuthGuard } from '@frontend/data-access/user/authentication';
import { authenticationRoutes, LogoutPage } from '@frontend/feature/authentication';
import { LoggedInGuard } from '@frontend/feature/guards';
import { OnboardingCompleteGuard, OnboardingIncompleteGuard, onboardingRoutes } from '@frontend/feature/onboarding';
import { OnboardingCoursePreviewPage, tabRoutes } from '@frontend/zigzag-main';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        canActivate: [LoggedInGuard],
        children: authenticationRoutes,
    },
    {
        path: 'onboarding',
        canActivate: [AuthGuard, OnboardingCompleteGuard],
        data: {
            requiresNewOnboarding: true,
        },
        children: onboardingRoutes,
    },
    {
        path: 'main',
        canActivate: [AuthGuard, OnboardingIncompleteGuard],
        children: tabRoutes,
    },
    {
        path: 'course-preview',
        canActivate: [AuthGuard, OnboardingIncompleteGuard],
        component: OnboardingCoursePreviewPage,
    },
    {
        path: 'logout',
        canActivate: [AuthGuard],
        component: LogoutPage,
        title: 'Logout',
    },
];

export default appRoutes;
