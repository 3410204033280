import { createAction, props } from '@ngrx/store';
import { HappinessCheckData } from '../models/happiness-check.model';

export const submitHappinessCheckData = createAction(
    '[Happiness Check] Submit Happiness Check Data',
    props<{ happinessCheckData: HappinessCheckData }>(),
);

export const submitHappinessCheckDataSuccess = createAction(
    '[Happiness Check] Submit Happiness Check Data Success',
    props<{ result: HappinessCheckData }>(),
);

export const submitHappinessCheckDataFailure = createAction(
    '[Happiness Check] Submit Happiness Check Data Failure',
    props<{ error: Error }>(),
);
