import { GeoLocation } from '../models/geo-location.model';

export class GeoLocationMock {
    private defaultValue: GeoLocation = {
        ip: 'ip',
        countryCode: 'GB',
        region: 'United Kingdom',
        city: 'London',
    };

    constructor(overrides?: Partial<GeoLocation>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): GeoLocation {
        return this.defaultValue;
    }
}
