import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { UserFeedbackContentService } from '../services/user-feedback-content.service';
import {
    createUserFeedbackContent,
    createUserFeedbackContentFailure,
    createUserFeedbackContentSuccess,
    getUserFeedbackContentFailure,
    getUserFeedbackContentSuccess,
} from './user-feedback-content.actions';
import { getHouseholdSuccess } from '@frontend/data-access/user/household';

@Injectable()
export class UserFeedbackContentEffects {
    private readonly actions$ = inject(Actions);
    private readonly userFeedbackContentService = inject(UserFeedbackContentService);

    getUserFeedbackContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            concatMap(() =>
                this.userFeedbackContentService.getFeedback().pipe(
                    map((internalUserFeedbackContentDto) =>
                        getUserFeedbackContentSuccess({
                            feedback: internalUserFeedbackContentDto,
                        }),
                    ),
                    catchError((error: Error) => of(getUserFeedbackContentFailure({ error }))),
                ),
            ),
        );
    });

    createUserFeedbackContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserFeedbackContent),
            concatMap(({ feedback }) => {
                return this.userFeedbackContentService.createFeedback(feedback).pipe(
                    map((newFeedback) => createUserFeedbackContentSuccess({ feedback: newFeedback })),
                    catchError((error: Error) => of(createUserFeedbackContentFailure({ error }))),
                );
            }),
        );
    });
}
