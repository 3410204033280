import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectCourses } from '@frontend/data-access/contentful';
import { selectIsStartCourseAb } from '@frontend/data-access/user/config-cat';
import { getHouseholdSuccess, householdFeature } from '@frontend/data-access/user/household';
import {
    createManyCourseProgress,
    createManyCourseProgressSuccess,
    getCourseProgress,
} from '@frontend/data-access/user/progress';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { CourseProgressType } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { filter, map } from 'rxjs/operators';
import { showCourseCompletionModal } from '../../../courses/course-completion-modal/course-completion-modal.actions';
import { openToast } from '../../toast/toast.actions';
import {
    triggerCreateManyCourseProgress,
    TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID,
    TRIGGERED_FROM_STEP_CORRELATION_ID,
} from './course-progress.actions';

@Injectable()
export class CourseProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    triggerGetCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(ofType(getHouseholdSuccess)).pipe(map(() => getCourseProgress()));
    });

    triggerCreateManyCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerCreateManyCourseProgress),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            map(([{ courseProgress, correlationId }, dogId]) => {
                // TODO: NBSon - ask, should we throw an error here or just ignore the action with a filter?
                if (!dogId) {
                    throw new Error('No dogId to store course progress');
                }

                const timestamp = new Date().getTime();

                const mappedCourseProgress = courseProgress.map((courseProgress) => {
                    return {
                        dogId,
                        courseId: courseProgress.id,
                        progressType: courseProgress.progressType,
                        timestamp,
                    };
                });

                return createManyCourseProgress({
                    courseProgress: mappedCourseProgress,
                    correlationId,
                });
            }),
        );
    });

    createManyCourseProgressSuccessTrackEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createManyCourseProgressSuccess),
            map(({ entries }) => {
                const mappedEntries = entries.map((entry) => ({
                    courseId: entry.courseId,
                    progress: entry.progressType,
                }));

                return trackEvent({
                    eventName: '[Course] Course Progress Created',
                    eventProperties: {
                        courseProgress: mappedEntries,
                    },
                });
            }),
        );
    });

    createManyCourseProgressSuccessShowToast$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createManyCourseProgressSuccess),
            concatLatestFrom(() => [this.store.select(selectIsStartCourseAb), this.store.select(selectCourses)]),
            filter(([{ entries, correlationId }, isStartCourseAb]) => {
                return (
                    isStartCourseAb &&
                    (correlationId === TRIGGERED_FROM_STEP_CORRELATION_ID ||
                        correlationId === TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID) &&
                    entries.some((entry) => entry.progressType === CourseProgressType.IN_PROGRESS)
                );
            }),
            map(([{ entries }, , courses]) => {
                const course = courses.find((course) => course.id === entries[0].courseId);

                return openToast({
                    id: 'course-started-toast',
                    message: course?.title ? `${course.title} course started` : 'Course started',
                    color: course?.color ?? Color.Sophie,
                });
            }),
        );
    });

    // TODO: NBSon - to improve upon this, can we check the course that the user was actively working on, and show the modal for that course?
    // bear in mind this is a very rare scenario
    createManyCourseProgressSuccessTriggerCourseCompletionModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createManyCourseProgressSuccess),
            filter(({ entries }) => entries.some((entry) => entry.progressType === CourseProgressType.COMPLETED)),
            map(({ entries }) => {
                // NBSon - if there are multiple courses, show the modal for the first one
                return showCourseCompletionModal({ courseId: entries[0].courseId });
            }),
        );
    });
}
