import { createFeature, createReducer, on } from '@ngrx/store';
import { clearLocalNotificationSuccess, getLocalNotificationSuccess } from './local-notification.actions';
import { LocalNotificationState } from './local-notification.model';

const initialState: LocalNotificationState = {
    notifications: [],
};

export const localNotificationsFeature = createFeature({
    name: 'localNotifications',
    reducer: createReducer(
        initialState,
        on(
            getLocalNotificationSuccess,
            (state, { result }): LocalNotificationState => ({
                ...state,
                notifications: result.notifications,
            }),
        ),
        on(
            clearLocalNotificationSuccess,
            (state, { notifications }): LocalNotificationState => ({
                ...state,
                notifications: state.notifications.filter((notification) => {
                    return notifications.indexOf(notification.id) < 0;
                }),
            }),
        ),
    ),
});
