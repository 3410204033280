import { createAction, props } from '@ngrx/store';
import { HouseholdUser } from '../../models/household-user.model';

export const patchHouseholdUser = createAction(
    '[Household User] Patch User',
    props<{ id: string; user: Partial<HouseholdUser> }>(),
);

export const patchHouseholdUserSuccess = createAction(
    '[Household User] Patch User Success',
    props<{ user: HouseholdUser }>(),
);

export const patchHouseholdUserFailure = createAction('[Household User] Patch User Failure', props<{ error: Error }>());
