import { InternalPostAccountDto } from '../../../../dtos/user/account/internal-post-account.dto';

export class InternalPostAccountDtoMock {
    private defaultValue: InternalPostAccountDto = {
        mail: 'test@test.zigzag.dog',
        password: 'something-very-secure',
    };

    constructor(overrides?: Partial<InternalPostAccountDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): InternalPostAccountDto {
        return this.defaultValue;
    }
}
