import { InternalIterableDataFields, IterableOrigin } from '../interfaces/iterable-fields.model';

import { InternalIterableDogFieldsMock } from './internal-iterable-dog-fields.mock';

export class InternalIterableDataFieldsMock {
    private defaultValue: InternalIterableDataFields = {
        userId: 'user-id',
        householdId: 'household-123',
        ownerName: 'owner-name',
        locale: 'locale',
        partners: ['partner'],
        stepsComplete: 1,
        appVersion: '123',
        deviceOs: 'android',
        ip: 'ip',
        dogs: [new InternalIterableDogFieldsMock().value],
        purinaMarketingAccepted: false,
        enabledFeatureFlags: ['flag1', 'flag2'],
        origin: IterableOrigin.APP,
    };

    constructor(overrides?: Partial<InternalIterableDataFields>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterableDataFields {
        return this.defaultValue;
    }
}
