<ion-content
    class="page-safe-margin"
    [fullscreen]="true"
    [scrollEvents]="true"
    [scrollY]="canScroll(vm().selectedCourse!)"
    (ionScroll)="setScrollHeight($any($event))"
>
    <div class="course-header page-padding-sides page-padding-top">
        <h1 class="page-title">My Journey</h1>
    </div>
    @if (vm().selectedCourse) {
    <div>
        <app-course-slider data-test="course-swipe" [courses]="vm().courses" />
        <div class="no-access-section">
            @if (displayNoAccess(vm().selectedCourse!)) {
            <app-no-access
                title="Unlock additional courses with premium"
                subTitle="You're on the Basic plan now."
                buttonText="Upgrade to Premium"
                (openPaymentModal)="openPaymentModal()"
            />
            }
            <div class="course-path page-padding-sides">
                <app-course-path />
            </div>
            @if (vm().selectedCourse!.status !== CourseStatus.COMPLETED) {
            <div
                *appElementRerender="vm().selectedCourse!.color"
                class="scroll-down-container"
                data-test="scroll-down-button"
                [ngClass]="{'point-up': (vm().heightOfFirstInactiveStep ?? 0) < scrollHeight}"
                (click)="scrollToFirstInactiveStep(vm().heightOfFirstInactiveStep)"
            >
                <i
                    class="fa-solid fa-circle-chevron-down fa-2xl"
                    [style.color]="'var(--ion-color-' + vm().selectedCourse!.color + ')'"
                ></i>
            </div>
            }
            <div class="page-padding-sides next-up-container">
                @if (vm().nextCourse) {
                <app-generic-card-large
                    callToActionText="View course"
                    label="Next Up"
                    imageAltText="An image of a dog excited about the next cup course"
                    data-test="next-up-tile"
                    [title]="vm().nextCourse.title"
                    [description]="vm().nextCourse.introduction"
                    [imageUrl]="vm().nextCourse.imageUrl"
                    [backgroundColor]="vm().nextCourse.color"
                    [backgroundUrl]="getCourseCardDoodle(vm().nextCourse.color)"
                    (callToActionClicked)="openCourseInfoModal(vm().nextCourse)"
                />
                }
            </div>
        </div>
    </div>
    }
</ion-content>
