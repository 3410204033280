<ion-header class="today-page-header">
    <ion-toolbar class="today-page-header__toolbar" [color]="Color.AppOutline" />
</ion-header>

<ion-content class="today-page page-ion-content" [fullscreen]="true">
    <app-today-developmental-window
        [@collapseAnimation]="showAiChatView() ? 'collapse' : 'expand'"
        class="today-page__developmental-window"
    />
    <app-ai-chat-header
        [@fadeInAnimation]="showAiChatView() ? 'in' : 'out'"
        class="today-page__ai-chat-header"
        appTrackClick
        identifier="today-ai-chat-input-back-btn"
        (backClicked)="onAiChatBackClicked()"
    />
    <app-today-ai-chat-input
        #aiChatInput
        class="today-page__ai-chat-input"
        appTrackClick
        identifier="today-ai-chat-input"
        [showSendButton]="showAiChatView()"
        (inputClicked)="onAiChatClicked()"
        (submitted)="onAiChatSubmitted($event)"
    />

    <div class="text-block" [style.display]="showAiChatView() ? 'none' : 'block'">
        @if (recommendedTiles().length) {
        <h2 class="title" data-test="today-page-recommended-courses-title">Picked for {{ dogName() }}</h2>
        <ui-slider class="today-page__recommended-tiles-slider" [gap]="12" [lastItemPadding]="20">
            @for (tile of recommendedTiles(); track tile.id; let i = $index) { @if (isTodaySeasonalTopic(tile)) {
            <app-topic-tile [seasonalTopic]="tile" />
            } @if (isTodayNourishTile(tile)) {
            <app-nourish-tile [id]="tile.id" [image]="tile.image" [pill]="tile.pill" [title]="tile.title" />
            } @if (isTodayProgressCourse(tile)) {
            <app-course-progress-tile
                [class.course-tile--animated]="i === 0 && (tile.type === CourseType.FOUNDATIONAL || tile.type === CourseType.PRE_PUP) && tile.streakLength === 0"
                [course]="tile"
                [attr.data-test]="'today-page-recommended-course-tile-' + tile.id"
            />
            } }
        </ui-slider>
        }
        <app-arrival-tile />

        <!-- This component appears in this file twice because once the claim button
            has been clicked it will be displayed at the bottom of the page
        -->
        @if (isZukesFreeBox() && dogAge()! <= 52 && countryCode() === 'US' && !zukesVoucherClicked()) {
        <ng-container *ngTemplateOutlet="zukesVoucher"></ng-container>
        }

        <app-pinned-topic-overview />

        <h2 class="no-margin-bottom">{{ 'Common questions for pups like ' + dogName() }}</h2>

        <ui-slider [gap]="12" [lastItemPadding]="20">
            @if (suggestedQuestions().length) {
            <app-suggested-ai-chat-questions
                #suggestedQuestionsPrompt
                [suggestedQuestionsCards]="suggestedQuestions()"
                (promptClicked)="onAiChatPromptClicked($event)"
            />
            } @for (tile of supportTiles(); track tile.title) {
            <ui-generic-card-square
                appTrackClick
                [identifier]="tile.contentUnlocked ? 'today-page-' + tile.cardName + '-tile': 'today-page-' + tile.cardName + '-tile-locked'"
                [title]="tile.title"
                [description]="tile.description"
                [imageUrl]="tile.imageUrl"
                [background]="tile.backgroundColor"
                [unlocked]="tile.contentUnlocked"
                [isBasicPlan]="isBasicPlan()"
                [attr.data-test]="tile.contentUnlocked ? 'today-page-' + tile.cardName + '-tile': 'today-page-' + tile.cardName + '-tile-locked'"
                (click)="onCardClick(tile.contentUnlocked, tile.cardName)"
            />
            }
        </ui-slider>

        <app-today-daily-bites />

        @if (isZukesFreeBox() && dogAge()! <= 52 && countryCode() === 'US' && zukesVoucherClicked()) {
        <ng-container *ngTemplateOutlet="zukesVoucher"></ng-container>
        }
    </div>
</ion-content>

<ng-template #zukesVoucher>
    <ui-voucher-large
        [heading]="'FREE box of training treats and dental chews worth $15!'"
        [subHeading]="'(whilst stocks last)'"
        [buttonColour]="'max'"
        [buttonText]="'Claim free box'"
        [image1Url]="'assets/images/third-party/zukes/treat-box.svg'"
        [image2Url]="'assets/images/third-party/zukes/zukes-and-purina.svg'"
        [image1Alt]="'treat box'"
        [image2Alt]="'zukes and purina'"
        [locked]="!contentUnlocked()"
        (handleButtonClick)="handleZukesButtonClick()"
    ></ui-voucher-large>
</ng-template>
