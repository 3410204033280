import { Lesson, stepsFeature } from '@frontend/data-access/contentful';
import { selectCompletedStepIds, stepProgressFeature } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { StepType } from '@shared/content-api-interface';
import { selectContentUnlocked } from '../payment/store/payment.selectors';
import { selectRouteParamStepId } from '../router/router.selectors';
import { Step } from './step.model';
import { getStepProgress } from './step.utils';

export const selectSteps = createSelector(
    stepsFeature.selectSteps,
    stepProgressFeature.selectStepProgress,
    (contentSteps, stepProgress): Step[] =>
        contentSteps.map((step) => ({
            id: step.id,
            topic: step.topic,
            content: step.content,
            progress: getStepProgress(step.id, stepProgress),
            imageUrl: step.content.contentType === StepType.ARTICLE ? step.content.imageUrl : step.topic.imageUrl,
        })),
);

const locationIconMap: Record<string, string> = {
    Outdoors: 'fa-sun-bright',
    Indoors: 'fa-house',
    Anywhere: 'fa-earth-europe',
};

export const selectSelectedLessonStep = createSelector(selectRouteParamStepId, selectSteps, (stepId, steps) => {
    return steps
        .filter((step): step is Step<Lesson> => step.content.contentType === StepType.LESSON)
        .find((step) => step.id === stepId);
});

export const selectSelectedStepUnlocked = createSelector(selectContentUnlocked, (contentUnlocked): boolean => {
    return contentUnlocked;
});

export const selectSelectedShowWhatsNeeded = createSelector(selectSelectedLessonStep, (lessonStep) => {
    return !(
        lessonStep?.content.whatsNeeded?.other.length === 0 &&
        lessonStep?.content.whatsNeeded?.toys.length === 0 &&
        lessonStep?.content.whatsNeeded?.treats.length === 0 &&
        lessonStep?.content.whatsNeeded?.equipmentAccessories.length === 0
    );
});

export const selectSelectedLocationIcon = createSelector(selectSelectedLessonStep, (lessonStep) => {
    return locationIconMap[lessonStep?.content?.location ?? 'Anywhere'];
});

export const selectSelectedLessonExtras = createSelector(
    selectSelectedLocationIcon,
    selectSelectedShowWhatsNeeded,
    (locationIcon, showWhatsNeeded) => ({
        locationIcon,
        showWhatsNeeded,
    }),
);

export const selectIsFirstTimeStepCompleted = createSelector(
    selectCompletedStepIds,
    selectRouteParamStepId,
    (completedStepIds, routeParamStepId) => {
        return !completedStepIds.includes(routeParamStepId);
    },
);
