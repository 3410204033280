import { StepType, TopicName } from '@shared/content-api-interface';
import { LessonProgressType } from '@shared/user-domain';

export interface CreateCompletedStepProgress {
    stepId: string;
    progressType: LessonProgressType.COMPLETED;
    rating?: number;
    isPractice?: boolean;
}

export interface CreateSkippedStepProgress {
    stepId: string;
    progressType: LessonProgressType.SKIPPED;
    skipReason: string;
}

export interface CreateReadStepProgress {
    stepId: string;
    progressType: LessonProgressType.READ;
}

export type CreateStepProgress = CreateCompletedStepProgress | CreateSkippedStepProgress | CreateReadStepProgress;

export interface SkipReason {
    id: string;
    text: string;
}

export interface SkipStepOptions {
    reasons: SkipReason[];
}

export enum StepUpdateEventName {
    TASK_COMPLETED = 'Task completed',
    TASK_READ = 'Task read',
    TASK_SKIPPED = 'Task skipped',
}

export interface StepUpdateBaseProperties {
    title: string;
    stepId: string;
    contentType: StepType;
    contentId: number;
    // TODO - make this the entity id of the topic
    topicId: TopicName;
}

export interface StepCompletedProperties extends StepUpdateBaseProperties {
    isPractice?: boolean;
    rating?: number;
}

export interface StepSkippedProperties extends StepUpdateBaseProperties {
    skipReason: string;
}

export interface StepUpdateEventProperties {
    title: string;
    stepId: string;
    contentType: StepType;
    contentId: number;
    // TODO JL - make this the entity id of the topic
    topicId: TopicName;
    rating?: number;
    skipReason?: string;
    isFirstCompletedStep?: boolean;
    isFirstTimeStepCompleted?: boolean;
}
