import { IsOptional, IsString } from 'class-validator';

export class DomainPatchHouseholdCommand {
    @IsOptional()
    @IsString()
    name?: string;

    @IsString()
    userId!: string;

    @IsString()
    dogId!: string;
}
