@if (vm$ | async; as vm) {
    <ion-content>
        <ion-toolbar class="modal-header">
            <ion-buttons slot="end">
                <ion-button
                    class="modal-header__close-btn"
                    (click)="closeModal()"
                    appTrackClick
                    data-test="food-calculator-disclaimer-modal-close"
                    identifier="food-calculator-disclaimer-modal-close"
                >
                    <i class="fa-light fa-xmark"></i>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
        <div class="page-padding-sides">
            <h3>Why can this be different from my packaging?</h3>
            <p>
                Most food packaging gives fairly generic and broad guidelines, which aren't hugely accurate when it
                comes to growing puppies! Here at Zigzag we can be a lot more
                {{ vm.isUsUser ? 'personalized' : 'personalised' }} and adapt the guidelines as your pup grows. We use
                some clever science (not magic!) to work out what you should be feeding your pup each day. We first work
                out what the estimated calories are in your pup’s brand of food (known as ME), and we then balance this
                against your pup's energy requirements (known as BMR), and this then calculates a figure representing
                their daily portion of food that's very specific to your pup at this very moment in time.
                <br />
                <br />
                Whilst we think our food calculator is pretty darn awesome and know its super scientific, the feeding
                guidelines it gives you, should only be considered as a starting point as every puppy is different 🙂
            </p>
            <a href="https://zigzag.dog/food-calculator/" class="external-link">Find out more</a>
        </div>
    </ion-content>
}
