<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>Is it {{ dogName() }}'s first day at home?</h1>
            <p data-test="question-first-day-at-home-subtitle">We’ll adjust the program to best fit you</p>
        </section>
    </main>
</ion-content>

<ion-footer class="page-footer ion-text-center">
    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--buddy"
            appTrackClick
            identifier="question-first-day-at-home-true-btn"
            expand="block"
            data-test="question-first-day-at-home-true-btn"
            [disabled]="isLoading()"
            (click)="onFirstDayAtHomeClick(true)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Yes }
        </ion-button>
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--buddy"
            appTrackClick
            identifier="question-first-day-at-home-false-btn"
            expand="block"
            data-test="question-first-day-at-home-false-btn"
            [disabled]="isLoading()"
            (click)="onFirstDayAtHomeClick(false)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { No }
        </ion-button>
    </div>
</ion-footer>
