import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
    getConfigCatFeatureFlags,
    getConfigCatFeatureFlagsFailure,
    getConfigCatFeatureFlagsSuccess,
} from './config-cat.actions';
import { ConfigCatFlagState } from './config-cat.model';

export const configCatFlagFeatureKey = 'configCatFlagState';

export const initialState: ConfigCatFlagState = {
    configCatFlags: [],
    loading: LoadingState.INIT,
    error: undefined,
};

export const configCatFeature = createFeature({
    name: configCatFlagFeatureKey,
    reducer: createReducer(
        initialState,
        on(
            getConfigCatFeatureFlags,
            (state): ConfigCatFlagState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            getConfigCatFeatureFlagsSuccess,
            (state, { configCatFlags }): ConfigCatFlagState => ({
                ...state,
                configCatFlags,
                loading: LoadingState.LOADED,
            }),
        ),
        on(
            getConfigCatFeatureFlagsFailure,
            (state, { error }): ConfigCatFlagState => ({
                ...state,
                loading: LoadingState.LOADED,
                error,
            }),
        ),
    ),
});
