export enum IterableOrigin {
    WEB = 'web',
    APP = 'app',
    PARTNER = 'partner',
}

export interface InternalIterableDataFields {
    userId?: string;
    householdId?: string;
    ownerName?: string;
    locale?: string;
    partners?: string[];
    stepsComplete?: number;
    dogs?: InternalIterableDogFields[];
    appVersion?: string;
    deviceOs?: string;
    ip?: string;
    marketingOptIn?: boolean;
    purinaMarketingAccepted?: boolean;
    enabledFeatureFlags?: string[];
    origin?: IterableOrigin;
}

export interface InternalIterableDogFields {
    dogId: string;
    dogName?: string;
    breedGroup?: string;
    breed?: string;
    ageInWeeks?: number;
    gender?: string;
    dateOfBirth?: string;
    dateOfArrival?: string;
    isRescuePup?: boolean;
    hasArrived?: boolean;
    isApproximateDateOfBirth?: boolean;
}
