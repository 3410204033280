<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>Has {{ dogName() }} come from a shelter or rescue?</h1>
            <p data-test="question-from-rescue-subtitle">This will help to tailor the program</p>
        </section>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--buddy"
            appTrackClick
            identifier="question-from-rescue-true-btn"
            expand="block"
            data-test="question-from-rescue-true-btn"
            [disabled]="isLoading()"
            (click)="onIsRescueClick(true)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Yes }
        </ion-button>
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--buddy"
            appTrackClick
            identifier="question-from-rescue-false-btn"
            expand="block"
            data-test="question-from-rescue-false-btn"
            [disabled]="isLoading()"
            (click)="onIsRescueClick(false)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { No }
        </ion-button>
    </div>
</ion-footer>
