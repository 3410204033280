import { Partner } from '../models/profile.model';

export class PartnerMock {
    private readonly defaultValue: Partner = {
        id: 'partner-id-123',
        name: 'Partner Name 123',
        freeAccessLengthInDays: 365,
    };

    constructor(overrides?: Partial<Partner>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): Partner {
        return this.defaultValue;
    }

    public set1YearFreeAccess(): PartnerMock {
        this.defaultValue.freeAccessLengthInDays = 365;

        return this;
    }

    public set6MonthsFreeAccess(): PartnerMock {
        this.defaultValue.freeAccessLengthInDays = 180;

        return this;
    }
}
