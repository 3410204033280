import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    getConfigCatFeatureFlags,
    getConfigCatFeatureFlagsFailure,
    getConfigCatFeatureFlagsSuccess,
} from './config-cat.actions';
import { mapConfigCatResponseToFlags } from './config-cat.utils';
import { ConfigCatService } from './services/config-cat.service';

@Injectable()
export class ConfigCatEffects {
    private readonly actions$ = inject(Actions);
    private readonly configCatService = inject(ConfigCatService);

    getConfigCatFeatureFlags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getConfigCatFeatureFlags),
            switchMap((action) =>
                from(this.configCatService.getConfig(action.userObject)).pipe(
                    map((values) => {
                        return getConfigCatFeatureFlagsSuccess({
                            configCatFlags: mapConfigCatResponseToFlags(values),
                        });
                    }),
                    catchError((error) => of(getConfigCatFeatureFlagsFailure({ error }))),
                ),
            ),
        );
    });
}
