import { dismissibleInfoFeature, shouldDisplayModal, ShowModalFrequency } from '@frontend/data-access/dismissible-info';
import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { differenceInDays } from 'date-fns/differenceInDays';
import { PUP_HAPPINESS_MODAL_ID } from '../../pup-happiness-modal/pup-happiness-modal.constant';
import { householdFeature, selectHasArrived } from '@frontend/data-access/user/household';

export const selectShouldLaunchPupHappinessModal = createSelector(
    selectHasArrived,
    householdFeature.selectDateOfOnboardingOrToday,
    timeFeature.selectToday,
    dismissibleInfoFeature.selectDismissedInfoItems,
    (hasArrived, dateOfOnboarding, today, dismissibleInfoItems) => {
        if (!hasArrived) {
            return false;
        }

        const has1DayPassedSinceOnboarding = differenceInDays(today, dateOfOnboarding) >= 1;

        return (
            has1DayPassedSinceOnboarding &&
            shouldDisplayModal(ShowModalFrequency.DAILY, PUP_HAPPINESS_MODAL_ID, dismissibleInfoItems, today)
        );
    },
);
