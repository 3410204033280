import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { accountFeature } from '@frontend/data-access/user/account';
import { IonContent, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { delay } from '@shared/utils/typescript';
import { Subscription } from 'rxjs';
import { OnboardingCreatingPlanCardsComponent } from '../../components/creating-plan-cards.component';
import { finalisePageFinishedWaiting } from '../../data-access/onboarding.actions';

@Component({
    standalone: true,
    selector: 'app-onboarding-creating-plan',
    templateUrl: 'creating-plan.page.html',
    styleUrls: ['creating-plan.page.scss', '../onboarding.scss'],
    imports: [NgClass, IonContent, OnboardingCreatingPlanCardsComponent],
})
export class OnboardingCreatingPlanPage implements ViewWillEnter, ViewWillLeave {
    private readonly store = inject(Store);

    private readonly platform = inject(Platform);

    public dogName = this.store.selectSignal(accountFeature.selectDogName);

    public fadeOutCards = false;
    public fadeInBailey = false;
    public fadeOutBailey = false;

    private backButtonSub: Subscription = new Subscription();

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => {
            return;
        });
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public async onCardsLoaded() {
        this.fadeOutCards = true;

        // The card fade out animation should be completed
        await delay(1500);

        this.fadeInBailey = true;

        // The Bailey fade in animation should be completed
        await delay(1500);

        this.fadeOutBailey = true;

        // The Bailey fade out animation should be completed
        await delay(750);

        this.store.dispatch(finalisePageFinishedWaiting());
    }
}
