import { InternalPostUserDto } from '../../../../dtos/user/account/internal-post-user.dto';

export class InternalPostUserDtoMock {
    private defaultValue: InternalPostUserDto = {
        accountId: 'accountId',
        mail: 'test@test.zigzag.dog',
        countryCode: 'GB',
        region: 'England',
        city: 'London',
    };

    constructor(overrides?: Partial<InternalPostUserDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): InternalPostUserDto {
        return this.defaultValue;
    }
}
