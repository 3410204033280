import { createAction, props } from '@ngrx/store';

export const showPaymentModal = createAction(
    '[Payment] Show Payment Modal',
    props<{ trigger: string; showDiscountProducts?: boolean }>(),
);

export const showPaymentModalLimitedOffer = createAction(
    '[Payment] Show Payment Modal Limited Offer',
    props<{ trigger: string }>(),
);

export const closePaymentModalClicked = createAction('[Payment] Close Payment Modal Clicked');
