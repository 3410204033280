import { Household } from '../household.model';
import { DogMock } from './dog.mock';
import { HouseholdUserMock } from './household-user.mock';
import { PublicUserMock } from './public-user.mock';

export class HouseholdMock {
    private defaultValue: Household = {
        id: '123',
        name: 'household',
        currentUser: new HouseholdUserMock().value,
        dogs: [new DogMock().value],
        users: [new PublicUserMock().value],
    };

    private empty: Household = {
        ...this.defaultValue,
        currentUser: new HouseholdUserMock().emptyValue,
        dogs: [],
        users: [],
    };

    constructor(overrides?: Partial<Household>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Household {
        return this.defaultValue;
    }

    public get emptyValue(): Household {
        return this.empty;
    }
}
