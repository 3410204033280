import { createSelector } from '@ngrx/store';
import { householdFeature, selectBreedName } from '@frontend/data-access/user/household';
import { mapDateOfBirthToAgeString } from '@frontend/utility/angular';
import { differenceInWeeks } from 'date-fns';

export const selectOurPickString = createSelector(
    householdFeature.selectDateOfBirth,
    selectBreedName,
    (dateOfBirth, breedName): string => {
        const currentDate = new Date();
        let birthDate = new Date();
        if (dateOfBirth) {
            birthDate = dateOfBirth;
        }
        const ageInWeeks = differenceInWeeks(currentDate, birthDate);

        if (ageInWeeks! > 104) {
            return `Our picks for ${breedName}`;
        }

        const age = mapDateOfBirthToAgeString(birthDate, currentDate, false);

        return `Our picks for ${age} old ${breedName}`;
    },
);
