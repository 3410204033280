import { createFeature, createReducer, on } from '@ngrx/store';
import { getUuid, getUuidFailure, getUuidSuccess } from './uuid.actions';
import { LoadingState } from '@frontend/data-access/shared-models';

export const uuidFeatureKey = 'uuidState';

export interface UuidState {
    id: string | undefined;
    loading: LoadingState;
}

const initialState: UuidState = {
    id: undefined,
    loading: LoadingState.INIT,
};

export const uuidFeature = createFeature({
    name: uuidFeatureKey,
    reducer: createReducer(
        initialState,
        on(
            getUuid,
            (state): UuidState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            getUuidSuccess,
            (state, { id }): UuidState => ({
                ...state,
                id,
                loading: LoadingState.LOADED,
            }),
        ),
        on(
            getUuidFailure,
            (state): UuidState => ({
                ...state,
                loading: LoadingState.LOADED,
            }),
        ),
    ),
});
