import { inject, Injectable } from '@angular/core';
import { updateAttributionData } from '@frontend/data-access/analytics';
import { capacitorAppResume } from '@frontend/data-access/capacitor';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, exhaustMap, of, switchMap } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { RevenueCatPurchaseErrorCodes } from '../revenue-cat/revenue-cat.constants';
import { SubscriptionInternalApiService } from '../services/subscription-internal-api.service';
import { SubscriptionService } from '../subscription.service';
import { mapPurchaserInfoToEntitlement } from '../subscription.utils';
import { PurchaseProduct } from './models/subscription.model';
import {
    GET_INITIAL_OFFERINGS_CORRELATION_ID,
    getActiveSubscription,
    getActiveSubscriptionFailure,
    getActiveSubscriptionSuccess,
    getEntitlements,
    getEntitlementsFailure,
    getEntitlementsSuccess,
    getOfferings,
    getOfferingsFailure,
    getOfferingsSuccess,
    initializeSubscriptionService,
    initializeSubscriptionServiceFailure,
    initializeSubscriptionServiceSuccess,
    logInToPurchases,
    logInToPurchasesFailure,
    logInToPurchasesSuccess,
    presentPromoCodePrompt,
    purchaseProduct,
    purchaseProductCancelled,
    purchaseProductFailure,
    purchaseProductSuccess,
    restoreSubscription,
} from './subscription.actions';
import { Action } from '@ngrx/store';

@Injectable()
export class SubscriptionEffects {
    private readonly actions$ = inject(Actions);
    private readonly subscriptionService = inject(SubscriptionService);
    private readonly subscriptionInternalApiService = inject(SubscriptionInternalApiService);

    //todo: add test
    initialize$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(initializeSubscriptionService),
            map(({ id, email }) => {
                this.subscriptionService.initialize(id);
                return initializeSubscriptionServiceSuccess({ profileId: id!, email });
            }),
            catchError((error) => of(initializeSubscriptionServiceFailure({ error }))),
        );
    });

    logInToPurchases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logInToPurchases),
            mergeMap(({ profileId, email }) =>
                this.subscriptionService.logInToPurchases(profileId, email).pipe(
                    mergeMap((data) => {
                        const actions: Action<string>[] = [
                            logInToPurchasesSuccess({
                                data: {
                                    value: mapPurchaserInfoToEntitlement(data.customerInfo),
                                    hasHistoricalPurchase: data.customerInfo.allPurchasedProductIdentifiers.length > 0,
                                },
                            }),
                        ];
                        return actions;
                    }),
                    catchError((error) => of(logInToPurchasesFailure({ error }))),
                ),
            ),
        );
    });

    logInToPurchasesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logInToPurchasesSuccess),
            switchMap(() => [getOfferings({ correlationId: GET_INITIAL_OFFERINGS_CORRELATION_ID }), getEntitlements()]),
        );
    });

    getEntitlements$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEntitlements),
            exhaustMap(() => {
                return this.subscriptionService.getEntitlements().pipe(
                    map((purchaserInfo) =>
                        getEntitlementsSuccess({
                            data: {
                                value: mapPurchaserInfoToEntitlement(purchaserInfo),
                                hasHistoricalPurchase: purchaserInfo.allPurchasedProductIdentifiers.length > 0,
                            },
                        }),
                    ),
                    catchError((error) => of(getEntitlementsFailure({ error }))),
                );
            }),
        );
    });

    getOfferings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getOfferings),
            exhaustMap(({ correlationId }) => {
                return this.subscriptionService.getOfferings().pipe(
                    map((offerings) => {
                        return getOfferingsSuccess({ data: { value: offerings }, correlationId });
                    }),
                    catchError((error) => of(getOfferingsFailure({ error }))),
                );
            }),
        );
    });

    purchaseProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(purchaseProduct),
            concatMap(({ data }) =>
                this.subscriptionService.purchaseProduct(data.identifier).pipe(
                    map(() => {
                        return purchaseProductSuccess({ data });
                    }),
                    catchError((error) => {
                        if (error.error?.readableErrorCode === RevenueCatPurchaseErrorCodes.PURCHASE_CANCELLED) {
                            return of(purchaseProductCancelled({ data: undefined as unknown as PurchaseProduct }));
                        }

                        return of(purchaseProductFailure({ error }));
                    }),
                ),
            ),
        );
    });

    updateAttributionData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(updateAttributionData),
                tap(({ attributionData }) => {
                    this.subscriptionService.collectDeviceIdentifiers(attributionData.adid);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    restoreSubscription$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(restoreSubscription),
                tap(() => {
                    this.subscriptionService.restoreTransactions();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    syncPurchasesOnAppResume$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(capacitorAppResume),
                tap(() => {
                    this.subscriptionService.syncPurchases();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    presentPromoCodePrompt$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(presentPromoCodePrompt),
                tap(() => {
                    this.subscriptionService.presentPromoCodePrompt();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    getActiveSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getActiveSubscription),
            switchMap(() => {
                return this.subscriptionInternalApiService.getActiveSubscription().pipe(
                    map((subscriptionInfo) => getActiveSubscriptionSuccess({ data: { value: subscriptionInfo } })),
                    catchError((error) => of(getActiveSubscriptionFailure({ error }))),
                );
            }),
        );
    });
}
