import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    FrontendFoodProductDto,
    InternalExpectedWeightDto,
    InternalFoodPortionDto,
    InternalFoodProductDto,
} from '@shared/food-calculator-interface';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ExpectedWeightQuery, FoodPortionQuery } from './food-calculator.model';
import { internalPaths } from '@shared/user-api-interface';

@Injectable({
    providedIn: 'root',
})
export class FoodCalculatorService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getProducts(): Observable<FrontendFoodProductDto[]> {
        return this.http.get<InternalFoodProductDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.foodCalculatorPath}/food-product`,
            httpOptions,
        );
    }

    public getPortion(foodPortionQuery: FoodPortionQuery): Observable<number> {
        const mappedParams = this.getMappedParams(foodPortionQuery);

        return this.http
            .get<InternalFoodPortionDto>(
                `${this.environment.internalApiUrl}/api/${internalPaths.foodCalculatorPath}/food-portion`,
                {
                    params: mappedParams,
                },
            )
            .pipe(map((foodPortionDto) => foodPortionDto.amountPerDay));
    }

    public getExpectedWeight(expectedWeightQuery: ExpectedWeightQuery): Observable<number> {
        const mappedParams = this.getMappedParams(expectedWeightQuery);
        return this.http
            .get<InternalExpectedWeightDto>(
                `${this.environment.internalApiUrl}/api/${internalPaths.foodCalculatorPath}/expected-weight`,
                {
                    params: mappedParams,
                },
            )
            .pipe(
                map((expectedWeightDto) => expectedWeightDto.expectedWeight),
                catchError(() => throwError(new Error('Expected weight could not be found'))),
            );
    }

    private getMappedParams(query: FoodPortionQuery | ExpectedWeightQuery) {
        return Object.entries(query)
            .map(([key, value]: [string, string | number]) => [key, `${value}`])
            .reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {});
    }
}
