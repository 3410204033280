import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { differenceInDays } from 'date-fns';
import { householdFeature } from '@frontend/data-access/user/household';

export const selectArrivalTileVm = createSelector(
    householdFeature.selectDateOfArrival,
    timeFeature.selectToday,
    householdFeature.selectHasArrivedOrUndefined,
    (profileDateOfArrival, today, hasArrived) => {
        const dateOfArrival = profileDateOfArrival ?? today;
        return {
            showTile: !hasArrived && hasArrived !== undefined,
            daysUntilArrival: differenceInDays(dateOfArrival, today),
        };
    },
);
