<div class="bullet-list ion-padding-bottom doodle">
    <ng-content></ng-content>
    @for (bullet of bullets; track bullet.title) {
        <div class="bullet-list__item ion-text-left" data-test="bullet-item">
            <div class="bullet-list__step-enum">
                <i class="fa-regular fa-check"></i>
                <div class="bullet-list__line"></div>
            </div>
            <div class="ion-padding-start">
                <h3 data-test="bullet-item-title">
                    {{ bullet.title }}
                </h3>
                <p class="s" data-test="bullet-item-text">{{ bullet.text }}</p>
            </div>
        </div>
    }
</div>
