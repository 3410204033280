import { LessonProgressType } from '@shared/user-domain';
import { InternalStepProgressDto } from '../../../dtos/internal-api/progress/step-progress/internal-step-progress.dto';

export class InternalStepProgressDtoMock {
    private defaultValue: InternalStepProgressDto = {
        id: 'id-1234',
        userId: 'uuid-1234',
        stepId: '101',
        contentId: 101,
        progressType: LessonProgressType.COMPLETED,
        timestamp: 1686905548509,
    };

    constructor(overrides?: Partial<InternalStepProgressDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): InternalStepProgressDto {
        return this.defaultValue;
    }
}
