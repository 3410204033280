import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, exhaustMap, filter, from, of, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { isAuthErrorUserCancelled } from '../utils/authentication.map';
import {
    enrollUserTOTP,
    enrollUserTOTPFailure,
    enrollUserTOTPSuccess,
    foundValidUserSession,
    generateTOTPSecret,
    generateTOTPSecretFailure,
    generateTOTPSecretSuccess,
    getMultiFactorInfo,
    getMultiFactorInfoFailure,
    getMultiFactorInfoSuccess,
    getUserSignInProvider,
    getUserSignInProviderFailure,
    getUserSignInProviderSuccess,
    initializeAuthenticationService,
    initializeAuthenticationServiceFailure,
    loginWithApple,
    loginWithAppleFailure,
    loginWithAppleSuccess,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordAndTotp,
    loginWithEmailAndPasswordAndTotpFailure,
    loginWithEmailAndPasswordAndTotpSuccess,
    loginWithEmailAndPasswordFailure,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogle,
    loginWithGoogleCancelled,
    loginWithGoogleFailure,
    loginWithGoogleSuccess,
    logout,
    logoutFailure,
    logoutSuccess,
    noValidUserSession,
    requestPasswordReset,
    requestPasswordResetFailure,
    requestPasswordResetSuccess,
    signUpWithEmailAndPassword,
    signUpWithEmailAndPasswordFailure,
    signUpWithEmailAndPasswordSuccess,
    unenrollMultiFactor,
    unenrollMultiFactorFailure,
    unenrollMultiFactorSuccess,
    watchForLoginStateChange,
} from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
    private readonly actions$ = inject(Actions);
    private readonly authenticationService = inject(AuthenticationService);

    initialize$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initializeAuthenticationService),
                tap(() => {
                    this.authenticationService.initialize();
                }),
                filter(() => false),
                catchError((error) => of(initializeAuthenticationServiceFailure({ error }))),
            );
        },
        { dispatch: false },
    );

    signUpWithEmailAndPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signUpWithEmailAndPassword),
            exhaustMap(({ email, password }) => {
                return this.authenticationService.signUpWithEmailAndPassword(email, password).pipe(
                    map(({ user }) => signUpWithEmailAndPasswordSuccess({ user })),
                    catchError((error) => of(signUpWithEmailAndPasswordFailure({ error }))),
                );
            }),
        );
    });

    loginWithEmailAndPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithEmailAndPassword),
            exhaustMap(({ email, password }) => {
                return this.authenticationService.loginWithEmailAndPassword(email, password).pipe(
                    map(({ user }) => loginWithEmailAndPasswordSuccess({ user })),
                    catchError((error) => of(loginWithEmailAndPasswordFailure({ error }))),
                );
            }),
        );
    });

    loginWithEmailAndPasswordAndTotp$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithEmailAndPasswordAndTotp),
            exhaustMap(({ email, password, code }) => {
                return from(this.authenticationService.loginWithEmailAndPasswordAndTotp(email, password, code)).pipe(
                    map(({ user }) => loginWithEmailAndPasswordAndTotpSuccess({ user })),
                    catchError((error) => of(loginWithEmailAndPasswordAndTotpFailure({ error }))),
                );
            }),
        );
    });

    loginWithGoogle$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithGoogle),
            exhaustMap(() => {
                return this.authenticationService.loginWithGoogle().pipe(
                    switchMap(({ user, additionalUserInfo }) => {
                        return [
                            trackEvent({ eventName: '[SSO] Sign In With Google Success' }),
                            loginWithGoogleSuccess({ user, additionalUserInfo }),
                        ];
                    }),
                    catchError((error) => {
                        if (isAuthErrorUserCancelled(error)) {
                            return of(loginWithGoogleCancelled());
                        }

                        return of(loginWithGoogleFailure({ error }));
                    }),
                );
            }),
        );
    });

    loginWithApple$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithApple),
            exhaustMap(() => {
                return this.authenticationService.loginWithApple().pipe(
                    switchMap(({ user, additionalUserInfo }) => {
                        return [
                            trackEvent({ eventName: '[SSO] Sign In With Apple Success' }),
                            loginWithAppleSuccess({ user, additionalUserInfo }),
                        ];
                    }),
                    catchError((error) => {
                        return of(loginWithAppleFailure({ error }));
                    }),
                );
            }),
        );
    });

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logout),
            exhaustMap(() => {
                return this.authenticationService.logout().pipe(
                    map(() => logoutSuccess()),
                    catchError((error) => of(logoutFailure({ error }))),
                );
            }),
        );
    });

    requestPasswordReset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestPasswordReset),
            exhaustMap(({ email }) => {
                return this.authenticationService.requestPasswordReset(email).pipe(
                    map(() => requestPasswordResetSuccess({ email })),
                    catchError((error) => of(requestPasswordResetFailure({ error }))),
                );
            }),
        );
    });

    watchForLoginStateChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(watchForLoginStateChange),
            exhaustMap(() => {
                return this.authenticationService.isLoggedIn().pipe(
                    map((loggedIn) => {
                        return loggedIn ? foundValidUserSession() : noValidUserSession();
                    }),
                );
            }),
        );
    });

    getSSOProviderWhenGetAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserSignInProvider),
            exhaustMap(() => {
                return this.authenticationService.getSignInProvider().pipe(
                    map((provider) => getUserSignInProviderSuccess({ signInProvider: provider })),
                    catchError((error) => of(getUserSignInProviderFailure({ error }))),
                );
            }),
        );
    });

    generateTOTPSecret$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(generateTOTPSecret),
            switchMap(() => {
                return from(this.authenticationService.generateTOTPSecret()).pipe(
                    map((totpSecret) => {
                        return generateTOTPSecretSuccess({ totpSecret });
                    }),
                    catchError((error) => {
                        return of(generateTOTPSecretFailure({ error }));
                    }),
                );
            }),
        );
    });

    getMultiFactorInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getMultiFactorInfo),
            concatMap(() => {
                return from(this.authenticationService.getMultiFactorInfo()).pipe(
                    map((multiFactorInfo) => {
                        return getMultiFactorInfoSuccess({ multiFactorInfo });
                    }),
                    catchError((error) => {
                        return of(getMultiFactorInfoFailure({ error }));
                    }),
                );
            }),
        );
    });

    enrollUserTOTP$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(enrollUserTOTP),
            switchMap(({ verificationCode }) => {
                return from(this.authenticationService.enrollUserTOTP(verificationCode)).pipe(
                    map(() => {
                        return enrollUserTOTPSuccess();
                    }),
                    catchError((error) => {
                        return of(enrollUserTOTPFailure({ error }));
                    }),
                );
            }),
        );
    });

    unenrollMultiFactor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(unenrollMultiFactor),
            concatMap(({ id }) => {
                return from(this.authenticationService.unenrollMultiFactor(id)).pipe(
                    map(() => {
                        return unenrollMultiFactorSuccess({ id });
                    }),
                    catchError((error) => {
                        return of(unenrollMultiFactorFailure({ error }));
                    }),
                );
            }),
        );
    });
}
