import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { UuidService } from './uuid.service';
import { getUuid, getUuidFailure, getUuidSuccess } from './uuid.actions';

@Injectable()
export class UuidEffects {
    private readonly actions$ = inject(Actions);
    private readonly uuidService = inject(UuidService);

    getUuid$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUuid),
            switchMap(() => {
                return this.uuidService.getUuid().pipe(
                    map((response) => getUuidSuccess(response)),
                    catchError((error: Error) => of(getUuidFailure({ error }))),
                );
            }),
        );
    });
}
