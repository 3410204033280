<ion-item (click)="toggleAccordion()" class="no-padding" [lines]="open ? 'none' : 'inset'" data-test="accordion-btn">
    <ion-label class="ion-text-wrap" style="font-weight: bold">{{ title }}</ion-label>
    @if (open) {
        <span>
            <i class="fa-solid fa-chevron-up"></i>
        </span>
    } @else {
        <span>
            <i class="fa-solid fa-chevron-down"></i>
        </span>
    }
</ion-item>
@if (open) {
    <ion-item>
        <p style="white-space: pre-wrap" [innerHtml]="content"></p>
    </ion-item>
}
