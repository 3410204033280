import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedMarketingOptIn } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-marketing-opt-in',
    templateUrl: 'question-marketing-opt-in.page.html',
    styleUrls: ['question-marketing-opt-in.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionMarketingOptInPage extends OnboardingQuestionBasePage {
    public onOptInClick(optedIn: boolean) {
        this.store.dispatch(onboardingQuestionSubmittedMarketingOptIn({ marketingAccepted: optedIn }));
    }
}
