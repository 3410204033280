import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LoadingState } from '@frontend/data-access/shared-models';
import { Household } from '../models/household.model';
import {
    createHousehold,
    createHouseholdFailure,
    createHouseholdSuccess,
    deleteHousehold,
    deleteHouseholdFailure,
    deleteHouseholdSuccess,
    getHousehold,
    getHouseholdFailure,
    getHouseholdSuccess,
    resetHouseholdErrorState,
} from './household.actions';
import { upsertDogInHousehold, upsertUserInHousehold } from '../utils/household.utils';
import { patchDog, patchDogFailure, patchDogSuccess } from './dog/dog.actions';
import {
    patchHouseholdUser,
    patchHouseholdUserFailure,
    patchHouseholdUserSuccess,
} from './household-user/household-user.actions';
import { InternalApiError } from '../models/internal-api-error.model';

export interface HouseholdState {
    household: Household | undefined;
    getHouseholdLoadingState: LoadingState;
    createHouseholdLoadingState: LoadingState;
    patchDogLoadingState: LoadingState;
    patchHouseholdUserLoadingState: LoadingState;
    deleteHouseholdLoadingState: LoadingState;
    getHouseholdError: Error | undefined;
    createHouseholdError: InternalApiError | undefined;
    deleteHouseholdError: Error | undefined;
}

export const initialState: HouseholdState = {
    household: undefined,
    getHouseholdLoadingState: LoadingState.INIT,
    createHouseholdLoadingState: LoadingState.INIT,
    patchDogLoadingState: LoadingState.INIT,
    patchHouseholdUserLoadingState: LoadingState.INIT,
    deleteHouseholdLoadingState: LoadingState.INIT,
    getHouseholdError: undefined,
    createHouseholdError: undefined,
    deleteHouseholdError: undefined,
};

export const householdFeature = createFeature({
    name: 'household',
    reducer: createReducer(
        initialState,
        on(
            getHousehold,
            (state): HouseholdState => ({
                ...state,
                getHouseholdLoadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getHouseholdSuccess,
            (state, action): HouseholdState => ({
                ...state,
                household: action.household,
                getHouseholdLoadingState: LoadingState.LOADED,
                getHouseholdError: undefined,
            }),
        ),
        on(
            getHouseholdFailure,
            (state, { error }): HouseholdState => ({
                ...state,
                household: undefined,
                getHouseholdLoadingState: LoadingState.LOADED,
                getHouseholdError: error,
            }),
        ),
        on(createHousehold, (state) => ({
            ...state,
            createHouseholdLoadingState: LoadingState.LOADING,
        })),
        on(
            createHouseholdSuccess,
            (state, { household }): HouseholdState => ({
                ...state,
                household,
                createHouseholdLoadingState: LoadingState.LOADED,
                createHouseholdError: undefined,
            }),
        ),
        on(
            createHouseholdFailure,
            (state, { error }): HouseholdState => ({
                ...state,
                household: undefined,
                createHouseholdLoadingState: LoadingState.LOADED,
                createHouseholdError: error,
            }),
        ),
        on(patchDog, (state) => ({
            ...state,
            patchDogLoadingState: LoadingState.LOADING,
        })),
        on(
            patchDogSuccess,
            (state, { dog }): HouseholdState => ({
                ...state,
                household: upsertDogInHousehold(state.household, dog),
                patchDogLoadingState: LoadingState.LOADED,
            }),
        ),
        on(
            patchDogFailure,
            (state): HouseholdState => ({
                ...state,
                patchDogLoadingState: LoadingState.LOADED,
            }),
        ),
        on(patchHouseholdUser, (state) => ({
            ...state,
            patchHouseholdUserLoadingState: LoadingState.LOADING,
        })),
        on(
            patchHouseholdUserSuccess,
            (state, { user }): HouseholdState => ({
                ...state,
                household: upsertUserInHousehold(state.household, user),
                patchHouseholdUserLoadingState: LoadingState.LOADED,
            }),
        ),
        on(
            patchHouseholdUserFailure,
            (state): HouseholdState => ({
                ...state,
                patchHouseholdUserLoadingState: LoadingState.LOADED,
            }),
        ),
        on(
            deleteHousehold,
            (state): HouseholdState => ({
                ...state,
                deleteHouseholdLoadingState: LoadingState.LOADING,
            }),
        ),
        on(deleteHouseholdSuccess, (): HouseholdState => initialState),
        on(
            deleteHouseholdFailure,
            (state, { error }): HouseholdState => ({
                ...state,
                deleteHouseholdLoadingState: LoadingState.LOADED,
                deleteHouseholdError: error,
            }),
        ),
        on(
            resetHouseholdErrorState,
            (state): HouseholdState => ({
                ...state,
                getHouseholdError: undefined,
                createHouseholdError: undefined,
            }),
        ),
    ),
    extraSelectors: ({ selectHousehold, selectGetHouseholdError, selectCreateHouseholdError }) => ({
        selectHouseholdId: createSelector(selectHousehold, (household) => household?.id),
        selectCurrentUser: createSelector(selectHousehold, (household) => household?.currentUser),
        selectCurrentUserId: createSelector(selectHousehold, (household) => household?.currentUser.id),
        selectCurrentDog: createSelector(selectHousehold, (household) => household?.dogs[0]),
        selectCurrentDogId: createSelector(selectHousehold, (household) => household?.dogs[0]?.id),
        selectPartners: createSelector(selectHousehold, (household) => household?.currentUser.partners),
        selectEmail: createSelector(selectHousehold, (household) => household?.currentUser.mail),
        selectOwnerName: createSelector(selectHousehold, (household) => household?.currentUser.name ?? ''),
        selectUserToken: createSelector(selectHousehold, (household) => household?.currentUser.userToken),
        selectCountryCode: createSelector(selectHousehold, (household) => household?.currentUser.countryCode),
        selectDateOfOnboarding: createSelector(selectHousehold, (household) => household?.currentUser.dateOfOnboarding),
        selectDateOfOnboardingOrToday: createSelector(
            selectHousehold,
            (household) => household?.currentUser.dateOfOnboarding ?? new Date(),
        ),
        selectGender: createSelector(selectHousehold, (household) => household?.dogs[0].gender),
        selectIsRescuePup: createSelector(selectHousehold, (household) => household?.dogs[0].isRescue),
        selectDateOfArrival: createSelector(selectHousehold, (household) => household?.dogs[0].dateOfArrival),
        selectDateOfBirth: createSelector(selectHousehold, (household) => household?.dogs[0].dateOfBirth),
        selectIsApproximateDateOfBirth: createSelector(
            selectHousehold,
            (household) => household?.dogs[0].isApproximateDateOfBirth,
        ),
        selectBreedId: createSelector(selectHousehold, (household) => household?.dogs[0].breedId),
        selectHasArrivedOrUndefined: createSelector(selectHousehold, (household) => household?.dogs[0].hasArrived),
        selectDogName: createSelector(selectHousehold, (household) => household?.dogs[0].name ?? ''),
        selectHasRequestError: createSelector(
            selectGetHouseholdError,
            selectCreateHouseholdError,
            (getHouseholdError, createHouseholdError) => getHouseholdError || createHouseholdError,
        ),
    }),
});
