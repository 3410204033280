import { Entry } from 'contentful';

export interface ContentfulBreedGroupBreed {
    name: string;
    size: string;
}

export interface ContentfulBreedGroup {
    name: string;
    breeds: Entry<ContentfulBreedGroupBreed>[];
}
