import { inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { openToast } from './toast.actions';

@Injectable()
export class ToastEffects {
    private readonly actions$ = inject(Actions);
    private readonly toastController = inject(ToastController);

    openToast$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openToast),
                concatMap(({ message, color, id }) =>
                    from(
                        this.toastController.create({
                            id: id || 'toast-message',
                            message,
                            color,
                            duration: 2000,
                            position: 'bottom',
                            cssClass: 'ion-text-center',
                        }),
                    ).pipe(
                        switchMap((toast) => {
                            return toast.present();
                        }),
                    ),
                ),
            );
        },
        { dispatch: false },
    );
}
