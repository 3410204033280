import { Component, inject } from '@angular/core';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { onboardingNavigateBack } from '../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-base',
    template: '',
})
export class OnboardingQuestionBasePage implements ViewWillEnter, ViewWillLeave {
    protected readonly store = inject(Store);

    private readonly platform = inject(Platform);

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);

    private backButtonSub: Subscription = new Subscription();

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => {
            this.onBackClicked();
        });
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public onBackClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }
}
