import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { routeTo } from '@frontend/data-access/router';
import {
    accountFeature,
    createAccount,
    createAccountSuccess,
    FROM_LOGIN_CORRELATION_ID,
    getAccount,
    getAccountFailure,
    getAccountSuccess,
    selectConfigCatUserProperties,
    selectCountryCode,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import {
    AuthenticationService,
    loginWithAppleSuccess,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogleSuccess,
} from '@frontend/data-access/user/authentication';
import { getConfigCatFeatureFlags } from '@frontend/data-access/user/config-cat';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ProfileEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly authenticationService = inject(AuthenticationService);

    routeToTodayOnceUserLoggedInWithEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithEmailAndPasswordSuccess),
            map(() => {
                return getAccount({ correlationId: FROM_LOGIN_CORRELATION_ID });
            }),
        );
    });

    /**
     * The {@link OnboardingCompleteGuard} should handle redirecting to onboarding for new SSO users.
     */
    routeToTodayOnceUserLoggedInWithSSO$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithGoogleSuccess, loginWithAppleSuccess),
            filter(({ additionalUserInfo }) => {
                return !!additionalUserInfo && !additionalUserInfo.isNewUser;
            }),
            map(() => {
                return getAccount({ correlationId: FROM_LOGIN_CORRELATION_ID });
            }),
        );
    });

    getAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            filter(({ correlationId }) => correlationId === FROM_LOGIN_CORRELATION_ID),
            map(() => routeTo({ commands: ['main', 'today'] })),
        );
    });

    getAccountFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountFailure),
            filter(({ error }) => {
                return error instanceof HttpErrorResponse && error.status === 404 && !!this.authenticationService.user;
            }),
            concatLatestFrom(() => [this.store.select(accountFeature.selectId), this.store.select(selectCountryCode)]),
            map(([_, id, countryCode]) => {
                return createAccount({
                    id,
                    accountId: this.authenticationService.user!.id,
                    email: this.authenticationService.user!.email,
                    countryCode,
                });
            }),
        );
    });

    getConfigCatFeatureFlags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess, updateAccountSuccess, createAccountSuccess),
            concatLatestFrom(() => this.store.select(selectConfigCatUserProperties)),
            filter(([, { identifier }]) => {
                return !!identifier;
            }),
            concatMap(([, userProfileProperties]) => {
                return [
                    trackEvent({
                        eventName: '[ConfigCat] Get Feature Flags',
                        eventProperties: userProfileProperties,
                    }),
                    getConfigCatFeatureFlags({
                        userObject: { ...userProfileProperties, identifier: userProfileProperties.identifier! },
                    }),
                ];
            }),
        );
    });
}
