import { createSelector } from '@ngrx/store';
import { geoLocationFeature, isCountryCodeNorthAmerica } from '@frontend/data-access/geo-location';
import { householdFeature } from '../household.reducer';

export const selectCountryCode = createSelector(
    householdFeature.selectCountryCode,
    geoLocationFeature.selectCountryCode,
    (profileCountryCode, currentIpCountryCode) => {
        if (profileCountryCode !== undefined && profileCountryCode !== '') {
            return profileCountryCode;
        }

        if (currentIpCountryCode !== undefined && currentIpCountryCode !== '') {
            return currentIpCountryCode;
        }

        return ''; // TODO: NL - think about a default value for country code (or change usages to work with undefined)
    },
);

export const selectIsCountryCodeResolved = createSelector(
    selectCountryCode,
    geoLocationFeature.selectLoadingState,
    (countryCode, currentIpLocationLoading) => {
        return (countryCode !== '' && countryCode !== undefined) || !currentIpLocationLoading;
    },
);

export const selectIsUsUser = createSelector(selectCountryCode, (countryCode) => countryCode === 'US');

export const selectIsGBUser = createSelector(selectCountryCode, (countryCode) => {
    return countryCode === 'GB';
});

export const selectIsNorthAmericaCountryCode = createSelector(selectCountryCode, (countryCode) => {
    return isCountryCodeNorthAmerica(countryCode);
});
