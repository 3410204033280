import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ElementRerenderDirective, GenericCardLargeComponent, NoAccessComponent } from '@frontend/ui';
import { IonContent, ScrollDetail } from '@ionic/angular/standalone';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { CourseProgressType } from '@shared/user-domain';
import { Color, isColorContrastLight } from '@shared/utils/typescript';
import { CourseStatus } from '../../store/course/course.model';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import {
    triggerCreateManyCourseProgress,
    TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID,
} from '../../store/progress/course-progress/course-progress.actions';
import { showCourseInformationModal } from '../course-information-modal/course-information-modal.actions';
import { CoursePathComponent } from '../course-path/course-path.component';
import {
    setSelectedPathCourseIdScrollToFirstInactiveStep,
    setSelectedPathCourseIdScrollToTop,
} from '../course-slider/course-slider.actions';
import { CourseSliderComponent } from '../course-slider/course-slider.component';
import { CourseWithSteps } from '../models/courses.models';
import { selectCoursesPageVm } from './courses.page.selectors';

// TODO: NBSon - think about making some of these methods either computed or with pipes
@Component({
    selector: 'app-courses',
    templateUrl: './courses.page.html',
    styleUrl: './courses.page.scss',
    standalone: true,
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        CourseSliderComponent,
        CoursePathComponent,
        NgClass,
        GenericCardLargeComponent,
        AsyncPipe,
        ElementRerenderDirective,
        NoAccessComponent,
    ],
})
export class CoursesPage {
    @ViewChild(IonContent, { static: false })
    public ionContent!: IonContent;

    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    protected readonly CourseStatus = CourseStatus;
    protected readonly Color = Color;

    vm = this.store.selectSignal(selectCoursesPageVm);

    scrollHeight = 0;

    constructor() {
        this.actions$.pipe(takeUntilDestroyed(), ofType(setSelectedPathCourseIdScrollToTop)).subscribe(() => {
            setTimeout(() => {
                void this.ionContent.scrollToPoint(0, 0, 100);
            }, 100);
        });

        this.actions$
            .pipe(takeUntilDestroyed(), ofType(setSelectedPathCourseIdScrollToFirstInactiveStep))
            .subscribe(() => {
                setTimeout(() => {
                    void this.ionContent.scrollToPoint(undefined, this.vm().heightOfFirstInactiveStep, 200);
                }, 100);
            });
    }

    openCourseInfoModal(course: CourseWithSteps): void {
        this.store.dispatch(showCourseInformationModal({ course }));
    }

    setScrollHeight(event: CustomEvent<ScrollDetail>): void {
        this.scrollHeight = event.detail.scrollTop;
    }

    scrollToFirstInactiveStep(firstInactiveStepHeight: number | undefined): void {
        void this.ionContent.scrollToPoint(undefined, firstInactiveStepHeight, 500);
    }

    getCourseCardDoodle(color: Color) {
        return isColorContrastLight(color)
            ? '/assets/images/squiggles/doodle.png'
            : '/assets/images/squiggles/doodle-dark.png';
    }

    canScroll(selectedCourse: CourseWithSteps): boolean {
        if (selectedCourse?.type !== CourseType.OPTIONAL) {
            return true;
        } else {
            return !this.vm().isBasicPlan;
        }
    }

    displayNoAccess(selectedCourse: CourseWithSteps): boolean {
        return this.vm().isBasicPlan && selectedCourse?.type === CourseType.OPTIONAL;
    }

    openPaymentModal(): void {
        return this.store.dispatch(showPaymentModal({ trigger: 'courses page' }));
    }

    public setCourseAsInProgress(selectedCourse: CourseWithSteps): void {
        const courseProgressEntry = {
            id: selectedCourse.id,
            title: selectedCourse.title,
            progressType: CourseProgressType.IN_PROGRESS,
        };

        this.store.dispatch(
            triggerCreateManyCourseProgress({
                courseProgress: [courseProgressEntry],
                correlationId: TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID,
            }),
        );
    }
}
