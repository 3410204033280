import { createAction, props } from '@ngrx/store';

export const launchPupHappinessModal = createAction(
    '[Pup Happiness] Launch Pup Happiness Modal',
    props<{ dogName: string }>(),
);

export const closePupHappinessModal = createAction('[Pup Happiness] Close Pup Happiness Modal');

export const pupHappinessButtonClicked = createAction('[Pup Happiness] Button Clicked', props<{ buttonId: string }>());

export const submitPupHappinessForm = createAction(
    '[Pup Happiness] Submit Pup Happiness Form',
    props<{ ownerHappinessScore: number; dogHappinessScore: number }>(),
);
