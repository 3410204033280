import { createSelector } from '@ngrx/store';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { selectDefaultOfferingProductCards } from '../payment-modal.selectors';

export const selectPaymentModalDefaultVm = createSelector(
    selectDefaultOfferingProductCards,
    subscriptionFeature.selectHasHistoricalPurchase,
    subscriptionFeature.selectIsPurchaseInProgress,
    (defaultOfferProductsCards, hasHistoricalPurchase, isPurchaseInProgress) => {
        return {
            defaultProducts: defaultOfferProductsCards,
            hasHistoricalPurchase: !!hasHistoricalPurchase,
            isPurchaseInProgress,
        };
    },
);
