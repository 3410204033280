import { Dog } from './dog.model';
import { HouseholdUser } from './household-user.model';
import { PublicUser } from './public-user.model';

export interface Household {
    id: string;
    name: string;
    currentUser: HouseholdUser;
    dogs: Dog[];
    users: PublicUser[];
}
