import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { routeTo } from '@frontend/data-access/router';
import { AuthenticationService } from '@frontend/data-access/user/authentication';
import { getConfigCatFeatureFlags } from '@frontend/data-access/user/config-cat';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    createHousehold,
    createHouseholdSuccess,
    FROM_FOUND_VALID_USER_SESSION,
    getHouseholdFailure,
    getHouseholdSuccess,
    householdFeature,
    patchDogSuccess,
    patchHouseholdUserSuccess,
    selectConfigCatUserProperties,
    selectCountryCode,
} from '@frontend/data-access/user/household';

@Injectable()
export class ProfileEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly authenticationService = inject(AuthenticationService);

    getAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            filter(({ correlationId }) => correlationId === FROM_FOUND_VALID_USER_SESSION),
            map(() => routeTo({ commands: ['main', 'today'] })),
        );
    });

    getHouseholdFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdFailure),
            filter(({ error }) => {
                return error instanceof HttpErrorResponse && error.status === 404 && !!this.authenticationService.user;
            }),
            concatLatestFrom(() => [
                this.store.select(householdFeature.selectCurrentUserId),
                this.store.select(selectCountryCode),
            ]),
            map(([_, id, countryCode]) => {
                return createHousehold({
                    id,
                    accountId: this.authenticationService.user!.id,
                    email: this.authenticationService.user!.email,
                    countryCode,
                });
            }),
        );
    });

    getConfigCatFeatureFlags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess, patchHouseholdUserSuccess, patchDogSuccess, createHouseholdSuccess),
            concatLatestFrom(() => this.store.select(selectConfigCatUserProperties)),
            filter(([, { identifier }]) => {
                return !!identifier;
            }),
            concatMap(([, userProfileProperties]) => {
                return [
                    trackEvent({
                        eventName: '[ConfigCat] Get Feature Flags',
                        eventProperties: userProfileProperties,
                    }),
                    getConfigCatFeatureFlags({
                        userObject: { ...userProfileProperties, identifier: userProfileProperties.identifier! },
                    }),
                ];
            }),
        );
    });
}
