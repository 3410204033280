import { Gender } from '@shared/user-domain';

export interface Dog {
    id: string;
    name: string | undefined;
    dateOfBirth: Date | undefined;
    dateOfArrival: Date | undefined;
    hasArrived: boolean | undefined;
    gender: Gender | undefined;
    dateOfOnboarding: Date | undefined;
    breedId: string | undefined;
    isRescue: boolean | undefined;
    isApproximateDateOfBirth: boolean | undefined;
}
