import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import {
    addPinnedTopic,
    addPinnedTopicFailure,
    addPinnedTopicSuccess,
    getPinnedTopicsFailure,
    getPinnedTopicsSuccess,
    removePinnedTopic,
    removePinnedTopicFailure,
    removePinnedTopicSuccess,
} from './pinned-topics.actions';
import { PinnedTopicsService } from '../services/pinned-topics.service';
import { getHouseholdSuccess } from '@frontend/data-access/user/household';

@Injectable()
export class PinnedTopicsEffects {
    private readonly actions$ = inject(Actions);
    private readonly pinnedTopicsService = inject(PinnedTopicsService);
    getPinnedTopics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            switchMap(() =>
                this.pinnedTopicsService.getPinnedTopics().pipe(
                    map((pinnedTopics) => getPinnedTopicsSuccess({ pinnedTopics })),
                    catchError((error: Error) => of(getPinnedTopicsFailure({ error }))),
                ),
            ),
        );
    });

    addPinnedTopic$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addPinnedTopic),
            switchMap(({ id }) => {
                return this.pinnedTopicsService.addPinnedTopic({ topicId: id }).pipe(
                    map((pinnedTopic) => addPinnedTopicSuccess({ pinnedTopic })),
                    catchError((error: Error) => of(addPinnedTopicFailure({ error }))),
                );
            }),
        );
    });

    removePinnedTopic$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removePinnedTopic),
            switchMap(({ pinnedTopic }) => {
                return this.pinnedTopicsService.removePinnedTopic(pinnedTopic.id).pipe(
                    map(() => removePinnedTopicSuccess({ pinnedTopic })),
                    catchError((error: Error) => of(removePinnedTopicFailure({ error }))),
                );
            }),
        );
    });
}
