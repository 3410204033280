import { Component, Input } from '@angular/core';
import { Bullet } from './bullet-list.model';

@Component({
    selector: 'app-bullet-list',
    templateUrl: './bullet-list.component.html',
    styleUrls: ['./bullet-list.component.scss'],
    standalone: true,
})
export class BulletListComponent {
    @Input({ required: true }) bullets!: Bullet[];
}
