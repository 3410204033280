import { Component, EventEmitter, Output } from '@angular/core';
import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-ai-chat-header',
    templateUrl: 'ai-chat-header.component.html',
    styleUrls: ['ai-chat-header.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, IonHeader, IonButtons, IonButton, IonToolbar, IonTitle],
})
export class AiChatHeaderComponent {
    @Output()
    backClicked: EventEmitter<void> = new EventEmitter<void>();

    onBackClicked() {
        this.backClicked.emit();
    }
}
