import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { selectIsTieredPricingPaywallAb } from '@frontend/data-access/user/config-cat';
import { HeaderComponent } from '@frontend/feature/header';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar, NavParams } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { closePaymentModalClicked } from '../../store/payment/store/payment.actions';
import { PaymentModalDefaultComponent } from '../payment-modal-default/payment-modal-default.component';
import { PaymentModalTieredPricingComponent } from '../payment-modal-tiered-pricing/payment-modal-tiered-pricing.component';

@Component({
    standalone: true,
    selector: 'app-payment-modal-parent',
    templateUrl: './payment-modal-parent.component.html',
    styleUrl: './payment-modal-parent.component.scss',
    imports: [
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        PaymentModalDefaultComponent,
        NgClass,
        PaymentModalTieredPricingComponent,
    ],
})
export class PaymentModalParentComponent {
    private readonly store = inject(Store);
    private readonly navParams = inject(NavParams);

    public showDiscountProducts = this.navParams.data['showDiscountProducts'] ?? false;

    public isTieredPricingPaywallAb = this.store.selectSignal(selectIsTieredPricingPaywallAb);

    public onCloseClick(): void {
        this.store.dispatch(closePaymentModalClicked());
    }
}
