import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { getAccountSuccess } from '@frontend/data-access/user/account';
import {
    createManyCourseProgress,
    createManyCourseProgressSuccess,
    getCourseProgress,
} from '@frontend/data-access/user/progress';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CourseProgressType } from '@shared/user-domain';
import { filter, map } from 'rxjs/operators';
import { showCourseCompletionModal } from '../../../courses/course-completion-modal/course-completion-modal.actions';
import { triggerCreateManyCourseProgress } from './course-progress.actions';

@Injectable()
export class CourseProgressEffects {
    private readonly actions$ = inject(Actions);

    triggerGetCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(ofType(getAccountSuccess)).pipe(map(() => getCourseProgress()));
    });

    triggerCreateManyCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerCreateManyCourseProgress),
            map(({ courseProgress, correlationId }) => {
                const timestamp = new Date().getTime();

                const mappedCourseProgress = courseProgress.map((courseProgress) => {
                    return {
                        courseId: courseProgress.id,
                        progressType: courseProgress.progressType,
                        timestamp,
                    };
                });

                return createManyCourseProgress({
                    courseProgress: mappedCourseProgress,
                    correlationId,
                });
            }),
        );
    });

    createManyCourseProgressSuccessTrackEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createManyCourseProgressSuccess),
            map(({ entries }) => {
                const mappedEntries = entries.map((entry) => ({
                    courseId: entry.courseId,
                    progress: entry.progressType,
                }));

                return trackEvent({
                    eventName: '[Course] Course Progress Created',
                    eventProperties: {
                        courseProgress: mappedEntries,
                    },
                });
            }),
        );
    });

    // TODO: NBSon - to improve upon this, can we check the course that the user was actively working on, and show the modal for that course?
    // bear in mind this is a very rare scenario
    createManyCourseProgressSuccessTriggerCourseCompletionModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createManyCourseProgressSuccess),
            filter(({ entries }) => entries.some((entry) => entry.progressType === CourseProgressType.COMPLETED)),
            map(({ entries }) => {
                // NBSon - if there are multiple courses, show the modal for the first one
                return showCourseCompletionModal({ courseId: entries[0].courseId });
            }),
        );
    });
}
