import { Component, effect } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { selectBreeds } from '@frontend/data-access/contentful';
import { BreedSelectorComponent } from '@frontend/feature/breed-selector';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedBreed } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';
import { householdFeature } from '@frontend/data-access/user/household';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-breed',
    templateUrl: 'question-breed.page.html',
    styleUrls: ['../onboarding.scss', 'question-breed.page.scss'],
    imports: [
        IonButton,
        IonSpinner,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        ProgressHeaderComponent,
        BreedSelectorComponent,
        FormsModule,
        ReactiveFormsModule,
        AnalyticsTrackClickDirective,
    ],
})
export class OnboardingQuestionBreedPage extends OnboardingQuestionBasePage {
    public breeds = this.store.selectSignal(selectBreeds);

    private breedId = this.store.selectSignal(householdFeature.selectBreedId);

    public breedForm = new FormGroup({
        breedId: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });

    constructor() {
        super();

        effect(() => {
            if (this.breedId()) {
                this.breedForm.setValue({
                    breedId: this.breedId()!,
                });
            }
        });
    }

    public onSubmit(): void {
        if (this.breedForm.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedBreed({ breedId: this.breedForm.controls.breedId.value }));
    }
}
