<ion-content [color]="'app-background'" class="review-qualifier">
    <button
        class="zz-button xl review-qualifier__close-button"
        (click)="closeModal()"
        appTrackClick
        identifier="review-qualifier-close"
        data-test="review-qualifier-close"
    >
        <i class="fa-regular fa-xmark"></i>
    </button>
    @switch (modalPage) {
        @case (1) {
            <section class="review-qualifier__first-page">
                <div class="review-qualifier__first-page-content">
                    <img
                        class="review-qualifier__dog-listening"
                        [src]="'/assets/images/bailey/bailey-holds-one-ear-up-listening-cropped.svg'"
                        alt="A puppy listening"
                    />
                    <h2 class="review-qualifier__title">Are you enjoying Zigzag?</h2>
                    <p class="review-qualifier__sub-title">We’d love to know if you’re having a great experience.</p>
                </div>
                <div class="review-qualifier__feedback-buttons">
                    <ion-button
                        fill="clear"
                        appTrackClick
                        identifier="review-qualifier-positive"
                        data-test="review-qualifier-positive"
                        (click)="launchRatingPrompt()"
                    >
                        <img [src]="'/assets/images/illustrations/chat/button-pos.svg'" alt="Thumbs up" />
                    </ion-button>
                    <ion-button
                        fill="clear"
                        appTrackClick
                        identifier="review-qualifier-negative"
                        data-test="review-qualifier-negative"
                        (click)="goToPageTwo()"
                    >
                        <img [src]="'/assets/images/illustrations/chat/button-neg.svg'" alt="Thumbs down" />
                    </ion-button>
                </div>
            </section>
        }
        @case (2) {
            <section class="review-qualifier__second-page">
                <div class="review-qualifier__second-page-content">
                    <img
                        class="review-qualifier__dog-listening"
                        [src]="'/assets/images/bailey/bailey-holds-one-ear-up-listening-cropped.svg'"
                        alt="A puppy listening"
                    />
                    <h2 class="review-qualifier__title">
                        Oh, no, sorry about that!<br />Is there anything we could do better?
                    </h2>
                    <ion-item class="review-qualifier__reason-item" [color]="'app-background'">
                        <ion-input
                            class="review-qualifier__reason-input"
                            #reasonInput
                            autocapitalize="sentences"
                            placeholder="Please add your comment"
                            type="text"
                            appTrackInput
                            identifier="review-qualifier-reason"
                            [tabindex]="1"
                        ></ion-input>
                    </ion-item>
                </div>
                <section class="review-qualifier__second-page-buttons">
                    <ion-button
                        color="max"
                        expand="block"
                        data-test="review-qualifier-submit"
                        (click)="finishedProvidingReason('submit')"
                        >Submit
                    </ion-button>
                    <button
                        class="zz-button"
                        data-test="review-qualifier-skip"
                        (click)="finishedProvidingReason('skip')"
                    >
                        Skip
                    </button>
                </section>
            </section>
        }
    }
</ion-content>
