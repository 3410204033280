import { inject, Injectable } from '@angular/core';
import {
    LocalNotificationDescriptor,
    LocalNotifications,
    LocalNotificationSchema,
    PendingResult,
    ScheduleResult,
} from '@capacitor/local-notifications';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class LocalNotificationService {
    private readonly platform = inject(Platform);

    public async checkAllPermissions() {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await LocalNotifications.checkPermissions();
    }

    public async requestPermissions() {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await LocalNotifications.requestPermissions();
    }

    public async scheduleNotification(notifications: LocalNotificationSchema[]): Promise<ScheduleResult> {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await LocalNotifications.schedule({
            notifications,
        });
    }

    public async getLocalNotifications(): Promise<PendingResult> {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await LocalNotifications.getPending();
    }

    public async clearLocalNotification(notifications: LocalNotificationDescriptor[]): Promise<void> {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await LocalNotifications.cancel({ notifications });
    }
}
