import { inject, Injectable } from '@angular/core';
import { openTailsUrl, openUrl } from '@frontend/data-access/router';
import {
    isItemInteracted,
    storeUserInteraction,
    userInteractionFeature,
} from '@frontend/data-access/user-interactions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { tabClicked } from '../../tabs/store/tab.actions';
import { TabRoute_NOURISH } from '../../tabs/tabs.constants';
import {
    NOURISH_PRODUCT_FEEDBACK_MODAL_ID,
    NOURISH_TAB_INDICATOR_ID,
    NOURISH_TILE_ANIMATION_ID,
    NourishTileId,
    PET_FOOD_FINDER_LINK,
    TAILS_ADULT_CTA_LINK,
    TAILS_PUPPY_CTA_LINK,
} from '../constants/nourish.constants';
import {
    nourishCalculateFoodButtonClicked,
    nourishFindFoodButtonClicked,
    nourishProductCtaClicked,
    nourishProductHeaderBackButtonClicked,
    nourishTileClicked,
} from './nourish.actions';

@Injectable()
export class NourishEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    dismissCalculateFoodNourishTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(([, userInteractions]) => !isItemInteracted(userInteractions, NourishTileId.CALCULATE_FOOD)),
            map(() => {
                return storeUserInteraction({
                    id: NourishTileId.CALCULATE_FOOD,
                    dontShowAgain: true,
                });
            }),
        );
    });

    dismissFindFoodNourishTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishFindFoodButtonClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(([, userInteractions]) => !isItemInteracted(userInteractions, NourishTileId.FIND_FOOD)),
            map(() => {
                return storeUserInteraction({
                    id: NourishTileId.FIND_FOOD,
                    dontShowAgain: true,
                });
            }),
        );
    });

    openPetFoodFinder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishFindFoodButtonClicked),
            map(() => {
                return openUrl({
                    url: PET_FOOD_FINDER_LINK,
                });
            }),
        );
    });

    dismissNourishIndicator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tabClicked, nourishTileClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(
                ([{ id }, userInteractions]) =>
                    (id === TabRoute_NOURISH ||
                        id === NourishTileId.CALCULATE_FOOD ||
                        id === NourishTileId.FIND_FOOD) &&
                    !isItemInteracted(userInteractions, NOURISH_TAB_INDICATOR_ID),
            ),
            map(() => {
                return storeUserInteraction({
                    id: NOURISH_TAB_INDICATOR_ID,
                    dontShowAgain: true,
                });
            }),
        );
    });

    dismissNourishTileAnimation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(
                ([{ id }, userInteractions]) =>
                    id === NourishTileId.WHY_NUTRITION_MATTERS &&
                    !isItemInteracted(userInteractions, NOURISH_TILE_ANIMATION_ID),
            ),
            map(() => {
                return storeUserInteraction({
                    id: NOURISH_TILE_ANIMATION_ID,
                    dontShowAgain: true,
                });
            }),
        );
    });

    openTails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            filter(({ url }) => url === TAILS_ADULT_CTA_LINK || url === TAILS_PUPPY_CTA_LINK),
            map(({ url }) => openTailsUrl({ url })),
        );
    });

    openExternalUrl$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            filter(({ url }) => url !== TAILS_ADULT_CTA_LINK && url !== TAILS_PUPPY_CTA_LINK),
            map(({ url }) => {
                return openUrl({
                    url,
                });
            }),
        );
    });

    addProductFeedbackInteraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductHeaderBackButtonClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(
                ([{ id }, dismissedInfo]) =>
                    !isItemInteracted(dismissedInfo, `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`),
            ),
            map(([{ id }]) => {
                return storeUserInteraction({
                    id: `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`,
                    dontShowAgain: true,
                });
            }),
        );
    });
}
