import { householdFeature, selectAgeInWeeks, selectBreedName } from '@frontend/data-access/user/household';
import { createSelector } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { selectRouteParamNourishProductId } from '../../store/router/router.selectors';
import {
    LILYS_KITCHEN_ADULT_CTA_LINK,
    LILYS_KITCHEN_PUPPY_CTA_LINK,
    NourishProductId,
    NUTRITIONAL_CONSULTATION_A_LINK,
    NUTRITIONAL_CONSULTATION_B_LINK,
    PURINA_PRO_PLAN_CTA_LINK,
    TAILS_ADULT_CTA_LINK,
    TAILS_PUPPY_CTA_LINK,
} from '../constants/nourish.constants';
import { NourishProductInfo } from '../models/nourish.model';
import { mapDateOfBirthToAgeString } from '@frontend/utility/angular';
import { selectNutritionalReferralAsPick } from '@frontend/data-access/user/config-cat';
import { differenceInWeeks } from 'date-fns';

export const selectLilysKitchenProductInfo = createSelector(selectAgeInWeeks, (ageInWeeks): NourishProductInfo => {
    const isDogOlderThan1Year = ageInWeeks ? ageInWeeks >= 52 : true;

    return {
        id: NourishProductId.LILYS_KITCHEN,
        title: '100% natural ingredients',
        logo: {
            src: '/assets/images/third-party/lilys-kitchen/lilys-kitchen-logo-dark.svg',
            alt: "An image depicting the Lily's Kitchen logo",
        },
        colour: Color.Alvin,
        perks: [
            {
                icon: 'fa-regular fa-shield-dog',
                text: 'Supports all-round health',
            },
            {
                icon: 'fa-regular fa-arrows-spin',
                text: 'Easy to digest',
            },
        ],
        productName: "Lily's Kitchen",
        bulletPoints: [
            {
                image: {
                    src: '/assets/images/oliver/oliver-licks-food-from-floor.svg',
                    alt: 'Oliver on all fours sticking his tongue out to lick food from the floor',
                },
                title: 'Proper food',
                description:
                    'Made with wholesome, natural ingredients, including freshly prepared meat and offal – no meat meal',
            },
            {
                image: {
                    src: '/assets/images/bailey/bailey-jumps-in-front-of-tennis-ball.svg',
                    alt: 'Bailey jumping in front of a tennis ball ready to catch it',
                },
                title: 'Complete Nutrition',
                description: 'Complete and balanced recipes to help [NAME] grow',
            },
            {
                image: {
                    src: '/assets/images/oliver/oliver-gets-checkup-from-vet.svg',
                    alt: 'Oliver getting a checkup from a vet using a stethoscope',
                },
                title: 'Vet approved',
                description:
                    'Developed with vet and nutritionists to ensure their recipes have everything [NAME] needs',
            },
        ],
        coach: {
            image: {
                src: '/assets/images/trainers/coach-emma-yellow-circle.png',
                alt: 'Emma Judson, Zigzag Training Coach, posing with a dog',
            },
            name: 'Emma Judson, Zigzag Training Coach',
            comment:
                "As a trainer and behaviourist I know how vital proper nutrition is for a dog’s development and behaviour. Lily's Kitchen recipes provide the balanced, wholesome nutrition that supports healthy growth, boosts energy levels, and helps pups stay focused during training.\nGood food is the foundation for physical health and mental well-being, and I’ve seen first hand how a nutritious diet like Lily’s Kitchen can make all the difference for happy, thriving dogs.",
        },
        discount: {
            title: '30% OFF',
            code: 'LOVELILYS',
        },
        callToAction: {
            text: 'Claim discount now',
            link: isDogOlderThan1Year ? LILYS_KITCHEN_ADULT_CTA_LINK : LILYS_KITCHEN_PUPPY_CTA_LINK,
        },
    };
});

export const selectTailsTailoredToString = createSelector(
    householdFeature.selectDateOfBirth,
    selectBreedName,
    (dateOfBirth, breedName): string => {
        const currentDate = new Date();
        let birthDate = new Date();
        if (dateOfBirth) {
            birthDate = dateOfBirth;
        }
        const ageInWeeks = differenceInWeeks(currentDate, birthDate);

        const ageString = mapDateOfBirthToAgeString(dateOfBirth, undefined, false);
        const titleCaseBreedName = breedName?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

        if (ageInWeeks! > 104) {
            return `Tailored to ${titleCaseBreedName}s`;
        }

        return `Tailored to ${ageString} old ${titleCaseBreedName}s`;
    },
);

export const selectTailsProductInfo = createSelector(
    selectAgeInWeeks,
    selectTailsTailoredToString,
    (ageInWeeks, tailoredToString): NourishProductInfo => {
        const isDogOlderThan1Year = ageInWeeks ? ageInWeeks >= 52 : true;

        return {
            id: NourishProductId.TAILS,
            title: '100% tailored for [NAME]',
            logo: {
                src: '/assets/images/third-party/tails/tails-logo-dark.svg',
                alt: 'An image depicting the Tails.com logo',
            },
            colour: Color.Harley,
            perks: [
                {
                    icon: 'fa-regular fa-mug',
                    text: 'Personalised portion scoop',
                },
                {
                    icon: 'fa-regular fa-dog',
                    text: 'Nutrition adapts as they grow',
                },
                {
                    icon: 'fa-regular fa-memo-circle-check',
                    text: tailoredToString,
                },
            ],
            productName: 'Tails',
            bulletPoints: [
                {
                    title: 'Tailored nutrition',
                    description:
                        'A vet-approved kibble blend tailored to your dog’s breed, age, and lifestyle, supporting health at a great price',
                    image: {
                        src: '/assets/images/bailey/bailey-looks-up-at-owner-with-checklist.svg',
                        alt: 'Bailey looking up at their owner who is holding a checklist',
                    },
                },
                {
                    title: 'Free home delivery',
                    description:
                        'Conveniently delivered to your door on a schedule that suits you, complete with a personalised portion scoop',
                    image: {
                        src: '/assets/images/bailey/bailey-leans-out-of-red-car-window.svg',
                        alt: 'Bailey leaning out of a red car window with their tongue out',
                    },
                },
                {
                    title: 'Ultimate flexibility',
                    description:
                        'Easily adapt kibble blend for digestive needs, switch flavours, or adjust your subscription anytime',
                    image: {
                        src: '/assets/images/buddy/buddy-licks-food-from-treat-mat.svg',
                        alt: 'Buddy licking food from a treat mat',
                    },
                },
            ],
            coach: {
                image: {
                    src: '/assets/images/trainers/coach-petrina-red-circle.png',
                    alt: 'Petrina Firth, a Zigzag dog training coach, posing with a dog',
                },
                name: 'Petrina Firth, Zigzag Training Coach',
                comment:
                    "As a dog trainer, I always recommend Tails.com for young pups. Their personalised recipes provide the perfect balance of nutrients to support growth, energy, and development during this crucial stage. It's tailored care in every bite!",
            },
            offer: {
                title: 'Get 75% OFF your 1st box',
                subtitle: '+ 25% off your 2nd',
            },
            callToAction: {
                text: 'Claim discount now',
                link: isDogOlderThan1Year ? TAILS_ADULT_CTA_LINK : TAILS_PUPPY_CTA_LINK,
            },
        };
    },
);

export const selectNutritionalConsultationProductInfo = createSelector(
    selectNutritionalReferralAsPick,
    (nutritionalReferralAsPick): NourishProductInfo => {
        return {
            id: NourishProductId.NUTRITIONAL_REFERRAL,
            title: 'Nutritional Consultation',
            logo: {
                src: '/assets/images/third-party/purina/powered-by-purina-white.svg',
                alt: 'An image depicting the Powered by Purina logo',
            },
            colour: Color.Maggie,
            background: Color.Alvin,
            perks: [
                {
                    icon: 'fa-regular fa-mug',
                    text: 'Promote healthy growth',
                },
                {
                    icon: 'fa-regular fa-dog',
                    text: 'Protect coat and skin',
                },
                {
                    icon: 'fa-regular fa-memo-circle-check',
                    text: 'Solve digestive issues with tailored nutrition',
                },
            ],
            productName: 'Nutritional Consultation',
            bulletPoints: [
                {
                    title: '76% of owners make nutritional mistakes',
                    description:
                        'A <b class="s">pet nutritionist</b> will create a custom plan, so you have peace of mind you’re feeding your pup the right food',
                    image: {
                        src: '/assets/images/buddy/buddy-diarrhoea.svg',
                        alt: 'Buddy standing with diarrhoea',
                    },
                },
                {
                    title: 'Free home delivery',
                    description: 'Delivered on a schedule that suits you, with a personalised portion scoop included',
                    image: {
                        src: '/assets/images/bailey/bailey-leans-out-of-red-car-window.svg',
                        alt: 'Bailey leaning out of a red car window with their tongue out',
                    },
                },
                {
                    title: '100% personalised nutrition',
                    description: "A tailored kibble blend based on your pup's breed, age, and needs",
                    image: {
                        src: '/assets/images/oliver/oliver-licks-food-from-floor.svg',
                        alt: 'Oliver licking food from the floor',
                    },
                },
            ],
            coach: {
                image: {
                    src: '/assets/images/trainers/coach-lorna-blue-circle.png',
                    alt: 'Lorna Winter, Zigzag Co Founder and Head of Programme',
                },
                name: 'Lorna Winter, Zigzag Co Founder and Head of Programme',
                comment:
                    'Every pup is unique, from their waggy tail to their wobbly sit—and that includes their nutritional needs! Getting a personalised nutritional referral means your dog gets exactly what they need to thrive, whether they’re a growing whirlwind of chaos or a more chilled-out snoozer. The right diet supports everything from energy levels to brain development, making training that much easier (because a pup who feels great learns better!). Plus, fewer tummy troubles mean fewer unexpected interruptions mid-training—win-win!',
                colour: Color.Alvin,
            },
            callToAction: {
                text: 'Get started now',
                link: nutritionalReferralAsPick ? NUTRITIONAL_CONSULTATION_B_LINK : NUTRITIONAL_CONSULTATION_A_LINK,
            },
        };
    },
);

export const selectNourishProductInfo = createSelector(
    selectLilysKitchenProductInfo,
    selectTailsProductInfo,
    selectNutritionalConsultationProductInfo,
    (lilysKitchenProductInfo, tailsProductInfo, nutritionalConsultationProductInfo): NourishProductInfo[] => {
        return [
            nutritionalConsultationProductInfo,
            lilysKitchenProductInfo,
            {
                id: NourishProductId.PURINA_PRO_PLAN,
                title: 'Advanced nutrition, backed by science',
                logo: {
                    src: '/assets/images/third-party/purina/purina-pro-plan-logo.svg',
                    alt: 'An image depicting the Purina Pro Plan logo',
                },
                colour: Color.Buddy,
                background: Color.Jack,
                perks: [
                    {
                        icon: 'fa-regular fa-arrow-up-right-dots',
                        text: 'Promotes healthy growth',
                    },
                    {
                        icon: 'fa-regular fa-stomach',
                        text: 'Helps sensitive stomachs',
                    },
                ],
                productName: 'Purina Pro Plan',
                bulletPoints: [
                    {
                        title: 'Vet recommended',
                        description: 'Formulated by Purina vets and nutritionists to maintain long-term health',
                        image: {
                            src: '/assets/images/buddy/buddy-holds-checklist-in-mouth-with-owner.svg',
                            alt: 'Buddy sitting holding a checklist in their mouth with their owner next to them',
                        },
                    },
                    {
                        title: 'Strengthens immunity',
                        description: 'Supports your pup’s natural defences for a healthier, happier life',
                        image: {
                            src: '/assets/images/charly/charly-holds-tennis-ball-in-mouth.svg',
                            alt: 'Charly holding a tennis ball in his mouth playfully',
                        },
                    },
                    {
                        title: 'Easy digestion',
                        description: 'Crafted for sensitive stomachs, reducing runny poos and tummy troubles',
                        image: {
                            src: '/assets/images/charly/charly-takes-a-poop.svg',
                            alt: 'Charly taking a poop',
                        },
                    },
                ],
                coach: {
                    image: {
                        src: '/assets/images/trainers/coach-lorna-blue-circle.png',
                        alt: 'Lorna Winter, Zigzag Co Founder and Head of Programme, posing with a dog',
                    },
                    name: 'Lorna Winter, Zigzag Co Founder and Head of Programme',
                    comment:
                        'As a trainer, behaviourist and dog parent myself, I always recommend Purina ProPlan for young pups. Their advanced nutrition contains colostrum and DHA which is super important to help immunity systems develop strongly, and to help with brain growth – which when it comes to training pups, they are going to definitely be needing!\nI also love the fact that it has high nutrient density, meaning you typically feed less volume than many other brands, and you get nice perfect poops out the other end! It’s a win win for everyone 😊',
                },
                discount: {
                    title: '20% OFF + FREE probiotics',
                    code: 'TRYPROPLAN',
                },
                callToAction: {
                    text: 'Claim discount now',
                    link: PURINA_PRO_PLAN_CTA_LINK,
                },
            },
            tailsProductInfo,
        ];
    },
);

export const selectSelectedNourishProductTile = createSelector(
    selectRouteParamNourishProductId,
    selectNourishProductInfo,
    (nourishProductId, allProductInfo): NourishProductInfo | undefined => {
        return allProductInfo.find((tile) => tile.id === nourishProductId);
    },
);
