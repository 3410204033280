<h2>Are you sure you want to close this lesson?</h2>
<p class="lesson-close-notification-modal-text s">
    The lesson will be marked as
    <span class="lesson-close-notification-modal-text__glasses-icon-container"
        ><i class="lesson-close-notification-modal-text__glasses-icon fa-regular fa-glasses"></i
    ></span>
    <strong>Read</strong> until you have completed it.
</p>

<section class="lesson-close-notification-modal-buttons">
    <ion-button
        class="lesson-close-notification-modal-buttons__close-button"
        appTrackClick
        identifier="step-notification-modal-close-button"
        color="max"
        (click)="setLessonAsRead()"
        >Close lesson
    </ion-button>
    <button class="zz-button" appTrackClick identifier="step-notification-modal-cancel-button" (click)="closeModal()">
        Cancel
    </button>
</section>
