import { IterableRegisterForPushNotificationsCommand } from '../commands/iterable-register-for-push-notifications.command';
import { IterablePlatformType } from '../interfaces/iterable-platform-type.model';

export class InternalIterableRegisterForPushNotificationsCommandMock {
    private defaultValue: IterableRegisterForPushNotificationsCommand = {
        userId: 'user-id',
        device: {
            token: 'token',
            platform: IterablePlatformType.FIREBASE_MESSAGING,
            applicationName: 'zigzag',
        },
    };

    constructor(overrides?: Partial<IterableRegisterForPushNotificationsCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterableRegisterForPushNotificationsCommand {
        return this.defaultValue;
    }
}
