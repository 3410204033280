import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { InternalSubscriptionDto } from '@shared/internal-api-interface';
import { Entitlement, PurchaseProduct, SubscriptionOffering } from './models/subscription.model';
import {
    getActiveSubscription,
    getActiveSubscriptionFailure,
    getActiveSubscriptionSuccess,
    getEntitlements,
    getEntitlementsFailure,
    getEntitlementsSuccess,
    getOfferings,
    getOfferingsFailure,
    getOfferingsSuccess,
    logInToPurchasesSuccess,
    purchaseProduct,
    purchaseProductCancelled,
    purchaseProductFailure,
    purchaseProductSuccess,
} from './subscription.actions';

export interface SubscriptionState {
    nonNativeSubscriptionInfo: InternalSubscriptionDto;
    entitlements: {
        value: Entitlement[];
        hasHistoricalPurchase?: boolean;
    };
    offering: SubscriptionOffering[];
    purchase: PurchaseProduct;
    isLoading: LoadingState;
}

export const initialState: SubscriptionState = {
    nonNativeSubscriptionInfo: {
        isActive: false,
    },
    entitlements: {
        value: [],
        hasHistoricalPurchase: false,
    },
    offering: [],
    purchase: { identifier: '', priceWithUnit: '' },
    isLoading: LoadingState.INIT,
};

export const subscriptionFeature = createFeature({
    name: 'subscription',
    reducer: createReducer(
        initialState,
        on(
            getActiveSubscription,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADING,
                nonNativeSubscriptionInfo: { ...state.nonNativeSubscriptionInfo },
            }),
        ),
        on(
            getActiveSubscriptionSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                nonNativeSubscriptionInfo: {
                    isActive: payload.data.value.isActive,
                    provider: payload.data.value.provider,
                },
            }),
        ),
        on(
            getActiveSubscriptionFailure,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                nonNativeSubscriptionInfo: { ...state.nonNativeSubscriptionInfo },
            }),
        ),
        on(
            getEntitlements,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADING,
                entitlements: { ...state.entitlements },
            }),
        ),
        on(
            getEntitlementsSuccess,
            logInToPurchasesSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                entitlements: payload.data,
            }),
        ),
        on(
            getEntitlementsFailure,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                entitlements: { ...state.entitlements },
            }),
        ),
        on(
            getOfferings,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADING,
                offering: { ...state.offering },
            }),
        ),
        on(
            getOfferingsSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                offering: payload.data.value,
            }),
        ),
        on(
            getOfferingsFailure,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                offering: { ...state.offering },
            }),
        ),
        on(
            purchaseProduct,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADING,
                purchase: { ...state.purchase },
            }),
        ),
        on(
            purchaseProductSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                purchase: { identifier: payload?.data?.identifier, priceWithUnit: payload?.data?.priceWithUnit },
            }),
        ),
        on(
            purchaseProductFailure,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                purchase: { ...state.purchase },
            }),
        ),
        on(
            purchaseProductCancelled,
            (state): SubscriptionState => ({
                ...state,
                isLoading: LoadingState.LOADED,
                purchase: { ...state.purchase },
            }),
        ),
    ),
    extraSelectors: ({ selectSubscriptionState }) => ({
        selectEntitlements: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.entitlements?.value || [],
        ),
        selectDefaultOffering: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.offering?.find((offering) => offering.isDefault) || undefined,
        ),
        selectOfferings: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.offering || [],
        ),
        selectNonNativeSubscriptionInfo: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.nonNativeSubscriptionInfo,
        ),
        selectHasHistoricalPurchase: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.entitlements?.hasHistoricalPurchase,
        ),
        selectAreSubscriptionsLoading: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.isLoading !== LoadingState.LOADED,
        ),
    }),
});
