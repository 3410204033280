export interface AccountErrorLink {
    text: string;
    route: string[];
}

export interface AccountErrorObject {
    message: FirebaseErrorMessage;
    link?: AccountErrorLink;
}

export enum FirebaseErrorCode {
    INVALID_PASSWORD = 'auth/invalid-password',
    INVALID_EMAIL = 'auth/invalid-email',
    INVALID_CLAIMS = 'auth/invalid-claims',
    ID_TOKEN_EXPIRED = 'auth/id-token-expired',
    ID_TOKEN_REVOKED = 'auth/id-token-revoked',
    INVALID_CREDENTIAL = 'auth/invalid-credential',
    INSUFFICIENT_PERMISSION = 'auth/insufficient-permission',
    USER_NOT_FOUND = 'auth/user-not-found',
    EMAIL_ALREADY_EXISTS = 'auth/email-already-exists',
    TOO_MANY_REQUESTS = 'auth/too-many-requests',
    INTERNAL_ERROR = 'auth/internal-error',
}

export const firebaseErrorCodeMap = new Map<FirebaseErrorCode, number>([
    [FirebaseErrorCode.INVALID_PASSWORD, 400],
    [FirebaseErrorCode.INVALID_EMAIL, 400],
    [FirebaseErrorCode.INVALID_CLAIMS, 400],
    [FirebaseErrorCode.ID_TOKEN_EXPIRED, 401],
    [FirebaseErrorCode.ID_TOKEN_REVOKED, 401],
    [FirebaseErrorCode.INVALID_CREDENTIAL, 401],
    [FirebaseErrorCode.INSUFFICIENT_PERMISSION, 401],
    [FirebaseErrorCode.USER_NOT_FOUND, 404],
    [FirebaseErrorCode.EMAIL_ALREADY_EXISTS, 409],
    [FirebaseErrorCode.TOO_MANY_REQUESTS, 429],
    [FirebaseErrorCode.INTERNAL_ERROR, 500],
]);

export enum FirebaseErrorMessage {
    INVALID_PASSWORD = 'Oops, this password is invalid. Forgot your password? Click the link below.',
    INVALID_EMAIL = 'Oops, this email is invalid, please try again with a different email or contact contact@zigzag.dog.',
    INVALID_CREDENTIAL = 'Oops, the email or password is invalid. Forgot your password? Click the link below.',
    EMAIL_ALREADY_EXISTS = 'This email is already in use, please try another email or go back to ',
    TOO_MANY_REQUESTS = 'Oops, you’ve reached your maximum number of login attempts. Please try again later. Forgot your password? Click the link below.',
    USER_NOT_FOUND = 'We couldn’t find this account, please try again with a different email or contact contact@zigzag.dog.',
    DEFAULT = 'Oops, looks like something went wrong... Please try again later or contact contact@zigzag.dog.',
}
