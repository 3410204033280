import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedIsRescue } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-rescue',
    templateUrl: 'question-rescue.page.html',
    styleUrls: ['question-rescue.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionRescuePage extends OnboardingQuestionBasePage {
    public onIsRescueClick(isRescue: boolean) {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedIsRescue({ isRescuePup: isRescue }));
    }
}
