<ion-item
    class="food-product-selector"
    appFoodProductSelector
    button
    lines="full"
    detail="false"
    data-test="food-product-selector-btn"
    [products]="foodProducts$ | async"
    (foodProductChange)="selectFoodProduct($event)"
>
    <ion-label position="stacked">Product</ion-label>
    <h3 data-test="food-product-selector-title">
        {{
            !!selectedFoodProduct
                ? selectedFoodProduct.brandName + ' ' + selectedFoodProduct.name
                : 'Search brand & product'
        }}
    </h3>
    <i class="food-product-selector__icon fa-solid fa-chevron-right" slot="end" size="small"></i>
</ion-item>
