<ion-content class="page-ion-content" [fullscreen]="true">
    <div class="support-modal">
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="{ analyticsIdentifier: 'expert-info-close-btn', background: 'white' }"
                    [rightButton]="false"
                    (leftButtonPressed)="closeModal()"
                />
            </ion-toolbar>
        </ion-header>

        @if (supportExpert) {
            <div>
                <img src="{{ supportExpert.imageUrl }}" class="image-self" alt="header-image" />
                <div class="text-block double-padding" style="padding-bottom: 20px">
                    <h1>{{ supportExpert.firstName + ' ' + supportExpert.lastName }}</h1>
                    <div class="content">
                        <h3>About {{ supportExpert.firstName }}</h3>
                        @for (about of supportExpert.aboutMe; track about) {
                            <p>{{ about }}</p>
                        }
                        <h3>Certifications</h3>
                        @for (cert of supportExpert.certifications; track cert) {
                            <div class="bullet-row">
                                <div class="checkmark">
                                    <i class="fa-regular fa-medal"></i>
                                </div>
                                <p class="bullet-text">{{ cert }}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</ion-content>
