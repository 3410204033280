import { AsyncPipe } from '@angular/common';
import { Component, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonItem, IonLabel } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { FoodProduct } from '../../data-access/food-calculator.model';
import { foodCalculatorFeature } from '../../data-access/store/food-calculator.reducer';
import { FoodProductSelectorDirective } from './food-product-selector.directive';

@Component({
    selector: 'app-food-product-selector',
    templateUrl: './food-product-selector.component.html',
    styleUrl: './food-product-selector.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FoodProductSelectorComponent),
        },
    ],
    imports: [FoodProductSelectorDirective, AsyncPipe, IonLabel, IonItem],
})
export class FoodProductSelectorComponent implements ControlValueAccessor {
    private readonly store = inject(Store);

    public selectedFoodProduct?: FoodProduct;

    public foodProducts$ = this.store
        .select(foodCalculatorFeature.selectFoodProducts)
        .pipe(filter((foodProducts) => !!foodProducts.length));

    public onChange: (value: string) => void = () => {
        // Do nothing
    };

    public onTouched: () => void = () => {
        // Do nothing
    };

    public registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: string): void {
        this.foodProducts$.pipe(first()).subscribe((foodProducts) => {
            this.selectedFoodProduct = foodProducts.find((fp) => fp.id === value);
        });
    }

    public selectFoodProduct($event: FoodProduct): void {
        this.selectedFoodProduct = $event;

        this.onTouched();
        this.onChange(this.selectedFoodProduct.id);
    }
}
