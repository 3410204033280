import { inject, Injectable } from '@angular/core';
import { logout } from '@frontend/data-access/user/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { trackEvent } from '@frontend/data-access/analytics';
import { deleteHouseholdSuccess } from '@frontend/data-access/user/household';
import { concatMap } from 'rxjs';

@Injectable()
export class DeleteAccountEffects {
    private readonly actions$ = inject(Actions);

    deleteAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteHouseholdSuccess),
            concatMap(() => [trackEvent({ eventName: 'delete account success' }), logout()]),
        );
    });
}
