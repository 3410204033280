import { IterablePostPushOpenEventCommand } from '../commands/iterable-post-push-open-event.command';

export class InternalIterablePostPushOpenEventCommandMock {
    private defaultValue: IterablePostPushOpenEventCommand = {
        campaignId: 0,
        messageId: '',
        templateId: 0,
        userId: 'user-id',
        createdAt: 1,
    };

    constructor(overrides?: Partial<IterablePostPushOpenEventCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostPushOpenEventCommand {
        return this.defaultValue;
    }
}
