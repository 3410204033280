import { SaIterableUpsertUserCommand } from '../../commands/user/sa-iterable-upsert-user.command';
import { InternalSaIterableDataFieldsMock } from './internal-sa-iterable-data-fields.mock';

export class InternalSaIterableUpsertUserMock {
    private defaultValue: SaIterableUpsertUserCommand = {
        email: 'jasper@mattijs.milos',
        dataFields: new InternalSaIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<SaIterableUpsertUserCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SaIterableUpsertUserCommand {
        return this.defaultValue;
    }
}
