import { createAction, props } from '@ngrx/store';

export const courseSliderChanged = createAction(
    '[Course Slider] Course Slider Changed',
    props<{ courseId: string; courseTitle: string }>(),
);

export const setCourseSliderScrollToTop = createAction(
    '[Course Slider] Change Course Slider Scroll To Top',
    props<{ courseId: string }>(),
);

export const setCourseSliderScrollToFirstInactiveStep = createAction(
    '[Course Slider] Change Course Slider Scroll To First Inactive Step',
    props<{ courseId: string }>(),
);

export const setSelectedPathCourseIdScrollToTop = createAction(
    '[Course Slider] Set Selected Path Course Id Scroll To Top',
    props<{ courseId: string }>(),
);

export const setSelectedPathCourseIdScrollToFirstInactiveStep = createAction(
    '[Course Slider] Set Selected Path Course Id Scroll To First Inactive Step',
    props<{ courseId: string }>(),
);
