import { SubscriptionPackage } from '@frontend/data-access/subscription';
import { roundNumber } from '@shared/utils/typescript';
import { computeOriginalPrice } from '../store/payment/utils/payment.utils';
import { PaymentProductCard } from './payment-modal-product/payment-product-card.model';

export function mapOfferingToPaymentProductCardArray(
    packages: SubscriptionPackage[],
    hasHistoricalPurchase: boolean,
): PaymentProductCard[] {
    if (packages.length === 0) {
        return [];
    }
    const mostExpensivePerMonth = packages[packages.length - 1].product.pricePerMonth;
    const originalPrice = computeOriginalPrice(mostExpensivePerMonth);

    return packages.map((aPackage, index) => {
        const discountPercentage = (1 - aPackage.product.price / originalPrice) * 100;
        return {
            identifier: aPackage.product.identifier,
            title: aPackage.product.title,
            description: constructDescription(
                aPackage.description,
                aPackage.product.introPricePeriod,
                hasHistoricalPurchase,
            ),
            priceWithUnit: aPackage.product.priceWithUnit,
            pricePerMonth: `${aPackage.product.currencySymbol + roundNumber(aPackage.product.pricePerMonth, 2)}/month`,
            discount:
                index === 0
                    ? {
                          title: `Save ${roundNumber(discountPercentage)}%`,
                          originalPrice: aPackage.product.currencySymbol + roundNumber(originalPrice, 2),
                          discountPercentage: `${roundNumber(discountPercentage)}%`,
                      }
                    : undefined,
        };
    });
}

export function mapOfferingToDiscountPaymentProductCardArray(packages: SubscriptionPackage[]): PaymentProductCard[] {
    return packages
        .filter((aPackage) => aPackage.product.discount)
        .map((aPackage, index) => {
            const discountPercentage = (1 - (aPackage.product.discount?.price ?? 0) / aPackage.product.price) * 100;
            return {
                identifier: aPackage.product.discount?.identifier ?? '',
                title: aPackage.product.title,
                description: `Billed ${aPackage.description}`,
                priceWithUnit: aPackage.product.discount?.priceWithUnit ?? '',
                pricePerMonth: `${
                    aPackage.product.currencySymbol + roundNumber(aPackage.product.discount?.pricePerMonth ?? 0, 2)
                }/month`,
                discount:
                    index === 0
                        ? {
                              title: `Save an extra ${roundNumber(discountPercentage)}%`,
                              originalPrice: aPackage.product.currencySymbol + roundNumber(aPackage.product.price, 2),
                              discountPercentage: `${roundNumber(discountPercentage)}%`,
                          }
                        : undefined,
            };
        });
}

export const mapOfferingToLimitedOfferPaymentProductCardArray = (
    packages: SubscriptionPackage[],
    offerEndDate: Date | undefined,
): PaymentProductCard[] => {
    if (packages.length === 0) {
        return [];
    }

    const mostExpensivePerMonth = packages[packages.length - 1].product.pricePerMonth;
    const originalPrice = computeOriginalPrice(mostExpensivePerMonth);

    return packages.map((aPackage, index) => {
        const discountPercentage = (1 - aPackage.product.price / originalPrice) * 100;

        return {
            identifier: aPackage.product.identifier,
            title: aPackage.product.title,
            description: constructDescription(aPackage.description, aPackage.product.introPricePeriod, false),
            priceWithUnit: aPackage.product.priceWithUnit,
            pricePerMonth: `${aPackage.product.currencySymbol + roundNumber(aPackage.product.pricePerMonth, 2)}/month`,
            discount:
                index === 0
                    ? {
                          identifier: 'limitedOfferDiscount',
                          originalPrice: aPackage.product.currencySymbol + roundNumber(originalPrice, 2),
                          title: `Save ${roundNumber(discountPercentage)}%`,
                          discountPercentage: `${roundNumber(discountPercentage)}%`,
                      }
                    : undefined,
            offerEndDate: index === 0 ? offerEndDate : undefined,
        };
    });
};

function constructDescription(
    packageDescription: string,
    introPricePeriod: string | undefined,
    hasHistoricalPurchase: boolean,
): string {
    let description = `Billed ${packageDescription}`;
    if (introPricePeriod && !hasHistoricalPurchase) {
        description = `${description} • ${introPricePeriod.replace(' ', '&nbsp')}&nbsp;free&nbsp;trial`;
    }
    return description;
}
