import { IterableUpsertUserCommand } from '../commands/user/iterable-upsert-user.command';
import { InternalIterableDataFieldsMock } from './internal-iterable-data-fields.mock';

export class InternalIterableUpsertUserCommandMock {
    private defaultValue: IterableUpsertUserCommand = {
        email: 'jasper@mattijs.milos',
        dataFields: new InternalIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<IterableUpsertUserCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterableUpsertUserCommand {
        return this.defaultValue;
    }
}
