import { Component, inject, input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderButton, HeaderComponent } from '@frontend/feature/header';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'app-error-screen-modal',
    templateUrl: './error-screen-modal.component.html',
    styleUrl: './error-screen-modal.component.scss',
    imports: [AnalyticsTrackClickDirective, IonContent, IonFooter, IonButton, HeaderComponent, IonHeader, IonToolbar],
})
export class ErrorScreenModalComponent {
    private readonly modalService = inject(ModalService);

    public errorMessage = input.required<string>();

    public buttonText = input('Close');

    protected readonly Color = Color;
    protected readonly leftButtonConfig: HeaderButton = {
        analyticsIdentifier: 'error-screen-back-btn',
        background: 'none',
    };

    public closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
