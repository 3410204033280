<button
    class="nourish-tile as-div"
    [ngStyle]="{
        'background-color': tileColour(),
        'border-color': 'var(--ion-color-' + colour() + ')',
    }"
    (click)="onNourishTileClicked()"
>
    <h3
        class="nourish-tile__pill"
        [ngStyle]="{
            'background-color': 'var(--ion-color-' + colour() + ')',
        }"
    >
        {{ pill() }}
    </h3>

    <h3
        class="nourish-tile__title"
        [ngStyle]="{
            color: 'var(--ion-color-' + backgroundColour() + '-contrast)',
        }"
    >
        {{ title() | textReplacement }}
    </h3>

    <img
        class="nourish-tile__image"
        [src]="image().src"
        [alt]="image().alt"
        [ngStyle]="{
            height: image().height,
        }"
    />
</button>
