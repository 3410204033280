import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { uuidFeature } from './uuid.reducer';
import { UuidEffects } from './uuid.effects';

@NgModule({
    providers: [provideState(uuidFeature), provideEffects([UuidEffects])],
})
export class ZigzagFrontendDataAccessUuidModule {}
