import { LoadingState } from '@frontend/data-access/shared-models';
import { CourseProgressType } from '@shared/user-domain';

export interface CourseProgressState {
    progress: FrontendCourseProgressEntry[];
    loadingState: LoadingState;
}

export interface FrontendCourseProgressEntry {
    dogId?: string;
    courseId: string;
    progressType: CourseProgressType;
    timestamp: number;
}
