import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { IonChip } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    selector: 'app-tiered-pricing-comparison-toggle',
    templateUrl: './tiered-pricing-comparison-toggle.component.html',
    styleUrl: './tiered-pricing-comparison-toggle.component.scss',
    imports: [NgClass, IonChip],
})
export class TieredPricingComparisonToggleComponent {
    public isPremiumSelected = input<boolean>(true);

    public selectTier = output<'premium' | 'basic'>();

    public basicClicked(): void {
        this.selectTier.emit('basic');
    }

    public premiumClicked(): void {
        this.selectTier.emit('premium');
    }
}
