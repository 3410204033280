import { subscriptionFeature } from '@frontend/data-access/subscription';
import { accountFeature } from '@frontend/data-access/user/account';
import { selectIsSelfSetReminders } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { selectQueryParamFromPracticeStep, selectQueryParamRating } from '../../store/router/router.selectors';
import {
    selectSelectedLessonExtras,
    selectSelectedLessonStep,
    selectSelectedStepUnlocked,
} from '../../store/step/step.selectors';

const selectRouteParams = createSelector(
    selectQueryParamRating,
    selectQueryParamFromPracticeStep,
    (rating, fromPracticeStep) => {
        return {
            rating,
            fromPracticeStep,
        };
    },
);

export const selectSelectedLessonPagesVm = createSelector(
    selectSelectedLessonStep,
    selectSelectedLessonExtras,
    selectSelectedStepUnlocked,
    accountFeature.selectDogName,
    subscriptionFeature.selectHasHistoricalPurchase,
    selectRouteParams,
    selectIsSelfSetReminders,
    (step, selectedLessonExtras, unlocked, dogName, hasHistoricalPurchase, routeParams, isSelfSetReminders) => {
        return {
            ...selectedLessonExtras,
            step,
            unlocked,
            dogName: dogName ?? 'My pup',
            hasHistoricalPurchase,
            routeParams,
            shouldShowSelfSetReminderTile: isSelfSetReminders,
        };
    },
);
