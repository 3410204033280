import { Component, inject } from '@angular/core';
import { HapticFeedbackDirective } from '@frontend/utility/native-plugins';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { deleteHousehold, householdFeature } from '@frontend/data-access/user/household';

@Component({
    standalone: true,
    selector: 'app-delete-account',
    templateUrl: 'delete-account.component.html',
    styleUrls: ['delete-account.component.scss'],
    imports: [AnalyticsTrackClickDirective, HapticFeedbackDirective, IonContent, IonButton],
})
export class DeleteAccountComponent {
    private store = inject(Store);
    private modalService = inject(ModalService);

    public householdId = this.store.selectSignal(householdFeature.selectHouseholdId);

    public closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    public deleteAccount(): void {
        const householdId = this.householdId();
        if (householdId) {
            this.store.dispatch(deleteHousehold({ householdId }));
        }

        this.closeModal();
    }
}
