import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { InternalChatTagDto, internalPaths, InternalPostChannelTagCommand } from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatTagsService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public addChannelTag(channelTagCommand: InternalPostChannelTagCommand): Observable<InternalChatTagDto> {
        return this.http.post<InternalChatTagDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.chatTagsPath}`,
            channelTagCommand,
            httpOptions,
        );
    }

    public findAndRemoveTag(channelId: string, tagId: string): Observable<InternalChatTagDto> {
        return this.http.delete<InternalChatTagDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.chatTagsPath}/${channelId}/${tagId}`,
            httpOptions,
        );
    }

    public getActiveChannelTags(id: string): Observable<InternalChatTagDto[]> {
        return this.http.get<InternalChatTagDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.chatTagsPath}/${id}`,
            httpOptions,
        );
    }

    public getAllChatTags(): Observable<InternalChatTagDto[]> {
        return this.http.get<InternalChatTagDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.chatTagsPath}`,
            httpOptions,
        );
    }
}
