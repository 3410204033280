import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { Observable } from 'rxjs';
import { internalPaths } from '@shared/user-api-interface';
import {
    IterablePostEventCommand,
    IterablePostPushOpenEventCommand,
    IterableRegisterForPushNotificationsCommand,
    IterableUpsertUserCommand,
} from '@shared/iterable-api-interface';

@Injectable({ providedIn: 'root' })
export class IterableService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    upsertIterableUser(user: IterableUpsertUserCommand): Observable<void> {
        return this.http.post<void>(`${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}`, user);
    }

    registerIterableUserForPushNotifications(command: IterableRegisterForPushNotificationsCommand): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/register-push`,
            command,
        );
    }

    trackIterableEvent(command: IterablePostEventCommand): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/event`,
            command,
        );
    }

    trackIterablePushOpenEvent(command: IterablePostPushOpenEventCommand): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/push-open`,
            command,
        );
    }
}
