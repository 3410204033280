import { createFeature, createReducer, on } from '@ngrx/store';
import { LoadingState } from '@frontend/data-access/shared-models';
import {
    createUserFeedbackContent,
    createUserFeedbackContentFailure,
    createUserFeedbackContentSuccess,
    getUserFeedbackContentSuccess,
} from './user-feedback-content.actions';
import { UserFeedbackContent } from '../models/user-feedback-content.model';

export interface UserFeedbackContentState {
    feedback: UserFeedbackContent[];
    loadingState: LoadingState;
}

export const initialState: UserFeedbackContentState = {
    feedback: [],
    loadingState: LoadingState.INIT,
};

export const userFeedbackContentFeature = createFeature({
    name: 'userFeedbackContent',
    reducer: createReducer(
        initialState,
        on(getUserFeedbackContentSuccess, (state, action): UserFeedbackContentState => {
            return {
                ...state,
                feedback: action.feedback,
                loadingState: LoadingState.LOADED,
            };
        }),
        on(createUserFeedbackContent, (state) => {
            return {
                ...state,
                loadingState: LoadingState.LOADING,
            };
        }),
        on(createUserFeedbackContentSuccess, (state, action): UserFeedbackContentState => {
            return {
                ...state,
                feedback: state.feedback!.concat([action.feedback]),
                loadingState: LoadingState.LOADED,
            };
        }),
        on(createUserFeedbackContentFailure, (state) => {
            return {
                ...state,
                loadingState: LoadingState.LOADED,
            };
        }),
    ),
});
