import { Course, selectCourses } from '@frontend/data-access/contentful';
import { courseProgressFeature, selectCompletedOrSkippedStepProgressIds } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { CourseProgressType } from '@shared/user-domain';
import { convertFractionToPercentage } from '@shared/utils/typescript';
import { CourseStatus } from './course.model';
import { calculateCourseProgress } from './course.utils';

export const selectCompletedCourseIds = createSelector(courseProgressFeature.selectProgress, (progress) => {
    return progress
        .filter((entry) => {
            return entry.progressType === CourseProgressType.COMPLETED;
        })
        .map((entry) => entry.courseId);
});

export const selectCoursesWithProgress = createSelector(
    selectCourses,
    selectCompletedOrSkippedStepProgressIds,
    selectCompletedCourseIds,
    (
        courses,
        completedOrSkippedStepIds,
        completedCourseIds,
    ): (Course & {
        status: CourseStatus;
        progress: number;
    })[] => {
        return courses
            .map((course) => {
                if (completedCourseIds.includes(course.id)) {
                    return { ...course, status: CourseStatus.COMPLETED, progress: 100 };
                } else
                    return {
                        ...course,
                        status: CourseStatus.NOT_COMPLETED,
                        progress: convertFractionToPercentage(
                            calculateCourseProgress(course, completedOrSkippedStepIds),
                        ),
                    };
            })
            .sort((a, b) => a.rank - b.rank);
    },
);

export const selectUncompletedCourses = createSelector(selectCoursesWithProgress, (courses) => {
    return courses.filter((course) => course.status !== CourseStatus.COMPLETED);
});

export const selectUnCompletedFoundationalCourses = createSelector(selectUncompletedCourses, (courses) => {
    return courses.filter((course) => course.type === CourseType.FOUNDATIONAL);
});

export const selectCoursesWith100PercentProgressButNotCompleted = createSelector(
    selectCoursesWithProgress,
    (courses) => {
        return courses.filter((course) => course.progress === 100 && course.status !== CourseStatus.COMPLETED);
    },
);
