import { PublicUser } from './public-user.model';
import { Partner } from './partner.model';

export interface HouseholdUser extends PublicUser {
    accountId: string | undefined;
    userToken: string | undefined;
    countryCode: string | undefined;
    purinaMarketingAccepted: boolean | undefined;
    partners: Partner[];
}
