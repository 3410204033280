import { StringUnion } from './string-union.utils';

const unitsOfTime = StringUnion('Year', 'Month', 'Date', 'Hours', 'Minutes', 'Seconds', 'Milliseconds');

export type UnitOfTime = typeof unitsOfTime.type;

export function toUTC(date: Date, precision: UnitOfTime = 'Milliseconds'): Date {
    const utc = {
        Year: date.getUTCFullYear(),
        Month: date.getUTCMonth(),
        Date: date.getUTCDate(),
        Hours: date.getUTCHours(),
        Minutes: date.getUTCMinutes(),
        Seconds: date.getUTCSeconds(),
        Milliseconds: date.getUTCMilliseconds(),
    };

    let precisionReached = false;

    const utcWithPrecision = unitsOfTime.values
        .filter((unit) => {
            if (precision === unit) {
                precisionReached = true;

                return true;
            }
            return !precisionReached;
        })
        .reduce(
            (dateWithPrecision, unit) => ({ ...dateWithPrecision, [unit]: utc[unit] }),
            {} as Record<string, number>,
        );

    // @ts-expect-error bit unsafe, but it does work fine
    return new Date(Date.UTC(...Object.values(utcWithPrecision)));
}

export const addDaysAndConvertToEpochMs = (numberOfDays: number, dateToAdd: Date): number => {
    return new Date(dateToAdd.getTime() + 1000 * 60 * 60 * 24 * numberOfDays).getTime();
};

export const mapDateToIterableDateFormat = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
