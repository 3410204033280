<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>How can we help with {{ dogName() }}’s care?</h1>
            <p data-test="question-care-needs-subtitle">We’ll adjust the program to your personal situation</p>
        </section>

        <form class="question-care-needs__form" id="careNeedsForm" [formGroup]="careNeedsForm" (ngSubmit)="onSubmit()">
            <app-multi-select class="question-care-needs__options" formControlName="careNeeds" [options]="options" />
        </form>
    </main>
</ion-content>

<ion-footer class="question-care-needs-footer gradient-footer ion-text-center">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="question-care-needs-next-btn"
        expand="block"
        type="submit"
        form="careNeedsForm"
        data-test="question-care-needs-next-btn"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { {{ careNeedsForm.controls.careNeeds.value.length > 0 ? 'Next' : 'Skip' }} }
    </ion-button>
</ion-footer>
