import { subscriptionFeature } from '@frontend/data-access/subscription';
import { selectIsSelfSetReminders } from '@frontend/data-access/user/config-cat';
import { householdFeature } from '@frontend/data-access/user/household';
import { createSelector } from '@ngrx/store';
import {
    selectQueryParamFrom,
    selectQueryParamFromPracticeStep,
    selectQueryParamRating,
} from '../../store/router/router.selectors';
import {
    selectSelectedLessonExtras,
    selectSelectedLessonStep,
    selectSelectedStepUnlocked,
} from '../../store/step/step.selectors';
import { selectLocalNotifications } from '@frontend/data-access/local-notification';

const selectRouteParams = createSelector(
    selectQueryParamFrom,
    selectQueryParamRating,
    selectQueryParamFromPracticeStep,
    (from, rating, fromPracticeStep) => {
        return {
            from,
            rating,
            fromPracticeStep,
        };
    },
);

export const selectSelectedLessonPagesVm = createSelector(
    selectSelectedLessonStep,
    selectSelectedLessonExtras,
    selectSelectedStepUnlocked,
    householdFeature.selectDogName,
    subscriptionFeature.selectHasHistoricalPurchase,
    selectRouteParams,
    selectIsSelfSetReminders,
    selectLocalNotifications,
    (
        step,
        selectedLessonExtras,
        unlocked,
        dogName,
        hasHistoricalPurchase,
        routeParams,
        isSelfSetReminders,
        localNotifications,
    ) => {
        return {
            ...selectedLessonExtras,
            step,
            unlocked,
            dogName: dogName ?? 'My pup',
            hasHistoricalPurchase,
            routeParams,
            shouldShowSelfSetReminderTile: isSelfSetReminders && !localNotifications.length,
        };
    },
);
