<ion-content class="making-the-right-food-choice-modal" [color]="Color.Lola">
    <button
        class="xl making-the-right-food-choice-modal__close-button"
        appTrackClick
        identifier="nourish-making-the-right-food-choice-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <article
        class="making-the-right-food-choice-modal__article page-padding-sides page-padding-bottom ignore-global-color"
    >
        <h2 class="no-margin-bottom">Making the right food choice</h2>

        <img
            class="making-the-right-food-choice-modal__image"
            src="/assets/images/buddy/buddy-licks-food-from-treat-mat.svg"
            alt="Buddy licking food from a treat mat"
        />

        <p class="no-margin">
            Proper nutrition is the foundation for your pup's lifelong health. During their growth phase, high-quality
            food provides the essential nutrients they need to thrive.
        </p>

        <h3 class="making-the-right-food-choice-modal__subheading no-margin">Why it’s crucial</h3>
        <section class="making-the-right-food-choice-modal__highlighted-text" [ngClass]="Color.Charly">
            <ul class="making-the-right-food-choice-modal__list">
                <li>
                    <p class="making-the-right-food-choice-modal__text">
                        <b>Supports healthy development:</b><br />
                        Quality food fuels growth, strengthens bones, and builds lean muscles
                    </p>
                </li>
                <br />

                <li>
                    <p class="making-the-right-food-choice-modal__text">
                        <b>Boosts immunity:</b><br />
                        A balanced diet rich in vitamins and minerals helps your pup fight off illness
                    </p>
                </li>
                <br />

                <li>
                    <p class="making-the-right-food-choice-modal__text">
                        <b>Promotes digestive health:</b><br />
                        High-quality ingredients are easier to digest, reducing tummy troubles
                    </p>
                </li>
                <br />

                <li>
                    <p class="making-the-right-food-choice-modal__text">
                        <b>Prevents Growth Disorders:</b><br />
                        Overfeeding or feeding low-quality food can lead to issues like obesity or joint problems
                    </p>
                </li>
            </ul>
        </section>

        @if (isGBUser()) {
            <app-nourish-product-tiles [productTiles]="productTiles()" [correlationId]="'modal'" />
        } @else if (isUsUser()) {
            <app-nourish-pet-food-finder
                [shouldShowHeading]="true"
                [origin]="NourishFoodFinderOriginId.RIGHT_FOOD_CHOICE_MODAL"
            />
        }

        <br />

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
