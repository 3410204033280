import { chatFeature, selectChannelEmpty } from '@frontend/data-access/chat';
import { createSelector } from '@ngrx/store';
import { keyboardFeature } from '@frontend/data-access/keyboard';
import { platformFeature } from '@frontend/data-access/platform';
import { householdFeature } from '@frontend/data-access/user/household';

export const selectPlatformInfo = createSelector(
    platformFeature.selectPlatformIsIos,
    keyboardFeature.selectIsKeyboardOpen,
    (isIos, isKeyboardOpen) => {
        return {
            isIos,
            isKeyboardOpen,
        };
    },
);

export const selectTrainingCoachChatPageVm = createSelector(
    householdFeature.selectDogName,
    chatFeature.selectChannelInitialized,
    selectChannelEmpty,
    selectPlatformInfo,
    (dogName, channelInitialized, channelEmpty, platformInfo) => {
        return {
            dogName,
            channelEmpty,
            channelInitialized,
            platformInfo,
        };
    },
);
