import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HouseholdService } from '../services/household.service';
import { deleteHousehold, deleteHouseholdFailure, deleteHouseholdSuccess } from './household.actions';
import { catchError, concatMap, map, of } from 'rxjs';

@Injectable()
export class DeleteHouseholdEffects {
    private readonly actions$ = inject(Actions);
    private readonly householdService = inject(HouseholdService);

    deleteHousehold$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteHousehold),
            concatMap(({ householdId }) =>
                this.householdService.deleteHousehold(householdId).pipe(
                    map(() => deleteHouseholdSuccess()),
                    catchError((error) => of(deleteHouseholdFailure({ error }))),
                ),
            ),
        );
    });
}
