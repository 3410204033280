<ion-content class="why-nutrition-matters-modal" [color]="Color.Maggie">
    <button
        class="why-nutrition-matters-modal__close-button xl"
        appTrackClick
        identifier="nourish-why-nutrition-matters-modal-modal-close-btn"
        data-test="nourish-why-nutrition-matters-modal-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="why-nutrition-matters-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>Why nutrition is a game-changer for training</h2>
        <img class="why-nutrition-matters-modal__top-image" src="/assets/images/ziggy/ziggy-reading.svg" />

        <section>
            <b class="no-margin">Did you know what’s in your pup’s bowl can make or break their training progress?</b>

            <p class="no-margin-bottom">
                Just like us, dogs need the right fuel to grow, learn, and stay happy. And when it comes to puppies,
                their diet is even more important—because they’re developing at lightning speed! ⚡
            </p>
        </section>

        <section>
            <b class="no-margin">Here’s why good nutrition is the secret sauce to a well-trained pup:</b>

            <ul class="why-nutrition-matters-modal__highlighted-text no-margin-bottom" [ngClass]="Color.Sophie">
                <li>
                    <b>🧠 Brain power boost</b>
                    <p>
                        Omega-3s, amino acids, and essential vitamins are like rocket fuel for your pup’s brain. The
                        better the nutrition, the better they can focus, learn, and retain new skills.
                    </p>
                </li>

                <li>
                    <b>💪 Energy & stamina</b>
                    <p>
                        Puppies have energy for days (and nights!). The right balance of protein, fats, and carbs keeps
                        them fuelled for training sessions without sudden crashes or zoomie overload.
                    </p>
                </li>

                <li>
                    <b>🍖 {{ behaviourText() | titlecase }} & mood</b>
                    <p>
                        Ever been hangry? Puppies feel it too! Low-quality food can lead to hyperactivity, lack of
                        focus, or even frustration. A well-balanced diet keeps them calm, happy, and ready to learn.
                    </p>
                </li>

                <li>
                    <b>⏳ Right food, right time</b>
                    <p>
                        A growing puppy has different nutritional needs at different stages. The right nutrients at the
                        right time support their brain, bones, and {{ behaviourText() }}.
                    </p>
                </li>

                <li>
                    <b>🐕 Gut health = Good {{ behaviourText() }}</b>
                    <p>
                        A healthy gut = a happy pup. Poor digestion or allergies can cause discomfort, which can lead to
                        training struggles and {{ behaviourText() }} issues.
                    </p>
                </li>
            </ul>
        </section>

        <section class="why-nutrition-matters-modal__highlighted-text-snowy no-margin">
            <img
                class="why-nutrition-matters-modal__small-illustration"
                src="/assets/images/buddy/buddy-sits-patiently.svg"
                alt="Buddy sits patiently"
            />
            <div>
                <h3 class="no-margin-top">Want a smart, happy, and trainable pup?</h3>
                <p class="no-margin">Start with what’s in their bowl! 🍲🐾.</p>
            </div>
        </section>

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
