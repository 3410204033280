import { Component, computed, inject, input } from '@angular/core';
import { SliderComponent, SliderItemComponent } from '@frontend/ui/slider';
import { Store } from '@ngrx/store';
import { NourishProductId } from '../constants/nourish.constants';
import { NourishProductTile } from '../models/nourish.model';
import { NourishProductTileComponent } from '../nourish-product-tile/nourish-product-tile.component';
import { nourishProductTileClicked } from '../store/nourish.actions';
import { selectOurPickString } from './nourish-product-tiles.selectors';
import { selectNutritionalReferralAsPick } from '@frontend/data-access/user/config-cat';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-nourish-product-tiles',
    templateUrl: './nourish-product-tiles.component.html',
    imports: [NourishProductTileComponent, SliderComponent, SliderItemComponent, NgTemplateOutlet],
})
export class NourishProductTilesComponent {
    private readonly store = inject(Store);

    public productTiles = input<NourishProductTile[]>([]);
    public correlationId = input<'page' | 'modal' | undefined>();

    public ourPickString = this.store.selectSignal(selectOurPickString);
    public isNutritionalReferralAsPickAB = this.store.selectSignal(selectNutritionalReferralAsPick);

    public ourPickStringFormatted = computed(() => {
        if (this.isNutritionalReferralAsPickAB()) {
            return this.ourPickString().replace('picks', 'pick');
        }

        return this.ourPickString();
    });

    public onClickProductTile(id: NourishProductId) {
        this.store.dispatch(
            nourishProductTileClicked({
                id,
                correlationId: this.correlationId(),
            }),
        );
    }
}
