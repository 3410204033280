import { Component, inject, Input } from '@angular/core';
import { IonItem, IonLabel } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';
import { AccordionAnalyticsModel } from './accordion-analytics.model';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    standalone: true,
    imports: [IonItem, IonLabel],
})
export class AccordionComponent {
    private readonly store = inject(Store);

    @Input({ required: true }) public title!: string;
    @Input({ required: true }) public content!: string;
    @Input({ required: true }) public analytics!: AccordionAnalyticsModel | false;

    public open = false;

    toggleAccordion(): void {
        this.open = !this.open;

        if (this.open && this.analytics) {
            this.store.dispatch(
                trackEvent({
                    eventName: 'click',
                    eventProperties: {
                        identifier: this.analytics?.identifier,
                        ...this.analytics.properties,
                    },
                }),
            );
        }
    }
}
