<div
    class="step-card-slim"
    [ngClass]="{
        'step-card-slim--locked': content.locked,
    }"
>
    <section class="step-card-slim__image-container {{ content.color }}">
        <img
            class="{{ 'step-card-slim--' + content.contentType + '-image' }}"
            [ngSrc]="content.thumbnailUrl"
            fill="true"
            loading="eager"
            [alt]="content.title"
        />

        @if (content.status) {
            @if (content.status === StepStatus.READ) {
                <div class="state-icon state-icon--read">
                    <i class="fa-solid fa-glasses state-icon__icon"></i>
                </div>
            }
            @if (content.status === StepStatus.SKIPPED) {
                <div class="state-icon state-icon--skipped">
                    <i class="fa-solid fa-forward state-icon__icon" data-test="status-icon"></i>
                </div>
            }
            @if (content.status === StepStatus.COMPLETED) {
                <div class="state-icon state-icon--completed">
                    <i class="fa-solid fa-check state-icon__icon"></i>
                </div>
            }
        }
        @if (content.locked) {
            <div class="step-card-slim__locked-icon">
                <i class="fa-solid fa-lock" data-test="card-item-locked-icon"></i>
            </div>
        }
    </section>
    <section class="step-card-slim__text-container">
        <div class="step-card-slim__title-container">
            <h3 class="step-card-slim__title ignore-screen-based-font-scaling">
                {{ content.title | textReplacement }}
            </h3>
            @if (content.isPractice) {
                <p class="step-card-slim__practice s">Practice</p>
            }
        </div>
        <div class="step-card-slim__details-container">
            @switch (content.contentType) {
                @case (StepType.ARTICLE) {
                    <p class="s no-margin">
                        <i class="fa-regular fa-memo"></i>
                        @if (content.duration) {
                            <span> {{ content.duration }} min </span>
                        }
                    </p>
                }
                @case (StepType.LESSON) {
                    <p class="s no-margin">
                        <i class="fa-regular fa-dog"></i>
                        @if (content.duration) {
                            <span> {{ content.duration }} min </span>
                        }

                        <span class="step-card-slim__location">
                            @switch (content.location) {
                                @case (LessonLocation.INDOORS) {
                                    <i class="fa-regular fa-home"></i>
                                }
                                @case (LessonLocation.OUTDOORS) {
                                    <i class="fa-regular fa-sun-cloud"></i>
                                }
                                @case (LessonLocation.ANYWHERE) {
                                    <i class="fa-regular fa-earth-europe"></i>
                                }
                            }
                            <span> {{ content.location }}</span>
                        </span>
                    </p>
                    @if (content.rating) {
                        <div class="step-card-slim__rating">
                            <i class="fa-solid fa-star step-card-slim__rating__star"></i>
                            <p class="s no-margin">{{ content.rating }}</p>
                        </div>
                    }
                }
            }
        </div>
    </section>
</div>
