import { DomainHappinessCheckDto } from '../../../dtos/domain-api/happiness-check/domain-happiness-check.dto';

export class DomainHappinessCheckDtoMock {
    private defaultValue: DomainHappinessCheckDto = {
        id: 'uuid-1234',
        userId: 'user-123',
        ownerHappinessScore: 0.5,
        dogHappinessScore: 0.6,
        timestamp: 0,
    };

    constructor(overrides?: Partial<DomainHappinessCheckDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainHappinessCheckDto {
        return this.defaultValue;
    }
}
