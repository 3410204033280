import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { Course } from '@frontend/data-access/contentful';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color, isColorContrastLight, setCourseBackgroundColor } from '@shared/utils/typescript';
import { goToCourseFromTile } from '../../data-access/today.page.actions';

@Component({
    selector: 'app-course-tile',
    templateUrl: './course-tile.component.html',
    styleUrls: ['./course-tile.component.scss'],
    standalone: true,
    imports: [AsyncPipe, NgStyle, IonButton, IonCard, AnalyticsTrackClickDirective],
})
export class CourseTileComponent {
    private readonly store = inject(Store);

    protected readonly Color = Color;

    public course = input.required<Course>();
    public backgroundColor = input(Color.Harley);

    public background = computed(() => {
        return {
            ...setCourseBackgroundColor(this.course().color),
            'background-position': 'center',
            'background-image': isColorContrastLight(this.course().color)
                ? 'url(/assets/images/squiggles/doodle.png)'
                : 'url(/assets/images/squiggles/doodle-dark.png)',
        };
    });

    public onGoToCourseClick(): void {
        this.store.dispatch(goToCourseFromTile({ courseId: this.course().id }));
    }
}
