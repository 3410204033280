import { Color } from '@shared/utils/typescript';
import { InternalChatTileTopicDto } from '../../dtos/internal-api';

export class InternalChatTileTopicDtoMock {
    private defaultValue: InternalChatTileTopicDto = {
        id: '',
        title: '',
        image: '',
        colour: Color.Max,
        chatTileContent: [''],
        ageBracket: '',
        isPupAtHome: false,
        isPupRescue: false,
    };

    constructor(overrides?: Partial<InternalChatTileTopicDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalChatTileTopicDto {
        return this.defaultValue;
    }
}
