import { Dog } from '../dog.model';
import { Gender } from '@shared/user-domain';

export class DogMock {
    private defaultValue: Dog = {
        id: '123',
        name: 'Dario',
        dateOfBirth: undefined,
        dateOfArrival: undefined,
        hasArrived: undefined,
        gender: Gender.FEMALE,
        dateOfOnboarding: undefined,
        breedId: '123',
        isRescue: false,
        isApproximateDateOfBirth: false,
    };

    private empty: Dog = {
        ...this.defaultValue,
        name: undefined,
        gender: undefined,
        isRescue: undefined,
        isApproximateDateOfBirth: undefined,
        breedId: undefined,
    };

    constructor(overrides?: Partial<Dog>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Dog {
        return this.defaultValue;
    }

    public get emptyValue(): Dog {
        return this.empty;
    }
}
