<ion-card
    class="sophie text-snowy chat-tile-card"
    appTrackClick
    identifier="support-chat-btn"
    (click)="sendChatMessage()"
>
    <div class="chat-tile-content-container">
        <img
            class="card-item-img"
            src="assets/images/buddy/buddy-talking-with-speech-bubble.svg"
            slot="start"
            alt="dog on the phone"
        />
        <div class="card-item-text">
            <h2>Chat now</h2>
            <p class="s">Our coaches are here to help</p>
        </div>
    </div>
</ion-card>
