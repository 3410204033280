import { Component, computed, effect, EventEmitter, inject, input, Input, Output } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { ChatTileTopic } from '@frontend/data-access/contentful';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { SimpleTextCardSlimComponent } from '@frontend/ui';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { SuggestedAiChatQuestionsComponent } from '../suggested-questions-prompt/suggested-ai-chat-questions.component';
import { SuggestedQuestionsPromptClicked } from '../suggested-questions-prompt/suggested-ai-chat-questions.model';
import { AiChatIntroUserInfo } from './ai-chat-intro-user-info.model';

@Component({
    selector: 'app-ai-chat-intro',
    templateUrl: './ai-chat-intro.component.html',
    styleUrls: ['./ai-chat-intro.component.scss'],
    standalone: true,
    imports: [SuggestedAiChatQuestionsComponent, SimpleTextCardSlimComponent, AnalyticsTrackClickDirective],
})
export class AiChatIntroComponent {
    private readonly store = inject(Store);
    @Input({ required: true }) user!: AiChatIntroUserInfo;
    @Input({ required: true }) suggestedQuestionsCards!: ChatTileTopic[];
    @Input({ required: true }) isChannelEmpty!: boolean;

    optionId = input<string>('');

    @Output() messageToPrefill = new EventEmitter<string | null>();
    @Output() hide = new EventEmitter<boolean>();

    protected readonly Color = Color;

    initialSelectedOption = computed(() => {
        return this.suggestedQuestionsCards
            .flatMap((card) => {
                return card.chatTileContent;
            })
            .find((option) => {
                return option.id === this.optionId();
            });
    });

    initialSelectedCard = computed(() => {
        return this.suggestedQuestionsCards.find((card) => {
            return card.chatTileContent.find((option) => option.id === this.optionId());
        });
    });

    constructor() {
        effect(() => {
            this.messageToPrefill.emit(this.initialSelectedOption()?.longQuestion);
        });
    }

    public close(): void {
        this.hide.emit(true);
    }

    public passMessageToParent(event: SuggestedQuestionsPromptClicked): void {
        if (event.option !== null) {
            this.store.dispatch(
                trackEvent({
                    eventName: 'click',
                    eventProperties: {
                        card: event.card,
                        option: event.option,
                    },
                }),
            );
        }
        this.messageToPrefill.emit(event.message);
    }
}
