import { Component, computed, inject, viewChild } from '@angular/core';
import { chatFeature } from '@frontend/data-access/chat';
import { ChatTileTopic } from '@frontend/data-access/contentful';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { SliderComponent, SliderItemComponent } from '@frontend/ui/slider';
import { IonContent, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { routeToCoachChat } from '../../../chat/chat.actions';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { selectIsBasicPlan } from '../../../store/payment/store/payment.selectors';
import { TodayGenericCardSquare } from '../../../today/today.model';
import {
    DEFAULT_NOURISH_PRODUCT_TILES,
    NOURISH_TILE_ANIMATION_ID,
    NourishTileId,
} from '../../constants/nourish.constants';
import { NourishHeaderComponent } from '../../nourish-header/nourish-header.component';
import { NourishProductTilesComponent } from '../../nourish-product-tiles/nourish-product-tiles.component';
import { NourishTileComponent } from '../../nourish-tile/nourish-tile.component';
import { nourishSuggestedAiChatQuestionClicked } from '../../store/nourish.actions';
import { showNourishModal } from '../../store/nourish-modal/nourish-modal.actions';
import { selectNourishPageInsightsTiles, selectNourishPageSupportTiles } from './nourish.page.selectors';
import { selectIsGBUser, selectIsUsUser } from '@frontend/data-access/user/household';
import { NourishHeaderUsComponent } from '../../nourish-header-us/nourish-header-us.component';
import { GenericCardSquareComponent } from '@frontend/ui/generic-cards';
import { ChatTileContentOption, OptionSelectorSquareComponent } from '@frontend/ui/option-selector';
import { selectNutritionalReferralAsPick } from '@frontend/data-access/user/config-cat';
import { CourseType } from '@shared/content-api-interface';
import { isItemInteracted, userInteractionFeature } from '@frontend/data-access/user-interactions';

@Component({
    selector: 'app-nourish',
    templateUrl: './nourish.page.html',
    styleUrl: './nourish.page.scss',
    imports: [
        AnalyticsTrackClickDirective,
        GenericCardSquareComponent,
        IonContent,
        IonHeader,
        IonToolbar,
        NourishHeaderComponent,
        NourishProductTilesComponent,
        NourishTileComponent,
        OptionSelectorSquareComponent,
        SliderComponent,
        SliderItemComponent,
        NourishHeaderUsComponent,
    ],
})
export class NourishPage {
    private readonly store = inject(Store);

    protected readonly CourseType = CourseType;
    protected readonly NourishTileId = NourishTileId;
    protected readonly Color = Color;

    // NBSon - right now we assume there will always be a single chat tile, if this changes, we should use ViewChildren and iterate over them
    public chatTileRef = viewChild<OptionSelectorSquareComponent>('chatTile');
    public supportTiles = this.store.selectSignal(selectNourishPageSupportTiles);
    public insightsTiles = this.store.selectSignal(selectNourishPageInsightsTiles);
    public isStreamChatBusyCleaningUp = this.store.selectSignal<boolean>(chatFeature.selectIsDestroying);
    public isBasicPlan = this.store.selectSignal(selectIsBasicPlan);
    public isGBUser = this.store.selectSignal(selectIsGBUser);
    public isUsUser = this.store.selectSignal(selectIsUsUser);
    public isNutritionalReferralAsPickAB = this.store.selectSignal(selectNutritionalReferralAsPick);
    public userInteractions = this.store.selectSignal(userInteractionFeature.selectUserInteractions);

    public productTiles = computed(() => {
        if (this.isNutritionalReferralAsPickAB()) {
            return [DEFAULT_NOURISH_PRODUCT_TILES[0]];
        }

        return DEFAULT_NOURISH_PRODUCT_TILES;
    });

    public shouldShowAnimation = computed(() => {
        return !isItemInteracted(this.userInteractions(), NOURISH_TILE_ANIMATION_ID);
    });

    public onCardClick(unlocked: boolean, cardName: string | NourishTileId): void {
        if (this.isStreamChatBusyCleaningUp()) {
            return;
        }

        if (!unlocked) {
            this.store.dispatch(
                showPaymentModal({
                    trigger: cardName,
                }),
            );

            return;
        }

        switch (cardName) {
            case 'puppy-coach': {
                this.store.dispatch(routeToCoachChat());
                break;
            }
            case NourishTileId.NUTRITIONAL_CONSULTATION: {
                this.store.dispatch(
                    showNourishModal({
                        id: cardName,
                    }),
                );
                break;
            }
            default: {
                break;
            }
        }
    }

    public onChatTileTopicClick(option: ChatTileContentOption | undefined): void {
        if (!this.chatTileRef() || !option) {
            return;
        }

        this.store.dispatch(
            nourishSuggestedAiChatQuestionClicked({
                optionId: option.id,
                option: option.shortQuestion,
                card: option.longQuestion,
            }),
        );

        this.chatTileRef()!.clearSelection();
    }

    public isTodayGenericCardSquare(
        content: TodayGenericCardSquare | ChatTileTopic,
    ): content is TodayGenericCardSquare {
        return 'cardName' in content && 'contentUnlocked' in content;
    }

    public isChatTileTopic(content: TodayGenericCardSquare | ChatTileTopic): content is ChatTileTopic {
        return 'chatTileContent' in content;
    }
}
