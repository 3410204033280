@if (course) {
    <ion-card class="course-tile tile" [color]="course.color" [attr.data-test]="'course-tile-' + course.id">
        <app-svg-color
            class="course-tile__squiggle"
            data-test="course-tile-squiggle"
            [color]="'var(--ion-color-' + course.color + '-secondary-light)'"
            [imagePath]="
                '/assets/images/squiggles/course-tile/course-tile-squiggle-' +
                (course.courseCardSquiggle ?? '1') +
                '.svg'
            "
        ></app-svg-color>
        <div class="course-tile__inner">
            <div>
                <h2 class="course-tile__title">{{ course.title }}</h2>
                <p class="course-tile__description s">{{ course.introduction }}</p>
            </div>

            <div>
                <ion-button
                    class="course-tile__go-to-btn s"
                    [ngStyle]="{
                        '--color': course.color + '-contrast',
                        '--border-color': course.color + '-contrast',
                    }"
                    appTrackClick
                    identifier="course-tile-go-to-btn"
                    size="small"
                    fill="outline"
                    data-test="course-tile-go-to-btn"
                    (click)="onGoToCourseClick()"
                >
                    Go to course
                </ion-button>
            </div>
        </div>
    </ion-card>
}
