import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { SentryService } from '../sentry.service';
import { initMobileAppSentry, initSupportPortalSentry, setUserMobileAppSentry } from './sentry.actions';

@Injectable()
export class SentryEffects {
    private readonly actions$ = inject(Actions);
    private readonly sentryService = inject(SentryService);

    initMobileSentry$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initMobileAppSentry),
                tap(({ platform }) => this.sentryService.initMobileAppSentry(platform)),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    setUserMobileAppSentry$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setUserMobileAppSentry),
                tap(({ userId, email }) => this.sentryService.setUserMobileAppSentry({ userId, email })),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    initSupportPortalSentry$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initSupportPortalSentry),
                tap(() => this.sentryService.initSupportPortalSentry()),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
