import { SettingKeyValue } from 'configcat-js';
import { ConfigCatFlag } from './config-cat.model';

export const mapConfigCatResponseToFlags = (response: SettingKeyValue[]): ConfigCatFlag[] => {
    return response.map((entry) => ({
        name: entry.settingKey,
        flagValue: entry.settingValue as boolean,
    }));
};

export const filterConfigCatStateFlag = (flagName: string, flags: ConfigCatFlag[]): boolean =>
    flags.filter((flag) => flag.name === flagName)[0]?.flagValue ?? false;
