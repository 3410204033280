import { createAction, props } from '@ngrx/store';
import { NourishModalId, NourishProductFeedbackModalState, NourishTileId } from '../../constants/nourish.constants';

export const showNourishModal = createAction('[Nourish Modal] Show Nourish Modal', props<{ id: NourishTileId }>());

export const closeNourishModal = createAction('[Nourish Modal] Close Nourish Modal', props<{ id?: NourishModalId }>());

export const closeNourishProductFeedbackModal = createAction('[Nourish Modal] Close Nourish Product Feedback Modal');

export const nourishProductFeedbackModalThumbsDownClicked = createAction(
    '[Nourish Modal] Product Feedback Modal Thumbs Down Clicked',
    props<{
        state: NourishProductFeedbackModalState.INITIAL | NourishProductFeedbackModalState.NEGATIVE;
    }>(),
);

export const nourishProductFeedbackModalSubmitted = createAction(
    '[Nourish Modal] Product Feedback Modal Submitted',
    props<{
        id: string;
        response: NourishProductFeedbackModalState.POSITIVE | NourishProductFeedbackModalState.NEGATIVE;
        reason?: string;
        comment?: string;
    }>(),
);
