import { MetaReducer, provideState } from '@ngrx/store';
import { aiChatFeature } from '../chat/ai-chat/store/ai-chat.reducer';
import { coursePathFeature } from '../courses/course-path/store/course-path.reducer';
import { foodCalculatorFeature } from '../food-calculator/data-access/store/food-calculator.reducer';
import { todayPageFeature } from '../today/data-access/today.page.reducer';
import { appStatusFeature } from './app-component/app-component.reducer';
import { clearStateMetaReducer } from './clear-state/clear-state.reducer';

export const zigzagMainFeatureStates = [
    provideState(aiChatFeature),
    provideState(todayPageFeature),
    provideState(appStatusFeature),
    provideState(foodCalculatorFeature),
    provideState(coursePathFeature),
];

export const metaReducers: MetaReducer[] = [clearStateMetaReducer];
