import { InternalHouseholdUserDto, InternalPublicUserDto } from '../internal-user.dto';
import { InternalDogDto } from '../dog/dog.dto';

export class InternalHouseholdDto {
    id!: string;
    name!: string;
    currentUser!: InternalHouseholdUserDto;
    users!: InternalPublicUserDto[];
    dogs!: InternalDogDto[];
}
