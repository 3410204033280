import { createAction, props } from '@ngrx/store';
import { CreateCourseProgress } from '@shared/user-api-interface';
import { FrontendCourseProgressEntry } from '../models/course-progress.model';

export const getCourseProgress = createAction('[Course Progress] Get Course Progress');
export const getCourseProgressSuccess = createAction(
    '[Course Progress] Get Course Progress Success',
    props<{ progress: FrontendCourseProgressEntry[] }>(),
);
export const getCourseProgressFailure = createAction(
    '[Course Progress] Get Course Progress Failure',
    props<{ error: Error }>(),
);

export const createCourseProgress = createAction(
    '[Course Progress] Create Course Progress',
    props<{ courseProgress: CreateCourseProgress; correlationId?: string }>(),
);
export const createCourseProgressSuccess = createAction(
    '[Course Progress] Create Course Progress Success',
    props<{
        entry: FrontendCourseProgressEntry;
        correlationId?: string;
    }>(),
);
export const createCourseProgressFailure = createAction(
    '[Progress] Create Course Progress Failure',
    props<{ error: Error }>(),
);

export const createManyCourseProgress = createAction(
    '[Course Progress] Create Many Course Progress',
    props<{
        courseProgress: CreateCourseProgress[];
        correlationId?: string;
    }>(),
);
export const createManyCourseProgressSuccess = createAction(
    '[Course Progress] Create Many Course Progress Success',
    props<{
        entries: FrontendCourseProgressEntry[];
        correlationId?: string;
    }>(),
);
export const createManyCourseProgressFailure = createAction(
    '[Course Progress] Create Many Course Progress Failure',
    props<{ error: Error }>(),
);
