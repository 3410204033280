import { LoadingState } from '@frontend/data-access/shared-models';

export interface ConfigCatFlagState {
    configCatFlags: ConfigCatFlag[];
    loading: LoadingState;
    error: Error | undefined;
}

export interface ConfigCatFlag {
    name: string;
    flagValue: boolean;
}
