import { ChatChannelType } from '@shared/constants';
import { ConversationMessageRatingType } from '@shared/user-api-interface';
import { createAction, props } from '@ngrx/store';

export const routeToCoachChat = createAction('[Chat] Route to Coach Chat');
export const routeToAiChat = createAction('[Chat] Route to Ai Chat', props<{ message?: string }>());

export const chatPageLoaded = createAction('[Chat Page] Chat Page Loaded', props<{ chatType: ChatChannelType }>());

export const userSentPuppyCoachMessage = createAction(
    '[Chat Page] User Sent Training Coach Message',
    props<{
        imagesAttached: number;
        videosAttached: number;
        audioAttached: number;
    }>(),
);

export const userRatedMessage = createAction(
    '[Chat Page] User Rated Message',
    props<{
        rating: ConversationMessageRatingType;
        streamChatMessageId: string;
        /** The ID of the user of the message */
        streamChatUserId: string;
    }>(),
);

export const userRatedMessageFailure = createAction(
    '[Chat Page] User Rated Message Failure',
    props<{
        error: Error;
    }>(),
);

export const userLeftMessageFeedback = createAction(
    '[Chat Page] User Left Message Feedback',
    props<{
        streamChatMessageId: string;
        comment?: string;
    }>(),
);
