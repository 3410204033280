import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HappinessCheckService } from '../services/happiness-check.service';
import {
    submitHappinessCheckData,
    submitHappinessCheckDataFailure,
    submitHappinessCheckDataSuccess,
} from './happiness-check.actions';

@Injectable()
export class HappinessCheckEffects {
    private actions$ = inject(Actions);
    private readonly happinessCheckService = inject(HappinessCheckService);

    submitHappinessCheckData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(submitHappinessCheckData),
            concatMap(({ happinessCheckData }) => {
                return this.happinessCheckService
                    .postHappinessCheck({
                        ownerHappinessScore: happinessCheckData.ownerHappinessScore,
                        dogHappinessScore: happinessCheckData.dogHappinessScore,
                        timestamp: new Date().getTime(),
                    })
                    .pipe(
                        map((result) =>
                            submitHappinessCheckDataSuccess({
                                result,
                            }),
                        ),
                        catchError((error: Error) => of(submitHappinessCheckDataFailure({ error }))),
                    );
            }),
        );
    });
}
