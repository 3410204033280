import { IterablePostUserCommand } from '../commands/user/iterable-post-user.command';
import { InternalIterableDataFieldsMock } from './internal-iterable-data-fields.mock';

export class InternalIterablePostUserCommandMock {
    private defaultValue: IterablePostUserCommand = {
        dataFields: new InternalIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<IterablePostUserCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostUserCommand {
        return this.defaultValue;
    }
}
