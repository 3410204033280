export interface FeedbackOption {
    key: string;
    value: string;
}

export interface UserFeedbackContent {
    id?: string;
    userId?: string;
    comment?: string;
    contentTypeId?: string;
    contentfulId: string;
    thumbsDown: boolean | undefined;
}
