<ion-content
    class="page-safe-margin"
    [fullscreen]="true"
    [scrollEvents]="true"
    [scrollY]="canScroll(vm().selectedCourse!)"
    (ionScroll)="setScrollHeight($any($event))"
>
    <div class="course-header page-padding-sides page-padding-top">
        <h1 class="page-title">My Journey</h1>
    </div>

    @if (vm().selectedCourse) {
    <div>
        <app-course-slider data-test="course-swipe" [courses]="vm().courses" />

        <div class="no-access-section">
            @if (displayNoAccess(vm().selectedCourse!)) {
            <app-no-access
                title="Unlock additional courses with premium"
                subTitle="You're on the Basic plan now."
                buttonText="Upgrade to Premium"
                (openPaymentModal)="openPaymentModal()"
            />
            }

            <div class="course-path page-padding-sides">
                <app-course-path />
            </div>

            @if (!vm().isStartCourseAb && vm().selectedCourse!.status !== CourseStatus.COMPLETED) {
            <div
                *appElementRerender="vm().selectedCourse!.color"
                class="scroll-down-container"
                data-test="scroll-down-button"
                [ngClass]="{'point-up': (vm().heightOfFirstInactiveStep ?? 0) < scrollHeight}"
                (click)="scrollToFirstInactiveStep(vm().heightOfFirstInactiveStep)"
            >
                <i
                    class="fa-solid fa-circle-chevron-down fa-2xl"
                    [style.color]="'var(--ion-color-' + vm().selectedCourse!.color + ')'"
                ></i>
            </div>
            }

            <div class="page-padding-sides next-up-container">
                @if (vm().nextCourse) {
                <app-generic-card-large
                    callToActionText="View course"
                    label="Next Up"
                    data-test="next-up-tile"
                    [title]="vm().nextCourse.title"
                    [description]="vm().nextCourse.introduction"
                    [imageUrl]="vm().nextCourse.imageUrl"
                    [imageAltText]="vm().nextCourse.subTitle || 'An image of a dog excited about the next course'"
                    [backgroundColor]="vm().nextCourse.color"
                    [backgroundUrl]="getCourseCardDoodle(vm().nextCourse.color)"
                    (callToActionClicked)="openCourseInfoModal(vm().nextCourse)"
                />
                }
            </div>

            @if (vm().showStartCourseButton) {
            <button
                data-test="course-page-start-course-btn"
                [class]="'start-course-btn ' + vm().selectedCourse!.color"
                (click)="setCourseAsInProgress(vm().selectedCourse!)"
            >
                Start course
            </button>
            } @else if (vm().isStartCourseAb && vm().selectedCourse!.status === CourseStatus.IN_PROGRESS) {
            <div class="paw-container page-padding-sides">
                <button
                    data-test="course-page-streak-btn"
                    [class]="'streak-button as-div ' + vm().selectedCourse!.color"
                >
                    <i class="streak-button__icon fa-solid fa-paw fa-2xl"></i>
                    <h3 class="streak-button__text">0</h3>
                </button>

                <button
                    data-test="course-page-todays-lesson-btn"
                    [class]="'todays-lesson-button as-div ' + vm().selectedCourse!.color"
                    (click)="scrollToFirstInactiveStep(vm().heightOfFirstInactiveStep)"
                >
                    <span>Today's lessons</span>
                    <span class="todays-lesson-button__steps-completed">0/3</span>
                </button>
            </div>
            }
        </div>
    </div>
    }
</ion-content>
