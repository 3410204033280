import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { InternalHouseholdDto, internalPaths, InternalPostUserCommand } from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HouseholdService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getHousehold(): Observable<InternalHouseholdDto> {
        return this.http.get<InternalHouseholdDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.householdPath}`,
            httpOptions,
        );
    }

    public createHousehold(command: InternalPostUserCommand): Observable<InternalHouseholdDto> {
        return this.http.post<InternalHouseholdDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.householdPath}`,
            command,
            httpOptions,
        );
    }

    public deleteHousehold(householdId: string): Observable<true> {
        return this.http.delete<true>(
            `${this.environment.internalApiUrl}/api/${internalPaths.householdPath}/${householdId}`,
            httpOptions,
        );
    }
}
