import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { triggerPatchDogAndHouseholdUser } from './profile-edit.page.actions';
import { householdFeature, patchDog, patchHouseholdUser } from '@frontend/data-access/user/household';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from '../../../../store/profile/account/account.constants';
import { concatLatestFrom } from '@ngrx/operators';

export class ProfileEditEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    triggerPatchHouseholdUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerPatchDogAndHouseholdUser),
            filter(({ mail, ownerName }) => mail !== undefined || ownerName !== undefined),
            map(({ mail, ownerName }) => {
                return patchHouseholdUser({
                    user: {
                        mail,
                        name: ownerName,
                    },
                    correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                });
            }),
        );
    });

    triggerPatchDog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerPatchDogAndHouseholdUser),
            filter(
                ({ name, breedId, gender, dateOfBirth, isApproximateDateOfBirth }) =>
                    name !== undefined ||
                    breedId !== undefined ||
                    gender !== undefined ||
                    dateOfBirth !== undefined ||
                    isApproximateDateOfBirth !== undefined,
            ),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            map(([{ name, breedId, gender, dateOfBirth, isApproximateDateOfBirth }, dogId]) => {
                return patchDog({
                    dogId: dogId!,
                    dog: {
                        name,
                        breedId,
                        gender,
                        dateOfBirth,
                        isApproximateDateOfBirth,
                    },
                    correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                });
            }),
        );
    });
}
