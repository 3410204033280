import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ModalService } from '@frontend/utility/modal';
import { chatPageLoaded, routeToCoachChat, userSentPuppyCoachMessage } from '../../chat.actions';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ChatChannelType } from '@shared/constants';
import { combineLatest } from 'rxjs';
import { chatFeature, setMessageData, watchChannelSuccess } from '@frontend/data-access/chat';
import { selectShowChatReviewModal } from '../../chat.selectors';
import { ChatReviewModalComponent } from '../../chat-review-modal/chat-review-modal.component';
import { trackEvent } from '@frontend/data-access/analytics';
import { dismissInfoWithTimestamp } from '@frontend/data-access/dismissible-info';
import { selectCurrentTab } from '../../../store/router/router.selectors';
import { routeTo } from '@frontend/data-access/router';
import { setPushNotifications } from '@frontend/data-access/push-notification';

@Injectable()
export class CoachChatEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    routeToCoachChatPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeToCoachChat),
            concatLatestFrom(() => {
                return this.store.select(selectCurrentTab);
            }),
            map(([, currentTab]) => {
                return routeTo({ commands: ['main', currentTab ?? 'today', 'chat', 'coaches'], isAnimated: true });
            }),
        );
    });

    triggerDisplayPushNotificationsCheck$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(chatPageLoaded),
            filter(({ chatType }) => chatType === ChatChannelType.AGENT),
            map(() => setPushNotifications()),
        );
    });

    showChatReviewModal$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(watchChannelSuccess)),
            this.actions$.pipe(ofType(setMessageData)),
        ]).pipe(
            filter(([{ chatType }]) => chatType === ChatChannelType.AGENT),
            concatLatestFrom(() => {
                return this.store.select(selectShowChatReviewModal);
            }),
            filter(([, showModal]) => showModal),
            tap((): void => {
                void this.modalService.showModal({
                    component: ChatReviewModalComponent,
                    cssClass: ['modal', 'modal-small'],
                });
            }),
            switchMap(() => [
                trackEvent({ eventName: '[Chat] Chat Review Modal Initiated' }),
                dismissInfoWithTimestamp({ id: 'chat-review', timestamp: new Date().toString() }),
            ]),
        );
    });

    trackUserMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userSentPuppyCoachMessage),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            filter(([, channelId]) => !!channelId),
            map(([{ imagesAttached, videosAttached, audioAttached }, activeChannel]) => {
                return trackEvent({
                    eventName: '[Chat] User Sent Message',
                    eventProperties: {
                        imagesAttached,
                        videosAttached,
                        audioAttached,
                        channelId: activeChannel?.id,
                        channelType: activeChannel?.type,
                    },
                });
            }),
        );
    });
}
