import { inject, Injectable } from '@angular/core';
import { getGeoLocation } from '@frontend/data-access/geo-location';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { setUserMobileAppSentry } from '@frontend/data-access/sentry';
import { routeTo } from '@frontend/data-access/router';
import {
    getActiveSubscription,
    initializeSubscriptionServiceSuccess,
    logInToPurchases,
} from '@frontend/data-access/subscription';
import { getHouseholdFailure, getHouseholdSuccess, householdFeature } from '@frontend/data-access/user/household';
import { combineLatest } from 'rxjs';

@Injectable()
export class GetAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    routeToAuth$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdFailure),
            map(() => {
                return routeTo({ commands: ['auth'] });
            }),
        );
    });

    getSubscriptions$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(initializeSubscriptionServiceSuccess), take(1)),
            this.actions$.pipe(ofType(getHouseholdSuccess)),
        ]).pipe(map(() => getActiveSubscription()));
    });

    loginToPurchases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(initializeSubscriptionServiceSuccess),
            take(1),
            map(({ profileId, email }) => {
                return logInToPurchases({
                    profileId: profileId!,
                    email: email!,
                });
            }),
        );
    });

    addMissingCountryCode$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            concatLatestFrom(() => this.store.select(householdFeature.selectCountryCode)),
            filter(([, countryCode]) => !countryCode),
            map(() => getGeoLocation()),
        );
    });

    setSentryUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            map(({ household }) =>
                setUserMobileAppSentry({ userId: household.currentUser.id, email: household.currentUser.mail }),
            ),
        );
    });
}
