import { createAction, props } from '@ngrx/store';
import { Gender } from '@shared/user-domain';

export const triggerPatchDogAndHouseholdUser = createAction(
    '[Profile] Trigger Patch Dog And Household User',
    props<{
        isApproximateDateOfBirth?: boolean;
        breedId?: string;
        gender?: Gender;
        name?: string;
        dateOfBirth?: Date;
        ownerName?: string;
        mail?: string;
    }>(),
);

export const resetProfileEditForm = createAction('[Profile] Reset Profile Edit Form');
