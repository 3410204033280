import { StepProgress } from '@frontend/data-access/user/progress';
import { StepType } from '@shared/content-api-interface';
import { ArticleProgressType, LessonProgressType } from '@shared/user-domain';
import { LessonLocation } from '../../step/step-card-slim/models/lesson-location.model';
import { StepCardSlimModel } from '../../step/step-card-slim/models/step-card-slim.model';
import { Step, StepProgressInfo, StepStatus } from './step.model';

export const mapStepToStepCardSlim = (
    { id, content, imageUrl, topic, progress }: Step,
    isLocked: boolean,
): StepCardSlimModel => {
    return {
        id,
        contentId: content.id,
        title: content.title,
        imageUrl,
        thumbnailUrl: `${imageUrl}${content.contentType === StepType.ARTICLE ? '?w=160&h=120' : '?w=152&h=112'}`,
        color: topic.color,
        contentType: content.contentType,
        status: progress.state,
        locked: isLocked,
        isPractice: progress.isPractice,
        topicTitle: topic.title,
        duration: content.duration,
        ...(progress.rating && { rating: progress.rating }),
        ...('location' in content && content.location && { location: content.location as LessonLocation }),
    };
};

export const getMostRecentProgressItem = (progressItems: StepProgress[]): StepProgress | undefined => {
    const sortedProgress = progressItems.sort((a, b) => b.timestamp - a.timestamp);
    return sortedProgress[0];
};

export const getStepProgress = (id: string, stepProgressItems: StepProgress[]): StepProgressInfo => {
    const stepProgressItemsById = stepProgressItems.filter((stepProgressItem) => stepProgressItem.stepId === id);

    const activeProgressItem = getMostRecentProgressItem(stepProgressItemsById);

    if (!activeProgressItem) {
        return {
            state: StepStatus.INACTIVE,
            isPractice: false,
        };
    }

    switch (activeProgressItem.progress) {
        case LessonProgressType.COMPLETED:
        case ArticleProgressType.COMPLETED: {
            return {
                state: StepStatus.COMPLETED,
                date: new Date(activeProgressItem.timestamp),
                rating: activeProgressItem.rating,
                isPractice: false,
            };
        }
        case LessonProgressType.SKIPPED: {
            return {
                state: StepStatus.SKIPPED,
                date: new Date(activeProgressItem.timestamp),
                rating: activeProgressItem.rating,
                isPractice: false,
            };
        }
        case LessonProgressType.READ: {
            return {
                state: StepStatus.INACTIVE,
                date: new Date(activeProgressItem.timestamp),
                rating: activeProgressItem.rating,
                isPractice: false,
            };
        }
    }
};
