@if (vm$ | async; as vm) {
    <ion-content [fullscreen]="true" [color]="Color.Charly">
        <div class="lesson-rating-page-content-container lesson-page-doodle">
            <app-lesson-rating [maxRating]="5" (ratingChangedEvent)="updateRating($event)" />
            <div class="page-padding-sides page-padding-bottom">
                <ion-button
                    appTrackClick
                    expand="block"
                    data-test="lesson-rating-next-btn"
                    [color]="Color.Max"
                    [disabled]="!hasProvidedRating"
                    [identifier]="
                        currentRating >= 4 ? 'step-page-view-feedback-well-done' : 'step-page-view-feedback-no-worries'
                    "
                    (click)="nextPage()"
                >
                    Next
                </ion-button>
            </div>
        </div>
    </ion-content>
}
