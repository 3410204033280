import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    getUserSignInProvider,
    loginWithAppleSuccess,
    loginWithGoogleSuccess,
    signUpWithEmailAndPasswordSuccess,
} from '@frontend/data-access/user/authentication';
import { concatLatestFrom } from '@ngrx/operators';
import { concatMap, filter, map, take } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getUuidSuccess, uuidFeature } from '@frontend/data-access/uuid';
import { createHousehold, getHouseholdSuccess, selectCountryCode } from '@frontend/data-access/user/household';
import { setAnalyticsProfile } from '@frontend/data-access/analytics';
import { adjustRequestTrackingConsent, initAdjust } from '@frontend/data-access/adjust';
import { initializeSubscriptionService } from '@frontend/data-access/subscription';
import { getConfigCatFeatureFlags } from '@frontend/data-access/user/config-cat';
import { APP_VERSION_NUMBER } from '@shared/constants';

@Injectable()
export class AppHouseholdEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    createHouseholdWhenFirebaseAccountCreatedWithEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signUpWithEmailAndPasswordSuccess),
            concatLatestFrom(() => [this.store.select(uuidFeature.selectId), this.store.select(selectCountryCode)]),
            filter(([_, userId]) => !!userId),
            map(([{ user }, userId, countryCode]) => {
                return createHousehold({
                    id: userId!,
                    accountId: user.id,
                    email: user.email,
                    countryCode: countryCode!,
                });
            }),
        );
    });

    createHouseholdWhenFirebaseAccountCreatedWithSSO$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithGoogleSuccess, loginWithAppleSuccess),
            filter(({ additionalUserInfo }) => {
                return !!additionalUserInfo && additionalUserInfo.isNewUser;
            }),
            concatLatestFrom(() => {
                return [this.store.select(uuidFeature.selectId), this.store.select(selectCountryCode)];
            }),
            filter(([_, userId]) => !!userId),
            map(([{ user }, userId, countryCode]) => {
                return createHousehold({
                    id: userId!,
                    accountId: user.id,
                    email: user.email,
                    countryCode: countryCode!,
                });
            }),
        );
    });

    requestTrackingConsentOnGetAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            map(() => adjustRequestTrackingConsent()),
        );
    });

    getUserLoginProvider$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            map(() => getUserSignInProvider()),
        );
    });

    loginToThirdPartyServiceWhenGetUuidSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUuidSuccess),
            take(1),
            concatLatestFrom(() => [this.store.select(selectCountryCode)]),
            concatMap(([{ id }, countryCode]) => [
                setAnalyticsProfile({ id, profile: {} }),
                initAdjust(),
                initializeSubscriptionService({ id: id }),
                getConfigCatFeatureFlags({
                    userObject: {
                        identifier: id,
                        country: countryCode,
                        custom: { appVersionNumber: APP_VERSION_NUMBER },
                    },
                }),
            ]),
        );
    });

    loginToThirdPartyServiceWhenGetHouseholdSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            take(1),
            concatMap(({ household }) => [
                setAnalyticsProfile({ id: household.currentUser.id, profile: {} }),
                initAdjust(),
                initializeSubscriptionService({ id: household.currentUser.id, email: household.currentUser.mail }),
            ]),
        );
    });
}
