import { HouseholdUser } from '../household-user.model';

export class HouseholdUserMock {
    private defaultValue: HouseholdUser = {
        id: 'user-123',
        accountId: 'account-123',
        name: 'Stevo',
        mail: 'iamatestuser@test.zigzag.dog',
        dateOfOnboarding: new Date('11-08-2022'),
        countryCode: 'GB',
        userToken: 'token',
        purinaMarketingAccepted: false,
        partners: [],
    };

    private empty: HouseholdUser = {
        ...this.defaultValue,
        accountId: undefined,
        name: undefined,
        dateOfOnboarding: undefined,
        countryCode: undefined,
        userToken: undefined,
        purinaMarketingAccepted: undefined,
    };

    constructor(overrides?: Partial<HouseholdUser>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): HouseholdUser {
        return this.defaultValue;
    }

    public get emptyValue(): HouseholdUser {
        return this.empty;
    }
}
