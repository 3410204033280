import { IsEmail, IsOptional, IsString } from 'class-validator';

export class InternalPostUserDto {
    @IsOptional()
    @IsString()
    id?: string;

    @IsString()
    accountId!: string;

    @IsEmail()
    mail!: string;

    @IsString()
    countryCode!: string;

    //TODO: LB - take IsOptional out from region + city on 1st May
    @IsOptional()
    @IsString()
    region?: string;

    @IsOptional()
    @IsString()
    city?: string;

    @IsOptional()
    @IsString()
    ownerName?: string;
}
