import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import {
    InternalIterablePostEventCommand,
    InternalIterablePostPushOpenEventCommand,
    InternalIterableRegisterForPushNotificationsCommand,
    InternalIterableUpsertUserCommand,
    internalPaths,
} from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IterableService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    upsertIterableUser(user: InternalIterableUpsertUserCommand): Observable<void> {
        return this.http.post<void>(`${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}`, user);
    }

    registerIterableUserForPushNotifications(
        command: InternalIterableRegisterForPushNotificationsCommand,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/register-push`,
            command,
        );
    }

    trackIterableEvent(command: InternalIterablePostEventCommand): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/event`,
            command,
        );
    }

    trackIterablePushOpenEvent(command: InternalIterablePostPushOpenEventCommand): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/push-open`,
            command,
        );
    }
}
