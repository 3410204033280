import {
    LocalNotificationDescriptor,
    LocalNotificationSchema,
    PendingResult,
    ScheduleResult,
} from '@capacitor/local-notifications';
import { createAction, props } from '@ngrx/store';

export const scheduleLocalNotification = createAction(
    '[Local Notification] Schedule Local Notification',
    props<{ notifications: LocalNotificationSchema[] }>(),
);

export const scheduleLocalNotificationSuccess = createAction(
    '[Local Notification] Schedule Local Notification Success',
    props<{ result: ScheduleResult }>(),
);

export const scheduleLocalNotificationFailure = createAction(
    '[Local Notification] Schedule Local Notification Failure',
    props<{ error: Error }>(),
);

export const getLocalNotifications = createAction('[Local Notification] Get Local Notifications');

export const getLocalNotificationSuccess = createAction(
    '[Local Notification] Get Local Notification Success',
    props<{ result: PendingResult }>(),
);

export const getLocalNotificationFailure = createAction(
    '[Local Notification] Get Local Notification Failure',
    props<{ error: Error }>(),
);

export const clearLocalNotification = createAction(
    '[Local Notification] Clear Local Notification',
    props<{ notifications: LocalNotificationDescriptor[] }>(),
);

export const clearLocalNotificationSuccess = createAction(
    '[Local Notification] Clear Local Notification Success',
    props<{ notifications: number[] }>(),
);

export const clearLocalNotificationFailure = createAction(
    '[Local Notification] Clear Local Notification Failure',
    props<{ error: Error }>(),
);
