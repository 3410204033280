import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LoadingState } from '@frontend/data-access/shared-models';
import { accountFeature } from '@frontend/data-access/user/account';
import { configCatFeature } from '@frontend/data-access/user/config-cat';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, skipWhile } from 'rxjs';
import { selectOnboardingGuardPage } from '../data-access/onboarding.selectors';

@Injectable({
    providedIn: 'root',
})
export class OnboardingIncompleteGuard {
    private readonly store = inject(Store);
    private readonly router = inject(Router);

    public canActivate(): Observable<boolean | UrlTree> {
        return combineLatest([
            this.store.select(accountFeature.selectAtLeastOneProfileCalledHasFinished),
            this.store.select(configCatFeature.selectLoading),
        ]).pipe(
            skipWhile(([ready, loading]) => !ready || loading === LoadingState.INIT || loading == LoadingState.LOADING),
            concatLatestFrom(() => [
                this.store.select(accountFeature.selectDateOfOnboarding),
                this.store.select(selectOnboardingGuardPage),
            ]),
            map(([_, dateOfOnboarding, onboardingRoute]) => {
                if (dateOfOnboarding) {
                    return true;
                }

                return this.router.createUrlTree(['onboarding', onboardingRoute]);
            }),
        );
    }
}
