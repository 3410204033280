import { createAction, props } from '@ngrx/store';
import { UserFeedbackContent } from '../models/user-feedback-content.model';

export const getUserFeedbackContentSuccess = createAction(
    '[User Feedback Content] Get User Feedback Content Success',
    props<{ feedback: UserFeedbackContent[] }>(),
);

export const getUserFeedbackContentFailure = createAction(
    '[User Feedback Content] Get User Feedback Content Failure',
    props<{ error: Error }>(),
);

export const createUserFeedbackContent = createAction(
    '[User Feedback Content] Create User Feedback Content',
    props<{ feedback: UserFeedbackContent }>(),
);

export const createUserFeedbackContentSuccess = createAction(
    '[User Feedback Content] Create User Feedback Content Success',
    props<{ feedback: UserFeedbackContent }>(),
);

export const createUserFeedbackContentFailure = createAction(
    '[User Feedback Content] Create User Feedback Content Failure',
    props<{ error: Error }>(),
);
