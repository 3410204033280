import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { FeedbackOption, UserFeedbackContent } from './feedback.model';

@Component({
    standalone: true,
    imports: [ReactiveFormsModule, IonItem, IonButton, IonInput, NgClass, NgTemplateOutlet],
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    @Input() aiChat = false;
    @Input() contentfulId!: string;
    @Input() existingRating?: UserFeedbackContent;
    @Input() otherFeedbackOptionLabel = 'Something else';
    @Input() feedbackOptions: FeedbackOption[] = [
        {
            key: 'not-clear',
            value: 'Not clear',
        },
        {
            key: 'too-generic',
            value: 'Too generic',
        },
        {
            key: 'incorrect',
            value: 'Incorrect',
        },
    ];

    @Output() optionSelected: EventEmitter<FeedbackOption> = new EventEmitter<FeedbackOption>();
    @Output() feedbackSubmitted: EventEmitter<UserFeedbackContent> = new EventEmitter<UserFeedbackContent>();

    commentFormControl = new FormControl();
    thumbsDownFormControl = new FormControl();
    thumbsDownReasonFormControl = new FormControl();
    selectedFeedbackOption: FeedbackOption | undefined;
    shouldShowCommentInput = false;
    thumbsDownRating: boolean | undefined = undefined;

    private otherFeedbackOption: FeedbackOption = {
        key: 'other',
        value: this.otherFeedbackOptionLabel,
    };

    ngOnInit() {
        //  If there is an existing rating, patch the form controls for display to the user
        if (
            this.existingRating &&
            this.existingRating.thumbsDown !== undefined &&
            this.existingRating.thumbsDown !== null
        ) {
            const selectedFeedbackOption = this.feedbackOptions.find(
                (option) => option.value === this.existingRating?.comment,
            );

            this.thumbsDownRating = this.existingRating.thumbsDown;
            this.thumbsDownFormControl.patchValue(this.existingRating.thumbsDown, { emitEvent: false });

            if (this.existingRating.thumbsDown) {
                if (this.existingRating.comment) {
                    if (selectedFeedbackOption) {
                        this.selectedFeedbackOption = selectedFeedbackOption;
                        this.thumbsDownReasonFormControl.patchValue(selectedFeedbackOption, { emitEvent: false });
                    } else {
                        this.shouldShowCommentInput = true;
                        this.optionSelected.emit(this.otherFeedbackOption);
                        this.commentFormControl.patchValue(this.existingRating.comment, { emitEvent: false });
                    }
                }
            }
        }
    }

    onClickFeedbackOption(option: FeedbackOption) {
        this.thumbsDownReasonFormControl.patchValue(option, { emitEvent: true });
        this.commentFormControl.reset(null, { emitEvent: false });
        this.selectedFeedbackOption = option;
        this.shouldShowCommentInput = false;

        this.optionSelected.emit(option);
    }

    onSelectOtherFeedbackOption() {
        this.selectedFeedbackOption = undefined;
        this.shouldShowCommentInput = true;
        setTimeout(() => {
            const elem = document.getElementById('comment-input-control');
            if (elem && elem instanceof HTMLElement) elem.scrollIntoView();
        });

        this.optionSelected.emit(this.otherFeedbackOption);
    }

    onClickDone() {
        let request: UserFeedbackContent = {
            thumbsDown: this.thumbsDownFormControl?.value,
            contentfulId: this.contentfulId,
            comment: this.selectedFeedbackOption?.value ?? this.commentFormControl?.value,
        };

        if (this.existingRating) {
            request = {
                ...this.existingRating,
                ...request,
            };
        }

        if (request.thumbsDown === null) {
            request.comment = undefined;
        }

        this.feedbackSubmitted.emit(request);
    }

    onClickThumbRating(thumbsDown: boolean) {
        this.shouldShowCommentInput = false;

        if (thumbsDown === this.thumbsDownRating) {
            this.thumbsDownRating = undefined;
            this.thumbsDownFormControl.patchValue(null, { emitEvent: false });
        } else {
            this.thumbsDownFormControl.patchValue(thumbsDown, { emitEvent: true });
            this.commentFormControl.reset(null, { emitEvent: false });
            this.thumbsDownRating = thumbsDown;
        }
    }
}
