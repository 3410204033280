import { Adjust } from '@awesome-cordova-plugins/adjust/ngx';
import { TitleCasePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    Router,
    RouteReuseStrategy,
    withComponentInputBinding,
    withPreloading,
} from '@angular/router';
import { BASE_ENVIRONMENT_CONFIG, ProjectEnvironment, WINDOW } from '@frontend/configuration';
import { ZigzagFrontendDataAccessSentryModule } from '@frontend/data-access/sentry';
import {
    CHAT_CONFIG,
    ChatApiEffects,
    ChatEffects,
    chatFeature,
    ChatService,
    chatServiceFactory,
} from '@frontend/data-access/chat';
import {
    AUTHENTICATION_CONFIG,
    AuthenticationEffects,
    authenticationFeature,
    AuthenticationService,
    authenticationServiceFactory,
} from '@frontend/data-access/user/authentication';
import {
    CONFIGCAT_CONFIG,
    ConfigCatEffects,
    configCatFeature,
    ConfigCatService,
    configCatServiceFactory,
} from '@frontend/data-access/user/config-cat';
import { effects, metaReducers, zigzagMainFeatureStates } from '@frontend/zigzag-main';
import { Preferences } from '@capacitor/preferences';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import * as SentrySibling from '@sentry/angular';
import * as Sentry from '@sentry/angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { StreamChatModule, StreamTextareaModule } from 'stream-chat-angular';
import { register } from 'swiper/element/bundle';
import {
    AnalyticsEffects,
    MIXPANEL_FRONTEND_CONFIG,
    MixpanelPeopleServiceProvider,
    MixpanelServiceProvider,
} from '@frontend/data-access/analytics';
import { ZigzagFrontendDataAccessApplicationInsightsModule } from '@frontend/data-access/application-insights';
import { AppComponent } from './app/app.component';
import appRoutes from './app/app.routes';
import { httpInterceptorProviders } from './app/http-interceptors';
import { adjustConfigProvider } from './environments/configuration-providers/adjust-config.provider';
import { applicationInsightsConfigProvider } from './environments/configuration-providers/application-insights-config.provider';
import { environment } from './environments/environment/environment';
import { ENVIRONMENT } from './environments/injection-tokens';
import { LOCAL_STORAGE_PLUGIN } from './injection-tokens/local-storage.injection-token';
import {
    SUBSCRIPTION_CONFIG,
    SubscriptionAnalyticsEffects,
    SubscriptionEffects,
    subscriptionFeature,
    SubscriptionInternalApiService,
    subscriptionServiceProvider,
} from '@frontend/data-access/subscription';
import { ZigzagFeatureAuthenticationModule } from '@frontend/feature/authentication';
import { ZigzagFeatureOnboardingModule } from '@frontend/feature/onboarding';
import { AdjustEffects } from '@frontend/data-access/adjust';
import {
    AppListenersEffects,
    capacitorListenersFeature,
    LocalStorageEffects,
    OpenSettingsEffects,
    PushListenersEffects,
    SocialShareEffects,
} from '@frontend/data-access/capacitor';
import {
    BreedGroupEffects,
    breedGroupFeature,
    ChatTileEffects,
    chatTileTopicsFeature,
    CourseEffects,
    coursesFeature,
    DailyBitesEffects,
    dailyBitesFeature,
    DevelopmentalWindowEffects,
    developmentalWindowsFeature,
    SeasonalTopicEffects,
    seasonalTopicsFeature,
    StepEffects,
    stepsFeature,
    TopicEffects,
    topicsFeature,
} from '@frontend/data-access/contentful';
import { GeoLocationEffects, geoLocationFeature } from '@frontend/data-access/geo-location';
import { PlatformEffects, platformFeature } from '@frontend/data-access/platform';
import { PushNotificationEffects } from '@frontend/data-access/push-notification';
import { TimeEffects, timeFeature } from '@frontend/data-access/time';
import {
    CourseProgressEffects,
    courseProgressFeature,
    RescheduledStepEffects,
    rescheduledStepFeature,
    RescheduledStepService,
    StepProgressEffects,
    stepProgressFeature,
    TodayCourseProgressEffects,
    todayCourseProgressFeature,
} from '@frontend/data-access/user/progress';
import { UuidEffects, uuidFeature } from '@frontend/data-access/uuid';
import { userInteractionFeature, UserInteractionsEffects } from '@frontend/data-access/user-interactions';
import { ProfileImageEffects, profileImageFeature } from '@frontend/feature/profile-picture';
import { pinnedTopicFeature, PinnedTopicsEffects } from '@frontend/data-access/user/pinned-topics';
import {
    CreateHouseholdEffects,
    DeleteHouseholdEffects,
    DogEffects,
    GetHouseholdEffects,
    householdFeature,
    HouseholdUserEffects,
} from '@frontend/data-access/user/household';
import { UserFeedbackContentEffects, userFeedbackContentFeature } from '@frontend/data-access/feedback';
import { LocalNotificationEffects, localNotificationsFeature } from '@frontend/data-access/local-notification';
import { HappinessCheckEffects } from '@frontend/data-access/happiness-check';
import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel/ngx';
import { RouterEffects } from '@frontend/data-access/router';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { keyboardFeature } from '@frontend/data-access/keyboard';

// swiper
register();

if (environment.baseEnvironmentConfig.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideFirebaseApp(() =>
            initializeApp({
                apiKey: environment.firebaseConfig.apiKey,
                authDomain: environment.firebaseConfig.authDomain,
                storageBucket: environment.firebaseConfig.storageBucket,
                messagingSenderId: environment.firebaseConfig.messagingSenderId,
                appId: environment.firebaseConfig.appId,
                projectId: environment.firebaseConfig.projectId,
                databaseURL: environment.firebaseConfig.databaseURL ?? '',
            }),
        ),
        importProvidersFrom(
            StreamChatModule,
            StreamTextareaModule,
            TranslateModule.forRoot(),
            NgCircleProgressModule.forRoot(),
            ZigzagFeatureAuthenticationModule,
            ZigzagFeatureOnboardingModule,
            ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
                ? [ZigzagFrontendDataAccessSentryModule]
                : []),
            ...(environment.applicationInsights.active ? [ZigzagFrontendDataAccessApplicationInsightsModule] : []),
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideRouter(appRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
        provideIonicAngular({
            mode: 'ios',
            useSetInputAPI: true,
        }),
        provideStore(
            {},
            {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            },
        ),
        ...zigzagMainFeatureStates,
        provideState(capacitorListenersFeature),
        provideState(breedGroupFeature),
        provideState(coursesFeature),
        provideState(dailyBitesFeature),
        provideState(developmentalWindowsFeature),
        provideState(stepsFeature),
        provideState(topicsFeature),
        provideState(chatTileTopicsFeature),
        provideState(seasonalTopicsFeature),
        provideEffects(effects),
        provideState(chatFeature),
        provideState(geoLocationFeature),
        provideState(platformFeature),
        provideState(timeFeature),
        provideState(courseProgressFeature),
        provideState(stepProgressFeature),
        provideState(todayCourseProgressFeature),
        provideState(uuidFeature),
        provideState(userInteractionFeature),
        provideState(profileImageFeature),
        provideState(pinnedTopicFeature),
        provideState(householdFeature),
        provideState(userFeedbackContentFeature),
        provideState(localNotificationsFeature),
        provideState(subscriptionFeature),
        provideState(rescheduledStepFeature),
        provideState(authenticationFeature),
        provideState(configCatFeature),
        provideState('router', routerReducer),
        provideState(keyboardFeature),
        provideEffects([
            AdjustEffects,
            AppListenersEffects,
            PushListenersEffects,
            SocialShareEffects,
            LocalStorageEffects,
            OpenSettingsEffects,
            BreedGroupEffects,
            CourseEffects,
            DailyBitesEffects,
            DevelopmentalWindowEffects,
            StepEffects,
            TopicEffects,
            ChatTileEffects,
            SeasonalTopicEffects,
            ChatEffects,
            ChatApiEffects,
            GeoLocationEffects,
            PlatformEffects,
            PushNotificationEffects,
            TimeEffects,
            CourseProgressEffects,
            StepProgressEffects,
            TodayCourseProgressEffects,
            UuidEffects,
            UserInteractionsEffects,
            ProfileImageEffects,
            PinnedTopicsEffects,
            GetHouseholdEffects,
            CreateHouseholdEffects,
            HouseholdUserEffects,
            DogEffects,
            DeleteHouseholdEffects,
            UserFeedbackContentEffects,
            LocalNotificationEffects,
            HappinessCheckEffects,
            AnalyticsEffects,
            SubscriptionEffects,
            SubscriptionAnalyticsEffects,
            RescheduledStepEffects,
            AuthenticationEffects,
            ConfigCatEffects,
            RouterEffects,
        ]),
        provideRouterStore(),
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.DEVELOPMENT
            ? [
                  provideStoreDevtools({
                      connectInZone: true,
                  }),
              ]
            : []),
        Adjust,
        TitleCasePipe,
        httpInterceptorProviders,
        adjustConfigProvider,
        applicationInsightsConfigProvider,
        { provide: ENVIRONMENT, useValue: environment },
        { provide: MIXPANEL_FRONTEND_CONFIG, useValue: environment.mixpanelConfig },
        { provide: LOCAL_STORAGE_PLUGIN, useValue: Preferences },
        { provide: WINDOW, useValue: window },
        { provide: AUTHENTICATION_CONFIG, useValue: environment.firebaseConfig },
        {
            provide: BASE_ENVIRONMENT_CONFIG,
            useValue: environment.baseEnvironmentConfig,
        },
        {
            provide: SUBSCRIPTION_CONFIG,
            useValue: {
                revenueCatKey: environment.revenueCatKey,
                useSubscriptionMock: environment.useSubscriptionMock,
            },
        },
        {
            provide: CHAT_CONFIG,
            useValue: environment.streamChatConfig,
        },
        {
            provide: CONFIGCAT_CONFIG,
            useValue: environment.configCatConfig,
        },
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
            ? [
                  {
                      provide: ErrorHandler,
                      // Attach the Sentry ErrorHandler
                      useValue: SentrySibling.createErrorHandler(),
                  },
                  {
                      provide: SentrySibling.TraceService,
                      deps: [Router],
                  },
                  provideAppInitializer(() => {
                      inject(Sentry.TraceService);
                  }),
              ]
            : []),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        Mixpanel,
        MixpanelPeople,
        MixpanelServiceProvider,
        MixpanelPeopleServiceProvider,
        subscriptionServiceProvider,
        SubscriptionInternalApiService,
        RescheduledStepService,
        {
            provide: AuthenticationService,
            useFactory: authenticationServiceFactory,
            deps: [AUTHENTICATION_CONFIG],
        },
        {
            provide: ConfigCatService,
            useFactory: configCatServiceFactory,
            deps: [CONFIGCAT_CONFIG],
        },
        {
            provide: ChatService,
            useFactory: chatServiceFactory,
            deps: [CHAT_CONFIG],
        },
    ],
}).catch((err) => console.error(err));
