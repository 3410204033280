<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="gender-page page-content ion-justify-content-start">
        <section>
            <h1>Is {{ dogName() }} a boy or a girl?</h1>
            <p data-test="question-gender-subtitle">We feel weird calling pups an 'it'</p>
        </section>

        <section class="gender-page__button-container">
            <button
                class="gender-page__button"
                [ngClass]="{'gender-page__button--male': selectedGender === Gender.MALE}"
                appTrackClick
                identifier="question-gender-male-btn"
                data-test="question-gender-male-btn"
                [disabled]="isLoading()"
                (click)="onGenderAnswered(Gender.MALE)"
            >
                <img
                    class="gender-page__button-image"
                    [src]="'/assets/images/jasper/jasper-sitting.png'"
                    alt="Jasper sitting patiently wearing his nice bow"
                />
                @if (isLoading() && selectedGender === Gender.MALE) {
                <ion-spinner class="gender-page__button-text" name="crescent" [color]="Color.Lola" />
                } @else {
                <h2 class="gender-page__button-text">Boy</h2>
                }
            </button>
            <button
                class="gender-page__button"
                [ngClass]="{'gender-page__button--female': selectedGender === Gender.FEMALE}"
                appTrackClick
                identifier="question-gender-female-btn"
                data-test="question-gender-female-btn"
                [disabled]="isLoading()"
                (click)="onGenderAnswered(Gender.FEMALE)"
            >
                <img
                    class="gender-page__button-image"
                    [src]="'/assets/images/elora/elora-sitting.png'"
                    alt="Elora sitting patiently wearing her lovely bow"
                />
                @if (isLoading() && selectedGender === Gender.FEMALE) {
                <ion-spinner class="gender-page__button-text" name="crescent" [color]="Color.Luna" />
                } @else {
                <h2 class="gender-page__button-text">Girl</h2>
                }
            </button>
        </section>
    </main>
</ion-content>
