import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectCurrentIp } from '@frontend/data-access/geo-location';
import {
    mapFeatureFlagsForIterable,
    mapTrackEventToInternalIterablePostEventCommand,
    mapUserToInternalIterableUpsertUserCommand,
    PushNotificationService,
    setPushNotifications,
    trackIterableEvent,
    upsertIterableUser,
    upsertIterableUserSuccess,
} from '@frontend/data-access/push-notification';
import { configCatFeature, getConfigCatFeatureFlagsSuccess } from '@frontend/data-access/user/config-cat';
import {
    createHouseholdSuccess,
    getHouseholdSuccess,
    householdFeature,
    patchDogSuccess,
    patchHouseholdUserSuccess,
    selectProfileBreedInfo,
} from '@frontend/data-access/user/household';
import { createStepProgressSuccess, selectCompletedStepIds } from '@frontend/data-access/user/progress';
import { onboardingQuestionSubmittedMarketingOptIn } from '@frontend/feature/onboarding';
import { getPlatform } from '@frontend/utility/native-plugins';
import { Platform } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { combineLatest, merge, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';

@Injectable()
export class PushNotificationsEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly pushNotificationService = inject(PushNotificationService);
    private readonly platform = inject(Platform);

    checkIfAppropriateUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setPushNotifications),
            filter(() => !this.pushNotificationService.pushNotificationsEnabled()),
            map(() => setPushNotifications()),
        );
    });

    displayPushNotificationPrompt$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setPushNotifications),
                tap(() => this.pushNotificationService.registerIterablePushNotifications()),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trackIterableEvent$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                ofType(trackEvent), // trigger
                filter(({ eventName }) => eventName !== 'App error' && eventName !== 'click'),
            ),
            merge(
                // conditions
                zip([
                    // user finished onboarding and exists in iterable
                    this.actions$.pipe(ofType(createHouseholdSuccess)),
                    this.actions$.pipe(ofType(upsertIterableUserSuccess)),
                ]),
                this.actions$.pipe(ofType(getHouseholdSuccess)), // user logged in
            ).pipe(take(1)),
        ]).pipe(
            concatLatestFrom(() => {
                return this.store.select(householdFeature.selectCurrentUserId);
            }),
            filter(([, profileId]) => {
                return !!profileId;
            }),
            map(([[{ eventName, eventProperties }], profileId]) => {
                return trackIterableEvent({
                    command: mapTrackEventToInternalIterablePostEventCommand(profileId!, eventName, eventProperties),
                });
            }),
        );
    });

    upsertIterableUser$ = createEffect(() => {
        return merge(
            this.actions$.pipe(
                ofType(
                    createHouseholdSuccess,
                    patchDogSuccess,
                    patchHouseholdUserSuccess,
                    createStepProgressSuccess,
                    getConfigCatFeatureFlagsSuccess,
                ),
            ), // step progress is there specifically to upsert the complete progress field in Iterable for Ed CRM
            combineLatest([
                this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess)),
                this.actions$.pipe(ofType(getHouseholdSuccess), take(1)),
            ]),
        ).pipe(
            concatLatestFrom(() => [
                this.store.select(householdFeature.selectHousehold),
                this.store.select(selectProfileBreedInfo),
                this.store.select(selectCurrentIp),
                this.store.select(selectCompletedStepIds),
                this.store.select(configCatFeature.selectConfigCatFlags),
            ]),
            filter(([, householdUser]) => !!householdUser && !!householdUser.id),
            // Detect changes to key fields that should trigger the upsert
            distinctUntilChanged((prev, curr) => {
                const [, prevHousehold, , , prevSteps, prevFlags] = prev;
                const [, currHousehold, , , currSteps, currFlags] = curr;

                // Check for any changes in feature flags, step progress, or profile data
                const hasFeatureFlagChanged = JSON.stringify(prevFlags) !== JSON.stringify(currFlags);
                const hasStepProgressChanged = prevSteps.length !== currSteps.length;
                const hasProfileChanged = JSON.stringify(prevHousehold) !== JSON.stringify(currHousehold);

                return !(hasFeatureFlagChanged || hasStepProgressChanged || hasProfileChanged);
            }),
            concatMap(([triggerAction, household, breedInfo, ipAddress, completedSteps, featureFlags]) => {
                return [
                    upsertIterableUser({
                        user: mapUserToInternalIterableUpsertUserCommand(
                            household!,
                            getPlatform(this.platform),
                            mapFeatureFlagsForIterable(featureFlags),
                            (triggerAction as Action)?.type === createHouseholdSuccess.type, // Only set when it is creating a user
                            ipAddress,
                            breedInfo,
                            completedSteps.length,
                        ),
                    }),
                ];
            }),
        );
    });

    // Not part of the standard effect because it is not saved in the user profile, but is in iterable
    upsertIterableUserForMarketingOptIn$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedMarketingOptIn),
            concatLatestFrom(() => this.store.select(householdFeature.selectEmail)),
            concatMap(([{ marketingAccepted }, email]) => {
                if (!email) {
                    return [];
                }

                return [
                    upsertIterableUser({
                        user: {
                            email,
                            dataFields: {
                                marketingOptIn: marketingAccepted,
                            },
                        },
                    }),
                ];
            }),
        );
    });
}
