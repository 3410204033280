<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>Sign me up!</h1>
            <p data-test="question-marketing-opt-in-subtitle">Click "Yes" to receive marketing communications about:</p>
        </section>

        <ul class="purina-opt-in-benefits">
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">The latest in pup education</h3>
            </li>
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Exclusive news from our trainers</h3>
            </li>
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Deals to help train {{ dogName() }}</h3>
            </li>
        </ul>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--buddy"
            appTrackClick
            identifier="question-marketing-opt-in-yes-btn"
            expand="block"
            data-test="question-marketing-opt-in-yes-btn"
            [disabled]="isLoading()"
            (click)="onOptInClick(true)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Yes }
        </ion-button>
        <button
            class="purina-opt-in-footer__button zz-button"
            appTrackClick
            data-test="question-marketing-opt-in-no-btn"
            identifier="question-marketing-opt-in-no-btn"
            [disabled]="isLoading()"
            (click)="onOptInClick(false)"
        >
            No thanks
        </button>
    </div>
</ion-footer>
