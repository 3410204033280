import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-invalid-input-error-message',
    templateUrl: './invalid-input-error-message.component.html',
    styleUrl: './invalid-input-error-message.component.scss',
    standalone: true,
})
export class InvalidInputErrorMessageComponent {
    @Input({ required: true }) errorMessage!: string;
    @Input() linkText: string | undefined = undefined;
    @Output() public linkClicked = new EventEmitter();
}
