import { createSelector } from '@ngrx/store';
import {
    isCountryCodeNorthAmerica,
    selectCurrentIpCountryCode,
    selectCurrentIpLocationLoading,
} from '@frontend/data-access/geo-location';
import { householdFeature } from '../household.reducer';

export const selectCountryCode = createSelector(
    householdFeature.selectCountryCode,
    selectCurrentIpCountryCode,
    (profileCountryCode, currentIpCountryCode) => {
        if (profileCountryCode !== undefined && profileCountryCode !== '') {
            return profileCountryCode;
        }

        if (currentIpCountryCode !== undefined && currentIpCountryCode !== '') {
            return currentIpCountryCode;
        }

        return ''; // TODO: NL - think about a default value for country code (or change usages to work with undefined)
    },
);

export const selectIsCountryCodeResolved = createSelector(
    householdFeature.selectCountryCode,
    selectCurrentIpLocationLoading,
    (profileCountryCode, currentIpLocationLoading) => {
        return (profileCountryCode !== '' && profileCountryCode !== undefined) || !currentIpLocationLoading;
    },
);

export const selectIsUsUser = createSelector(selectCountryCode, (countryCode) => countryCode === 'US');

export const selectIsNorthAmericaCountryCode = createSelector(selectCountryCode, (countryCode) => {
    return isCountryCodeNorthAmerica(countryCode);
});
