import { InternalPostHappinessCheckCommand } from '../../../commands/internal-api/happiness-check/internal-post-happiness-check.command';

export class InternalPostHappinessCheckCommandMock {
    private defaultValue: InternalPostHappinessCheckCommand = {
        ownerHappinessScore: 0.5,
        dogHappinessScore: 0.6,
        timestamp: 0,
    };

    constructor(overrides?: Partial<InternalPostHappinessCheckCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPostHappinessCheckCommand {
        return this.defaultValue;
    }
}
