import { DecimalPipe, LowerCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { openSettingsAndroid, openSettingsIos } from '@frontend/data-access/capacitor';
import { clearLocalNotification, selectLocalNotifications } from '@frontend/data-access/local-notification';
import { platformFeature } from '@frontend/data-access/platform';
import { accountFeature } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonList,
    IonToggle,
    IonToolbar,
    NavController,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { launchTrainingReminderModal } from '../../training-reminder/store/training-reminder-modal.actions';

@Component({
    standalone: true,
    selector: 'app-notifications',
    templateUrl: 'notifications.page.html',
    styleUrls: ['notifications.page.scss'],
    imports: [
        HeaderComponent,
        IonHeader,
        IonToolbar,
        IonContent,
        IonItem,
        AnalyticsTrackClickDirective,
        IonButton,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonToggle,
        IonItemSliding,
        IonItemOptions,
        IonItemOption,
        LowerCasePipe,
        DecimalPipe,
        IonList,
    ],
})
export class NotificationsPage {
    private readonly store = inject(Store);
    private readonly navController = inject(NavController);

    public platformIsIos = this.store.selectSignal(platformFeature.selectPlatformIsIos);
    public platformIsAndroid = this.store.selectSignal(platformFeature.selectPlatformIsAndroid);
    public platformIsCapacitor = this.store.selectSignal(platformFeature.selectPlatformIsCapacitor);
    public notifications = this.store.selectSignal(selectLocalNotifications);

    private dogName = this.store.selectSignal(accountFeature.selectDogName);

    protected readonly Color = Color;

    public previousPage(): void {
        this.navController.back();
    }

    public setTrainingReminder(): void {
        this.store.dispatch(launchTrainingReminderModal({ dogName: this.dogName(), trigger: 'notifications-page' }));
    }

    public openApplicationDetails() {
        if (this.platformIsAndroid()) {
            this.store.dispatch(openSettingsAndroid({ option: AndroidSettings.ApplicationDetails }));
        }

        if (this.platformIsCapacitor() && this.platformIsIos()) {
            this.store.dispatch(openSettingsIos({ option: IOSSettings.App }));
        }
    }

    public async clearTrainingReminder(notificationIds: number[]) {
        const notifications = notificationIds.map((id) => {
            return { id };
        });

        this.store.dispatch(clearLocalNotification({ notifications }));
    }
}
