import { PublicUser } from '../public-user.model';

export class PublicUserMock {
    private defaultValue: PublicUser = {
        id: 'user-123',
        mail: 'iamatestuser@test.zigzag.dog',
        name: 'Stevo',
        dateOfOnboarding: new Date('11-08-2022'),
        countryCode: 'GB',
    };

    constructor(overrides?: Partial<PublicUser>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): PublicUser {
        return this.defaultValue;
    }
}
