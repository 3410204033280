import { DomainUserFeedbackContentDto } from '../../../dtos/domain-api/feedback/domain-user-feedback-content.dto';

export class DomainUserFeedbackContentDtoMock {
    private defaultValue: DomainUserFeedbackContentDto = {
        id: '',
        userId: '',
        contentfulId: '',
        contentTypeId: '',
        comment: null,
        thumbsDown: false,
        timestamp: 0,
    };

    constructor(overrides?: Partial<DomainUserFeedbackContentDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainUserFeedbackContentDto {
        return this.defaultValue;
    }
}
