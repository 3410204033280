import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, tap } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import {
    NOURISH_PRODUCT_FEEDBACK_CLOSE_DELAY,
    NOURISH_PRODUCT_FEEDBACK_MODAL_ID,
    NourishProductFeedbackModalState,
    NourishTileId,
} from '../../constants/nourish.constants';
import { HelpWithTummyTroublesModalComponent } from '../../modals/help-with-tummy-troubles/help-with-tummy-troubles-modal.component';
import { HowMuchExerciseModalComponent } from '../../modals/how-much-exercise-is-enough/how-much-exercise-is-enough-modal.component';
import { HowToWeighYourDogModalComponent } from '../../modals/how-to-weigh-your-dog-modal/how-to-weigh-your-dog-modal.component';
import { MakingTheRightFoodChoiceModalComponent } from '../../modals/making-the-right-food-choice/making-the-right-food-choice-modal.component';
import { PreventDiarrhoeaModalComponent } from '../../modals/prevent-diarrhoea-modal/prevent-diarrhoea-modal.component';
import { PupNotEatingModalComponent } from '../../modals/pup-not-eating-modal/pup-not-eating-modal.component';
import { WhatCanPoopTellYouModalComponent } from '../../modals/what-can-poop-tell-you-modal/what-can-poop-tell-you-modal.component';
import { WhenAndHowToSwitchFoodModalComponent } from '../../modals/when-and-how-to-switch-food-modal/when-and-how-to-switch-food-modal.component';
import { WhyNutritionMattersModalComponent } from '../../modals/why-nutrition-matters-modal/why-nutrition-matters-modal.component';
import {
    closeNourishModal,
    closeNourishProductFeedbackModal,
    nourishProductFeedbackModalSubmitted,
    nourishProductFeedbackModalThumbsDownClicked,
    showNourishModal,
} from './nourish-modal.actions';
import {
    nourishProductHeaderBackButtonClicked,
    nourishProductTileClicked,
    nourishTileClicked,
} from '../nourish.actions';
import { concatLatestFrom } from '@ngrx/operators';
import { isItemInteracted, userInteractionFeature } from '@frontend/data-access/user-interactions';
import { NourishProductFeedbackModalComponent } from '../../nourish-product-feedback-modal/nourish-product-feedback-modal.component';
import { Store } from '@ngrx/store';

@Injectable()
export class NourishModalEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    showNourishModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showNourishModal),
                tap(({ id }) => {
                    let component;
                    switch (id) {
                        case NourishTileId.WHY_NUTRITION_MATTERS: {
                            component = WhyNutritionMattersModalComponent;
                            break;
                        }
                        case NourishTileId.WHAT_CAN_POOP_TELL_YOU: {
                            component = WhatCanPoopTellYouModalComponent;
                            break;
                        }
                        case NourishTileId.PREVENT_DIARRHOEA: {
                            component = PreventDiarrhoeaModalComponent;
                            break;
                        }
                        case NourishTileId.RIGHT_FOOD_CHOICE: {
                            component = MakingTheRightFoodChoiceModalComponent;
                            break;
                        }
                        case NourishTileId.SWITCH_FOOD: {
                            component = WhenAndHowToSwitchFoodModalComponent;
                            break;
                        }
                        case NourishTileId.HOW_MUCH_EXERCISE: {
                            component = HowMuchExerciseModalComponent;
                            break;
                        }
                        case NourishTileId.PUP_NOT_EATING: {
                            component = PupNotEatingModalComponent;
                            break;
                        }
                        case NourishTileId.WEIGH_YOUR_DOG: {
                            component = HowToWeighYourDogModalComponent;
                            break;
                        }
                        case NourishTileId.TUMMY_TROUBLES: {
                            component = HelpWithTummyTroublesModalComponent;
                            break;
                        }
                        default: {
                            return EMPTY;
                        }
                    }

                    return this.modalService.showModal({
                        component,
                        cssClass: ['modal', 'modal-large'],
                        // TODO: NBSon - we should look into creating a directive/service for this, confirm with Marija if we want it so that
                        // modals without a back arrow should all inherit this behaviour
                        /**
                         * NBSon - this is used in combination with the `ionViewWillEnter` and `ionViewWillLeave` lifecycle
                         * to prevent the user from being able to dismiss the modal through the back gesture/Android back button
                         * see {@link NourishModalBaseComponent.ionViewWillEnter}
                         */
                        backdropDismiss: false,
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeNourishModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeNourishModal),
                tap(() => {
                    void this.modalService.dismiss();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openNourishModalOnNourishTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            filter(({ id }) => id !== NourishTileId.CALCULATE_FOOD && id !== NourishTileId.FIND_FOOD),
            map(({ id }) => {
                return showNourishModal({ id });
            }),
        );
    });

    closeNourishModalWhenProductTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            filter(({ correlationId }) => correlationId === 'modal'),
            map(() => closeNourishModal({})),
        );
    });

    setProductFeedbackModalClass$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nourishProductFeedbackModalThumbsDownClicked),
                tap(({ state }) => {
                    const modalClasses = ['modal', 'nourish-product-review-modal'];

                    if (state === NourishProductFeedbackModalState.NEGATIVE) {
                        modalClasses.push('nourish-product-review-modal--large');
                    }

                    void this.modalService.setModalClass(NOURISH_PRODUCT_FEEDBACK_MODAL_ID, modalClasses);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    resetProductFeedbackModalClass$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nourishProductFeedbackModalSubmitted),
                filter(({ response }) => response === NourishProductFeedbackModalState.POSITIVE),
                tap(() => {
                    void this.modalService.setModalClass(NOURISH_PRODUCT_FEEDBACK_MODAL_ID, [
                        'modal',
                        'nourish-product-review-modal',
                    ]);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showProductFeedbackModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nourishProductHeaderBackButtonClicked),
                concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
                filter(
                    ([{ id }, dismissedInfo]) =>
                        !isItemInteracted(dismissedInfo, `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`),
                ),
                tap(([{ id }]) => {
                    return this.modalService.showModal({
                        id: NOURISH_PRODUCT_FEEDBACK_MODAL_ID,
                        component: NourishProductFeedbackModalComponent,
                        cssClass: ['modal', 'nourish-product-review-modal'],
                        componentProps: {
                            id,
                        },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeProductFeedbackModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeNourishProductFeedbackModal),
                tap(() => {
                    void this.modalService.dismissById(NOURISH_PRODUCT_FEEDBACK_MODAL_ID);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeProductFeedbackModalAfterPositiveRating$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductFeedbackModalSubmitted),
            filter(({ response }) => response === NourishProductFeedbackModalState.POSITIVE),
            delay(NOURISH_PRODUCT_FEEDBACK_CLOSE_DELAY),
            map(() => {
                return closeNourishProductFeedbackModal();
            }),
        );
    });

    closeProductFeedbackModalAfterNegativeRating$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductFeedbackModalSubmitted),
            filter(({ response }) => response === NourishProductFeedbackModalState.NEGATIVE),
            map(() => {
                return closeNourishProductFeedbackModal();
            }),
        );
    });

    trackProductFeedbackSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductFeedbackModalSubmitted),
            map(({ id, response, reason, comment }) => {
                return trackEvent({
                    eventName: '[Nourish] Product Feedback Submitted',
                    eventProperties: {
                        id,
                        response,
                        reason: reason ?? '',
                        comment: comment ?? '',
                    },
                });
            }),
        );
    });
}
