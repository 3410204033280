import { inject, Injectable } from '@angular/core';
import { AdjustEventType, trackAdjustEvent } from '@frontend/data-access/adjust';
import { setUserMobileAppSentry } from '@frontend/data-access/sentry';
import { logInToPurchases } from '@frontend/data-access/subscription';
import { onboardingComplete, OnboardingRoutes, onboardingRouteTo } from '@frontend/feature/onboarding';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';
import { createHouseholdSuccess, householdFeature } from '@frontend/data-access/user/household';
import {
    getConfigCatFeatureFlagsSuccess,
    selectIsHowDidYouHearOnboardingPage,
} from '@frontend/data-access/user/config-cat';
import { combineLatest } from 'rxjs';
import { differenceInWeeks } from 'date-fns';

@Injectable()
export class CreateAccountEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    routeToOnboardingWhenAccountCreationSucceeds$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(2)),
            this.actions$.pipe(ofType(createHouseholdSuccess), take(1)),
        ]).pipe(
            concatLatestFrom(() => this.store.select(selectIsHowDidYouHearOnboardingPage)),
            map(([, isHowDidYouHearOnboardingPage]) => {
                return isHowDidYouHearOnboardingPage
                    ? onboardingRouteTo({ route: OnboardingRoutes.HOW_DID_YOU_HEAR })
                    : onboardingRouteTo({ route: OnboardingRoutes.NAME });
            }),
        );
    });

    setUpSentry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createHouseholdSuccess),
            map(({ household }) => {
                return setUserMobileAppSentry({ userId: household.currentUser.id, email: household.currentUser.mail });
            }),
        );
    });

    updateUserInRevenueCat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createHouseholdSuccess),
            map(({ household }) => {
                return logInToPurchases({ profileId: household.currentUser.id, email: household.currentUser.mail });
            }),
        );
    });

    fireThirdPartyActionsOnceOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingComplete),
            concatLatestFrom(() => this.store.select(householdFeature.selectDateOfBirth)),
            switchMap(([, dateOfBirth]) => {
                let birthDate = new Date();
                if (dateOfBirth) {
                    birthDate = dateOfBirth;
                }
                return [
                    ...(differenceInWeeks(new Date(), birthDate) < 11
                        ? [trackAdjustEvent({ event: AdjustEventType.YOUNG_PUPPY_INSTALL })]
                        : []),
                    trackAdjustEvent({ event: AdjustEventType.ONBOARDING_COMPLETED }),
                ];
            }),
        );
    });
}
