import { NgStyle } from '@angular/common';
import { Component, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelectOption, MultiSelectOptionValue } from './multi-select.model';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrl: './multi-select.component.scss',
    standalone: true,
    imports: [NgStyle],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiSelectComponent), multi: true }],
})
export class MultiSelectComponent implements ControlValueAccessor {
    public options = input<MultiSelectOption[]>([]);

    public value: MultiSelectOptionValue[] = [];

    public onTouched = () => {
        /* Do nothing */
    };

    public onChange: (value: MultiSelectOptionValue[]) => void = () => {
        /* Do nothing */
    };

    public writeValue(value: MultiSelectOptionValue[]): void {
        this.value = value;
    }

    public registerOnChange(onChange: (value: MultiSelectOptionValue[]) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public isSelected(value: MultiSelectOptionValue): boolean {
        return this.value.includes(value);
    }

    public onOptionSelected(selectedValue: MultiSelectOptionValue): void {
        if (this.isSelected(selectedValue)) {
            this.value = this.value.filter((value) => value !== selectedValue);
        } else {
            this.value = [...this.value, selectedValue];
        }

        this.onChange(this.value);
        this.onTouched();
    }
}
