import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { dismissInfo } from '@frontend/data-access/dismissible-info';
import { submitHappinessCheckData } from '@frontend/data-access/happiness-check';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DEFAULT_PUPPY_NAME } from '@shared/constants';
import { tap } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { PupHappinessModalComponent } from '../pup-happiness-modal.component';
import { PUP_HAPPINESS_MODAL_ID } from '../pup-happiness-modal.constant';
import {
    closePupHappinessModal,
    launchPupHappinessModal,
    pupHappinessButtonClicked,
    submitPupHappinessForm,
} from './pup-happiness-modal.actions';

@Injectable()
export class PupHappinessModalEffects {
    private actions$ = inject(Actions);
    private modalService = inject(ModalService);

    showPupHappinessModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchPupHappinessModal),
            tap(({ dogName }) => {
                void this.modalService.showModal({
                    component: PupHappinessModalComponent,
                    componentProps: {
                        dogName: dogName || DEFAULT_PUPPY_NAME,
                    },
                    cssClass: ['modal', 'modal-pup-happiness'],
                });
            }),
            switchMap(() => [
                dismissInfo({ id: PUP_HAPPINESS_MODAL_ID }),
                trackEvent({ eventName: '[Pup Happiness] Show Modal' }),
            ]),
        );
    });

    closePupHappinessModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closePupHappinessModal),
                tap(() => {
                    void this.modalService.dismiss();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    pupHappinessModalButtonClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(pupHappinessButtonClicked),
            mergeMap(({ buttonId }) => {
                return [
                    closePupHappinessModal(),
                    trackEvent({
                        eventName: `[Pup Happiness] ${buttonId} Button Clicked`,
                    }),
                ];
            }),
        );
    });

    submitPupHappinessForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(submitPupHappinessForm),
            mergeMap(({ ownerHappinessScore, dogHappinessScore }) => {
                return [
                    submitHappinessCheckData({ happinessCheckData: { ownerHappinessScore, dogHappinessScore } }),
                    trackEvent({
                        eventName: `[Pup Happiness] Form Submitted`,
                        eventProperties: {
                            ownerHappinessScore,
                            dogHappinessScore,
                        },
                    }),
                ];
            }),
        );
    });
}
