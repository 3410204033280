import { NgClass, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonContent } from '@ionic/angular/standalone';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { GenericFeedbackComponent } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-why-nutrition-matters',
    templateUrl: './why-nutrition-matters-modal.component.html',
    styleUrl: './why-nutrition-matters-modal.component.scss',
    imports: [NgClass, IonContent, AnalyticsTrackClickDirective, GenericFeedbackComponent, TitleCasePipe],
})
export class WhyNutritionMattersModalComponent extends NourishModalBaseComponent {}
