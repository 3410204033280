import { TitleCasePipe } from '@angular/common';
import { computed, inject, Pipe, PipeTransform, Signal } from '@angular/core';
import { selectBreeds } from '@frontend/data-access/contentful';
import { Store } from '@ngrx/store';
import { heShe, himHer, hisHer, hisHers } from '@frontend/utility/pronouns';
import { accountFeature } from '@frontend/data-access/user/account';
import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';

@Pipe({
    name: 'textReplacement',
    pure: false,
    standalone: true,
})
export class TextReplacementPipe implements PipeTransform {
    private store = inject(Store);
    private titleCasePipe = inject(TitleCasePipe);

    private readonly SEARCH_VALUES_REGEX = [
        '\\[OWNERNAME\\]',
        '\\[OWNERNAME_C\\]',
        '\\[NAME\\]',
        '\\[NAME_C\\]',
        '\\[BREED\\]',
        '\\[BREED_C\\]',
        '\\[HIM/HER\\]',
        '\\[HIM/HER_C\\]',
        '\\[HE/SHE\\]',
        '\\[HE/SHE_C\\]',
        '\\[HIS/HER\\]',
        '\\[HIS/HER_C\\]',
        '\\[HIS/HERS\\]',
        '\\[HIS/HERS_C\\]',
        '\\[DOG/PUPPY\\]',
        '\\[DOG/PUPPY_C\\]',
        '\\[DOGS/PUPPIES\\]',
        '\\[DOGS/PUPPIES_C\\]',
    ] as const;

    private RegexMatcher = new RegExp(this.SEARCH_VALUES_REGEX.join('|'), 'gi');

    breedName = computed(() => {
        return this.store
            .selectSignal(selectBreeds)()
            .find((b) => {
                return b.id === this.store.selectSignal(accountFeature.selectBreedId)();
            })?.name;
    });
    breedNameCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.breedName());
    });

    ownerNameCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.store.selectSignal(accountFeature.selectOwnerName)());
    });

    dogNameCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.store.selectSignal(accountFeature.selectDogName)());
    });

    dateOfBirth = this.store.selectSignal(accountFeature.selectDateOfBirth);

    gender = this.store.selectSignal(accountFeature.selectGender);
    himHer = computed(() => {
        return himHer(this.gender());
    });

    heShe = computed(() => {
        return heShe(this.gender());
    });

    hisHer = computed(() => {
        return hisHer(this.gender());
    });

    hisHers = computed(() => {
        return hisHers(this.gender());
    });

    himHerCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.himHer());
    });

    heSheCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.heShe());
    });

    hisHerCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.hisHer());
    });

    hisHersCapitalised = computed(() => {
        return this.titleCasePipe.transform(this.hisHers());
    });

    dogPuppy = computed(() => {
        return mapDateOfBirthToAgeInWeeks(this.dateOfBirth()) <= 26 ? 'puppy' : 'dog';
    });

    dogPuppyCapitalised = computed(() => {
        return mapDateOfBirthToAgeInWeeks(this.dateOfBirth()) <= 26 ? 'Puppy' : 'Dog';
    });

    dogsPuppies = computed(() => {
        return mapDateOfBirthToAgeInWeeks(this.dateOfBirth()) <= 26 ? 'puppies' : 'dogs';
    });

    dogsPuppiesCapitalised = computed(() => {
        return mapDateOfBirthToAgeInWeeks(this.dateOfBirth()) <= 26 ? 'Puppies' : 'Dogs';
    });

    profileMap: Signal<Record<string, string>> = computed(() => {
        // Proper nouns (owner and dog name) and Nouns (breed) *should* already be capitalised but let's ensure for completeness' sake
        return {
            '[OWNERNAME]': this.ownerNameCapitalised() ?? 'you',
            '[OWNERNAME_C]': this.ownerNameCapitalised() ?? 'You',
            '[NAME]': this.dogNameCapitalised() ?? 'your pup',
            '[NAME_C]': this.dogNameCapitalised() ?? 'Your pup',
            '[BREED]': this.breedNameCapitalised() ?? 'dog',
            '[BREED_C]': this.breedNameCapitalised() ?? 'Dog',
            '[HIM/HER]': this.himHer(),
            '[HIM/HER_C]': this.himHerCapitalised(),
            '[HE/SHE]': this.heShe(),
            '[HE/SHE_C]': this.heSheCapitalised(),
            '[HIS/HER]': this.hisHer(),
            '[HIS/HER_C]': this.hisHerCapitalised(),
            '[HIS/HERS]': this.hisHers(),
            '[HIS/HERS_C]': this.hisHersCapitalised(),
            '[DOG/PUPPY]': this.dogPuppy(),
            '[DOG/PUPPY_C]': this.dogPuppyCapitalised(),
            '[DOGS/PUPPIES]': this.dogsPuppies(),
            '[DOGS/PUPPIES_C]': this.dogsPuppiesCapitalised(),
        };
    });

    transform(value: string | undefined): string {
        if (value === undefined) {
            return '';
        }

        return value.replace(this.RegexMatcher, (x) => this.profileMap()[x]);
    }
}
