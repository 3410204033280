import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    InternalPatchAccountCommand,
    internalPaths,
    InternalPostUserCommand,
    InternalUserDto,
} from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getAccount(): Observable<InternalUserDto> {
        return this.http.get<InternalUserDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.accountsPath}`,
            httpOptions,
        );
    }

    public createAccount(command: InternalPostUserCommand): Observable<InternalUserDto> {
        return this.http.post<InternalUserDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.accountsPath}/with-account-id`,
            command,
            httpOptions,
        );
    }

    public updateAccount(command: InternalPatchAccountCommand): Observable<InternalUserDto> {
        return this.http.patch<InternalUserDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.accountsPath}`,
            command,
            httpOptions,
        );
    }

    public deleteAccount() {
        return this.http.delete<boolean>(
            `${this.environment.internalApiUrl}/api/${internalPaths.accountsPath}`,
            httpOptions,
        );
    }

    public createEmptyUser(): Observable<InternalUserDto> {
        return this.http.post<InternalUserDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.accountsPath}/empty-user`,
            httpOptions,
        );
    }
}
