export interface AnalyticsProfile {
    householdId?: string;
    dogId?: string;
    name?: string;
    dateOfBirth?: Date;
    breed?: string;
    breedType?: string;
    gender?: string;
    ownerName?: string;
    mail?: string;
    dateOfOnboarding?: Date;
    dateOfArrival?: Date;
    hasArrived?: boolean;
    properties?: Record<string, string>;
    partners?: { id: string; name: string }[];
    pupAtHomeWhenOnboardingCompleted?: boolean;
    $email?: string;
    featureFlags?: Record<string, boolean>;
    countryCode?: string;
    region?: string;
    city?: string;
    userToken?: ''; // Due to a past bug overwrite this where it was accidentally set
    isRescuePup?: boolean;
    purinaMarketingAccepted?: boolean;
    marketingOptIn?: boolean;
}
