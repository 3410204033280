import { Component, effect } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { MultiSelectComponent, MultiSelectOption, ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Color, shuffleArray } from '@shared/utils/typescript';
import {
    onboardingQuestionCareNeedsSetOrderSeed,
    onboardingQuestionSubmittedCareNeeds,
} from '../../data-access/onboarding.actions';
import { onboardingFeature } from '../../data-access/onboarding.reducer';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-care-needs',
    templateUrl: 'question-care-needs.page.html',
    styleUrls: ['question-care-needs.page.scss', '../onboarding.scss'],
    imports: [
        ReactiveFormsModule,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        AnalyticsTrackClickDirective,
        MultiSelectComponent,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionCareNeedsPage extends OnboardingQuestionBasePage {
    public selectedCareNeeds = this.store.selectSignal(onboardingFeature.selectSelectedCareNeeds);
    public careNeedsOrderSeed = this.store.selectSignal(onboardingFeature.selectCareNeedsOrderSeed);

    public careNeedsForm = new FormGroup({
        careNeeds: new FormControl<string[]>([], { nonNullable: true }),
    });

    public options: MultiSelectOption<string>[] = [
        {
            title: 'Healthcare',
            value: 'healthcare',
            image: 'assets/images/oliver/oliver-gets-checkup-from-vet.svg',
            alt: 'Oliver gets a checkup from the veterinarian',
            backgroundColor: Color.Lola,
        },
        {
            title: 'Nutrition & Feeding',
            value: 'nutrition-and-feeding',
            image: 'assets/images/buddy/buddy-licks-food-from-treat-mat.svg',
            alt: 'Buddy licks food from a treat mat',
            backgroundColor: Color.Bailey,
        },
        {
            title: 'Dog insurance',
            value: 'dog-insurance',
            image: 'assets/images/charly/charly-holds-tennis-ball-in-mouth.svg',
            alt: 'Charly holds a tennis ball in his mouth',
            backgroundColor: Color.Oliver,
        },
        {
            title: 'Training aids',
            value: 'training-aids',
            image: 'assets/images/buddy/buddy-holds-checklist-in-mouth.svg',
            alt: 'Buddy holds a checklist in his mouth',
            backgroundColor: Color.Maggie,
        },
        {
            title: 'Grooming & hygiene',
            value: 'grooming-and-hygiene',
            image: 'assets/images/buddy/buddy-gets-groomed-with-brush.svg',
            alt: 'Buddy gets groomed with a brush',
            backgroundColor: Color.Luna,
        },
        {
            title: 'Dog-sitting & walking',
            value: 'dog-sitting-and-walking',
            image: 'assets/images/bailey/bailey-walks-nicely-on-leash.svg',
            alt: 'Bailey walks on a leash',
            backgroundColor: Color.Bella,
        },
    ];

    constructor() {
        super();

        effect(() => {
            this.careNeedsForm.setValue({
                careNeeds: this.selectedCareNeeds(),
            });
        });

        effect(
            () => {
                if (this.careNeedsOrderSeed() !== undefined) {
                    this.options = shuffleArray(this.options, this.careNeedsOrderSeed()!);
                } else {
                    const seed = Math.floor(Math.random() * 100000);

                    this.store.dispatch(
                        onboardingQuestionCareNeedsSetOrderSeed({
                            seed,
                        }),
                    );

                    this.options = shuffleArray(this.options, seed);
                }
            },
            { allowSignalWrites: true },
        );
    }

    public onSubmit() {
        if (this.isLoading()) {
            return;
        }

        const careNeeds = this.options.reduce((acc: Record<string, boolean>, option) => {
            acc[option.value] = this.careNeedsForm.controls.careNeeds.value.includes(option.value);

            return acc;
        }, {});

        this.store.dispatch(
            onboardingQuestionSubmittedCareNeeds({
                careNeeds,
            }),
        );
    }
}
