<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <ui-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>Treat yourself and {{ dogName() }}!</h1>
            <p data-test="question-purina-opt-in-subtitle">
                Click "YES" to receive marketing communications from Purina UK&I about:
            </p>
        </section>

        <ul class="purina-opt-in-benefits">
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Exclusive discounts</h3>
            </li>
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Prize draws & experiences</h3>
            </li>
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Guidance on pup ownership</h3>
            </li>
        </ul>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <p class="purina-opt-in-footer__policy xs" data-test="question-purina-opt-in-policy">
        Click if you would like to receive offers, news, competitions and information about Purina UK&I, its brands and
        its products. Your personal data will be processed by Nestlé Purina Petcare UK&I and its Affiliates. You can
        withdraw your consent at any time
        <u
            data-test="purina-opt-in-show-consent"
            (click)="onExternalLinkClick('https://apps.nestle.co.uk/nestle/consenthub/')"
            >here</u
        >. You confirm that you are over 18 years old. If you are already opted-in you will continue to receive future
        communications, but can out opt-out
        <u
            data-test="purina-opt-in-show-opt-out"
            (click)="onExternalLinkClick('https://apps.nestle.co.uk/nestle/consenthub/')"
            >here</u
        >.
    </p>

    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--harley"
            appTrackClick
            identifier="question-purina-opt-in-uk-ab-yes-btn"
            expand="block"
            data-test="question-purina-opt-in-uk-ab-yes-btn"
            [disabled]="isLoading()"
            (click)="onOptInClick(true)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Yes }
        </ion-button>
        <button
            class="purina-opt-in-footer__button zz-button"
            appTrackClick
            data-test="question-purina-opt-in-uk-ab-no-btn"
            identifier="question-purina-opt-in-uk-ab-no-btn"
            [disabled]="isLoading()"
            (click)="onOptInClick(false)"
        >
            Maybe later
        </button>
    </div>
</ion-footer>
