export const contentPath = 'content';
export const contentCachePath = 'content/cache';
export const contentBreedsPath = 'content/breeds';
export const contentBreedGroupsPath = 'content/breed-groups';
export const contentTopicsPath = 'content/topics';
export const contentCoursesPath = 'content/courses';
export const contentDailyBitesPath = 'content/daily-bites';
export const contentDevelopmentalWindowsPath = 'content/developmental-windows';
export const contentFaqsPath = 'content/faqs';
export const contentStepsPath = 'content/steps';
export const contentChatTileContentPath = 'content/chat-tile-content';
export const contentChatTileTopicPath = 'content/chat-tile-topic';

export const contentCollection = `/api/${contentPath}`;
export const contentCacheCollection = `/api/${contentCachePath}`;
export const contentBreedCollection = `/api/${contentBreedsPath}`;
export const contentBreedGroupsCollection = `/api/${contentBreedGroupsPath}`;
export const contentTopicsCollection = `/api/${contentTopicsPath}`;
export const contentCoursesCollection = `/api/${contentCoursesPath}`;
export const contentDailyBitesCollection = `/api/${contentDailyBitesPath}`;
export const contentDevelopmentalWindowsCollection = `/api/${contentDevelopmentalWindowsPath}`;
export const contentFaqsCollection = `/api/${contentFaqsPath}`;
export const contentStepsCollection = `/api/${contentStepsPath}`;
export const contentChatTileTopicsCollection = `/api/${contentChatTileTopicPath}`;

export const contentEndpoints = {
    contentPath,
    contentCachePath,
    contentBreedPath: contentBreedsPath,
    contentBreedGroupsPath,
    contentTopicsPath,
    contentCoursesPath,
    contentDailyBitesPath,
    contentDevelopmentalWindowsPath,
    contentFaqsPath,
    contentStepsPath,
};

export const contentCollections = {
    contentCollection,
    contentCacheCollection,
    contentBreedCollection,
    contentBreedGroupsCollection,
    contentTopicsCollection,
    contentCoursesCollection,
    contentDailyBitesCollection,
    contentDevelopmentalWindowsCollection,
    contentFaqsCollection,
    contentStepsCollection,
    contentChatTileTopicsCollection,
};
