import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { LoadingState } from '@frontend/data-access/shared-models';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { pinnedTopicFeature } from '@frontend/data-access/user/pinned-topics';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { GenericCardSlimComponent, NoAccessComponent } from '@frontend/ui';
import { IonButton, IonCard, IonContent, IonHeader, IonToolbar, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CircleProgressOptions } from 'ng-circle-progress/lib/ng-circle-progress.component';
import { routeToAiChat, routeToCoachChat } from '../../../chat/chat.actions';
import { StepCardSlimComponent } from '../../../step/step-card-slim/step-card-slim.component';
import {
    selectSelectedTopic,
    selectSelectedTopicProgress,
    selectSelectedTopicStepDetails,
} from '../../../store/library/library.selector';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { selectContentUnlocked, selectIsBasicPlan } from '../../../store/payment/store/payment.selectors';
import { routeToStep } from '../../../store/router/router.actions';
import { pinTopic, unpinTopic } from '../../../store/topic/topic.actions';

@Component({
    selector: 'app-topic',
    templateUrl: 'topic.page.html',
    styleUrls: ['../../library.scss', './topic.scss'],
    standalone: true,
    imports: [
        IonContent,
        NgCircleProgressModule,
        StepCardSlimComponent,
        AnalyticsTrackClickDirective,
        GenericCardSlimComponent,
        AsyncPipe,
        TextReplacementPipe,
        IonCard,
        IonButton,
        HeaderComponent,
        IonHeader,
        IonToolbar,
        LowerCasePipe,
        NoAccessComponent,
    ],
})
export class TopicPage {
    private readonly store = inject(Store);
    private readonly navController = inject(NavController);

    public selectedTopic = this.store.selectSignal(selectSelectedTopic);
    public selectedTopicStepDetails = this.store.selectSignal(selectSelectedTopicStepDetails);
    public selectedTopicProgress = this.store.selectSignal(selectSelectedTopicProgress);
    public isContentUnlocked = this.store.selectSignal(selectContentUnlocked);
    public pinnedTopicsLoadingState = this.store.selectSignal(pinnedTopicFeature.selectLoadingState);
    public isBasicPlan = this.store.selectSignal(selectIsBasicPlan);

    public getCircleProgressOptions = computed(() => {
        const topic = this.selectedTopic();

        if (!topic) {
            return undefined as unknown as CircleProgressOptions;
        }

        const progress = this.selectedTopicProgress();

        const title = progress < 100 ? progress.toString() : '✓';
        const showUnits = progress < 100;
        const titleColor = progress < 100 ? 'var(--ion-color-paragraph-color)' : 'white';
        const outerStrokeColor = `var(--ion-color-${topic.color})`;
        const titleFontSize = progress < 100 ? '12' : '25';
        const backgroundColor = progress < 100 ? 'white' : `var(--ion-color-${topic.color})`;
        const innerStrokeWidth = progress < 100 ? 4 : 0;

        return {
            title,
            titleColor,
            titleFontSize,
            showSubtitle: false,

            units: '%',
            showUnits,
            percent: progress,
            clockwise: true,
            startFromZero: false,

            radius: 20,
            space: -4,

            showBackground: true,
            backgroundColor,
            backgroundPadding: 0,

            innerStrokeWidth,
            innerStrokeColor: '#F5EFEB',

            outerStrokeWidth: 4,
            outerStrokeColor,
            showZeroOuterStroke: false,

            animation: false,
            animateTitle: false,
            renderOnClick: false,
        } as CircleProgressOptions;
    });
    public getRightButtonIdentifier = computed(() => {
        const topic = this.selectedTopic();

        if (!topic) {
            return '';
        }

        return topic?.pinned
            ? `unpin-topic-${topic.entityId}-from-topic-page`
            : `pin-topic-${topic.entityId}-from-topic-page`;
    });
    public getRightButtonSprite = computed(() => {
        const topic = this.selectedTopic();

        if (!topic) {
            return '';
        }

        return topic?.pinned ? 'solid' : 'thin';
    });
    public getRightButtonIconName = computed(() => {
        const topic = this.selectedTopic();

        if (!topic) {
            return '';
        }

        return topic?.pinned ? 'circle-check' : 'circle-plus';
    });
    public getCoachChatButtonIdentifier = computed(() => {
        return this.isContentUnlocked() ? 'topic-page-expert-support-tile' : 'topic-page-expert-support-tile-locked';
    });
    public arePinnedTopicsLoading = computed(() => {
        return this.pinnedTopicsLoadingState() === LoadingState.LOADING;
    });

    protected readonly Color = Color;

    public onLeftButtonPressed(): void {
        void this.navController.navigateBack(['main', 'library']);
    }

    public onRightButtonPressed(): void {
        const topicEntityId = this.selectedTopic()?.entityId;
        const isPinned = !!this.selectedTopic()?.pinned;

        if (!this.selectedTopic() || !topicEntityId) {
            return;
        }

        if (isPinned) {
            this.store.dispatch(unpinTopic({ topicEntityId }));

            return;
        }

        if (!isPinned) {
            this.store.dispatch(pinTopic({ topicEntityId }));

            return;
        }
    }

    public onStepClick(stepId: string): void {
        const topic = this.selectedTopic();

        if (!topic) {
            return;
        }

        this.store.dispatch(
            routeToStep({
                baseUrl: ['main', 'library', 'topic', topic.entityId],
                stepId,
                from: 'library',
            }),
        );
    }

    public onZiggyChatClick(): void {
        this.store.dispatch(routeToAiChat({}));
    }

    public onCoachChatClick(): void {
        if (!this.isContentUnlocked()) {
            this.store.dispatch(showPaymentModal({ trigger: 'topic-page-expert-support-tile-locked' }));

            return;
        }

        this.store.dispatch(routeToCoachChat());
    }

    openPaymentModal(): void {
        return this.store.dispatch(showPaymentModal({ trigger: 'topic page' }));
    }
}
