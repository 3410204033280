@if (vm()) {
<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Sign in"
            titleColour="white"
            [leftButton]="{
                analyticsIdentifier:'login-back-btn',
                background: 'none',
                iconColour: 'white',
            }"
            [rightButton]="false"
            [isDisabled]="vm().isLoading"
            (leftButtonPressed)="onBackButtonPressed()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="login-page-ion-content authentication-ion-content" [fullscreen]="true">
    <main class="login-page page-content ion-justify-content-start">
        <section class="authentication-title-container">
            <h1>Log in to your Zigzag account</h1>
            <p data-test="login-page-subtitle">Let’s start training again!</p>
        </section>

        <form class="authentication-form authentication-form--max" [formGroup]="loginForm">
            <ion-item>
                <ion-input
                    class="zz-input"
                    formControlName="email"
                    type="email"
                    placeholder="Your email"
                    data-test="email-input"
                    [tabindex]="1"
                />
            </ion-item>

            @if (showEmailError()) {
            <app-invalid-input-error-message errorMessage="Please fill in a valid email" />
            }

            <ion-item class="authentication-form__password-item ion-margin-top">
                <ion-input
                    class="authentication-form__password-input zz-input"
                    #passwordInput
                    formControlName="password"
                    type="password"
                    placeholder="Password"
                    data-test="password-input"
                    [tabindex]="2"
                />

                @if (isPasswordVisible) {
                <button class="zz-button l" type="button" (click)="onPasswordVisibilityToggle()">
                    <i class="authentication-form__password-visibility fa-regular fa-eye-slash"></i>
                </button>
                } @else {
                <button class="zz-button l" type="button" (click)="onPasswordVisibilityToggle()">
                    <i class="authentication-form__password-visibility fa-regular fa-eye"></i>
                </button>
                }
            </ion-item>

            @if (!!vm().error) {
            <app-invalid-input-error-message
                [errorMessage]="vm().error!.message"
                [linkText]="vm().error!.link?.text"
                (linkClicked)="onErrorLinkClick(vm().error!.link?.route ?? [])"
            />
            }
        </form>

        @if (vm().isIos) {
        <ion-button
            class="authentication-button"
            appTrackClick
            identifier="auth-apple-btn"
            expand="block"
            data-test="auth-apple-btn"
            [color]="Color.Snowy"
            [disabled]="vm().isLoading"
            (click)="onSignInWithAppleClick()"
        >
            <h3 class="authentication-button__content">
                <i class="authentication-button__icon fa-brands fa-apple"></i>
                <span class="authentication-button__text">Continue with Apple</span>
            </h3>
        </ion-button>
        }

        <ion-button
            class="authentication-button"
            appTrackClick
            identifier="auth-google-btn"
            expand="block"
            data-test="auth-google-btn"
            [color]="Color.Snowy"
            [disabled]="vm().isLoading"
            (click)="onSignInWithGoogleClick()"
        >
            <h3 class="authentication-button__content">
                <img src="assets/images/third-party/google-logo-color.svg" alt="Continue with Google" />
                <span class="authentication-button__text">Continue with Google</span>
            </h3>
        </ion-button>
    </main>
</ion-content>

@if (!vm().isInputFocusedOnAndroid) {
<ion-footer class="login-page-footer authentication-ion-footer ion-text-center page-footer">
    <button
        class="zz-button login-page-footer__forgot-password"
        appTrackClick
        identifier="forgot-password"
        data-test="forgot-password"
        (click)="onForgotPasswordClick()"
    >
        Forgot your password?
    </button>
    <ion-button
        class="ion-button-color-app-background-max"
        appTrackClick
        identifier="sign-in-btn"
        expand="block"
        data-test="login-btn"
        [class.login-page-footer__sign-in-button--disabled]="!loginForm.valid"
        [disabled]="!loginForm.valid || vm().isLoading"
        (click)="onLoginClick()"
    >
        @if (vm().isLoading) {
        <ion-spinner name="crescent" />
        } @else {
        <div>Sign In</div>
        }
    </ion-button>
</ion-footer>
} }
