export interface UserFeedbackContent {
    id?: string;
    userId?: string;
    timestamp?: number;
    contentfulId: string;
    contentTypeId: string;
    thumbsDown: boolean | undefined;
    thumbsUp?: boolean | undefined;
    comment: string | undefined;
}
