import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { capacitorPushNotificationOpened, capacitorPushNotificationReceived } from '@frontend/data-access/capacitor';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class PushNotificationAnalyticsEffects {
    private readonly actions$ = inject(Actions);

    trackPushNotificationReceived$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(capacitorPushNotificationReceived),
            map(({ id }) => {
                return trackEvent({
                    eventName: '[Push Notification] Received',
                    eventProperties: {
                        pushNotificationId: id,
                    },
                });
            }),
        );
    });

    trackPushNotificationOpened$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(capacitorPushNotificationOpened),
            map(({ campaignId, messageId, templateId, urlToOpen }) => {
                return trackEvent({
                    eventName: '[Push Notification] Opened',
                    eventProperties: {
                        campaignId,
                        messageId,
                        templateId,
                        urlToOpen,
                    },
                });
            }),
        );
    });
}
