import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { HouseholdService } from '../services/household.service';
import { createHousehold, createHouseholdFailure, createHouseholdSuccess } from './household.actions';
import { mapInternalHouseholdDtoToHousehold } from '../utils/household.utils';
import { InternalApiError } from '../models/internal-api-error.model';

@Injectable()
export class CreateHouseholdEffects {
    private readonly actions$ = inject(Actions);
    private readonly householdService = inject(HouseholdService);

    createHousehold$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createHousehold),
            concatMap(({ id, accountId, email, countryCode, region, city, ownerName }) =>
                this.householdService
                    .createHousehold({ id, accountId, mail: email, countryCode, region, city, ownerName })
                    .pipe(
                        map((household) =>
                            createHouseholdSuccess({ household: mapInternalHouseholdDtoToHousehold(household) }),
                        ),
                        catchError((error: InternalApiError) => of(createHouseholdFailure({ error }))),
                    ),
            ),
        );
    });
}
