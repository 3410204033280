import { ArticleProgressType, LessonProgressType } from '@shared/user-domain';
import { createSelector } from '@ngrx/store';
import { stepProgressFeature } from './step-progress.reducer';

export const selectCompletedStepProgress = createSelector(stepProgressFeature.selectStepProgress, (stepProgress) =>
    stepProgress.filter(
        (progress) =>
            progress.progress === LessonProgressType.COMPLETED || progress.progress === ArticleProgressType.COMPLETED,
    ),
);

export const selectCompletedOrSkippedStepProgressIds = createSelector(
    stepProgressFeature.selectStepProgress,
    (stepProgresses) => {
        const completedOrSkippedStepIds = stepProgresses
            .filter(
                (stepProgress) =>
                    stepProgress.progress === LessonProgressType.COMPLETED ||
                    stepProgress.progress === LessonProgressType.SKIPPED ||
                    stepProgress.progress === ArticleProgressType.COMPLETED,
            )
            .map((stepProgress) => stepProgress.stepId);

        // only return unique values
        return [...new Set(completedOrSkippedStepIds)];
    },
);

export const selectCompletedStepIds = createSelector(selectCompletedStepProgress, (stepProgress) => {
    const completedStepIds = stepProgress.map((step) => step.stepId);

    // only return unique values
    return [...new Set(completedStepIds)];
});

export const selectCompletedStepsCount = createSelector(selectCompletedStepIds, (completedIds) => completedIds.length);
export const selectIsFirstCompletedStep = createSelector(
    selectCompletedStepsCount,
    (completedSteps) => completedSteps === 0,
);
