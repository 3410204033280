import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AudioPlayerComponent } from '@frontend/feature/audio-player';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { FilterPipe, GenericCardSlimComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color, emptyString, isStringNumber } from '@shared/utils/typescript';
import { tap } from 'rxjs/operators';
import { ChatTileComponent } from '../../../chat/chat-tile/chat-tile.component';
import { DynamicContentBlockComponent } from '../../../step/dynamic-content-block/dynamic-content-block.component';
import { launchTrainingReminderModal } from '../../../training-reminder/store/training-reminder-modal.actions';
import { selectSelectedLessonPagesVm } from '../lesson.page.selectors';

// TODO: NBSon - remove nav controller and modal service from this component, use effects
@Component({
    selector: 'app-lesson-instruction-page',
    templateUrl: './lesson-instruction-page.component.html',
    styleUrls: ['./lesson-instruction-page.component.scss', '../lesson.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        AudioPlayerComponent,
        ChatTileComponent,
        DynamicContentBlockComponent,
        FilterPipe,
        GenericCardSlimComponent,
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        TextReplacementPipe,
    ],
})
export class LessonInstructionPage {
    private readonly store = inject(Store);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly navController = inject(NavController);
    private readonly sanitizer = inject(DomSanitizer);

    public vm$ = this.store.select(selectSelectedLessonPagesVm);

    public videoUrl: SafeResourceUrl | undefined;

    protected readonly Color = Color;
    protected readonly emptyString = emptyString;

    constructor() {
        this.vm$
            .pipe(
                takeUntilDestroyed(),
                tap((vm) => {
                    if (vm.step && vm.step.content) {
                        this.setVideoUrl(vm.step.content.videoId);
                    }
                }),
            )
            .subscribe();
    }

    public nextPage(): void {
        void this.navController.navigateForward(['../rating'], {
            animated: true,
            relativeTo: this.activatedRoute,
            queryParams: this.activatedRoute.snapshot.queryParams,
        });
    }

    public previousPage(): void {
        this.navController.back();
    }

    public onCloseButtonClicked(): void {
        // TODO: NBSon - look for eslint rule to prevent this, we should use proper paths
        void this.navController.navigateBack(['../../../../'], {
            relativeTo: this.activatedRoute,
            animated: false,
        });
    }

    public setTrainingReminder(dogName: string): void {
        this.store.dispatch(launchTrainingReminderModal({ dogName, trigger: 'lesson-instruction-page' }));
    }

    private setVideoUrl(id: string): void {
        this.videoUrl = isStringNumber(id)
            ? this.sanitizer.bypassSecurityTrustResourceUrl(
                  `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&texttrack=en`,
              )
            : undefined;
    }
}
