<div
    [ngStyle]="{ transform: 'translate(' + step().coordinates.x + 'px,' + step().coordinates.y + 'px)' }"
    class="course-path-step"
    [attr.data-test]="'course-path-step-' + step().id"
>
    <div class="course-path-step__inner-container">
        @if (step().topic && step().progress) {
            <div
                class="course-path-step__step-container"
                [ngClass]="{ 'course-path-step__step-container--animated': step().isFirstInactiveStep }"
                [ngStyle]="step().isFirstInactiveStep ? setBorderAnimationColour() : null"
                (click)="goToStep()"
                data-test="course-path-step-icon"
            >
                @if (!step().progress.isPractice) {
                    <div class="course-path-step__rating-container">
                        @if (step().progress.rating && step().progress.state !== StepStatus.INACTIVE && unlocked()) {
                            <div class="course-path-step__circle-container">
                                @for (number of [1, 2, 3, 4, 5]; track number) {
                                    @if (number <= step()?.progress.rating) {
                                        <div class="course-path-step__star-container">
                                            <i class="course-path-step__star fa-solid fa-star"></i>
                                        </div>
                                    } @else {
                                        <div class="course-path-step__star-container">
                                            <i class="course-path-step__empty-star fa-solid fa-star"></i>
                                        </div>
                                    }
                                }
                            </div>
                        }
                    </div>
                } @else {
                    <img
                        class="course-path-step__practice-image image-no-max-width"
                        [src]="'/assets/images/illustrations/step/step-practice-text.svg'"
                        alt="This is a practice lesson"
                    />
                }
                <div>
                    <img
                        class="course-path-step__image course-path-step__{{ setImageClass() }}"
                        [ngClass]="
                            !unlocked()
                                ? 'course-path-step__image--locked'
                                : 'course-path-step__image--' + step().progress.state
                        "
                        [ngSrc]="step().thumbnailUrl"
                        fill="true"
                        loading="eager"
                        alt="step-image"
                    />
                    @if (unlocked()) {
                        @if (!step().progress.isPractice) {
                            @if (step().progress.state === StepStatus.READ) {
                                <div
                                    class="course-path-step__state-icon-container course-path-step__state-icon-container--read"
                                >
                                    <i
                                        [attr.data-test]="'icon-' + step().progress.state"
                                        class="course-path-step__state-icon fa-solid fa-glasses"
                                    ></i>
                                </div>
                            }
                            @if (step().progress.state === StepStatus.SKIPPED) {
                                <div
                                    class="course-path-step__state-icon-container course-path-step__state-icon-container--skipped"
                                >
                                    <i
                                        [attr.data-test]="'icon-' + step().progress.state"
                                        class="course-path-step__state-icon fa-solid fa-forward"
                                    ></i>
                                </div>
                            }
                            @if (step().progress.state === StepStatus.COMPLETED) {
                                <div
                                    class="course-path-step__state-icon-container course-path-step__state-icon-container--completed"
                                >
                                    <i
                                        [attr.data-test]="'icon-' + step().progress.state"
                                        class="course-path-step__state-icon fa-solid fa-check"
                                    ></i>
                                </div>
                            }
                        }
                    } @else {
                        <div
                            class="course-path-step__state-icon-container course-path-step__state-icon-container--locked"
                        >
                            <i class="course-path-step__state-icon fa-solid fa-lock" data-test="icon-locked"></i>
                        </div>
                    }
                </div>
            </div>
        }
        <p class="course-path-step__step-title ignore-screen-based-font-scaling xs">
            {{ step().content.title | textReplacement }}
        </p>
    </div>
</div>
