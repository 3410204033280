import { Component, computed, inject, signal, Signal } from '@angular/core';
import { PurchaseProduct, purchaseProduct, restoreSubscription } from '@frontend/data-access/subscription';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { PaymentModalPlanFeaturesListComponent } from '../payment-modal-plan-features-list/payment-modal-plan-features-list.component';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentModalTermsComponent } from '../payment-modal-terms/payment-modal-terms.component';
import { defaultPlanDescriptionList } from '../payment-modal.constant';
import { selectPaymentModalDefaultVm } from './payment-modal-default.selectors';
import { PaymentProductCard } from '../payment-modal-product/payment-product-card.model';

@Component({
    standalone: true,
    selector: 'app-payment-modal-default',
    templateUrl: './payment-modal-default.component.html',
    styleUrl: './payment-modal-default.component.scss',
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        PaymentModalProductComponent,
        PaymentModalTermsComponent,
        PaymentModalPlanFeaturesListComponent,
    ],
})
export class PaymentModalDefaultComponent {
    private readonly store = inject(Store);
    protected readonly defaultFeaturesList = defaultPlanDescriptionList;

    public vm = this.store.selectSignal(selectPaymentModalDefaultVm);

    public initialSelectedProduct: Signal<PurchaseProduct> = computed(() => {
        return {
            identifier: this.vm().defaultProducts?.[0]?.identifier,
            priceWithUnit: this.vm().defaultProducts?.[0]?.priceWithUnit,
        };
    });

    public selectedProduct = signal<PurchaseProduct>(this.initialSelectedProduct());

    public onProductClick(product: PaymentProductCard): void {
        this.selectedProduct.set({ identifier: product.identifier, priceWithUnit: product.priceWithUnit });
    }

    public onPurchaseClick(product: PurchaseProduct): void {
        this.store.dispatch(purchaseProduct({ data: product }));
    }

    public onRestoreClick(): void {
        this.store.dispatch(restoreSubscription());
    }
}
