import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getChatTileTopics, getChatTileTopicsFailure, getChatTileTopicsSuccess } from './chat-tile.actions';

@Injectable()
export class ChatTileEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);

    getChatTileTopics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChatTileTopics),
            switchMap(({ params }) => {
                return this.contentService.getChatTileTopics(params).pipe(
                    map((chatTileTopics) => {
                        return getChatTileTopicsSuccess({ chatTileTopics });
                    }),
                    catchError((error: Error) => {
                        return of(getChatTileTopicsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
