import { Course, SeasonalTopic } from '@frontend/data-access/contentful';
import { FrontendCourseProgressEntry } from '@frontend/data-access/user/progress';
import { OptionalCourseId } from '@shared/constants';
import { CourseType } from '@shared/content-api-interface';
import { CourseProgressType } from '@shared/user-domain';

export const filterCoursesForTodayPage = (
    courses: Course[],
    hasArrived: boolean,
    ageInWeeks: number | undefined,
    isRescue: boolean | undefined,
    progress: FrontendCourseProgressEntry[],
    seasonalTopics: SeasonalTopic[],
): (Course | SeasonalTopic)[] => {
    let result: (Course | SeasonalTopic)[] = [];

    const separationFoundationsCourse = courses.filter(
        (course) => course.id === OptionalCourseId.SEPARATION_FOUNDATIONS,
    );

    if (!courses.length || hasArrived === undefined || ageInWeeks === undefined || isRescue === undefined) {
        return result;
    }

    // Pre-pup
    if (!hasArrived) {
        const prePupCourse = courses.filter((course) => course.type === CourseType.PRE_PUP);
        result.push(...prePupCourse);
    }

    // Foundational
    const foundationalCourses = courses.filter((course) => course.type === CourseType.FOUNDATIONAL);
    result.push(...foundationalCourses);

    if (!hasArrived) {
        return result;
    }

    // Separation Foundations for 6 months+
    if (ageInWeeks >= 26) {
        result.push(...separationFoundationsCourse);
    }

    // Pup Master
    if (ageInWeeks >= 0 && ageInWeeks <= 25) {
        const pupMasterCourse = courses.filter((course) => course.id === OptionalCourseId.PUP_MASTER);
        result.push(...pupMasterCourse);
    }

    // Terrible Teens
    if (ageInWeeks >= 12 && ageInWeeks <= 51 && !isRescue) {
        const terribleTeensCourse = courses.filter((course) => course.id === OptionalCourseId.TERRIBLE_TEENS);
        result.push(...terribleTeensCourse);
    }

    // Trick Starter
    if (ageInWeeks >= 26) {
        const trickStarterCourse = courses.filter((course) => course.id === OptionalCourseId.TRICK_STARTER);
        result.push(...trickStarterCourse);
    }

    // Separation Foundations for 12 weeks - 6 months
    if (ageInWeeks >= 12 && ageInWeeks < 26) {
        result.push(...separationFoundationsCourse);
    }

    if (progress && progress.length) {
        progress.forEach((progress) => {
            if (progress.progressType === CourseProgressType.COMPLETED) {
                result = result.filter((course) => course.id !== progress.courseId);
            }
        });
    }

    if (seasonalTopics && seasonalTopics.length) {
        result.splice(1, 0, ...seasonalTopics);
    }

    return result;
};
