import { MultiSelectOption, MultiSelectOptionValue } from './multi-select.model';

export class MultiSelectOptionMock<TValue = MultiSelectOptionValue> {
    private defaultValue: MultiSelectOption<TValue> = {
        title: 'multi select',
        value: 'value' as TValue,
        image: 'imageUrl',
        alt: 'altText',
        backgroundColor: 'max',
    };

    constructor(overrides?: Partial<MultiSelectOption<TValue>>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): MultiSelectOption<TValue> {
        return this.defaultValue;
    }
}
