import { createSelector } from '@ngrx/store';
import { getContentfulLocaleFromCountryCode } from '@shared/constants';
import {
    householdFeature,
    selectAgeInWeeksAtOnboarding,
    selectCountryCode,
    selectHasArrived,
    selectHasArrivedOnDateOfOnboarding,
} from '@frontend/data-access/user/household';

export const selectGetContentInfo = createSelector(
    householdFeature.selectBreedId,
    selectCountryCode,
    selectAgeInWeeksAtOnboarding,
    selectHasArrivedOnDateOfOnboarding,
    householdFeature.selectIsRescuePup,
    selectHasArrived,
    (breedId, countryCode, ageInWeeks, hasArrivedOnDateOfOnboarding, isRescue, hasArrived) => {
        return {
            breedId,
            locale: getContentfulLocaleFromCountryCode(countryCode),
            ageInWeeks,
            hasArrivedOnDateOfOnboarding,
            isRescue,
            hasArrived,
        };
    },
);

export const selectProfileForGetContent = createSelector(
    householdFeature.selectCurrentUser,
    householdFeature.selectCurrentDog,
    (user, dog) => {
        return {
            countryCode: user?.countryCode,
            breedId: dog?.breedId,
            dateOfBirth: dog?.dateOfBirth,
            hasArrived: dog?.hasArrived,
            isRescuePup: dog?.isRescue,
        };
    },
);
