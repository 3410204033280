import { Color } from '@shared/utils/typescript';
import { Asset, Entry } from 'contentful';
import { ContentfulChatTileContent } from './contentful-chat-tile-content.model';

export interface ContentfulChatTileTopic {
    id: string;
    title: string;
    image: Asset;
    colour: Color;
    chatTileContent: Entry<ContentfulChatTileContent>[];
    ageBracket: string;
    isPupAtHome: boolean;
    isPupRescue: boolean;
}
