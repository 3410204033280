export interface User {
    id: string;
    email: string;
    ownerName?: string;
    signInProvider: string; // Apple or Android for example
}

export interface TotpSecret {
    key: string;
    url: string;
}
