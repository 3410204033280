import { IterablePostEventCommand } from '../commands/iterable-post-event.command';

export class InternalIterablePostEventCommandMock {
    private defaultValue: IterablePostEventCommand = {
        userId: 'user-id',
        eventName: '[Event Source] Event Name',
        createdAt: 1,
        dataFields: {
            important: 'value',
        },
    };

    constructor(overrides?: Partial<IterablePostEventCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostEventCommand {
        return this.defaultValue;
    }
}
