import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { dismissInfo } from '@frontend/data-access/dismissible-info';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { DatetimeComponent } from '@frontend/ui';
import { IonButton, IonCard, IonContent, IonLabel, IonRange } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { PUP_HAPPINESS_MODAL_ID } from './pup-happiness-modal.constant';
import { pupHappinessButtonClicked, submitPupHappinessForm } from './store/pup-happiness-modal.actions';

@Component({
    standalone: true,
    selector: 'app-pup-happiness-modal',
    templateUrl: './pup-happiness-modal.component.html',
    styleUrl: './pup-happiness-modal.component.scss',
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        NgClass,
        IonButton,
        IonCard,
        IonRange,
        IonLabel,
        ReactiveFormsModule,
        DatetimeComponent,
    ],
})
export class PupHappinessModalComponent {
    public dogName = input.required<string>();

    private store = inject(Store);

    protected readonly Color = Color;
    protected readonly defaultValue = 0;

    public dailyPupHappinessForm = new FormGroup({
        ownerHappinessScore: new FormControl<number>(this.defaultValue, { nonNullable: true }),
        pupHappinessScore: new FormControl<number>(this.defaultValue, { nonNullable: true }),
    });

    public buttonClicked(buttonId: string): void {
        this.store.dispatch(pupHappinessButtonClicked({ buttonId }));
    }

    public submitForm(): void {
        this.store.dispatch(
            submitPupHappinessForm({
                ownerHappinessScore: this.dailyPupHappinessForm.controls.ownerHappinessScore.value / 10,
                dogHappinessScore: this.dailyPupHappinessForm.controls.pupHappinessScore.value / 10,
            }),
        );

        this.buttonClicked('Submit');
    }

    public dontShowAgain(): void {
        this.store.dispatch(
            dismissInfo({
                id: PUP_HAPPINESS_MODAL_ID,
                dontShowAgain: true,
            }),
        );

        this.buttonClicked('Dont Show Again');
    }
}
