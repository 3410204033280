import { SaIterableResults } from '../../commands/user/sa-iterable-upsert-user.command';

export class InternalSaIterableResultFieldsMock {
    private defaultValue: SaIterableResults = {
        exit_frustration: 3,
        boredom: 90,
        redirect_reactive: 2,
        reactive_inhibited: 0,
    };

    constructor(overrides?: Partial<SaIterableResults>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SaIterableResults {
        return this.defaultValue;
    }
}
