import { NgModule } from '@angular/core';
import { ZigzagFrontendDataAccessAdjustModule } from '@frontend/data-access/adjust';
import { ZigzagFrontendDataAccessAnalyticsModule } from '@frontend/data-access/analytics';
import { ZigzagFrontendDataAccessCapacitorModule } from '@frontend/data-access/capacitor';
import { ZigzagFrontendDataAccessChatModule } from '@frontend/data-access/chat';
import { ZigzagFrontendDataAccessContentfulModule } from '@frontend/data-access/contentful';
import { ZigzagFrontendFeatureDismissibleInfoModule } from '@frontend/data-access/dismissible-info';
import { ZigzagFrontendDataAccessUserFeedbackContentModule } from '@frontend/data-access/feedback';
import { ZigzagFrontendDataAccessGeoLocationModule } from '@frontend/data-access/geo-location';
import { ZigzagFrontendDataAccessHappinessCheckModule } from '@frontend/data-access/happiness-check';
import { ZigzagFrontendDataAccessKeyboardModule } from '@frontend/data-access/keyboard';
import { ZigzagFrontendDataAccessLocalNotificationModule } from '@frontend/data-access/local-notification';
import { ZigzagFrontendDataAccessPlatformModule } from '@frontend/data-access/platform';
import { ZigzagFrontendDataAccessPushNotificationModule } from '@frontend/data-access/push-notification';
import { ZigzagFrontendDataAccessRouterModule } from '@frontend/data-access/router';
import { ZigzagFrontendDataAccessSubscriptionModule } from '@frontend/data-access/subscription';
import { ZigzagFrontendDataAccessTimeModule } from '@frontend/data-access/time';
import { ZigzagFrontendDataAccessUserAccountModule } from '@frontend/data-access/user/account';
import { ZigzagFrontendDataAccessUserAuthenticationModule } from '@frontend/data-access/user/authentication';
import { ZigzagFrontendDataAccessUserConfigCatModule } from '@frontend/data-access/user/config-cat';
import { ZigzagFrontendDataAccessHouseholdModule } from '@frontend/data-access/user/household';
import { ZigzagFrontendDataAccessPinnedTopicsModule } from '@frontend/data-access/user/pinned-topics';
import {
    ZigzagFrontendDataAccessUserCourseProgressModule,
    ZigzagFrontendDataAccessUserRescheduledStepModule,
    ZigzagFrontendDataAccessUserStepProgressModule,
    ZigzagFrontendDataAccessUserTodayCourseProgressModule,
} from '@frontend/data-access/user/progress';
import { ZigzagFrontendDataAccessUuidModule } from '@frontend/data-access/uuid';
import { ZigzagFrontendFeatureProfileImageModule } from '@frontend/feature/profile-picture';

@NgModule({
    imports: [
        ZigzagFrontendDataAccessAdjustModule,
        ZigzagFrontendDataAccessAnalyticsModule,
        ZigzagFrontendDataAccessCapacitorModule,
        ZigzagFrontendDataAccessChatModule,
        ZigzagFrontendDataAccessContentfulModule,
        ZigzagFrontendDataAccessGeoLocationModule,
        ZigzagFrontendDataAccessKeyboardModule,
        ZigzagFrontendDataAccessPlatformModule,
        ZigzagFrontendDataAccessPushNotificationModule,
        ZigzagFrontendDataAccessRouterModule,
        ZigzagFrontendDataAccessSubscriptionModule,
        ZigzagFrontendDataAccessTimeModule,
        ZigzagFrontendDataAccessUserAccountModule,
        ZigzagFrontendDataAccessUserAuthenticationModule,
        ZigzagFrontendDataAccessUserConfigCatModule,
        ZigzagFrontendDataAccessUserCourseProgressModule,
        ZigzagFrontendDataAccessUserRescheduledStepModule,
        ZigzagFrontendDataAccessUserStepProgressModule,
        ZigzagFrontendDataAccessUserTodayCourseProgressModule,
        ZigzagFrontendDataAccessUuidModule,
        ZigzagFrontendFeatureDismissibleInfoModule,
        ZigzagFrontendFeatureProfileImageModule,
        ZigzagFrontendDataAccessPinnedTopicsModule,
        ZigzagFrontendDataAccessHouseholdModule,
        ZigzagFrontendDataAccessUserFeedbackContentModule,
        ZigzagFrontendDataAccessLocalNotificationModule,
        ZigzagFrontendDataAccessHappinessCheckModule,
    ],
})
export class ZigzagMainModule {}
