import { createAction, props } from '@ngrx/store';

export const initMobileAppSentry = createAction('[Sentry] Init Mobile App Sentry', props<{ platform: string }>());

export const setUserMobileAppSentry = createAction(
    '[Sentry] Set User Mobile App Sentry',
    props<{ userId: string; email: string }>(),
);

export const initSupportPortalSentry = createAction('[Sentry] Init Support Portal Sentry');
